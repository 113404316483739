import { Injectable } from "@angular/core";
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { ApiResponse } from "../models/api-response";

@Injectable()
export class ImageService {

  constructor(private http: HttpClient) {

  }

  deleteInspectionImage(inspectionId, filename) : Promise<any> {
    const headers = {
      'Content-Type': 'application/json',
    };


    return new Promise((resolve, reject) => {
      this.http.delete<any>(environment.apiUrl + "image/deleteinspectionimage?inspectionId=" + inspectionId + "&filename=" + filename, { headers: headers }).subscribe(data => {
        resolve(data);
      }, (error) => {
        resolve(null)
      });
    });
  }


  getInspectionImages(inspectionId) : Promise<any> {
    const headers = {
      'Content-Type': 'application/json',
    };


    return new Promise((resolve, reject) => {
      this.http.get<any>(environment.apiUrl + "image/getinspectionimages?inspectionId=" + inspectionId, { headers: headers }).subscribe(data => {
        resolve(data);
      }, (error) => {
        resolve(null)
      });
    });
  }

  uploadInspectionImage(base64, inspectionId, fileName): Promise<ApiResponse> {
    const headers = {
      'Content-Type': 'application/json',
    };

    var dto = {
      file: base64,
      inspectionId: inspectionId,
      fileName: fileName
    }

    console.log(dto);

    return new Promise((resolve, reject) => {
      this.http.post<ApiResponse>(environment.apiUrl + "image/uploadinspectionimage", dto, { headers: headers }).subscribe(data => {
        resolve(data);
      }, (error) => {
        resolve(null)
      });
    });
  }
}