<div class="inspection-preview" [ngClass]="{'small' : small}" (click)="export(inspection.inspectionId)">
  <img *ngIf="inspection?.inServiceFormatted == 'Complies'" src="/assets/svgs/arrows_circle_check_colour.svg"/>
  <img *ngIf="inspection?.inServiceFormatted == 'Does Not Comply'" src="/assets/svgs/basic_ban_colour.svg"/>
  <img *ngIf="inspection?.inServiceFormatted == 'Out Of Service'" src="/assets/svgs/basic_exclamation_colour.svg"/>

  <label class="in-service">{{ inspection.inServiceFormatted }}</label>
  <div class="date" *ngIf="!small">
    <label>{{ inspection.inspectionDate | date: 'dd MMMM yyyy'}}</label>
  </div>
  <div class="date small-date" *ngIf="small">
    <label>{{ inspection.inspectionDate | date: 'dd MMMM'}}</label>
    <label class="year">{{ inspection.inspectionDate | date: 'yyyy'}}</label>
  </div>
</div>
