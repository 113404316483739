import { Component, ElementRef, EventEmitter, forwardRef, Input, KeyValueDiffer, KeyValueDiffers, OnChanges, OnInit, Output, Renderer2, SimpleChange, SimpleChanges } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-label-control',
  templateUrl: './label-control.component.html',
  styleUrls: ['./label-control.component.scss'],
  providers: [
    { 
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => LabelControlComponent),
      multi: true
    }
  ]
})
export class LabelControlComponent implements ControlValueAccessor {
  @Input() title: string = "";
  @Input() data = [];

  @Input() keyPairValue = "id";
  @Input() keyPairName = "name";

  @Output() selectedIds = new EventEmitter<any>();
  
  isModalOpen: boolean = false;

  touched = false;
  disabled = false;

  searchFilter: string = "";

  onChange = (data) => {console.log("ONCHANGE")};
  onTouched = () => {console.log("TOUCHED")};

  constructor(private renderer: Renderer2, private el: ElementRef, private differs: KeyValueDiffers) {
    this.renderer.listen('window', 'click', (e: Event) => {
      if (e.target !== el.nativeElement && !el.nativeElement.contains(e.target) && this.isModalOpen) {
        this.isModalOpen = false;
      }
    })
  }
  

  openModal() {
    if (this.disabled)
      return;

    this.isModalOpen = true;
  }
  
  closeModal() {
    this.isModalOpen = false;
  }

  toggleItem(item) {
    if (item.selected == null)
      item.selected = false;

    item.selected = !item.selected;

    this.markAsTouched();
    this.onChange(this.data);
    
    var selectedItems = this.data.filter(s => s.selected == true);
    var selectedIds = [];
    for (let item of selectedItems) {
      selectedIds.push(item[this.keyPairValue].toString());
    }

    this.selectedIds.emit(selectedIds);
  }

  getItemName(item: any) {
    return item[this.keyPairName];
  }
  
  writeValue(obj: any): void {
    console.log(obj);
    this.data = obj;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  markAsTouched() {
    if (this.touched) {
      this.onTouched();
      this.touched = true;
    }
  }

}
