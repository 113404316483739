import { ViewChild } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { tap } from 'rxjs/operators';
import { PDAUser } from 'src/app/models/pda-user';
import { DataServiceParam } from 'src/app/service-data-sources/data-table/data-service-param';
import { TableDataSource } from 'src/app/service-data-sources/data-table/table-data-source';
import { PDAUserService } from 'src/app/services/administration/pda-user.service';
import { PdaUserManageComponent } from './pda-user-manage/pda-user-manage.component';

@Component({
  selector: 'app-pda-user-management',
  templateUrl: './pda-user-management.component.html',
  styleUrls: ['./pda-user-management.component.scss']
})
export class PdaUserManagementComponent implements OnInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  
  dataSource: TableDataSource<PDAUser>;
  displayedColumns: string[] = ['firstname', 'lastname', 'email', 'expired', 'option'];

  filter: string = "";

  constructor(private pdaUserService: PDAUserService, public dialog: MatDialog) { }

  ngOnInit(): void {
    this.dataSource = new TableDataSource(this.pdaUserService);
    this.loadData();
  }

  ngAfterViewInit() {
    this.paginator.page.pipe(tap(() => this.loadDataPage())).subscribe();  
  }

  loadData() {
    var params: DataServiceParam[] = [];
    params.push(<DataServiceParam>{ paramName: 'filter', paramValue: this.filter });
    this.dataSource.loadData(params);
  }

  loadDataPage() {
    var params: DataServiceParam[] = [];
    params.push(<DataServiceParam>{ paramName: 'filter', paramValue: this.filter });
    this.dataSource.loadData(params, 'asc', this.paginator.pageIndex, this.paginator.pageSize);
  }

  add() {
    const dialogRef = this.dialog.open(PdaUserManageComponent, {
      width: '900px',
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.loadData();
      }
    });
  }

  edit(pdaUserId) {
    const dialogRef = this.dialog.open(PdaUserManageComponent, {
      width: '900px',
      data: {pdaUserId: pdaUserId}
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.loadData();
      }
    });
  }
}
