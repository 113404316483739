<div class="modal-panel" *ngIf="equipmentForm != null">
  <form [formGroup]="equipmentForm" class="form-manage">
    <div class="header">
      <h1>Equipment Management</h1>
    </div>

    <div class="form-controls">

      <!--<div class="panel-section">
    <app-search-select [(ngModel)]="filter.categoryId" [label]="'Categories'" [list]="categories" [value]="'categoryId'" [name]="'categoryName'"></app-search-select>
  </div>-->

      <div class="form-input">
        <app-search-select formControlName="locationId" [label]="'Locations'" [list]="locations" [value]="'locationId'"
                           [name]="'name'"></app-search-select>

        <!--<mat-form-field appearance="fill">
      <mat-label class="form-label">Location</mat-label>
      <mat-select formControlName="locationId">
        <mat-option [value]="location.locationId" *ngFor="let location of locations">{{ location.name }}</mat-option>
      </mat-select>
    </mat-form-field>-->
      </div>

      <div class="form-input">
        <app-search-select formControlName="descriptionList" [label]="'Descriptions'" [list]="descriptions" [value]="'descriptionText'"
                           [name]="'descriptionText'" (onChanged)="onDescriptionChange($event)"></app-search-select>
        <!--<mat-form-field appearance="fill">
      <mat-label class="form-label">Descriptions</mat-label>
      <mat-select formControlName="descriptionList" (selectionChange)="onDescriptionChange($event)">
        <mat-option value="">Other</mat-option>
        <mat-option [value]="description.descriptionText" *ngFor="let description of descriptions">{{ description.descriptionText }}</mat-option>
      </mat-select>
    </mat-form-field>-->
      </div>

      <div class="form-input" *ngIf="equipmentForm.controls.descriptionList.value == 'Other'">
        <mat-form-field appearance="fill">
          <mat-label class="form-label">Descriptions (Freetext)</mat-label>
          <input matInput formControlName="description">
        </mat-form-field>
      </div>

      <div class="form-input">
        <app-search-select formControlName="categoryId" [label]="'Categories'" [list]="categories" [value]="'categoryId'"
                           [name]="'categoryName'"></app-search-select>
        <!--<mat-form-field appearance="fill">
        <mat-label class="form-label">Categories</mat-label>
        <mat-select formControlName="categoryId">
          <mat-option [value]="category.categoryId" *ngFor="let category of categories">{{ category.categoryName }}</mat-option>
        </mat-select>
      </mat-form-field>-->
      </div>

      <div class="form-input">
        <mat-form-field appearance="fill">
          <mat-label class="form-label">Serial #</mat-label>
          <div class="input-with-button">
            <input matInput formControlName="serialNum">
            <button mat-button (click)="onUploadClick()">Upload</button>
          </div>
        </mat-form-field>
      </div>

      <div class="form-input" *ngIf="showFileUpload">
        <mat-form-field appearance="fill">
          <mat-label class="form-label">File</mat-label>
          <div class="input-with-button">
            <button mat-icon-button matPrefix class="attach-file-button" style="margin-left: 10%;" (click)="f_input.click()">
              <mat-icon class="attach-file-icon">attach_file</mat-icon>
            </button>
            <input type="text" readonly matInput [formControl]="display" />
            <input type="file"
                   multiple
                   hidden
                   #f_input
                   (change)="handleFileInputChange(f_input.files)" />
            <mat-card-actions align="end">
              <button mat-stroked-button color="primary" [disabled]="display.invalid" (click)="handleSubmit()">
                Save
              </button>
            </mat-card-actions>
          </div>
        </mat-form-field>
      </div>


      <div class="form-input">
        <mat-form-field appearance="fill">
          <mat-label class="form-label">Asset #</mat-label>
          <input matInput formControlName="assetNum">
        </mat-form-field>
      </div>

      <div class="form-input">
        <mat-form-field appearance="fill">
          <mat-label class="form-label">Load Test To</mat-label>
          <input matInput formControlName="loadTestTo">
        </mat-form-field>
      </div>

      <div class="form-input">
        <mat-form-field appearance="fill">
          <mat-label class="form-label">Working Load Limit</mat-label>
          <input matInput formControlName="wll">
        </mat-form-field>
      </div>

      <div class="form-input">
        <app-search-select formControlName="australianStdId" [label]="'Australian Stds'" [list]="ausStds" [value]="'australianStdId'"
                           [name]="'australianStd'"></app-search-select>
        <!--<mat-form-field appearance="fill">
        <mat-label class="form-label">Australian Stds</mat-label>
        <mat-select formControlName="australianStdId">
          <mat-option [value]="ausStd.australianStdId" *ngFor="let ausStd of ausStds">{{ ausStd.australianStd }}</mat-option>
        </mat-select>
      </mat-form-field>-->
      </div>

      <div class="form-input">
        <mat-form-field appearance="fill">
          <mat-label>Date of Manuf</mat-label>
          <input matInput formControlName="dateOfManuf" [matDatepicker]="picker">
          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
        </mat-form-field>
      </div>

      <div class="form-input">
        <mat-form-field appearance="fill">
          <mat-label class="form-label">Owner</mat-label>
          <mat-select formControlName="ownerId">
            <mat-option [value]="1">Unknown</mat-option>
            <mat-option [value]="2">Ranger Owned</mat-option>
            <mat-option [value]="3">Customer Owned</mat-option>
          </mat-select>
        </mat-form-field>
      </div>



      <div class="form-input">
        <mat-form-field appearance="fill">
          <mat-label class="form-label">Client Asset #</mat-label>
          <input matInput formControlName="clientAssetNumber">
        </mat-form-field>
      </div>

      <div class="form-input">
        <mat-form-field appearance="fill">
          <mat-label class="form-label">Department</mat-label>
          <input matInput formControlName="department">
        </mat-form-field>
      </div>
      <div class="form-input">
        <mat-form-field appearance="fill">
          <mat-label class="form-label">Other Details</mat-label>
          <textarea matInput formControlName="otherDetails"></textarea>
          <!--<input matInput formControlName="otherDetails">-->
        </mat-form-field>
      </div>
      <div class="form-input">
        <mat-checkbox formControlName="newIntoService">New Into Service</mat-checkbox>
      </div>

      <div class="form-input">
        <mat-checkbox formControlName="decommisioned">Decommisioned</mat-checkbox>
      </div>
    </div>

    <div class="form-controls">
      <div class="form-input">
        <app-search-select formControlName="batchTypeId" [label]="'Batch Type'" [list]="batchTypes" [value]="'batchTypeId'"
                           [name]="'type'"></app-search-select>
        <!--<mat-form-field appearance="fill">
            <mat-label class="form-label">Batch Type</mat-label>
            <mat-select formControlName="batchTypeId">
              <mat-option [value]="type.batchTypeId" *ngFor="let type of batchTypes">{{ type.type }}</mat-option>
            </mat-select>
          </mat-form-field>-->
      </div>

      <div class="form-input">
        <app-search-select formControlName="batchManufId" [label]="'Batch Manufacturer'" [list]="batchManf" [value]="'batchManufId'"
                           [name]="'batchManuf'"></app-search-select>
        <!--<mat-form-field appearance="fill">
            <mat-label class="form-label">Batch Manufacturer</mat-label>
            <mat-select formControlName="batchManufId">
              <mat-option [value]="batch.batchManufId" *ngFor="let batch of batchManf">{{ batch.batchManuf }}</mat-option>
            </mat-select>
          </mat-form-field>-->
      </div>
      <div class="form-input">
        <mat-form-field appearance="fill">
          <mat-label class="form-label">Batch #</mat-label>
          <input matInput formControlName="batchNum">
        </mat-form-field>
      </div>
      <div class="form-input">
        <button type="button" class="add-btn" style="align-self: baseline;" (click)="addBatch()">Add Batch</button>
      </div>
    </div>

    <div style="max-height: 25vh; overflow-y: auto;" *ngIf="batchs.length > 0">
      <table style="width: 100%;margin-top:1%;">
        <tr style="border-top: solid 1px #CECECE; border-collapse: collapse">
          <th style="padding:1%;border:solid 1px #CECECE;">Batch Type</th>
          <th style="padding:1%;border:solid 1px #CECECE;">Batch Manufacturer</th>
          <th style="padding:1%;border:solid 1px #CECECE;">Batch #</th>
        </tr>
        <ng-container *ngFor="let batch of batchs">
          <tr style="border-top: solid 1px #CECECE; ">
            <td style="padding:1%;border:solid 1px #CECECE;">{{batch.batchType}}</td>
            <td style="padding:1%;border:solid 1px #CECECE;">{{batch.manufName}}</td>
            <td style="padding:1%;border:solid 1px #CECECE;">{{batch.Batch_Num}}</td>
          </tr>
        </ng-container>
      </table>

    </div>

    <div class="footer">
      <button type="button" class="cancel-btn" (click)="cancel()">CANCEL</button>
      <button type="submit" class="add-btn" [disabled]="!equipmentForm.valid" (click)="onSubmit()">SAVE</button>
    </div>
  </form>
</div>
