import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SnotifyService } from 'ng-snotify';
import { InspSetService } from 'src/app/services/administration/insp-set-service';
import { QuestionService } from 'src/app/services/administration/question.service';

@Component({
  selector: 'app-add-question',
  templateUrl: './add-question.component.html',
  styleUrls: ['./add-question.component.scss']
})
export class AddQuestionComponent implements OnInit {

  questionForm;
  
  responses = [];

  constructor(private inspSetService: InspSetService, private snotifyService: SnotifyService, 
    @Inject(MAT_DIALOG_DATA) public data: {inspSetId: number, inspSetQuestionId: number}, public dialogRef: MatDialogRef<AddQuestionComponent>) { }

  async ngOnInit() {
    await this.loadResponses();

    var inspSetQuestion = {
      inspSetQuestionId: null,
      inspSetId: this.data.inspSetId,
      question: '',
      positionType: 'At End',
      questionTypeId: 1
    };

    if (this.data.inspSetQuestionId != null && this.data.inspSetQuestionId != 0) {
      var question = await this.inspSetService.getQuestionById(this.data.inspSetQuestionId);
      inspSetQuestion.inspSetQuestionId = question.inspSetQuestionId;
      inspSetQuestion.question = question.question;
      inspSetQuestion.questionTypeId = question.questionTypeId;

      for (let response of this.responses) {
        response.inspSetQuestionResponseId = null;
        response.selected = false;
        // Check if we have this with our question
        var questionResponse = question.responses.find(q => q.responseId == response.responseId);
        if (questionResponse != null) { response.selected = questionResponse.selected; response.inspSetQuestionResponseId = questionResponse.inspSetQuestionResponseId }
      }
    }
    
    this.questionForm = new FormGroup({
      inspSetQuestionId: new FormControl(inspSetQuestion.inspSetQuestionId),
      inspSetId: new FormControl(this.data.inspSetId),
      question: new FormControl(inspSetQuestion.question, [Validators.required]),
      positionType: new FormControl(inspSetQuestion.positionType),
      questionTypeId: new FormControl(inspSetQuestion.questionTypeId),
    });
  }

  async loadResponses() {
    var responses = await this.inspSetService.getResponses();
    for (let response of responses) { response.selected = true; }

    this.responses = responses;
  }

  cancel() {
    this.dialogRef.close(false);
  }

  toggleResponse(response) {
    response.selected = !response.selected;
  }

  async onSubmit() {
    var question = this.questionForm.value;
    var success = null;


    if (this.data.inspSetQuestionId != null) {
      success = await this.inspSetService.updateQuestion(question, this.responses);
    } else {
      success = await this.inspSetService.addQuestion(question, this.responses);
    }

    if (!success) {
      this.snotifyService.error("Error saving Question");
      return;
    }

    this.dialogRef.close(true);
    this.snotifyService.success("Successfully added Question");
  }

}
