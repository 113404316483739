<div class="download-container">
  <h1 *ngIf="data.share">SHARE</h1>
  <h1 *ngIf="!data.share">DOWNLOAD</h1>
  
  <div class="download-option">
    <label>Register</label>
    <div class="download-input">
      <input type="text" placeholder="Name" [(ngModel)]="registerFileName" [disabled]="reportsProgress > 0"/>
      <select placeholder="Save As" [(ngModel)]="reportFileType" [disabled]="reportsProgress > 0">
        <option value="-1">Save As</option>
        <option value="0">Single Combined PDF</option>
        <!--<option value="1">Individual PDF'S in ZIP file</option>-->
      </select>
    </div>
  </div>

  <div *ngIf="reportsProgress > 0" style="margin-bottom: 15px;">
    
    <div *ngIf="reportFileType == 0">
      <span>Generating report. Please wait...</span>
      <mat-progress-bar [mode]="'indeterminate'">
      </mat-progress-bar>
    </div>
    <div *ngIf="reportFileType == 1">
      <span>{{ reportsProgress | number:'1.0-0' }}%</span>
      <span *ngIf="reportsZipping"> -- Zipping...</span>
      <mat-progress-bar [mode]="'determinate'" [value]="reportsProgress">
      </mat-progress-bar>
    </div>
  </div>

  <div class="download-option">
    <label>Certificate/s</label>
    <div class="download-input">
      <input type="text" placeholder="Name" [(ngModel)]="certificateFileName" [disabled]="data.inspections.length == 0 || certificatesProgress > 0"/>
      <select placeholder="Save As" [(ngModel)]="certificateFileType" [disabled]="data.inspections.length == 0 || certificatesProgress > 0">
        <option value="-1">Save As</option>
        <option value="0">Single Combined PDF</option>
        <option value="1">Individual PDF'S in ZIP file</option>
      </select>
    </div>
  </div>

  <div *ngIf="certificatesProgress > 0" style="margin-bottom: 15px;">
    <span>{{ certificatesProgress | number:'1.0-0' }}%</span>
    <span *ngIf="certificatesZipping"> -- Zipping...</span>
    <mat-progress-bar [mode]="'determinate'" [value]="certificatesProgress">
    </mat-progress-bar>
  </div>

  <p *ngIf="message != null">{{ message }}</p>

  <p *ngIf="data.share" class="share-helper">
    For multiple email addresses use  the semicolon character, e.g. kristie@ranger.com.au; dave@ranger.com.au) 
  </p>

  <div class="form-controls" *ngIf="data.share">
    <div class="form-input">
      <mat-form-field appearance="fill">
        <mat-label class="form-label">Email Addresses</mat-label>
        <input matInput [(ngModel)]="emailAddress">
      </mat-form-field>
    </div>
  </div>

  <div class="action-buttons">
    <button class="btn-save" type="button" (click)="download()">
      SAVE
    </button>

    <button class="btn-cancel" type="button" (click)="cancel()">
      CANCEL
    </button>
  </div>
  
</div>
