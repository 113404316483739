import { Component, Inject, OnInit } from '@angular/core';
import { BatchManufService } from 'src/app/services/administration/batch-manuf-service';
import { SnotifyService } from 'ng-snotify';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BatchType } from 'src/app/models/batch-type';
import { BatchTypeManageComponent } from '../../batch-types-management/batch-type-manage/batch-type-manage.component';
import { BatchManuf } from 'src/app/models/batch-manuf';

@Component({
  selector: 'app-batch-manufacturer-manage',
  templateUrl: './batch-manufacturer-manage.component.html',
  styleUrls: ['./batch-manufacturer-manage.component.scss']
})
export class BatchManufacturerManageComponent implements OnInit {
  dataForm;

  constructor(private batchService: BatchManufService, private snotifyService: SnotifyService,
    public dialogRef: MatDialogRef<BatchTypeManageComponent>, @Inject(MAT_DIALOG_DATA) public data: any) { }

  async ngOnInit() {
    var batch = new BatchManuf();
    
    if (this.data != null && this.data.batchManufId != null) {
      batch = await this.batchService.get(this.data.batchManufId);
    }

    this.dataForm = new FormGroup({
      batchManufId: new FormControl(batch.batchManufId),
      batchManuf: new FormControl(batch.batchManuf, [Validators.required]),
    })
  }

  cancel() {
    this.dialogRef.close(false);
  }

  async onSubmit() {
    var success = await this.batchService.save(this.dataForm.value);
    if (!success) {
      this.snotifyService.error("Error saving Batch Manufacturer");
      return;
    }

    this.dialogRef.close(true);
    this.snotifyService.success("Successfully saved Batch Manufacturer");
  }


}
