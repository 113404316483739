import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import * as moment from 'moment';
import { tap } from 'rxjs/operators';
import { State } from 'src/app/models/state';
import { CompletedJobsDataSource } from 'src/app/service-data-sources/job-management/completed-job-assets-data-source';
import { CompanyService } from 'src/app/services/administration/company-service';
import { JobService } from 'src/app/services/administration/job-service';
import { LocationService } from 'src/app/services/administration/location.service';
import { ReportService } from 'src/app/services/reports/reports.service';

@Component({
  selector: 'app-completed-jobs',
  templateUrl: './completed-jobs.component.html',
  styleUrls: ['./completed-jobs.component.scss']
})
export class CompletedJobsComponent implements OnInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  
  states = [];

  companies = [];
  locations = [];

  filter = {
    stateId: -1,
    companyId: 0,
    locationId: 0,
    accNum: "",
    startDate: moment().add(4, "months").toDate(),
    endDate: moment().add(6, "months").toDate(),
    salesOrderNum: "",
  }

  dataSource: CompletedJobsDataSource;
  displayedColumns: string[] = ['company', 'location', 'acc', 'salesOrder', 'scheduledInspectionDate'];

  constructor(private companyService: CompanyService, private locationService: LocationService, private reportService: ReportService) { }

  ngOnInit(): void {
    this.companyService.getStates().then((states: State[]) => this.states = states);
    this.companyService.getAll(-1, true, "All").then((companies) => {
      this.companies = companies;
    });

    this.dataSource = new CompletedJobsDataSource(this.reportService);
  }
  
  ngAfterViewInit() {
    this.paginator.page.pipe(tap(() => this.loadTablePage())).subscribe();  
  }

  loadTable() {
    this.dataSource.loadData(this.filter); 
  }

  loadTablePage() {
    this.dataSource.loadData(this.filter, 'asc', this.paginator.pageIndex, this.paginator.pageSize);
  }

  stateChanged($ev) {
    this.companyService.getAll($ev.value, true, "All").then((companies) => {
      this.companies = companies;
    });
  }

  companyChanged($ev) {
    this.locationService.getAll(this.filter.companyId).then((locations) => {
      this.locations = locations;
    })
  }

  createJob(companyId, locationId, locationInspectionId) {

  }

  updateDates(ev) {
    this.filter.startDate = ev.startDate;
    this.filter.endDate = ev.endDate;
  }
}
