import { Injectable } from "@angular/core";
import { environment } from '../../../environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from "rxjs";
import * as moment from 'moment';
import { filter } from "rxjs/operators";

@Injectable()
export class ReportService {
  constructor(private http: HttpClient) {
    
  }

  getBatchReport(filterDto, sortOrder: string = 'asc', pageIndex: number = 0, pageSize: number = 10) : Observable<[]> {
    const headers = {
      'Content-Type': 'application/json',
    };


    var httpParams = new HttpParams()
      .set('sortOrder', sortOrder)
      .set('pageIndex', pageIndex.toString())
      .set('pageSize', pageSize.toString());

    return this.http.post<[]>(environment.apiUrl + "report/getbatchreport", JSON.stringify(filterDto), { 
      headers: headers,
      params: httpParams
    });
  }

  getJobReport(filterDto, sortOrder: string = 'asc', pageIndex: number = 0, pageSize: number = 10) : Observable<[]> {
    const headers = {
      'Content-Type': 'application/json',
    };

    filterDto.startDate = moment(filterDto.startDate).format("YYYY-MM-DD");
    filterDto.endDate = moment(filterDto.endDate).format("YYYY-MM-DD");

    var httpParams = new HttpParams()
      .set('sortOrder', sortOrder)
      .set('pageIndex', pageIndex.toString())
      .set('pageSize', pageSize.toString());

    return this.http.post<[]>(environment.apiUrl + "report/getjobreport", JSON.stringify(filterDto), { 
      headers: headers,
      params: httpParams
    });
  }

  getInspReport(filterDto, sortOrder: string = 'asc', pageIndex: number = 0, pageSize: number = 10) : Observable<[]> {
    const headers = {
      'Content-Type': 'application/json',
    };

    filterDto.startDate = moment(filterDto.startDate).format("YYYY-MM-DD");
    filterDto.endDate = moment(filterDto.endDate).format("YYYY-MM-DD");
    console.log(filterDto);

    var httpParams = new HttpParams()
      .set('sortOrder', sortOrder)
      .set('pageIndex', pageIndex.toString())
      .set('pageSize', pageSize.toString());

    return this.http.post<[]>(environment.apiUrl + "report/getinspreport", JSON.stringify(filterDto), { 
      headers: headers,
      params: httpParams
    });
  }

  getAssetRegister(filterDto, sortOrder: string = 'asc', pageIndex: number = 0, pageSize: number = 10) : Observable<[]> {
    const headers = {
      'Content-Type': 'application/json',
    };

    //console.log(filterDto.startDate);

    var httpParams = new HttpParams()
      .set('sortOrder', sortOrder)
      .set('pageIndex', pageIndex.toString())
      .set('pageSize', pageSize.toString());

    return this.http.post<[]>(environment.apiUrl + "report/getassetregister", JSON.stringify(filterDto), { 
      headers: headers,
      params: httpParams
    });
  }

  getRangerRegister(filterDto, sortOrder: string = 'asc', pageIndex: number = 0, pageSize: number = 10) : Observable<[]> {
    const headers = {
      'Content-Type': 'application/json',
    };

    filterDto.startDate = moment(filterDto.startDate).format("YYYY-MM-DD");
    filterDto.endDate = moment(filterDto.endDate).format("YYYY-MM-DD");

    var httpParams = new HttpParams()
      .set('sortOrder', sortOrder)
      .set('pageIndex', pageIndex.toString())
      .set('pageSize', pageSize.toString());

    return this.http.post<[]>(environment.apiUrl + "report/getrangerregister", JSON.stringify(filterDto), { 
      headers: headers,
      params: httpParams
    });
  }

  getRangerRegisterIds(filterDto, batchStatus: number = 0, sortOrder: string = 'asc', pageIndex: number = 0, pageSize: number = 10) : Observable<[]> {
    const headers = {
      'Content-Type': 'application/json',
    };

    filterDto.batchStatus = batchStatus;
    filterDto.startDate = moment(filterDto.startDate).format("YYYY-MM-DD");
    filterDto.endDate = moment(filterDto.endDate).format("YYYY-MM-DD");

    var httpParams = new HttpParams()
      .set('sortOrder', sortOrder)
      .set('pageIndex', pageIndex.toString())
      .set('pageSize', pageSize.toString());

    return this.http.post<[]>(environment.apiUrl + "report/getrangerregisterids", JSON.stringify(filterDto), { 
      headers: headers,
      params: httpParams
    });
  }

  getCompletedJobs(filterDto, sortOrder: string = 'asc', pageIndex: number = 0, pageSize: number = 10): Observable<any> {
    const headers = {
      'Content-Type': 'application/json',
    };

    filterDto.startDate = moment(filterDto.startDate).format("YYYY-MM-DD");
    filterDto.endDate = moment(filterDto.endDate).format("YYYY-MM-DD");

    var httpParams = new HttpParams()
      .set('sortOrder', sortOrder)
      .set('pageIndex', pageIndex.toString())
      .set('pageSize', pageSize.toString());

    return this.http.post<any>(environment.apiUrl + "report/getcompletedjobs", JSON.stringify(filterDto), {
      headers: headers,
      params: httpParams
    });
  }
}