import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AusStd } from 'src/app/models/aus-std';
import { AusStdService } from 'src/app/services/administration/aus-std.service';
import { SnotifyService } from 'ng-snotify';

@Component({
  selector: 'app-aus-std-manage',
  templateUrl: './aus-std-manage.component.html',
  styleUrls: ['./aus-std-manage.component.scss']
})
export class AusStdManageComponent implements OnInit {

  ausForm;


  constructor(private ausStdService: AusStdService, private snotifyService: SnotifyService,
    public dialogRef: MatDialogRef<AusStdManageComponent>, @Inject(MAT_DIALOG_DATA) public data: any) { }

  async ngOnInit() {
    var ausStd = new AusStd();
    
    if (this.data != null && this.data.ausStdId != null) {
      ausStd = await this.ausStdService.get(this.data.ausStdId);
    }

    this.ausForm = new FormGroup({
      australianStdId: new FormControl(ausStd.australianStdId),
      australianStd: new FormControl(ausStd.australianStd, [Validators.required]),
    })
  }

  cancel() {
    this.dialogRef.close(false);
  }

  async onSubmit() {
    console.log(this.ausForm.value);
    var success = await this.ausStdService.save(this.ausForm.value);
    if (!success) {
      this.snotifyService.error("Error saving Australian Standard");
      return;
    }

    this.dialogRef.close(true);
    this.snotifyService.success("Successfully saved Australian Standard");
  }

}
