<div class="dialog-content" *ngIf="currentState == 0">
  <h1>NOTIFY RANGER</h1>
  <form>
    <mat-form-field appearance="fill" class="full-width">
      <mat-label>Notes</mat-label>
      <textarea rows="5" [(ngModel)]="notes" name="notes" matInput placeholder="Notes..."></textarea>
    </mat-form-field>

    <h3>SELECTED ASSETS</h3>
    <!-- <div class="equipment-list">
      <div class="equipment" *ngFor="let inspection of data.inspections">
        <span>{{ inspection.serialNum }}</span> - {{ inspection.description }}
      </div>
    </div> -->

    <table mat-table matSort [dataSource]="dataSource">
      <ng-container matColumnDef="serialNum">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>SERIAL #</th>
        <td mat-cell *matCellDef="let element" >{{element.serialNum}} </td>
      </ng-container>

      <ng-container matColumnDef="description">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>DESCRIPTION</th>
        <td mat-cell *matCellDef="let element" >{{element.description}} </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>

    <mat-paginator [pageSizeOptions]="[5, 10]" [pageSize]='5'></mat-paginator>

    <div class="action-buttons">
      <button class="btn-save" (click)="notify('Reinspection of Items')">
        REINSPECT
      </button>

      <button class="btn-save" (click)="notify('Replacement of Items')">
        REPLACEMENT
      </button>

      <button class="btn-save" (click)="notify('Removal of Items')">
        REMOVE FROM REGISTER
      </button>
  
      <button class="btn-cancel" type="button" (click)="cancel()">
        CANCEL
      </button>
    </div>
  </form>
</div>

<div class="dialog-content" *ngIf="currentState == 1">
  <div class="title">
    <h1>THANK YOU</h1>
  </div>
  <div class="confirm-message">
    <p>Our Inspections team will be in touch with you.</p>
    <div class="action-buttons">
      <button class="btn-cancel" type="button" (click)="cancel()">
        CLOSE
      </button>
    </div>
  </div>
</div>