import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { tap } from 'rxjs/operators';
import { Category } from 'src/app/models/category';
import { Company } from 'src/app/models/company';
import { Location } from 'src/app/models/location';
import { AssetRegisterDataSource } from 'src/app/service-data-sources/reports/asset-register-data-source';
import { CategoryService } from 'src/app/services/administration/category.service';
import { CompanyService } from 'src/app/services/administration/company-service';
import { LocationService } from 'src/app/services/administration/location.service';
import { AuthService } from 'src/app/services/auth.service';
import { ReportService } from 'src/app/services/reports/reports.service';
import { ReportableService } from 'src/app/services/reportable.service';
import { saveAs as importedSaveAs } from 'file-saver';
import { LoadTestComponent } from 'src/app/components/load-test/load-test.component';
import { MovementHistoryComponent } from 'src/app/components/movement-history/movement-history.component';
import { InspectionHistoryComponent } from 'src/app/components/inspection-history/inspection-history.component';
import { MoveEquipComponent } from 'src/app/components/move-equip/move-equip.component';

@Component({
  selector: 'app-asset-register',
  templateUrl: './asset-register.component.html',
  styleUrls: ['./asset-register.component.scss']
})
export class AssetRegisterComponent implements OnInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;

  filter = {
    stateId: 0,
    companyId: 0,
    locationId: 0,
    categoryId: 0,
    serialNum: "",
    status: "All",
    inspBeforeText: "",
    decomissioned: "-1",
    salesOrderNum: "",
    showArchived: false,
    department: "",
  }

  companies: Company[];
  locations: Location[];
  categories: Category[];

  dataSource: AssetRegisterDataSource;
  displayedColumns: string[] = ['links', 'companyName', 'locationName', 'description', 'category', 'serialNum', 'loadTestTo', 'inspected', 'nextInspection', 'status', 'clientAssetNum', 'department', 'options'];

  constructor(private authService: AuthService, private companyService: CompanyService, private locationService: LocationService,
    private categoryService: CategoryService, private reportsService: ReportService, private reportableService: ReportableService, public dialog: MatDialog) { }

  ngOnInit(): void {
    this.loadCompanies();

    this.loadCategories();

    this.dataSource = new AssetRegisterDataSource(this.reportsService);
  }

  ngAfterViewInit() {
    this.paginator.page.pipe(tap(() => this.loadTablePage())).subscribe();
  }

  loadTable() {
    this.dataSource.loadData(this.filter);
  }

  loadTablePage() {
    this.dataSource.loadData(this.filter, 'asc', this.paginator.pageIndex, this.paginator.pageSize);
  }

  loadCompanies() {
    this.companies = [];
    if (this.authService.isAdmin()) {
      this.companyService.getAll().then((companies: Company[]) => {
        this.companies = companies;
        this.locations = [];
      });
    } else {
      this.companyService.get(this.authService.currentUser.value.companyId).then((company: Company) => {
        this.companies.push(company);
        this.filter.companyId = company.companyId;
        this.loadLocations(this.filter.companyId);
      });
    }
  }

  loadLocations(companyId: number) {
    this.locationService.getAll(companyId, true, "All").then((locations: Location[]) => {
      this.locations = locations;
    });
  }

  loadCategories() {
    this.categoryService.getAll(true, "All").then((categories: Category[]) => {
      this.categories = categories;
    })
  }

  companyChanged($ev) {
    this.loadLocations($ev);
  }

  loadTests(equipmentId: number) {
    const dialogRef = this.dialog.open(LoadTestComponent, {
      data: { equipmentId: equipmentId }
    });
  }

  viewMovementHistory(equipmentId: number) {
    const dialogRef = this.dialog.open(MovementHistoryComponent, {
      data: { equipmentId: equipmentId }
    });
  }
  
  viewInspectionHistory(equipmentId: number) {
    const dialogRef = this.dialog.open(InspectionHistoryComponent, {
      data: { equipmentId: equipmentId }
    });
  }

  moveEquipment(equipmentId: number) {
    const dialogRef = this.dialog.open(MoveEquipComponent, {
      data: { equipmentId: equipmentId }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result.success) {
        this.loadTablePage();
      }
    });
  }

  /* -------------------------------------------------------------------- */
  /* CERT HELPERS */
  /* -------------------------------------------------------------------- */
  //TODO: Get proper names for reports when downloading
  downloadCert(inspectionId: number, safeForUse: boolean, row: any) {
    row.certLoading = true;
    this.reportableService.getLiftingExam(inspectionId, safeForUse).then((data) => {
      importedSaveAs(data, 'Report.pdf');
      row.certLoading = false;
    });
  }

  downloadServiceCert(equipmentId: number, row: any) {
    row.serviceCertLoading = true;
    this.reportableService.getServiceCert(equipmentId).then((data) => {
      importedSaveAs(data, 'Service Report.pdf');
      row.serviceCertLoading = false;
    });
  }

}
