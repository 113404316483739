export class Company {
  companyId: number = 0;
  companyName: string;

  address1: string;
  address2: string;
  address3: string;
  address4: string;
  postcode: string;
  accNum: string;
  telephone: string;
  mainContactNumber: string;
  contactName: string;
  contactTelephone: string;
  contactEmail: string;
  monthlyInterval: number = 0;

  county: string;
  officeNumber: string;
  fax: string;
  email: string;
  hideSynthetic: boolean = false;
  expired: boolean = false;
  showClientNo: boolean = false;

  stateId: number;
  state: string;

  nextInspectionPeriod: number;
  nextInspectionPeriodInterval: number = 0;
}