import { CollectionViewer, DataSource } from "@angular/cdk/collections";
import { BehaviorSubject, Observable, of } from "rxjs";
import { Location } from "src/app/models/location";;

import { catchError, finalize } from 'rxjs/operators';
import { DataServiceParam } from "../data-table/data-service-param";
import { ReportService } from "src/app/services/reports/reports.service";

export class InspReportDataSource {
  private dataSubject = new BehaviorSubject<[]>([]);
  private loadingSubject = new BehaviorSubject<boolean>(false);
  private countSubject = new BehaviorSubject<number>(0);

  public loading$ = this.loadingSubject.asObservable();
  public total$ = this.countSubject.asObservable();
  
  constructor(private reportsService: ReportService) {

  }

  connect(collectionViewer: CollectionViewer): Observable<[]> {
    return this.dataSubject.asObservable();
  }

  disconnect(collectionViewer: CollectionViewer): void {
    this.dataSubject.complete();
    this.loadingSubject.complete();
  }

  loadData(dto, sortDirection = 'asc', pageIndex = 0, pageSize = 10) {
    this.loadingSubject.next(true);

    this.reportsService.getInspReport(dto, sortDirection, pageIndex, pageSize)
      .pipe(catchError(() => of([])), finalize(() => { this.loadingSubject.next(false)}))
      .subscribe((response: any) => {
        this.countSubject.next(response.total);
        this.dataSubject.next(response.data);
      })
  };
}