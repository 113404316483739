import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { ActivatedRoute, Router } from '@angular/router';
import { SnotifyService } from 'ng-snotify';
import { tap } from 'rxjs/operators';
import { ApiResponse } from 'src/app/models/api-response';
import { DataServiceParam } from 'src/app/service-data-sources/data-table/data-service-param';
import { PendingJobDataSource } from 'src/app/service-data-sources/job-management/pending-job-data-source';
import { JobService } from 'src/app/services/administration/job-service';
import { Location } from '@angular/common';
import { MatDialog } from '@angular/material/dialog';
import { InspectionComponent } from 'src/app/components/inspection/inspection.component';


@Component({
  selector: 'app-view-pending-job',
  templateUrl: './view-pending-job.component.html',
  styleUrls: ['./view-pending-job.component.scss']
})
export class ViewPendingJobComponent implements OnInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  
  viewAllAssets: boolean = false;
  dataSource: PendingJobDataSource;
  displayedColumns: string[] = ['inspectionDate', 'serial', 'description', 'pass', 'fail', 'missing', 'repaired', 'condemned', 'nata', 'newIntoService', 'nextInspectionDate', 'completed'];

  pdaUser;
  locationInspectionId;
  emails;
  showEmail;

  constructor(private jobService: JobService, private route: ActivatedRoute, private location: Location, 
    private snotifyService: SnotifyService, public dialog: MatDialog) {
    
}

  ngOnInit(): void {
    this.getPDAUser();

    this.route.paramMap.subscribe((params) => {
      this.locationInspectionId = params.get('locationInspectionId');
      this.dataSource = new PendingJobDataSource(this.jobService);
      this.loadJobs(); 
    })

    this.jobService.getAllEmails(this.locationInspectionId).then((data) => {
      this.emails = data;
      console.log("emails");
      console.log(this.emails);
      console.log(this.emails.length);
      if (this.emails != null && this.emails.length > 0) {
        this.showEmail = false;
      } else {
        this.showEmail = true;
      }
    });
  }

  getPDAUser() {
    this.pdaUser = null;
    var pdaUserJson = localStorage.getItem('pdaUser');
    if (pdaUserJson != null && pdaUserJson != '') {
      this.pdaUser = JSON.parse(pdaUserJson);
    }
  }

  
  

  ngAfterViewInit() {
    this.paginator.page.pipe(tap(() => this.loadJobsPage())).subscribe();  
  }

  loadJobs() {
    var params: DataServiceParam[] = [];
    params.push(<DataServiceParam>{ paramName: 'locationInspectionId', paramValue: this.locationInspectionId });
    params.push(<DataServiceParam>{ paramName: 'pdaUserId', paramValue: this.pdaUser.pdaUserId });
    params.push(<DataServiceParam>{ paramName: 'showAll', paramValue: this.viewAllAssets });
    this.dataSource.loadData(params);
  }

  loadJobsPage() {
    var params: DataServiceParam[] = [];
    params.push(<DataServiceParam>{ paramName: 'locationInspectionId', paramValue: this.locationInspectionId });
    params.push(<DataServiceParam>{ paramName: 'pdaUserId', paramValue: this.pdaUser.pdaUserId });
    params.push(<DataServiceParam>{ paramName: 'showAll', paramValue: this.viewAllAssets });
    this.dataSource.loadData(params, 'asc', this.paginator.pageIndex, this.paginator.pageSize);
  }

  approveJob() {
    this.jobService.approveJob(this.locationInspectionId).then((response: ApiResponse) => {
      if (response.success) {
        this.snotifyService.success("Successfully approved job");
        this.location.back();
      } else {
        this.snotifyService.error(response.message);
      }
    })
  }

  view(locationInspectionEquipId: number, equipmentId: number, inspectionId: number) {
    console.log(inspectionId);
    const dialogRef = this.dialog.open(InspectionComponent, {
      width: '900px',
      data: {
        locationInspectionEquipId: locationInspectionEquipId, 
        pdaUserId: this.pdaUser.pdaUserId, 
        equipmentId: equipmentId,
        viewOnly: false,
        inspectionId: inspectionId,
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.loadJobsPage();
      }
    });
  }
  
}
