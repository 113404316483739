import { Injectable } from "@angular/core";
import { environment } from '../../../environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from "rxjs";
import { Location } from "src/app/models/location";
import { AusStd } from "src/app/models/aus-std";
import { DataService } from "../../service-data-sources/data-table/data-service-interface";
import { DataServiceParam } from "../../service-data-sources/data-table/data-service-param";
import { PDAUser } from "src/app/models/pda-user";


@Injectable()
export class PDAUserService implements DataService {
  constructor(private http: HttpClient) {
    
  }

  get(pdaUserId: number) : Promise<PDAUser> {
    const headers = {
      'Content-Type': 'application/json',
    };

    return new Promise((resolve, reject) => {
      this.http.get<any>(environment.apiUrl + "pdauser/get?pdaUserId=" + pdaUserId, { headers: headers }).subscribe(data => {
        resolve(data);
      }, (error) => {
        resolve(null)
      });
    });
  }

  getTable(params: DataServiceParam[], sortDirection: string, pageIndex: number, pageSize: number) {
    const headers = {
      'Content-Type': 'application/json',
    };

    var httpParams = new HttpParams()
      .set('sortOrder', sortDirection)
      .set('pageIndex', pageIndex.toString())
      .set('pageSize', pageSize.toString());

    for (let param of params) {
      httpParams = httpParams.append(param.paramName, param.paramValue);
    }

    return this.http.get<Location[]>(environment.apiUrl + "pdauser/gettable", { 
      headers: headers,
      params: httpParams
    });
  }

  getAll(includePleaseSelect: boolean = false, pleaseSelectLabel = "Please Select") : Promise<any> {
    const headers = {
      'Content-Type': 'application/json',
    };

    return new Promise((resolve, reject) => {
      this.http.get<any>(environment.apiUrl + "pdauser/getall", { headers: headers }).subscribe(data => {
        if (includePleaseSelect) {
          var pleaseSelect = {
            pdaUserId: 0,
            name: pleaseSelectLabel
          }
          
          data.unshift(pleaseSelect);
        }
        resolve(data);
      }, (error) => {
        resolve(null)
      });
    });
  }

  save(pdaUser: PDAUser) : Promise<boolean> {
    const headers = {
      'Content-Type': 'application/json',
    };

    return new Promise((resolve, reject) => {
      this.http.post<any>(environment.apiUrl + "pdauser/post", pdaUser, { headers: headers }).subscribe(data => {
        resolve(true);
      }, (error) => {
        resolve(false)
      });
    });
  }

  authenticate(pdaUserId: number, pin: string) : Promise<boolean> {
    const headers = {
      'Content-Type': 'application/json',
    };

    var dto = {
      pdaUserId: pdaUserId,
      pin: pin
    }

    return new Promise((resolve, reject) => {
      this.http.post<any>(environment.apiUrl + "pdauser/authenticate", dto, { headers: headers }).subscribe(user => {
        resolve(user);
      }, (error) => {
        resolve(null)
      });
    });
  }
}