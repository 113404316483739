<div class="dialog-content " style="width: 350px ">
  <h1>MOVE LOCATION</h1>
  <div *ngIf="!loading">
    <div class="mat-form-field full-width mat-primary form" style="margin-bottom:2vh">
      <mat-label class="form-label">Company</mat-label>

      <ng-select appendTo="body" id="company" *ngIf="allowCompanyMove" [(ngModel)]="data.companyId" (change)="onCompanyChange()">
        <ng-option *ngFor="let company of companies" [value]="company.companyId" [virtualScroll]="'true'">
          {{company.companyName}}
        </ng-option>
      </ng-select>
    </div>


    <div class="mat-form-field full-width mat-primary" style="margin-bottom:2vh">
      <mat-label class="form-label">Location</mat-label>

      <ng-select appendTo="body" id="location" [(ngModel)]="locationId">
        <ng-option *ngFor="let location of locations" [value]="location.locationId" [virtualScroll]="'true'">
          {{location.name}}
        </ng-option>
      </ng-select>
    </div>

    <div class="action-buttons">
      <button class="btn-save" type="submit" (click)="onSubmit()">
        MOVE
      </button>

      <button class="btn-cancel" type="button" (click)="cancel()">
        CANCEL
      </button>
    </div>
  </div>
  <div *ngIf="loading">
    <div class="loader-container">
      <div  class="rig-loader"><img src="/assets/rigspinner.jpg" /></div>
    </div>

  </div>

</div>
