<div class="topbar__block">
  <div class="spacer"></div>
  <button [matMenuTriggerFor]="menu">
    <span>{{ currentUser.name }}</span>
    <mat-icon>expand_more</mat-icon>
  </button>
  <mat-menu #menu="matMenu">
    <button class="profile-btn" mat-menu-item (click)="logout()">
      <mat-icon style="color: #000">logout</mat-icon>
      <span>Logout</span>
    </button>
  </mat-menu>
  
</div>
