<div class="content">
  <h1>Web Users Management</h1>

  <form class="panel" (ngSubmit)="loadTable()">
    <div class="panel-section-grid">
      <div class="panel-section">
        <ng-select style="width: 100%" [(ngModel)]="filter.companyId" name="companyId">
          <ng-option *ngFor="let company of companies" [value]="company.companyId" [virtualScroll]="'true'">{{company.companyName}}</ng-option>
        </ng-select>
      </div>

      <div class="panel-section">
        <mat-form-field>
          <mat-icon matPrefix style="padding-right: 10px;">search</mat-icon>
          <input type="text" matInput name="filter" placeholder="Search" [(ngModel)]="filter.filter" >
        </mat-form-field>
      </div>
    </div>
  
    <div class="panel-btns__block">
      <button type="button" class="panel-btn panel-btn-margin" (click)="export()">
        EXPORT
      </button>

      <button class="panel-btn panel-btn-margin" type="submit">
        FILTER
      </button>

      <button class="add-btn" type="button" (click)="add()">
        ADD NEW
      </button>
    </div>
  </form>

  <div class="spinner-container" *ngIf="dataSource.loading$ | async">
    <mat-spinner [diameter]="'50'"></mat-spinner>
  </div>

  <table mat-table class="data-table mat-elevation-z8" [dataSource]="dataSource" fixedLayout="true">
    <ng-container matColumnDef="firstname">
      <th mat-header-cell *matHeaderCellDef>Firstname</th>
      <td mat-cell *matCellDef="let element"><span class="truncate-text">{{element.firstname}}</span></td>
    </ng-container>

    <ng-container matColumnDef="lastname">
      <th mat-header-cell *matHeaderCellDef>Lastname</th>
      <td mat-cell *matCellDef="let element"><span class="truncate-text">{{element.lastname}}</span></td>
    </ng-container>

    <ng-container matColumnDef="email">
      <th mat-header-cell *matHeaderCellDef>Email</th>
      <td mat-cell *matCellDef="let element"><span class="truncate-text">{{element.email}}</span></td>
    </ng-container>

    <ng-container matColumnDef="lastLoginDate">
      <th mat-header-cell *matHeaderCellDef>Last Login Date</th>
      <td mat-cell *matCellDef="let element"><span class="truncate-text">{{element.lastLoginDate | date: 'dd/MM/yyyy'}}</span></td>
    </ng-container>

    <ng-container matColumnDef="userType">
      <th mat-header-cell *matHeaderCellDef>User Type</th>
      <td mat-cell *matCellDef="let element"><span class="truncate-text">{{element.userType}}</span></td>
    </ng-container>

    <ng-container matColumnDef="company">
      <th mat-header-cell *matHeaderCellDef>Company</th>
      <td mat-cell *matCellDef="let element"><span class="truncate-text">{{element.company}}</span></td>
    </ng-container>

    <ng-container matColumnDef="state">
      <th mat-header-cell *matHeaderCellDef>State</th>
      <td mat-cell *matCellDef="let element"><span class="truncate-text">{{element.state}}</span></td>
    </ng-container>

    <ng-container matColumnDef="isLockedOut">
      <th mat-header-cell *matHeaderCellDef>Is Locked Out</th>
      <td mat-cell *matCellDef="let element"><span class="truncate-text">{{element.isLockedOut ? 'LOCKED OUT': null}}</span></td>
    </ng-container>

    <ng-container matColumnDef="option">
      <th mat-header-cell *matHeaderCellDef>EDIT</th>
      <td mat-cell *matCellDef="let element"><a class="link-icon" (click)="edit(element.userId)"><mat-icon>edit</mat-icon></a></td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>

  <mat-paginator [pageSizeOptions]="[10, 25, 50, 100]" [pageSize]='10' [length]="dataSource.total$ | async" showFirstLastButtons></mat-paginator>
</div>