import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { ActivatedRoute } from '@angular/router';
import { tap } from 'rxjs/operators';
import { Company } from 'src/app/models/company';
import { CompanyService } from 'src/app/services/administration/company-service';
import { LocationService } from 'src/app/services/administration/location.service';
import { DataServiceParam } from 'src/app/service-data-sources/data-table/data-service-param';
import { TableDataSource } from 'src/app/service-data-sources/data-table/table-data-source';
import { LocationManageComponent } from './location-manage/location-manage.component';
import { SnotifyService } from 'ng-snotify';

@Component({
  selector: 'app-location-management',
  templateUrl: './location-management.component.html',
  styleUrls: ['./location-management.component.scss']
})
export class LocationManagementComponent implements OnInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  company: Company;


  filter: string = "";
  dataSource: TableDataSource<Location>;
  displayedColumns: string[] = ['name', 'accNum', 'address1', 'county', 'postcode', 'telephone', 'fax', 'email1', 'email2', 'expired', 'monthlyInterval', 'option'];

  companyId: number;

  constructor(private route: ActivatedRoute, private locationService: LocationService, private companyService: CompanyService, public dialog: MatDialog) { }

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      this.companyId = +params['companyId'];

      this.companyService.get(this.companyId).then((company: Company) => {
        this.company = company;
        
      });

      this.dataSource = new TableDataSource(this.locationService);
      this.loadLocations();
   });
  }

  ngAfterViewInit() {
    this.paginator.page.pipe(tap(() => this.loadCompaniesPage())).subscribe();  
  }

  loadLocations() {
    var params: DataServiceParam[] = [];
    params.push(<DataServiceParam>{ paramName: 'companyId', paramValue: this.companyId });
    params.push(<DataServiceParam>{ paramName: 'filter', paramValue: this.filter });
    this.dataSource.loadData(params);
  }

  loadCompaniesPage() {
    var params: DataServiceParam[] = [];
    params.push(<DataServiceParam>{ paramName: 'companyId', paramValue: this.companyId });
    params.push(<DataServiceParam>{ paramName: 'filter', paramValue: this.filter });
    this.dataSource.loadData(params, 'asc', this.paginator.pageIndex, this.paginator.pageSize);
  }

  edit(locationId) {
    console.log(locationId);
    const dialogRef = this.dialog.open(LocationManageComponent, {
      width: '900px',
      data: {companyId: this.companyId, locationId: locationId}
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.loadLocations();
      }
    });
  }

  add() {
    const dialogRef = this.dialog.open(LocationManageComponent, {
      width: '900px',
      data: {companyId: this.companyId}
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.loadLocations();
      }
    });
  }
}
