import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import * as moment from 'moment';
import { tap } from 'rxjs/operators';
import { Category } from 'src/app/models/category';
import { Company } from 'src/app/models/company';
import { Location } from 'src/app/models/location';
import { RangerRegisterDataSource } from 'src/app/service-data-sources/reports/ranger-register-data-source';
import { CategoryService } from 'src/app/services/administration/category.service';
import { CompanyService } from 'src/app/services/administration/company-service';
import { LocationService } from 'src/app/services/administration/location.service';
import { AuthService } from 'src/app/services/auth.service';
import { ReportService } from 'src/app/services/reports/reports.service';
import { ReportableService } from 'src/app/services/reportable.service';
import { saveAs as importedSaveAs } from 'file-saver';

import { EmailReportDialogComponent } from 'src/app/components/email-report-dialog/email-report-dialog.component';
import { MoveEquipComponent } from 'src/app/components/move-equip/move-equip.component';
import { MovementHistoryComponent } from 'src/app/components/movement-history/movement-history.component';
import { InspectionHistoryComponent } from 'src/app/components/inspection-history/inspection-history.component';
import { LoadTestComponent } from 'src/app/components/load-test/load-test.component';
import { ImageUploadComponent } from 'src/app/components/image-upload/image-upload.component';

@Component({
  selector: 'app-ranger-register',
  templateUrl: './ranger-register.component.html',
  styleUrls: ['./ranger-register.component.scss']
})
export class RangerRegisterComponent implements OnInit {
  //TODO: On page change make the table data clear as its loading so the user knows its loading
  //and scroll the user to the top of the page.
  @ViewChild(MatPaginator) paginator: MatPaginator;
  
  filter = {
    stateId: 0,
    companyId: 0,
    locationId: 0,
    categoryId: 0,
    filterByDate: false,
    startDate: moment().toDate(),
    endDate: moment().toDate(),
    showDecommisioned: "ALL",
    showMissing: "ALL",
    showPassFail: "ALL",
    serial: "",
    salesOrderNum: "",
    showArchived: false,
    status: "-1",
    department: ""
  }

  companies: Company[];
  locations: Location[];
  categories: Category[];

  dataSource: RangerRegisterDataSource;
  displayedColumns: string[] = ['images', 'inspectionDate', 'serialNum', 'description', 'australianStd', 'safeForUse', 'failed', 'missing', 
    'wasRepaired', 'condemned', 'nataCert', 'newIntoService', 'hasRAMS', 'hasJobForm', 'nextInspDateFormatted', 'location', 'clientAssetNumber', 
    'department', 'options', 'load'];

  isBatchExporting = false;

  constructor(private companyService: CompanyService, private locationService: LocationService, 
    private categoryService: CategoryService, private authService: AuthService, 
    private reportsService: ReportService, public dialog: MatDialog, private reportableService: ReportableService) { }

  ngOnInit(): void {
    this.loadCompanies();

    this.loadCategories();

    this.dataSource = new RangerRegisterDataSource(this.reportsService);
  }

  ngAfterViewInit() {
    this.paginator.page.pipe(tap(() => this.loadTablePage())).subscribe();  
  }

  loadTable() {
    this.dataSource.loadData(this.filter); 
  }

  loadTablePage() {
    this.dataSource.loadData(this.filter, 'asc', this.paginator.pageIndex, this.paginator.pageSize);
  }

  loadCompanies() {
    this.companies = [];
    if (this.authService.isAdmin()) {
      this.companyService.getAll().then((companies: Company[]) => {
        this.companies = companies;
        this.locations = [];
      });
    } else {
      this.companyService.get(this.authService.currentUser.value.companyId).then((company: Company) => {
        this.companies.push(company);
        this.filter.companyId = company.companyId;
        this.loadLocations(this.filter.companyId);
      });
    }
  }

  loadLocations(companyId: number) {
    this.locationService.getAll(companyId, true, "All").then((locations: Location[]) => {
      this.locations = locations;
    });
  }

  loadCategories() {
    this.categoryService.getAll(true, "All").then((categories: Category[]) => {
      this.categories = categories;
    })
  }

  companyChanged($ev) {
    this.loadLocations($ev);
  }

  moveEquipment(equipmentId: number) {
    const dialogRef = this.dialog.open(MoveEquipComponent, {
      data: { equipmentId: equipmentId }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result.success) {
        this.loadTablePage();
      }
    });
  }

  viewMovementHistory(equipmentId: number) {
    const dialogRef = this.dialog.open(MovementHistoryComponent, {
      data: { equipmentId: equipmentId }
    });
  }
  
  viewInspectionHistory(equipmentId: number) {
    const dialogRef = this.dialog.open(InspectionHistoryComponent, {
      data: { equipmentId: equipmentId }
    });
  }

  loadTests(equipmentId: number) {
    const dialogRef = this.dialog.open(LoadTestComponent, {
      data: { equipmentId: equipmentId }
    });
  }

  viewImages(inspectionId: number) {
    const dialogRef = this.dialog.open(ImageUploadComponent, {
      data: { inspectionId: inspectionId }
    });
  }

  /* -------------------------------------------------------------------- */
  /* CERT HELPERS */
  /* -------------------------------------------------------------------- */
  //TODO: Get proper names for reports when downloading
  downloadPassedCert(inspectionId: number, row: any) {
    row.passedCertLoading = true;
    this.reportableService.getLiftingExam(inspectionId, true).then((data) => {
      importedSaveAs(data, 'Passed Report.pdf');
      row.passedCertLoading = false;
    });
  }

  downloadFailedCert(inspectionId: number, row: any) {
    row.failedCertLoading = true;
    this.reportableService.getLiftingExam(inspectionId, false).then((data) => {
      importedSaveAs(data, 'Failed Report.pdf');
      row.failedCertLoading = false;
    });
  }

  downloadMissedCert(inspectionId: number, row: any) {
    row.missedCertLoading = true;
    this.reportableService.getLiftingExam(inspectionId, true).then((data) => {
      importedSaveAs(data, 'Missing Report.pdf');
      row.missedCertLoading = false;
    });
  }

  downloadRepairCert(inspectionId: number, row: any) {
    row.repairedCertLoading = true;
    this.reportableService.getRepairCert(inspectionId).then((data) => {
      importedSaveAs(data, 'Repair Report.pdf');
      row.repairedCertLoading = false;
    });
  }

 downloadCondemnedCert(inspectionId: number, row: any) {
    row.condemnedCertLoading = true;
    this.reportableService.getLiftingExam(inspectionId, false).then((data) => {
      importedSaveAs(data, 'Condemned Report.pdf');
      row.condemnedCertLoading = false;
    });
  }

  downloadNataCert(inspectionId: number, row: any) {
    row.nataCertLoading = true;
    this.reportableService.getNataCert(inspectionId).then((data) => {
      importedSaveAs(data, 'Nata Report.pdf');
      row.nataCertLoading = false;
    });
  }
  
  downloadServiceCert(equipmentId: number, row: any) {
    row.serviceCertLoading = true;
    this.reportableService.getServiceCert(equipmentId).then((data) => {
      importedSaveAs(data, 'Service Report.pdf');
      row.serviceCertLoading = false;
    });
  }
  
  downloadRAMS(locationInspectionId: number, row: any) {
    row.ramsCertLoading = true;
    this.reportableService.getRAMS(locationInspectionId).then((data) => {
      importedSaveAs(data, 'RAMS Report.pdf');
      row.ramsCertLoading = false;
    });
  }

  downloadJobForm(locationInspectionId: number, row: any) {
    row.jobFormLoading = true;
    this.reportableService.getJobForm(locationInspectionId).then((data) => {
      importedSaveAs(data, 'Job Form.pdf');
      row.jobFormLoading = false;
    });
  }

  batchLifting() {
    const dialogRef = this.dialog.open(EmailReportDialogComponent);

    dialogRef.afterClosed().subscribe(result => {
      if (result && result.success) {
        this.reportsService.getRangerRegisterIds(this.filter).subscribe((ids) => {
          this.reportableService.getLiftingBatch(ids, result.email).then((data) => {
            alert("You will receive an email shortly");
          })
        });
      }
    });
  }

  batchNata() {
    const dialogRef = this.dialog.open(EmailReportDialogComponent);

    dialogRef.afterClosed().subscribe(result => {
      if (result.success) {
        this.reportsService.getRangerRegisterIds(this.filter, 1).subscribe((ids) => {
          console.log(ids);
          this.reportableService.getNataBatch(ids, result.email).then((data) => {
            alert("You will receive an email shortly");
          })
        });
      }
    });
  }

  batchRepair() {
    const dialogRef = this.dialog.open(EmailReportDialogComponent);

    dialogRef.afterClosed().subscribe(result => {
      if (result.success) {
        this.reportsService.getRangerRegisterIds(this.filter, 2).subscribe((ids) => {
          console.log(ids);
          this.reportableService.getRepairBatch(ids, result.email).then((data) => {
            alert("You will receive an email shortly");
          })
        });
      }
    });
  }

  batchNew() {
    const dialogRef = this.dialog.open(EmailReportDialogComponent);

    dialogRef.afterClosed().subscribe(result => {
      if (result.success) {
        this.reportsService.getRangerRegisterIds(this.filter, 3).subscribe((ids) => {
          console.log(ids);
          this.reportableService.getNewBatch(ids, result.email).then((data) => {
            alert("You will receive an email shortly");
          })
        });
      }
    });
  }

  exportBatch() {
    this.isBatchExporting = true;
    this.reportableService.getRangerRegisterExport(this.filter).then((data) => {
      importedSaveAs(data, 'Report.pdf');
      this.isBatchExporting = false;
    });
  }

  updateDates(ev) {
    this.filter.startDate = ev.startDate;
    this.filter.endDate = ev.endDate;
  }
}
