import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import * as moment from 'moment';
import { tap } from 'rxjs/operators';
import { Category } from 'src/app/models/category';
import { Company } from 'src/app/models/company';
import { JobReportDataSource } from 'src/app/service-data-sources/reports/job-report-data-source';
import { RangerRegisterDataSource } from 'src/app/service-data-sources/reports/ranger-register-data-source';
import { CategoryService } from 'src/app/services/administration/category.service';
import { CompanyService } from 'src/app/services/administration/company-service';
import { LocationService } from 'src/app/services/administration/location.service';
import { AuthService } from 'src/app/services/auth.service';
import { ReportService } from 'src/app/services/reports/reports.service';
import { ReportableService } from 'src/app/services/reportable.service';
import { saveAs as importedSaveAs } from 'file-saver';

@Component({
  selector: 'app-job-report',
  templateUrl: './job-report.component.html',
  styleUrls: ['./job-report.component.scss']
})
export class JobReportComponent implements OnInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  
  filter = {
    stateId: 0,
    companyId: 0,
    locationId: 0,
    startDate: moment().add(-2, 'M').toDate(),
    endDate: moment().toDate(),
  }

  companies: Company[];
  locations: Location[];

  dataSource: JobReportDataSource;
  displayedColumns: string[] = ['passed', 'failed', 'missing', 'locationName', 'locationAccNum', 'companyName', 'companyAccNum', 'emailSent', 'created'];

  constructor(private companyService: CompanyService, private locationService: LocationService, 
    private authService: AuthService, private reportsService: ReportService, private reportableService: ReportableService) { 
  
  }

  ngOnInit(): void {
    this.loadCompanies();

    this.dataSource = new JobReportDataSource(this.reportsService);
  }

  ngAfterViewInit() {
    this.paginator.page.pipe(tap(() => this.loadTablePage())).subscribe();  
  }

  loadTable() {
    this.dataSource.loadData(this.filter); 
  }

  loadTablePage() {
    this.dataSource.loadData(this.filter, 'asc', this.paginator.pageIndex, this.paginator.pageSize);
  }

  loadCompanies() {
    this.companies = [];
    if (this.authService.isAdmin()) {
      this.companyService.getAll().then((companies: Company[]) => {
        this.companies = companies;
        this.locations = [];
      });
    } else {
      this.companyService.get(this.authService.currentUser.value.companyId).then((company: Company) => {
        this.companies.push(company);
        this.filter.companyId = company.companyId;
        this.loadLocations(this.filter.companyId);
      });
    }
  }

  loadLocations(companyId: number) {
    this.locationService.getAll(companyId, true, "All").then((locations: Location[]) => {
      this.locations = locations;
    });
  }

  companyChanged($ev) {
    this.loadLocations($ev);
  }

  updateDates(ev) {
    this.filter.startDate = ev.startDate;
    this.filter.endDate = ev.endDate;
  }

   /* -------------------------------------------------------------------- */
  /* CERT HELPERS */
  /* -------------------------------------------------------------------- */
  //TODO: Get proper names for reports when downloading
  downloadPassedCert(locationInspectionId: number, row: any) {
    row.passedCertLoading = true;
    this.reportableService.getJobReport(locationInspectionId, "Inspection_Passed_Assets").then((data) => {
      importedSaveAs(data, 'Passed Report.pdf');
      row.passedCertLoading = false;
    });
  }

  downloadFailedCert(locationInspectionId: number, row: any) {
    row.failedCertLoading = true;
    this.reportableService.getJobReport(locationInspectionId, "Inspection_Failed_Assets").then((data) => {
      importedSaveAs(data, 'Failed Report.pdf');
      row.failedCertLoading = false;
    });
  }

   downloadMissingCert(locationInspectionId: number, row: any) {
    row.missingCertLoading = true;
    this.reportableService.getJobReport(locationInspectionId, "Inspection_Missing_Assets").then((data) => {
      importedSaveAs(data, 'Missing Report.pdf');
      row.missingCertLoading = false;
    });
  }
}
