import { Component, OnInit } from '@angular/core';
import * as moment from 'moment';
import { EquipmentService } from 'src/app/services/administration/equipment.service';
import { saveAs as importedSaveAs } from 'file-saver';

@Component({
  selector: 'app-download-data',
  templateUrl: './download-data.component.html',
  styleUrls: ['./download-data.component.scss']
})
export class DownloadDataComponent implements OnInit {

  isLoading: boolean = false;

  filter = {
    startDate: moment().add(-1, 'months').toDate(),
    endDate: moment().toDate(),
  }

  constructor(private equipmentService: EquipmentService) { }

  ngOnInit(): void {
  }

  download() {
    this.isLoading = true;
    this.equipmentService.getEquipmentBatch(this.filter.startDate.toString(), this.filter.endDate.toString()).then((data) => {
      importedSaveAs(data, 'Data.xls');
      this.isLoading = false;
    });
  }

  updateDates(ev) {
    this.filter.startDate = ev.startDate;
    this.filter.endDate = ev.endDate;
  }
}
