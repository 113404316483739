import { Component, Inject, OnInit } from '@angular/core';
import { CategoryService } from 'src/app/services/administration/category.service';
import { SnotifyService } from 'ng-snotify';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Category } from 'src/app/models/category';
import { FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-category-manage',
  templateUrl: './category-manage.component.html',
  styleUrls: ['./category-manage.component.scss']
})
export class CategoryManageComponent implements OnInit {
  dataForm;


  constructor(private categoryService: CategoryService, private snotifyService: SnotifyService,
    public dialogRef: MatDialogRef<CategoryManageComponent>, @Inject(MAT_DIALOG_DATA) public data: any) { }

  async ngOnInit() {
    var category = new Category();
    
    if (this.data != null && this.data.categoryId != null) {
      category = await this.categoryService.get(this.data.categoryId);
    }

    console.log(category);

    this.dataForm = new FormGroup({
      categoryId: new FormControl(category.categoryId),
      categoryName: new FormControl(category.categoryName, [Validators.required]),
      inspTimePeriod: new FormControl(category.inspTimePeriod, [Validators.required]),
      inspTimePeriodUnits: new FormControl(category.inspTimePeriodUnits, [Validators.required]),
      loadTest: new FormControl(category.loadTest, [Validators.required]),
    })
  }

  cancel() {
    this.dialogRef.close(false);
  }

  async onSubmit() {
    var success = await this.categoryService.save(this.dataForm.value);
    if (!success) {
      this.snotifyService.error("Error saving Category");
      return;
    }

    this.dialogRef.close(true);
    this.snotifyService.success("Successfully saved Category");
  }
}
