<div class="content">
  <h1>JOBS REPORT</h1>

  <form class="panel" (ngSubmit)="loadTable()">
    <div class="panel-section-grid">
      <div class="panel-section">
        <app-search-select [(ngModel)]="filter.companyId" [label]="'Companies'" [list]="companies" [value]="'companyId'" [name]="'companyName'" (onChanged)="companyChanged($event)"></app-search-select>
      </div>
  
      <div class="panel-section">
        <app-search-select [(ngModel)]="filter.locationId" [label]="'Locations'" [list]="locations" [value]="'locationId'" [name]="'name'"></app-search-select>
      </div>
  
      <div class="panel-section">
        <app-datepicker [startDate]="filter.startDate" [endDate]="filter.endDate" (onChange)="updateDates($event)"></app-datepicker>
        <!-- <mat-form-field appearance="fill">
          <mat-label>Date Range</mat-label>
          <mat-date-range-input [rangePicker]="picker" >
            <input matStartDate name="startDate" [(ngModel)]="filter.startDate" placeholder="Start date">
            <input matEndDate name="endDate" [(ngModel)]="filter.endDate" placeholder="End date">
          </mat-date-range-input>
          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-date-range-picker #picker></mat-date-range-picker>
        </mat-form-field> -->
      </div>
    </div>
  
    
    <button class="panel-btn panel-btn-margin" type="submit">
      FILTER
    </button>
  </form>

  <div class="spinner-container" *ngIf="(dataSource.loading$ | async)">
    <mat-spinner [diameter]="'50'"></mat-spinner>
  </div>


  <table mat-table class="data-table mat-elevation-z8" [dataSource]="dataSource" fixedLayout="true">
  
    <ng-container matColumnDef="passed">
      <th mat-header-cell *matHeaderCellDef>Passed</th>
      <td mat-cell *matCellDef="let element">
        <span *ngIf="!element.passedCertLoading" (click)="downloadPassedCert(element.locationInspectionId, element)" class="truncate-text clickable-link"><mat-icon aria-hidden="false">summarize</mat-icon></span>
        <mat-progress-spinner *ngIf="element.passedCertLoading"  [diameter]="'16'" [color]="'#000'" [mode]="'indeterminate'"></mat-progress-spinner>
      </td>
    </ng-container>

    <ng-container matColumnDef="failed">
      <th mat-header-cell *matHeaderCellDef>Failed</th>
      <td mat-cell *matCellDef="let element">
        <span *ngIf="!element.failedCertLoading" (click)="downloadFailedCert(element.locationInspectionId, element)" class="truncate-text clickable-link"><mat-icon aria-hidden="false">summarize</mat-icon></span>
        <mat-progress-spinner *ngIf="element.failedCertLoading"  [diameter]="'16'" [color]="'#000'" [mode]="'indeterminate'"></mat-progress-spinner>
      </td>
    </ng-container>

    <ng-container matColumnDef="missing">
      <th mat-header-cell *matHeaderCellDef>Missed</th>
      <td mat-cell *matCellDef="let element">
        <span *ngIf="!element.missingCertLoading" (click)="downloadMissingCert(element.locationInspectionId, element)" class="truncate-text clickable-link"><mat-icon aria-hidden="false">summarize</mat-icon></span>
        <mat-progress-spinner *ngIf="element.missingCertLoading"  [diameter]="'16'" [color]="'#000'" [mode]="'indeterminate'"></mat-progress-spinner>
      </td>
    </ng-container>

    <ng-container matColumnDef="locationName">
      <th mat-header-cell *matHeaderCellDef>Location</th>
      <td mat-cell *matCellDef="let element"><span class="truncate-text">{{element.locationName}}</span></td>
    </ng-container>

    <ng-container matColumnDef="locationAccNum">
      <th mat-header-cell *matHeaderCellDef>Location Account #</th>
      <td mat-cell *matCellDef="let element"><span class="truncate-text">{{element.locationAccNum}}</span></td>
    </ng-container>

    <ng-container matColumnDef="companyName">
      <th mat-header-cell *matHeaderCellDef>Company</th>
      <td mat-cell *matCellDef="let element"><span class="truncate-text">{{element.companyName}}</span></td>
    </ng-container>

    <ng-container matColumnDef="companyAccNum">
      <th mat-header-cell *matHeaderCellDef>Company Account #</th>
      <td mat-cell *matCellDef="let element"><span class="truncate-text">{{element.companyAccNum}}</span></td>
    </ng-container>

    <ng-container matColumnDef="emailSent">
      <th mat-header-cell *matHeaderCellDef>Email Sent</th>
      <td mat-cell *matCellDef="let element"><span class="truncate-text">{{element.emailSent == true ? 'Yes' : 'No'}}</span></td>
    </ng-container>
    
    <ng-container matColumnDef="created">
      <th mat-header-cell *matHeaderCellDef>Created</th>
      <td mat-cell *matCellDef="let element"><span class="truncate-text">{{element.created | date: 'dd MMM YYYY'}}</span></td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>

  <mat-paginator [pageSizeOptions]="[10, 25, 50, 100]" [pageSize]='10' [length]="dataSource.total$ | async" showFirstLastButtons></mat-paginator>
</div>
