import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { tap } from 'rxjs/operators';
import { BatchManuf } from 'src/app/models/batch-manuf';
import { BatchType } from 'src/app/models/batch-type';
import { BatchReportDataSource } from 'src/app/service-data-sources/reports/batch-report-data-source';
import { BatchManufService } from 'src/app/services/administration/batch-manuf-service';
import { BatchTypeService } from 'src/app/services/administration/batch-type-service';
import { ReportService } from 'src/app/services/reports/reports.service';

@Component({
  selector: 'app-batch-report',
  templateUrl: './batch-report.component.html',
  styleUrls: ['./batch-report.component.scss']
})
export class BatchReportComponent implements OnInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;

  constructor(private batchTypeService: BatchTypeService, private batchManufService: BatchManufService, private reportsService: ReportService) { }

  batchTypes: BatchType[];
  batchManufs: BatchManuf[];

  filter = {
    batchTypeId: 0,
    batchManufId: 0,
    batchNum: "",
  }

  dataSource: BatchReportDataSource;
  displayedColumns: string[] = ['companyName', 'locationName', 'description', 'categoryName', 'serialNum', 'owner', 'batchNum', 'otherDetails', 'batchManuf', 'assemblyDate'];


  ngOnInit(): void {
    this.loadBatchTypes();
    this.loadBatchManufs();

    this.dataSource = new BatchReportDataSource(this.reportsService);
  }

  ngAfterViewInit() {
    this.paginator.page.pipe(tap(() => this.loadTablePage())).subscribe();  
  }

  loadTable() {
    this.dataSource.loadData(this.filter); 
  }

  loadTablePage() {
    this.dataSource.loadData(this.filter, 'asc', this.paginator.pageIndex, this.paginator.pageSize);
  }

  loadBatchTypes() {
    this.batchTypeService.getAll(true, "Please Select").then((data) => {
      this.batchTypes = data;
    })
  }

  loadBatchManufs() {
    this.batchManufService.getAll(true, "All").then((data) => {
      this.batchManufs = data;
    })
  }


  batchTypeChanged($ev) {

  }

}
