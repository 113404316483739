import { Injectable } from "@angular/core";
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { DataService } from "src/app/service-data-sources/data-table/data-service-interface";
import { Category } from "src/app/models/category";
import { DataServiceParam } from "src/app/service-data-sources/data-table/data-service-param";
import { Observable } from "rxjs";


@Injectable()
export class CategoryService implements DataService {
  constructor(private http: HttpClient) {
    
  }

  get(categoryId: number) : Promise<Category> {
    const headers = {
      'Content-Type': 'application/json',
    };

    return new Promise((resolve, reject) => {
      this.http.get<any>(environment.apiUrl + "category/get?categoryId=" + categoryId, { headers: headers }).subscribe(data => {
        resolve(data);
      }, (error) => {
        resolve(null)
      });
    });
  }

  getAll(includePleaseSelect: boolean = false, pleaseSelectLabel = "Please Select") : Promise<any> {
    const headers = {
      'Content-Type': 'application/json',
    };

    return new Promise((resolve, reject) => {
      this.http.get<any>(environment.apiUrl + "category/getall", { headers: headers }).subscribe(data => {
        if (includePleaseSelect) {
          var pleaseSelect = new Category();
          pleaseSelect.categoryId = 0;
          pleaseSelect.categoryName = pleaseSelectLabel;
          data.unshift(pleaseSelect);
        }
        resolve(data);
      }, (error) => {
        resolve(null)
      });
    });
  }

  getTable(params: DataServiceParam[], sortOrder: string = 'asc', pageIndex: number = 0, pageSize: number = 10) : Observable<Category[]> {
    const headers = {
      'Content-Type': 'application/json',
    };

    var httpParams = new HttpParams()
      .set('sortOrder', sortOrder)
      .set('pageIndex', pageIndex.toString())
      .set('pageSize', pageSize.toString());

    for (let param of params) {
      httpParams = httpParams.append(param.paramName, param.paramValue);
    }

    return this.http.get<Category[]>(environment.apiUrl + "category/gettable", { 
      headers: headers,
      params: httpParams
    });
  }

  save(category: Category) : Promise<boolean> {
    const headers = {
      'Content-Type': 'application/json',
    };

    return new Promise((resolve, reject) => {
      this.http.post<any>(environment.apiUrl + "category/post", category, { headers: headers }).subscribe(data => {
        resolve(true);
      }, (error) => {
        resolve(false)
      });
    });
  }
}