import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SnotifyService } from 'ng-snotify';
import { DashboardService } from 'src/app/services/dashboard/dashboard.service';
import { ReportableService } from 'src/app/services/reportable.service';
import { ZipDownloaderService } from '../zip-downloader/zip-downloader-service';
import { saveAs as importedSaveAs } from 'file-saver';
import { MoveEquipComponent } from '../move-equip/move-equip.component';
import { AuthService } from 'src/app/services/auth.service';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-inspection-basket',
  templateUrl: './inspection-basket.component.html',
  styleUrls: ['./inspection-basket.component.scss']
})
export class InspectionBasketComponent implements OnInit {
  @Input() identifier: string;
  @Input() addAllFunc;
  @Output() onMove = new EventEmitter<any>();

  items = [];
  reportName = "";
  exportType = "PDF";

  nataCount: number = 0;
  newCount: number = 0;
  repairCount: number = 0;

  isDownloadingPDF: boolean = false;

  constructor(private zipDownloaderService: ZipDownloaderService, private snotifyService: SnotifyService, 
    private dashboardService: DashboardService, private reportableService: ReportableService, 
    private authService: AuthService, public dialog: MatDialog) { }

  ngOnInit(): void {
    // Check if we have items
    var itemsJson = localStorage.getItem(this.getIdentifier());
    if (itemsJson != null) {
      this.items = JSON.parse(itemsJson);
      this.updateCounts();
    }
  }

  addToBasket(inspection: any) {
    this.items.push(inspection);
    localStorage.setItem(this.getIdentifier(), JSON.stringify(this.items));

    this.updateCounts();
  }

  removeFromBasket(inspection) {
    var idx = this.items.indexOf(this.items.find(i => i.inspectionId == inspection.inspectionId));
    if (idx == -1)
      return;

    this.items.splice(idx, 1);
    localStorage.setItem(this.getIdentifier(), JSON.stringify(this.items));

    this.updateCounts();
  }

  updateCounts() {
    this.nataCount = this.items.filter(i => i.nataCert == true).length;
    this.newCount = this.items.filter(i => i.newIntoService == true).length;
    this.repairCount = this.items.filter(i => i.hasBeenRepaired == true).length;
  }

  getIdentifier() {
    return "basket-" + this.identifier;
  }

  clearBasket() {
    this.items = [];
    localStorage.setItem(this.getIdentifier(), JSON.stringify(this.items));
  }

  addAllToBasket() {
    this.addAllFunc.subscribe((data) => {
      this.items = [];
      for (let inspection of data.data) {
        this.items.push(inspection);
      }

      localStorage.setItem(this.getIdentifier(), JSON.stringify(this.items));
      this.updateCounts();
    }); 
  }

  exportLifting() {
    if (this.reportName == "") {
      this.snotifyService.error("Enter report name");
      return;
    }

    if (this.exportType == "ZIP") {
      var data = this.items.map((i) => ({
        inspectionId: i.inspectionId,
        serialNum: i.serialNum,
      }));

      console.log("BATCH LIFTING ZIP")

      this.zipDownloaderService.createJob(data, this.reportName, "Lifting");
    } else {
      console.log("BATCH LIFTING PDF")
      this.isDownloadingPDF = true;
      this.dashboardService.exportReportBatch(this.items.map(i => i.inspectionId)).then((response) => {
        // Get the ID and pass this into the reporting services to generate a batch
        this.reportableService.getBatchLifting(response).then((data) => {
          importedSaveAs(data, this.reportName + ".pdf");
          this.isDownloadingPDF = false;
        })
      });
    }
  }

  exportNata() {
    if (this.reportName == "") {
      this.snotifyService.error("Enter report name");
      return;
    }

    if (this.nataCount == 0) {
      this.snotifyService.error("No Nata certs selected");
      return;
    }

    if (this.exportType == "ZIP") {
      var data = this.items.filter(i => i.nataCert == true).map((i) => ({
        inspectionId: i.inspectionId,
        serialNum: i.serialNum,
      }));

      this.zipDownloaderService.createJob(data, this.reportName, "Nata");
    } else {
      this.isDownloadingPDF = true;
      this.dashboardService.exportReportBatch(this.items.filter(i => i.nataCert == true).map(i => i.inspectionId)).then((response) => {
        this.isDownloadingPDF = true;
        this.reportableService.getBatchNata(response).then((data) => {
          importedSaveAs(data, this.reportName + ".pdf");
          this.isDownloadingPDF = false;
        })
      });
    }
  }

  exportNewIntoService() {
    if (this.reportName == "") {
      this.snotifyService.error("Enter report name");
      return;
    }

    if (this.newCount == 0) {
      this.snotifyService.error("No New certs selected");
      return;
    }

    if (this.exportType == "ZIP") {
      var data = this.items.filter(i => i.newIntoService == true).map((i) => ({
        inspectionId: i.inspectionId,
        serialNum: i.serialNum,
      }));

      console.log(data);

      this.zipDownloaderService.createJob(data, this.reportName, "New");
    } else {
      this.dashboardService.exportReportBatch(this.items.filter(i => i.newIntoService == true).map(i => i.equipmentId)).then((response) => {
        this.isDownloadingPDF = true;
        this.reportableService.getBatchNew(response).then((data) => {
          importedSaveAs(data, this.reportName + ".pdf");
          this.isDownloadingPDF = false;
        })
      });
    }
  }

  exportRepair() {
    if (this.reportName == "") {
      this.snotifyService.error("Enter report name");
      return;
    }

    if (this.repairCount == 0) {
      this.snotifyService.error("No Repair certs selected");
      return;
    }

    if (this.exportType == "ZIP") {
      var data = this.items.filter(i => i.hasBeenRepaired == true).map((i) => ({
        inspectionId: i.inspectionId,
        serialNum: i.serialNum,
      }));

      this.zipDownloaderService.createJob(data, this.reportName, "Repair");
    } else {
      this.dashboardService.exportReportBatch(this.items.filter(i => i.hasBeenRepaired == true).map(i => i.equipmentId)).then((response) => {
        this.isDownloadingPDF = true;
        this.reportableService.getBatchRepair(response).then((data) => {
          importedSaveAs(data, this.reportName + ".pdf");
          this.isDownloadingPDF = false;
        })
      });
    }
  }

  moveBatch() {
    var user = this.authService.currentUser.value;

    var ids = this.items.map(a => a.equipmentId);
    const dialogRef = this.dialog.open(MoveEquipComponent, {
      data: { batchIds: ids, companyId: user.companyId }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result.success) {
        this.onMove.emit();
        this.snotifyService.success("Successfully moved equipment");
      }
    });
  }
}
