import { prepareSyntheticListenerName } from '@angular/compiler/src/render3/util';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';

enum State {
  login,
  forgottenPassword,
  resetPassword
}

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  currentState = State.login;

  isLoading: boolean = false;
  invalid: boolean = false;

  loginForm = new FormGroup({
    username: new FormControl('', Validators.required),
    password: new FormControl('', Validators.required),
    rememberMe: new FormControl(false),
  })

  resetForm = new FormGroup({
    token: new FormControl('', Validators.required),
    password: new FormControl('', Validators.required),
    confirmPassword: new FormControl('', Validators.required)
  });

  constructor(private authService: AuthService, private router: Router, private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      var token = params.token;
      if (token != null) {
        this.currentState = State.resetPassword;

        this.resetForm.patchValue({ 'token': token });
      }
    });
    // // Check to see if we have already logged in
    // var previousLogin = this.authService.getLoggedInCookie();
    // if (previousLogin == null || previousLogin == "") {
    //   return;
    // }

    // var user = JSON.parse(this.authService.getLoggedInCookie());
  
    // this.loginForm.patchValue({
    //   username: user.username,
    //   password: user.password
    // });

    // this.submit();
  }

  async submit() {
    this.isLoading = true;
    this.invalid = false;

    var userModel = this.loginForm.value;
    var loginDto = await this.authService.login(userModel.username, userModel.password, userModel.rememberMe);

    this.isLoading = false;

    if (loginDto == null) {
      this.invalid = true;
    }

    this.router.navigate(['/dashboard']);
  }

  async reset() {
    var userModel = this.loginForm.value;
    if (userModel.username == null || userModel.username == "") {
      return;
    }

    this.isLoading = true;

    var response = await this.authService.reset(userModel.username);
    if (response) {
      alert("Please check your inbox");
      this.currentState = State.login;
    }

    this.isLoading = false;
  }

  async resetPassword() {
    var model = this.resetForm.value;
    if (model.password != model.confirmPassword) {
      alert("Passwords do not match");
      return;
    }

    this.isLoading = true;

    var response = await this.authService.resetPassword(model.token, model.password);
    if (response) {
      this.currentState = State.login;
      alert("Successfully reset");
    }

    this.isLoading = false;
  }
}
