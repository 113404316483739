import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { Category } from 'src/app/models/category';
import { DataServiceParam } from 'src/app/service-data-sources/data-table/data-service-param';
import { TableDataSource } from 'src/app/service-data-sources/data-table/table-data-source';
import { CategoryService } from 'src/app/services/administration/category.service';
import { AusStdManageComponent } from '../australian-standard-management/aus-std-manage/aus-std-manage.component';
import { CategoryManageComponent } from './category-manage/category-manage.component';

@Component({
  selector: 'app-category-management',
  templateUrl: './category-management.component.html',
  styleUrls: ['./category-management.component.scss']
})
export class CategoryManagementComponent implements OnInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  
  dataSource: TableDataSource<Category>;
  displayedColumns: string[] = ['categoryName', 'edit'];

  filter: string = "";

  constructor(private categoryService: CategoryService, public dialog: MatDialog) { }

  ngOnInit(): void {
    this.dataSource = new TableDataSource(this.categoryService);
    this.loadTable();
  }

  loadTable() {
    var params: DataServiceParam[] = [];
    params.push(<DataServiceParam>{ paramName: 'filter', paramValue: this.filter });
    this.dataSource.loadData(params);
  }

  loadTablePage() {
    var params: DataServiceParam[] = [];
    params.push(<DataServiceParam>{ paramName: 'filter', paramValue: this.filter });
    this.dataSource.loadData(params, 'asc', this.paginator.pageIndex, this.paginator.pageSize);
  }
  
  add() {
    const dialogRef = this.dialog.open(CategoryManageComponent, {
      width: '900px',
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.loadTable();
      }
    });
  }

  edit(categoryId) {
    const dialogRef = this.dialog.open(CategoryManageComponent, {
      width: '900px',
      data: {categoryId: categoryId}
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.loadTable();
      }
    });
  }


}
