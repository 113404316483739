import { Component, Inject, Input, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ReportableService } from 'src/app/services/reportable.service';
import { saveAs as importedSaveAs } from 'file-saver';
import * as moment from 'moment';
import { LocationService } from 'src/app/services/administration/location.service';
import * as JSZip from 'jszip';
import { pdfDocEncodingDecode, PDFDocument } from 'pdf-lib'
import { resolve } from 'dns';
import { AuthService } from 'src/app/services/auth.service';
import * as signalR from "@microsoft/signalr"
import { environment } from 'src/environments/environment';
import { HttpClient, HttpEventType } from '@angular/common/http';

@Component({
  selector: 'app-inspection-download',
  templateUrl: './inspection-download.component.html',
  styleUrls: ['./inspection-download.component.scss']
})
export class InspectionDownloadComponent implements OnInit, OnDestroy {
  registerFileName: string = "";
  reportFileType: string = "-1";
  reportsProgress: number = 0;
  reportsZipping: boolean = false;

  certificateFileName: string = "";
  certificateFileType: string = "-1";
  certificatesProgress: number = 0;
  certificatesZipping: boolean = false;

  emailAddress: string = "";

  connection: signalR.HubConnection;
  message: string = "";

  constructor(public dialogRef: MatDialogRef<InspectionDownloadComponent>, private reportableService: ReportableService, private locationsService: LocationService,
    @Inject(MAT_DIALOG_DATA) public data: { inspections: any, allInspections: any, share: boolean }, private authService: AuthService,
    private http: HttpClient) { }

  ngOnInit(): void {
    this.connection = new signalR.HubConnectionBuilder().withUrl(environment.apiUrl + 'report', {
      skipNegotiation: true,
      transport: signalR.HttpTransportType.WebSockets
    }).build();
    this.connection.start().then(() => console.log('connection started')).catch(err => console.log('Error: ' + err));
  }

  ngOnDestroy(): void {
    this.connection.stop();
  }

  cancel() {
    this.dialogRef.close();
  }

  download() {
    if (this.certificatesProgress > 0 || this.reportsProgress > 0) {
      alert("Reports are generating. Please wait...");
      return;
    }

    if ((this.registerFileName == null || this.registerFileName == "")
      && (this.certificateFileName == null || this.certificateFileName == "")) {
      alert("Must enter a name for at least 1 report");
      return;
    }

    if (this.data.share && this.emailAddress == "") {
      alert("Enter at least 1 email address");
      return;
    }

    if (this.certificateFileName != null && this.certificateFileName != "") {
      this.downloadCertificates();
    }

    if (this.registerFileName != null && this.registerFileName != "") {
      this.downloadRegister();
    }

  }

  async downloadCertificates() {
    // var exportOptions = {
    //   fileName: "",
    //   base64: ""
    // };

    if (this.certificateFileType == "0") {
      await this.downloadCombinedCertificates();
    } else if (this.certificateFileType == "1") {
      await this.downloadIndividualCertificates();
    } else {
      alert("select report type");
    }

    if (this.data.share) {
      alert("Email has been sent to: " + this.emailAddress);
    }

    // if (this.data.share && exportOptions.fileName != "") {
    //   var user = this.authService.currentUser.value;
    //   // Upload file to server
    //   await this.reportableService.shareFile(this.emailAddress, exportOptions.base64, exportOptions.fileName, user.firstname + ' ' + user.lastname);
    //   alert("Email has been sent to: " + this.emailAddress);
    // }
  }

  async downloadCombinedCertificates(): Promise<any> {
    return new Promise(async (resolve, reject) => {

      var dto = this.data.inspections.map(insp => ({
        inspectionId: insp.inspectionId,
        hasBeenRepaired: insp.hasBeenRepaired,
        nataCert: insp.nataCert,
        safeForUse: insp.safeForUse,
        serialNum: insp.serialNum
      }));

      var user = this.authService.currentUser.value;

      this.connection.send("Start", dto, "application/pdf", this.emailAddress, this.certificateFileName + ".pdf",
        user.firstname + ' ' + user.lastname).catch(err => console.log("err: " + err));
      this.connection.on('progress', (data) => {
        this.certificatesProgress = data;
      });
      this.connection.on('message', (data) => {
        this.message = data;
      })
      this.connection.on('complete', async (id) => {
        this.message = "Downloading from server...";

        this.reportableService.getBatchById(id).subscribe(event => {
          if (event.type == HttpEventType.DownloadProgress || event.type == HttpEventType.UploadProgress) {
            var progress = Math.round((100 * event.loaded) / event.total);
            this.certificatesProgress = progress;
            this.message = "Downloading file...";
          } else if (event.type == HttpEventType.Response) {   
            importedSaveAs(event.body, `${this.certificateFileName}.pdf`);

            this.certificatesProgress = 0;
            this.message = "";

            console.log(new Date());

            // Unsubscribe from the messages
            this.connection.off("progress");
            this.connection.off("message");
            this.connection.off("complete");

            //resolve(true);
            resolve(true);
            // resolve({
            //   base64: base64,
            //   fileName: `${this.certificateFileName}.pdf`
            // });
          }
        });
        
      })
      this.connection.on('error', (data) => {console.log("error") })
    });
  }

  downloadIndividualCertificates(): Promise<any> {
    return new Promise(async (resolve, reject) => {
      var dto = this.data.inspections.map(insp => ({
        inspectionId: insp.inspectionId,
        hasBeenRepaired: insp.hasBeenRepaired,
        nataCert: insp.nataCert,
        safeForUse: insp.safeForUse,
        serialNum: insp.serialNum
      }));

      console.log(new Date());

      var user = this.authService.currentUser.value;
  
      this.connection.send("Start", dto, "application/zip", this.emailAddress, this.certificateFileName + ".zip", user.firstname + ' ' + user.lastname);
      this.connection.on('progress', (data) => {
        this.certificatesProgress = data;
      });
      this.connection.on('message', (data) => {
        this.message = data;
      })
      this.connection.on('complete', async (id) => {
        this.message = "Downloading from server...";

        this.reportableService.getBatchById(id).subscribe(event => {
          if (event.type == HttpEventType.DownloadProgress || event.type == HttpEventType.UploadProgress) {
            var progress = Math.round((100 * event.loaded) / event.total);
            this.certificatesProgress = progress;
            this.message = "Downloading file...";
          } else if (event.type == HttpEventType.Response) {   
            importedSaveAs(event.body, `${this.certificateFileName}.zip`);

            this.certificatesProgress = 0;
            this.message = "";

            console.log(new Date());

            // Unsubscribe from the messages
            this.connection.off("progress");
            this.connection.off("message");
            this.connection.off("complete");

            resolve(true);
          }
        });
        
      })
    });
    // return new Promise(async (resolve, reject) => {
    //   // Download the latest cert depending on what comes last (i.e, report, nata, etc.)
    //   this.certificatesProgress = 0.1;
    //   var promises = [];
    //   var zip = new JSZip();

    //   var count = 0;

    //   for (let inspection of this.data.inspections) {
    //     promises.push(new Promise(async (resolve, reject) => {
    //       try {
    //         var base64 = null;
    //         if (inspection.hasBeenRepaired) {
    //           // Download repaired cert first if needed
    //           base64 = await this.reportableService.getRepairCertBase64(inspection.inspectionId);
    //         } else if (inspection.nataCert) {
    //           // Then nata
    //           base64 = await this.reportableService.getNataCertBase64(inspection.inspectionId);
    //         } else {
    //           // Finally just the ROTE
    //           base64 = await this.reportableService.getLiftingExamBase64(inspection.inspectionId, inspection.safeForUse);
    //         }

    //         var fileName = inspection.serialNum.replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, '');
    //         zip.file(`${fileName}.pdf`, base64, { base64: true });

    //         count++;

    //         this.certificatesProgress = (count / this.data.inspections.length) * 100;

    //         resolve(true);
    //       } catch (e) {

    //       }
    //     }));
    //   }

    //   Promise.all(promises).then(() => {
    //     this.certificatesZipping = true;
    //     zip.generateAsync({ type: "blob" }, (update) => {
    //       this.certificatesProgress = update.percent;
    //     }).then(async (content) => {
    //       this.certificatesZipping = false;
    //       this.certificatesProgress = 0;
    //       importedSaveAs(content, `${this.certificateFileName}.zip`);

    //       var base64 = await blobToBase64(content) as string;

    //       resolve({
    //         base64: base64,
    //         fileName: `${this.certificateFileName}.zip`
    //       });

    //     })
    //   });
    // });
  }

  async downloadRegister() {
    var exportOptions = {
      fileName: "",
      base64: ""
    };

    if (this.reportFileType == "0") {
      exportOptions = await this.downloadSingleRegister();
    } else if (this.reportFileType == "1") {
      exportOptions = await this.downloadIndividualRegister();
    } else {
      alert("Select report type");
    }

    if (this.data.share && exportOptions.fileName != "") {
      var user = this.authService.currentUser.value;
      // Upload file to server
      await this.reportableService.shareFile(this.emailAddress, exportOptions.base64, exportOptions.fileName, user.firstname + ' ' + user.lastname);
      this.dialogRef.close();
      alert("Email has been sent to: " + this.emailAddress);
      
    }
  }

  downloadSingleRegister(): Promise<any> {
    return new Promise(async (resolve, reject) => {

      this.reportsProgress = 0.1;

      var filter = {
        inspectionIds: this.data.inspections.map(i => i.inspectionId)
      }
      console.log(filter.inspectionIds.length);
      if (filter.inspectionIds.length == 0) {
        filter.inspectionIds = this.data.allInspections.map(i => i.inspectionId);
      }
      this.reportableService.getRangerRegisterExportV2(filter).then(async (data) => {
        importedSaveAs(data, `${this.registerFileName}.pdf`);

        var base64 = await blobToBase64(data);

        this.reportsProgress = 0;
        resolve({
          base64: base64,
          fileName: `${this.registerFileName}.pdf`
        });
      });

    });

  }

  async downloadIndividualRegister(): Promise<any> {    return new Promise(async (resolve, reject) => {
      this.reportsProgress = 0.1;
      var zip = new JSZip();


      // Get all the lccations for this company
      var promises = [];
      var count = 0;

      var locationIds = [...new Set(this.data.allInspections.map(item => item.locationId))];
      for (let locationId of locationIds) {
        promises.push(new Promise((resolve, reject) => {
          try {
            
            var inspections = this.data.allInspections.filter(l => l.locationId == locationId);
            
            var filter = {
              inspectionIds: inspections.map(i => i.inspectionId),
            }
            
            var locationName = this.data.allInspections.filter(l => l.locationId == locationId)[0].location;

            this.reportableService.getRangerRegisterExport(filter, true).then(base64 => {
              var fileName = locationName.replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, '');
              zip.file(`${fileName}.pdf`, base64, { base64: true });

              count++;
              this.reportsProgress = (count / locationIds.length) * 100;

              resolve(true);
            }, (error) => {
              resolve(true);
            })
          } catch {
            resolve(true);
          }
        }));
      }

      Promise.all(promises).then(() => {
        this.reportsZipping = true;
        zip.generateAsync({ type: "blob" }, (update) => {
          // job.status = "SAVING";
          // job.percentage = update.percent;
          this.reportsProgress = update.percent;
        }).then(async (content) => {
          this.reportsProgress = 0;
          this.reportsZipping = false;
          importedSaveAs(content, `${this.registerFileName}.zip`);

          var base64 = await blobToBase64(content) as string;

          resolve({
            base64: base64,
            fileName: `${this.registerFileName}.zip`
          });
        })
      });
    });
  }
}

const uint8ToBase64 = (arr: Uint8Array): string =>
  btoa(
    Array(arr.length)
      .fill('')
      .map((_, i) => String.fromCharCode(arr[i]))
      .join('')
  );

const blobToBase64 = blob => {
  const reader = new FileReader();
  reader.readAsDataURL(blob);
  return new Promise(resolve => {
    reader.onloadend = () => {
      resolve(reader.result);
    };
  });
};

const convertBase64toBlob = (content, contentType) => {
  contentType = contentType || '';
  let sliceSize = 512;
  let byteCharacters = window.atob(content); //method which converts base64 to binary
  let byteArrays = [
  ];
  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    let slice = byteCharacters.slice(offset, offset + sliceSize);
    let byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }
    let byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }
  let blob = new Blob(byteArrays, {
    type: contentType
  }); //statement which creates the blob
  return blob;
}
