<div class="content">
  <div>
    <h1>Equipment List</h1>
  </div>

  <div class="asset-list-buttons">
    <div style="display: flex; flex-direction: row; align-items: center; justify-content: center;">
      <div class="form-input" style="margin-top: 15px; margin-right: 15px;">
        <mat-form-field appearance="fill">
          <mat-label class="form-label">Select Colour</mat-label>
          <mat-select name="colourId" [(ngModel)]="selectedColourId" (selectionChange)="onColourChanged($event.value)">
            <mat-option *ngFor="let colour of colours" [value]="colour.colourId">{{ colour.colourName }}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <button class="add-btn" type="button" (click)="addEquipment()">
        ADD NEW EQUIPMENT
      </button>
    </div>


    <div class="spacer"></div>

    <button *ngIf="pdaUser == null" class="add-btn" type="button" (click)="pdaUserLogin()">
      PDA USER SIGN IN
    </button>

    <button *ngIf="pdaUser != null" class="add-btn" type="button" (click)="pdaUserLogout()">
      PDA USER SIGN OUT ({{ pdaUser.name }})
    </button>
  </div>

  <form class="panel" (ngSubmit)="loadAssetsPage()">
    <div class="panel-section-grid">
      <div class="panel-section">
        <mat-form-field>
          <mat-icon matPrefix style="padding-right: 10px;">search</mat-icon>
          <input type="text" matInput name="filter" placeholder="Name" [(ngModel)]="filter">
        </mat-form-field>
      </div>
    </div>

    <div class="panel-btns__block">
      <button class="panel-btn panel-btn-margin" type="submit">
        FILTER
      </button>
    </div>
  </form>

  <div class="spinner-container" *ngIf="dataSource.loading$ | async">
    <mat-spinner [diameter]="'50'"></mat-spinner>
  </div>
  <!--class="data-table mat-elevation-z8" fixedLayout="true"-->

  <table mat-table matSort matSortActive="inspectedAt" matSortDirection="asc" matSortDisableClear [dataSource]="dataSource">

    <ng-container matColumnDef="checkbox">
      <th mat-header-cell *matHeaderCellDef><mat-checkbox (change)="selectAll()"></mat-checkbox></th>
      <td mat-cell *matCellDef="let element"><mat-checkbox *ngIf="!element.completed" [(ngModel)]="element.isSelected"></mat-checkbox></td>
    </ng-container>

    <ng-container matColumnDef="description">
      <th mat-header-cell mat-sort-header *matHeaderCellDef (click)="loadAssetsPage()">DESCRIPTION</th>
      <td mat-cell *matCellDef="let element"><a style="color: black; text-align: center" class="truncate-text" href="javascript:void(0)" (click)="editEquipment(element.equipmentId)">{{element.description}}</a></td>
    </ng-container>

    <ng-container matColumnDef="categoryName">
      <th mat-header-cell mat-sort-header *matHeaderCellDef (click)="loadAssetsPage()">CATEGORY</th>
      <td mat-cell *matCellDef="let element"><span class="truncate-text">{{element.categoryName}}</span></td>
    </ng-container>

    <ng-container matColumnDef="name">
      <th mat-header-cell mat-sort-header *matHeaderCellDef (click)="loadAssetsPage()">LOCATION</th>
      <td mat-cell *matCellDef="let element"><span class="truncate-text">{{element.name}}</span></td>
    </ng-container>

    <ng-container matColumnDef="serialNum">
      <th mat-header-cell mat-sort-header *matHeaderCellDef (click)="loadAssetsPage()">SERIAL #</th>
      <td mat-cell *matCellDef="let element"><span class="truncate-text">{{element.serialNum}}</span></td>
    </ng-container>

    <ng-container matColumnDef="assetNum">
      <th mat-header-cell mat-sort-header *matHeaderCellDef (click)="loadAssetsPage()">ASSET #</th>
      <td mat-cell *matCellDef="let element"><span class="truncate-text">{{element.assetNum}}</span></td>
    </ng-container>

    <ng-container matColumnDef="manuf">
      <th mat-header-cell mat-sort-header *matHeaderCellDef (click)="loadAssetsPage()">MANUF</th>
      <td mat-cell *matCellDef="let element"><span class="truncate-text">{{element.manuf}}</span></td>
    </ng-container>

    <ng-container matColumnDef="inspectedAt">
      <th mat-header-cell mat-sort-header *matHeaderCellDef (click)="loadAssetsPage()">LAST INSP DATE</th>
      <td mat-cell *matCellDef="let element"><span class="truncate-text">{{element.inspectedAt}}</span></td>
    </ng-container>

    <ng-container matColumnDef="otherDetails">
      <th mat-header-cell mat-sort-header *matHeaderCellDef (click)="loadAssetsPage()">OTHER DETAILS</th>
      <td mat-cell *matCellDef="let element"><span class="truncate-text">{{element.otherDetails}}</span></td>
    </ng-container>

    <ng-container matColumnDef="completed">
      <th mat-header-cell mat-sort-header *matHeaderCellDef (click)="loadAssetsPage()">COMPLETED</th>
      <td mat-cell *matCellDef="let element" style="text-align: center">
        <mat-icon *ngIf="element.completed" aria-hidden="false">done</mat-icon>
        <a style="color: black; text-align: center" href="javascript:void(0)" *ngIf="!element.completed" (click)="inspect(element.locationInspectionEquipId, element.equipmentId)">
          INSPECT
          <!-- <mat-icon aria-hidden="false">checklist</mat-icon> -->
        </a>
      </td>
    </ng-container>

    <ng-container matColumnDef="archived">
      <th mat-header-cell mat-sort-header *matHeaderCellDef (click)="loadAssetsPage()">ARCHIVED</th>
      <td mat-cell *matCellDef="let element"><span *ngIf="element.archived" class="truncate-text">Archived - <a href="javascript:void(0)" (click)="unarchive(element.equipmentId)">Make Active</a></span></td>
    </ng-container>

    <ng-container matColumnDef="clientAssetNumber">
      <th mat-header-cell mat-sort-header *matHeaderCellDef (click)="loadAssetsPage()">CLIENT ASSET #</th>
      <td mat-cell *matCellDef="let element"><span class="truncate-text">{{element.clientAssetNum}}</span></td>
    </ng-container>

    <ng-container matColumnDef="department">
      <th mat-header-cell mat-sort-header *matHeaderCellDef (click)="loadAssetsPage()">DEPARTMENT</th>
      <td mat-cell *matCellDef="let element"><span class="truncate-text">{{element.department}}</span></td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns" ></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;" [ngStyle]="{'background-color': row.backgroundClass}"></tr>
  </table>

  <mat-paginator [pageSizeOptions]="[10, 25, 50, 100]" [pageSize]='10' [length]="dataSource.total$ | async" showFirstLastButtons></mat-paginator>

  <div class="asset-list-buttons inspect-buttons" *ngIf="pdaUser != null">
    <button class="add-btn" type="button" (click)="inspectSelected()">
      Inspect Selected
    </button>

    <p> as passed and safe for use with no comments on date</p>

    <mat-form-field appearance="fill">
      <mat-label>Inspection Date</mat-label>
      <input matInput [(ngModel)]="inspectionDate" [matDatepicker]="picker">
      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-datepicker #picker></mat-datepicker>
    </mat-form-field>

    <button class="add-btn" type="button" (click)="missingSelected()">
      Missing Selected
    </button>

    <button class="add-btn" type="button" (click)="decomSelected()">
      Decomission Selected
    </button>

    <div class="spacer"></div>

    <button class="add-btn" type="button" (click)="moveSelected()">
      Change Locations
    </button>
  </div>
</div>
