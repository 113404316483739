import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { AuthService } from 'src/app/services/auth.service';
import { InspectionService } from 'src/app/services/inspection.service';

enum State {
  selectList,
  thankYou
}

@Component({
  selector: 'app-notify-dialog',
  templateUrl: './notify-dialog.component.html',
  styleUrls: ['./notify-dialog.component.scss']
})
export class NotifyDialogComponent implements OnInit {


  dataSource: MatTableDataSource<any>;
  displayedColumns: string[] = ['serialNum', 'description'];
  @ViewChild(MatPaginator) paginator: MatPaginator;

  notes: "";

  currentState = State.selectList;

  constructor(private inspectionService: InspectionService, public dialogRef: MatDialogRef<NotifyDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {inspections, companyId: number}, private authService: AuthService) { }

  ngOnInit(): void {
    
  }

  ngAfterViewInit() {
    this.dataSource = new MatTableDataSource(this.data.inspections);
    this.dataSource.paginator = this.paginator;
    console.log(this.paginator);
  }

  notify(notifyType) {
    var user = this.authService.currentUser.value;

    this.inspectionService.notifyRanger(this.data.inspections.map(i => i.inspectionId), notifyType, this.notes, user.firstname + ' ' + user.lastname);
    //this.dialogRef.close();
    this.currentState = State.thankYou;
  }

  cancel() {
    this.dialogRef.close();
  }
}
