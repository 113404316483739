<div class="content">
  <h1>Completed Jobs</h1>

  <form class="panel" (ngSubmit)="loadTable()">
    <div class="panel-section-grid">
      <div class="panel-section">
        <mat-form-field appearance="fill">
          <mat-label class="form-label">State</mat-label>
          <mat-select [(ngModel)]="filter.stateId" name="stateId" (selectionChange)="stateChanged($event)">
            <mat-option [value]="-1">All</mat-option>
            <mat-option [value]="state.stateId" *ngFor="let state of states">{{ state.stateName }}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div class="panel-section">
        <mat-form-field appearance="fill">
          <mat-label class="form-label">Company</mat-label>
          <mat-select [(ngModel)]="filter.companyId" name="companyId" (selectionChange)="companyChanged($event)">
            <mat-option [value]="company.companyId" *ngFor="let company of companies">{{ company.companyName }}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div class="panel-section">
        <mat-form-field appearance="fill">
          <mat-label class="form-label">Location</mat-label>
          <mat-select [(ngModel)]="filter.locationId" name="locationId">
            <mat-option [value]="-1">All</mat-option>
            <mat-option [value]="location.locationId" *ngFor="let location of locations">{{ location.name }}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div class="panel-section">
        <mat-form-field>
          <mat-label class="form-label">Acc #</mat-label>
          <input type="text" name="accNum" [(ngModel)]="filter.accNum" matInput placeholder="Acc #">
        </mat-form-field>
      </div>

      <div class="panel-section">
        <app-datepicker [startDate]="filter.startDate" [endDate]="filter.endDate" (onChange)="updateDates($event)"></app-datepicker>
        <!-- <mat-form-field appearance="fill">
          <mat-label>Date Range</mat-label>
          <mat-date-range-input [rangePicker]="picker" >
            <input matStartDate name="startDate" [(ngModel)]="filter.startDate" placeholder="Start date">
            <input matEndDate name="endDate" [(ngModel)]="filter.endDate" placeholder="End date">
          </mat-date-range-input>
          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-date-range-picker #picker></mat-date-range-picker>
        </mat-form-field> -->
      </div>

      <div class="panel-section">
        <mat-form-field>
          <mat-label class="form-label">Job #</mat-label>
          <input type="text" name="salesOrderNum" [(ngModel)]="filter.salesOrderNum" matInput placeholder="Job #">
        </mat-form-field>
      </div>
    </div>

    <button class="panel-btn panel-btn-margin" type="submit">
      FILTER
    </button>
  </form>

  <div class="spinner-container" *ngIf="(dataSource.loading$ | async)">
    <mat-spinner [diameter]="'50'"></mat-spinner>
  </div>


  <table mat-table class="data-table mat-elevation-z8" [dataSource]="dataSource" fixedLayout="true">
  
    <ng-container matColumnDef="company">
      <th mat-header-cell *matHeaderCellDef>COMPANY</th>
      <td mat-cell *matCellDef="let element"><span class="truncate-text">{{element.companyName}}</span></td>
    </ng-container>

    <ng-container matColumnDef="location">
      <th mat-header-cell *matHeaderCellDef>LOCATION</th>
      <td mat-cell *matCellDef="let element"><span class="truncate-text">{{element.locationName}}</span></td>
    </ng-container>

    <ng-container matColumnDef="acc">
      <th mat-header-cell *matHeaderCellDef>ACC #</th>
      <td mat-cell *matCellDef="let element"><span class="truncate-text">{{element.accNum}}</span></td>
    </ng-container>

    <ng-container matColumnDef="salesOrder">
      <th mat-header-cell *matHeaderCellDef>JOB #</th>
      <td mat-cell *matCellDef="let element"><span class="truncate-text">{{element.salesOrderNum}}</span></td>
    </ng-container>

    <ng-container matColumnDef="scheduledInspectionDate">
      <th mat-header-cell *matHeaderCellDef>Next Inspection Date</th>
      <td mat-cell *matCellDef="let element"><span class="truncate-text">{{element.nextScheduledDate}}</span></td>
    </ng-container>


    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>

  <mat-paginator [pageSizeOptions]="[10, 25, 50, 100]" [pageSize]='10' [length]="dataSource.total$ | async" showFirstLastButtons></mat-paginator>
</div>