<mat-form-field appearance="fill">
  <mat-label class="form-label">{{ label }}</mat-label>

  <mat-select  [formControl]="selectedId" #singleSelect (selectionChange)="selectionChanged($event.value)">
    <mat-option>
      <ngx-mat-select-search [placeholderLabel]="'Search'" [formControl]="filter"></ngx-mat-select-search>
    </mat-option>

    <mat-option *ngFor="let data of filteredList" [value]="data[value]">
      {{ data[name] }}
    </mat-option>
  </mat-select>
</mat-form-field>