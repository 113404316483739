<div class="dialog-content">
  <h1>Image Upload</h1>

  <input #csvInput hidden="true" type="file" onclick="this.value=null" (change)="inputChange($event)" accept=".png,.jpg,.jpeg"/>
  <button mat-flat-button color="primary" (click)="csvInput.click()">Upload Image</button>

  <table>
    <thead>
      <tr>
        <th></th>
        <th>File Name</th>
        <th>File Size</th>
        <th>Timestamp</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let image of images">
        <td><mat-icon (click)="deleteImage(image.filename)" class="material-icons-outlined">delete_outline</mat-icon></td>
        <td>{{ image.filename }}</td>
        <td>{{ image.filesize }}</td>
        <td>{{ image.timestamp }}</td>
      </tr>
    </tbody>
  </table>
</div>