<div class="content">
  <h1>CHANGE PASSWORD</h1>

  <div class="form-controls">
    <div class="form-title">CHANGE YOUR PASSWORD</div>
    <div class="form-input">
      <mat-form-field appearance="fill">
        <mat-label class="form-label">Current Password</mat-label>
        <input matInput [(ngModel)]="currentPassword">
      </mat-form-field>
    </div>
    <div class="form-input">
      <mat-form-field appearance="fill">
        <mat-label class="form-label">New Password</mat-label>
        <input matInput [(ngModel)]="newPassword">
      </mat-form-field>
    </div>

    <div class="form-input">
      <mat-form-field appearance="fill">
        <mat-label class="form-label">Confirm Password</mat-label>
        <input matInput [(ngModel)]="newPassword2">
      </mat-form-field>
    </div>
     
    <button type="submit" class="add-btn" (click)="changePassword()">CHANGE</button>
  </div>
</div>