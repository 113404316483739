import { Injectable } from "@angular/core";
import { environment } from '../../../environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';
import * as moment from "moment";
import { Observable } from "rxjs";
import { AuthService } from "../auth.service";


@Injectable()
export class DashboardService {
  constructor(private http: HttpClient, private authService: AuthService) {
    
  }

  saveSearchFilters(filters) {
    var user = this.authService.currentUser.value;
    localStorage.setItem(`@${user.userId}`, JSON.stringify(filters));
  }

  getSearchFilters() {
    var user = this.authService.currentUser.value;
    return JSON.parse(localStorage.getItem(`@${user.userId}`));
  }

  exportReportBatch(ids: any) : Promise<any> {
    const headers = {
      'Content-Type': 'application/json',
    };

    return new Promise((resolve, reject) => {
      var dto = {
        ids: ids
      }

      this.http.post<any>(environment.apiUrl + "dashboard/postReportBatch", JSON.stringify(dto), { headers: headers }).subscribe(data => {
        resolve(data);
      }, (error) => {
        resolve(null)
      });
    });
  }

  getStats(companyId: number) : Promise<any> {
    const headers = {
      'Content-Type': 'application/json',
    };

    return new Promise((resolve, reject) => {
      this.http.get<any>(environment.apiUrl + "dashboard/getstats?companyId=" + companyId, { headers: headers }).subscribe(data => {
        resolve(data);
      }, (error) => {
        resolve(null)
      });
    });
  }

  getJobs(companyId: number, locationId: number, startDate: string, endDate: string, sortOrder: string = 'asc', pageIndex: number = 0, pageSize: number = 10) : Observable<[]> {
    const headers = {
      'Content-Type': 'application/json',
    };

    startDate = moment(startDate).format("YYYY-MM-DD");
    endDate = moment(endDate).format("YYYY-MM-DD");

    var httpParams = new HttpParams()
    .set('companyId', companyId.toString())
    .set('locationId', locationId.toString())
    .set('startDate', startDate.toString())
    .set('endDate', endDate.toString())
    .set('sortOrder', sortOrder)
    .set('pageIndex', pageIndex.toString())
    .set('pageSize', pageSize.toString());

    return this.http.get<[]>(environment.apiUrl + "dashboard/getjobs", { 
      headers: headers,
      params: httpParams
    });
  }

  getInService(companyId: number, serial: string, sortOrder: string = 'asc', pageIndex: number = 0, pageSize: number = 10) : Observable<[]> {
    const headers = {
      'Content-Type': 'application/json',
    };

    var httpParams = new HttpParams()
    .set('companyId', companyId.toString())
    .set('serial', serial)
    .set('sortOrder', sortOrder)
    .set('pageIndex', pageIndex.toString())
    .set('pageSize', pageSize.toString());

    return this.http.get<[]>(environment.apiUrl + "dashboard/getinservice", { 
      headers: headers,
      params: httpParams
    });
  }

  getAssetRegister(companyId, statusIds, categoryIds, locationIds, startDate, endDate, serialNum, jobNo, sortOrder: string = 'asc', pageIndex: number = 0, pageSize: number = 10) : Observable<[]> {
    const headers = {
      'Content-Type': 'application/json',
    };

    startDate = moment(startDate).format("YYYY-MM-DD");
    endDate = moment(endDate).format("YYYY-MM-DD");
    var dto = {
      companyId: companyId,
      statusIds: statusIds.map(s => parseInt(s)),
      categoryIds: categoryIds.map(c => parseInt(c)),
      locationIds: locationIds.map(l => parseInt(l)),
      startDate: startDate,
      endDate: endDate,
      serialNum: serialNum,
      jobNo: jobNo,
    }

    var httpParams = new HttpParams()
    .set('sortOrder', sortOrder)
    .set('pageIndex', pageIndex.toString())
    .set('pageSize', pageSize.toString());

    return this.http.post<[]>(environment.apiUrl + "report/GetDashboardAssetRegister", JSON.stringify(dto), { 
      headers: headers,
      params: httpParams
    });
  }

  getAssetStatusRegisterByNextInspectionDate(companyId, months, years, sortOrder: string = 'asc', pageIndex: number = 0, pageSize: number = 10) : Observable<[]> {
    const headers = {
      'Content-Type': 'application/json',
    };

    var dto = {
      companyId: companyId,
      months: months,
      years: years
    }

    var httpParams = new HttpParams()
    .set('sortOrder', sortOrder)
    .set('pageIndex', pageIndex.toString())
    .set('pageSize', pageSize.toString());

    return this.http.post<[]>(environment.apiUrl + "report/GetDashboardAssetStatusRegisterByNextInspectionDate", JSON.stringify(dto), { 
      headers: headers,
      params: httpParams
    });
  }


  exportAssetRegister(companyId, statusIds, jobNo, serialNum, startDate, endDate, categoryIds, locationIds) : Promise<any> {
    const headers = {
      'Content-Type': 'application/json',
    };

    var dto = {
      companyId: companyId,
      statusIds: statusIds.map(s => parseInt(s)),
      jobNo: jobNo,
      serialNum: serialNum,
      startDate: startDate,
      endDate: endDate,
      categoryIds: categoryIds.map(s => parseInt(s)),
      locationIds: locationIds.map(s => parseInt(s))
    }

    return new Promise((resolve, reject) => {
      this.http.post<any>(environment.apiUrl + "report/ExportDashboardAssetRegister", JSON.stringify(dto), { headers: headers }).subscribe(data => {
        let blob = this.convertBase64toBlob(data.value, 'application/vnd.ms-excel');
        resolve(blob);
      }, (error) => {
        resolve(null)
      });
    });
  }

  getAssetStatusRegister(companyId, statusIds, jobNo, serialNum, startDate, endDate, categoryIds, locationIds, sortOrder: string = 'asc', pageIndex: number = 0, 
  pageSize: number = 10) : Observable<[]> {
    const headers = {
      'Content-Type': 'application/json',
    };

    var dto = {
      companyId: companyId,
      statusIds: statusIds.map(s => parseInt(s)),
      jobNo: jobNo,
      serialNum: serialNum,
      startDate: startDate,
      endDate: endDate,
      categoryIds: categoryIds.map(s => parseInt(s)),
      locationIds: locationIds.map(s => parseInt(s))
    }

    var httpParams = new HttpParams()
    .set('sortOrder', sortOrder)
    .set('pageIndex', pageIndex.toString())
    .set('pageSize', pageSize.toString());

    return this.http.post<[]>(environment.apiUrl + "report/GetDashboardAssetStatusRegister", JSON.stringify(dto), { 
      headers: headers,
      params: httpParams
    });
  }

  getRequiresAttention(companyId: number, serial: string, sortOrder: string = 'asc', pageIndex: number = 0, pageSize: number = 10) : Observable<[]> {
    const headers = {
      'Content-Type': 'application/json',
    };

    var httpParams = new HttpParams()
    .set('companyId', companyId.toString())
    .set('serial', serial)
    .set('sortOrder', sortOrder)
    .set('pageIndex', pageIndex.toString())
    .set('pageSize', pageSize.toString());

    return this.http.get<[]>(environment.apiUrl + "dashboard/getrequiresattention", { 
      headers: headers,
      params: httpParams
    });
  }

  getOutOfService(companyId: number, serial: string, sortOrder: string = 'asc', pageIndex: number = 0, pageSize: number = 10) : Observable<[]> {
    const headers = {
      'Content-Type': 'application/json',
    };

    var httpParams = new HttpParams()
    .set('companyId', companyId.toString())
    .set('serial', serial)
    .set('sortOrder', sortOrder)
    .set('pageIndex', pageIndex.toString())
    .set('pageSize', pageSize.toString());

    return this.http.get<[]>(environment.apiUrl + "dashboard/getoutofservice", { 
      headers: headers,
      params: httpParams
    });
  }

  getRepaired(companyId: number, serial: string,sortOrder: string = 'asc', pageIndex: number = 0, pageSize: number = 10) : Observable<[]> {
    const headers = {
      'Content-Type': 'application/json',
    };

    var httpParams = new HttpParams()
    .set('companyId', companyId.toString())
    .set('serial', serial)
    .set('sortOrder', sortOrder)
    .set('pageIndex', pageIndex.toString())
    .set('pageSize', pageSize.toString());

    return this.http.get<[]>(environment.apiUrl + "dashboard/getrepaired", { 
      headers: headers,
      params: httpParams
    });
  }

  getMissing(companyId: number, serial: string, sortOrder: string = 'asc', pageIndex: number = 0, pageSize: number = 10) : Observable<[]> {
    const headers = {
      'Content-Type': 'application/json',
    };

    var httpParams = new HttpParams()
    .set('companyId', companyId.toString())
    .set('serial', serial)
    .set('sortOrder', sortOrder)
    .set('pageIndex', pageIndex.toString())
    .set('pageSize', pageSize.toString());

    return this.http.get<[]>(environment.apiUrl + "dashboard/getmissing", { 
      headers: headers,
      params: httpParams
    });
  }

  getCondemned(companyId: number, serial: string, sortOrder: string = 'asc', pageIndex: number = 0, pageSize: number = 10) : Observable<[]> {
    const headers = {
      'Content-Type': 'application/json',
    };

    var httpParams = new HttpParams()
    .set('companyId', companyId.toString())
    .set('serial', serial)
    .set('sortOrder', sortOrder)
    .set('pageIndex', pageIndex.toString())
    .set('pageSize', pageSize.toString());

    return this.http.get<[]>(environment.apiUrl + "dashboard/getcondemned", { 
      headers: headers,
      params: httpParams
    });
  }

  exportInService(companyId) : Promise<any> {
    const headers = {
      'Content-Type': 'application/json',
    };

    var httpParams = new HttpParams()
    .set('companyId', companyId.toString());

    return new Promise((resolve, reject) => {
      this.http.get<any>(environment.apiUrl + "dashboard/getexportinservice?companyId=" + companyId).subscribe(data => {
        resolve(data);
      });
    });
  }

  exportRequiresAttention(companyId) : Promise<any> {
    const headers = {
      'Content-Type': 'application/json',
    };

    return new Promise((resolve, reject) => {
      this.http.get<any>(environment.apiUrl + "dashboard/getexportrequiresattention?companyId=" + companyId, { headers: headers }).subscribe(data => {
        resolve(data);
      });
    });
  }

  exportOutOfService(companyId) : Promise<any> {
    const headers = {
      'Content-Type': 'application/json',
    };

    return new Promise((resolve, reject) => {
      this.http.get<any>(environment.apiUrl + "dashboard/getexportoutofservice?companyId=" + companyId, { headers: headers }).subscribe(data => {
        resolve(data);
      });
    });
  }

  exportRepaired(companyId) : Promise<any> {
    const headers = {
      'Content-Type': 'application/json',
    };

    return new Promise((resolve, reject) => {
      this.http.get<any>(environment.apiUrl + "dashboard/getexportrepaired?companyId=" + companyId, { headers: headers }).subscribe(data => {
        resolve(data);
      });
    });
  }

  exportMissing(companyId) : Promise<any> {
    const headers = {
      'Content-Type': 'application/json',
    };

    return new Promise((resolve, reject) => {
      this.http.get<any>(environment.apiUrl + "dashboard/getexportMissing?companyId=" + companyId, { headers: headers }).subscribe(data => {
        resolve(data);
      });
    });
  }

  exportCondemned(companyId) : Promise<any> {
    const headers = {
      'Content-Type': 'application/json',
    };

    return new Promise((resolve, reject) => {
      this.http.get<any>(environment.apiUrl + "dashboard/getExportCondemned?companyId=" + companyId, { headers: headers }).subscribe(data => {
        resolve(data);
      });
    });
  }

  sendRigPurchaseEmail(email: string, name: string, subject: string) : Observable<[]> {
    const headers = {
      'Content-Type': 'application/json',
    };

    var dto = {
      email: email,
      name: name,
      subject: subject
    }

    return this.http.post<[]>(environment.apiUrl + "email/sendrigpurchase", JSON.stringify(dto), { 
      headers: headers
    });
  }

  convertBase64toBlob(content, contentType) {
    contentType = contentType || '';
    let sliceSize = 512;
    let byteCharacters = window.atob(content); //method which converts base64 to binary
    let byteArrays = [
    ];
    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      let slice = byteCharacters.slice(offset, offset + sliceSize);
      let byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
      let byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }
    let blob = new Blob(byteArrays, {
      type: contentType
    }); //statement which creates the blob
    return blob;
  }
}