import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { AusStd } from 'src/app/models/aus-std';
import { AusStdService } from 'src/app/services/administration/aus-std.service';
import { DataServiceParam } from 'src/app/service-data-sources/data-table/data-service-param';
import { TableDataSource } from 'src/app/service-data-sources/data-table/table-data-source';
import { AusStdManageComponent } from './aus-std-manage/aus-std-manage.component';

@Component({
  selector: 'app-australian-standard-management',
  templateUrl: './australian-standard-management.component.html',
  styleUrls: ['./australian-standard-management.component.scss']
})
export class AustralianStandardManagementComponent implements OnInit {

  @ViewChild(MatPaginator) paginator: MatPaginator;
  
  dataSource: TableDataSource<AusStd>;
  displayedColumns: string[] = ['australianStd', 'edit'];

  filter: string = "";

  constructor(private ausStdService: AusStdService, public dialog: MatDialog) { }

  ngOnInit(): void {
    this.dataSource = new TableDataSource(this.ausStdService);
    this.loadTable();
  }

  loadTable() {
    var params: DataServiceParam[] = [];
    params.push(<DataServiceParam>{ paramName: 'filter', paramValue: this.filter });

    var func = this.ausStdService.getTable(params);
    console.log(func);

    //var method = this.dataSource.loadTable())
    this.dataSource.loadData(params);
  }

  loadTablePage() {
    var params: DataServiceParam[] = [];
    params.push(<DataServiceParam>{ paramName: 'filter', paramValue: this.filter });
    this.dataSource.loadData(params, 'asc', this.paginator.pageIndex, this.paginator.pageSize);
  }
  
  add() {
    const dialogRef = this.dialog.open(AusStdManageComponent, {
      width: '900px',
      // data: {name: this.name, animal: this.animal}
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.loadTable();
      }
    });
  }

  edit(ausStdId) {
    const dialogRef = this.dialog.open(AusStdManageComponent, {
      width: '900px',
      data: {ausStdId: ausStdId}
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.loadTable();
      }
    });
  }

}
