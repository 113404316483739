import { Injectable } from "@angular/core";
import { environment } from '../../../environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from "rxjs";
import { Location } from "src/app/models/location";
import { AusStd } from "src/app/models/aus-std";
import { DataService } from "../../service-data-sources/data-table/data-service-interface";
import { DataServiceParam } from "../../service-data-sources/data-table/data-service-param";


@Injectable()
export class AusStdService implements DataService {
  constructor(private http: HttpClient) {
    
  }

  get(ausStdId: number) : Promise<AusStd> {
    const headers = {
      'Content-Type': 'application/json',
    };

    return new Promise((resolve, reject) => {
      this.http.get<any>(environment.apiUrl + "ausstd/get?ausStdId=" + ausStdId, { headers: headers }).subscribe(data => {
        resolve(data);
      }, (error) => {
        resolve(null)
      });
    });
  }

  getAll(includePleaseSelect: boolean = false, pleaseSelectLabel = "Please Select") : Promise<any> {
    const headers = {
      'Content-Type': 'application/json',
    };

    return new Promise((resolve, reject) => {
      this.http.get<any>(environment.apiUrl + "ausstd/getall", { headers: headers }).subscribe(data => {
        if (includePleaseSelect) {
          var pleaseSelect = new AusStd();
          pleaseSelect.australianStdId = 0;
          pleaseSelect.australianStd = pleaseSelectLabel;
          data.unshift(pleaseSelect);
        }

        resolve(data);
      }, (error) => {
        resolve(null)
      });
    });
  }

  getTable(params: DataServiceParam[], sortOrder: string = 'asc', pageIndex: number = 0, pageSize: number = 10) : Observable<AusStd[]> {
    const headers = {
      'Content-Type': 'application/json',
    };

    var httpParams = new HttpParams()
      .set('sortOrder', sortOrder)
      .set('pageIndex', pageIndex.toString())
      .set('pageSize', pageSize.toString());

    for (let param of params) {
      httpParams = httpParams.append(param.paramName, param.paramValue);
    }

    return this.http.get<AusStd[]>(environment.apiUrl + "ausstd/gettable", { 
      headers: headers,
      params: httpParams
    });
  }

  save(ausStd: AusStd) : Promise<boolean> {
    const headers = {
      'Content-Type': 'application/json',
    };

    return new Promise((resolve, reject) => {
      this.http.post<any>(environment.apiUrl + "ausstd/post", ausStd, { headers: headers }).subscribe(data => {
        resolve(true);
      }, (error) => {
        resolve(false)
      });
    });
  }
}