import { Component, OnInit } from '@angular/core';
import { ReportableService } from 'src/app/services/reportable.service';
import { ZipDownloaderService } from './zip-downloader-service';

import { saveAs as importedSaveAs } from 'file-saver';
import * as JSZip from 'jszip';

@Component({
  selector: 'app-zip-downloader',
  templateUrl: './zip-downloader.component.html',
  styleUrls: ['./zip-downloader.component.scss']
})
export class ZipDownloaderComponent implements OnInit {

  jobs = [];

  constructor(private zipService: ZipDownloaderService, private reportableService: ReportableService) { 
    zipService.jobs.subscribe((jobs) => {
      // We only want to add the pending jobs to our job list as IN_PROGRESS jobs are currently scheduled
      for (let job of jobs.filter(j => j.status == 'PENDING')) {
        this.jobs.push(job);
        
        // Start job download
        this.downloadReports(job);
      }
    });
  }

  ngOnInit(): void {
  }

  async downloadReports(job) {
    job.status = "IN_PROGRESS";

    var zip = new JSZip();

    var promises = [];
    var count = 0;
    for (let inspection of job.inspections) {
      count++;

      var exportFunc = null;

      switch (job.jobType) {
        case "Lifting":
          //base64 = await this.reportableService.getLiftingExamBase64(inspection.inspectionId);
          exportFunc = this.reportableService.getLiftingExamBase64(inspection.inspectionId, inspection.safeForUse);
          break;
        case "Nata":
          // base64 = await this.reportableService.getNataCertBase64(inspection.inspectionId);
          exportFunc = this.reportableService.getNataCertBase64(inspection.inspectionId);
          break;
        case "New":
          //base64 = await this.reportableService.getServiceCertBase64(inspection.inspectionId);
          exportFunc = this.reportableService.getServiceCertBase64(inspection.inspectionId);
          break;
        case "Repair":
          //base64 = await this.reportableService.getRepairCertBase64(inspection.inspectionId);
          exportFunc = this.reportableService.getRepairCertBase64(inspection.inspectionId);
          break;
      }

      promises.push(new Promise((resolve, reject) => {
        try {
          exportFunc.then(base64 => {
            job.downloaded += 1;
            job.percentage = (job.downloaded / job.total) * 100;
            
            var fileName = inspection.serialNum.replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, '');
            zip.file(fileName + " - " + count + ".pdf", base64, { base64: true });
    
            resolve(true);
          }, (error) => {
            resolve(true);
          })
        } catch {
          resolve(true);
        }
      }));
      // job.downloaded += 1;
      // job.percentage = (job.downloaded / job.total) * 100;

      // var fileName = inspection.serialNum.replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, '')
      // zip.file(fileName + " - " + count + ".pdf", base64, { base64: true });
    }

    Promise.all(promises).then(() => {
      zip.generateAsync({ type: "blob"}, (update) => {
        job.status = "SAVING";
        job.percentage = update.percent;
      }).then((content) => {
        importedSaveAs(content, job.name + ".zip");
        this.jobs.splice(this.jobs.indexOf(job), 1);
        this.zipService.removeJob(job);
      })
    });
    
   

   
  }
}
