<div class="content">
  <h1>ASSET REGISTER</h1>

  <form class="panel" (ngSubmit)="loadTable()">
    <div class="panel-section-grid">
      <div class="panel-section">
        <app-search-select [(ngModel)]="filter.companyId" [label]="'Companies'" [list]="companies" [value]="'companyId'" [name]="'companyName'" (onChanged)="companyChanged($event)"></app-search-select>
      </div>
  
      <div class="panel-section">
        <app-search-select [(ngModel)]="filter.locationId" [label]="'Locations'" [list]="locations" [value]="'locationId'" [name]="'name'"></app-search-select>
      </div>
  
      <div class="panel-section">
        <app-search-select [(ngModel)]="filter.categoryId" [label]="'Categories'" [list]="categories" [value]="'categoryId'" [name]="'categoryName'"></app-search-select>
      </div>
  
      <div class="panel-section">
        <mat-form-field>
          <mat-label class="form-label">Job #</mat-label>
          <input type="text" name="salesOrderNum" [(ngModel)]="filter.salesOrderNum" matInput placeholder="Job #">
        </mat-form-field>
      </div>

      <div class="panel-section">
        <mat-form-field>
          <mat-label class="form-label">Serial #</mat-label>
          <input type="text" name="serial" [(ngModel)]="filter.serialNum" matInput placeholder="Serial #" >
        </mat-form-field>
      </div>
  
      <div class="panel-section">
        <mat-form-field appearance="fill">
          <mat-label class="form-label">Status</mat-label>
          <mat-select name="showPassFail" [(ngModel)]="filter.status">
            <mat-option value="All">All</mat-option>
            <mat-option value="Pass">Pass</mat-option>
            <mat-option value="Fail">Fail</mat-option>
            <mat-option value="Missing">Missing</mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div class="panel-section">
        <mat-form-field>
          <mat-label class="form-label">Inspection Due Before</mat-label>
          <input type="text" name="inspDueBefore" [(ngModel)]="filter.inspBeforeText" matInput placeholder="Insp Due Before" >
        </mat-form-field>
      </div>

      <div class="panel-section">
        <mat-form-field appearance="fill">
          <mat-label class="form-label">Decomissioned</mat-label>
          <mat-select name="decomissioned" [(ngModel)]="filter.decomissioned">
            <mat-option value="-1">All</mat-option>
            <mat-option value="1">YES</mat-option>
            <mat-option value="0">NO</mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div class="panel-section">
        <mat-form-field>
          <mat-label class="form-label">Department</mat-label>
          <input type="text" name="department" [(ngModel)]="filter.department" matInput placeholder="Department">
        </mat-form-field>
      </div>
  
      <div class="panel-section">
        <mat-checkbox name="showArchived"  name="showArchived" [(ngModel)]="filter.showArchived">Show Archived</mat-checkbox>
      </div>
    </div>
  
    
    <button class="panel-btn panel-btn-margin" type="submit">
      FILTER
    </button>
  </form>

  <div class="spinner-container" *ngIf="(dataSource.loading$ | async)">
    <mat-spinner [diameter]="'50'"></mat-spinner>
  </div>

  <table mat-table class="data-table mat-elevation-z8" [dataSource]="dataSource" fixedLayout="true">
    
    <ng-container matColumnDef="links">
      <th mat-header-cell *matHeaderCellDef>Links</th>
      <td mat-cell *matCellDef="let element">
        <div class="table-links__block">
          <span *ngIf="!element.certLoading && element.inspectionId != null" (click)="downloadCert(element.inspectionId, element.safeForUse, element)" class="truncate-text table-link clickable-link"><mat-icon aria-hidden="false">summarize</mat-icon></span>
          <mat-progress-spinner class="table-link" *ngIf="element.certLoading && element.inspectionId != null" [diameter]="'16'" [color]="'#000'" [mode]="'indeterminate'"></mat-progress-spinner>
          <a href="javascript:void(0)" (click)="loadTests(element.equipmentId)" class="table-link truncate-text clickable-url"><img class="table-link-img" src="/assets/loadtest.png"/></a>
        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="companyName">
      <th mat-header-cell *matHeaderCellDef>Company</th>
      <td mat-cell *matCellDef="let element"><span class="truncate-text">{{element.companyName}}</span></td>
    </ng-container>

    <ng-container matColumnDef="locationName">
      <th mat-header-cell *matHeaderCellDef>Location</th>
      <td mat-cell *matCellDef="let element"><span class="truncate-text">{{element.locationName}}</span></td>
    </ng-container>

    <ng-container matColumnDef="description">
      <th mat-header-cell *matHeaderCellDef>Description</th>
      <td mat-cell *matCellDef="let element">
        <a href="javascript:void(0)" (click)="viewInspectionHistory(element.equipmentId)" class="truncate-text clickable-url">{{element.description}}</a>
      </td>
    </ng-container>

    <ng-container matColumnDef="category">
      <th mat-header-cell *matHeaderCellDef>Category</th>
      <td mat-cell *matCellDef="let element">
        <a href="javascript:void(0)" *ngIf="!element.serviceCertLoading" (click)="downloadServiceCert(element.equipmentId, element)" class="truncate-text clickable-url">{{ element.categoryName }}</a>
        <mat-progress-spinner *ngIf="element.serviceCertLoading"  [diameter]="'16'" [color]="'#000'" [mode]="'indeterminate'"></mat-progress-spinner>
      </td>
    </ng-container>

    <ng-container matColumnDef="serialNum">
      <th mat-header-cell *matHeaderCellDef>Serial #</th>
      <td mat-cell *matCellDef="let element"><span class="truncate-text">{{element.serialNum}}</span></td>
    </ng-container>

    <ng-container matColumnDef="loadTestTo">
      <th mat-header-cell *matHeaderCellDef>Load Test To</th>
      <td mat-cell *matCellDef="let element"><span class="truncate-text">{{element.loadTestTo}}</span></td>
    </ng-container>

    <ng-container matColumnDef="inspected">
      <th mat-header-cell *matHeaderCellDef>Inspected</th>
      <td mat-cell *matCellDef="let element"><span class="truncate-text">{{element.pdaFinishTimeFormatted}}</span></td>
    </ng-container>

    <ng-container matColumnDef="nextInspection">
      <th mat-header-cell *matHeaderCellDef>Next Inspection</th>
      <td mat-cell *matCellDef="let element"><span class="truncate-text">{{element.nextInspDateFormatted}}</span></td>
    </ng-container>

    <ng-container matColumnDef="status">
      <th mat-header-cell *matHeaderCellDef>Status</th>
      <td mat-cell *matCellDef="let element"><span class="truncate-text">{{element.status}}</span></td>
    </ng-container>

    <ng-container matColumnDef="clientAssetNum">
      <th mat-header-cell *matHeaderCellDef>Client Asset #</th>
      <td mat-cell *matCellDef="let element"><span class="truncate-text">{{element.clientAssetNum}}</span></td>
    </ng-container>

    <ng-container matColumnDef="department">
      <th mat-header-cell *matHeaderCellDef>Department</th>
      <td mat-cell *matCellDef="let element"><span class="truncate-text">{{element.department}}</span></td>
    </ng-container>

    <ng-container matColumnDef="options">
      <th mat-header-cell *matHeaderCellDef>Option</th>
      <td mat-cell *matCellDef="let element"><a href="javascript:void(0)" (click)="moveEquipment(element.equipmentId)" class="truncate-text clickable-url">Move</a></td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>

  <mat-paginator [pageSizeOptions]="[10, 25, 50, 100]" [pageSize]='10' [length]="dataSource.total$ | async" showFirstLastButtons></mat-paginator>

</div>