import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatSelect } from '@angular/material/select';
import { ReplaySubject, Subject } from 'rxjs';
import { takeUntil, take } from 'rxjs/operators';

@Component({
  selector: 'app-category-search',
  templateUrl: './category-search.component.html',
  styleUrls: ['./category-search.component.scss']
})
export class CategorySearchComponent implements OnInit {
  @Input() categories;
  @Input() disabled: boolean = false;
  @Output() selectedCategories = new EventEmitter<any>();

  public categoryMultiCtrl: FormControl = new FormControl();

  public categoryMultiFilterCtrl: FormControl = new FormControl();

  public filteredCategoriesMulti: ReplaySubject<[]> = new ReplaySubject<[]>(1);

  @ViewChild('multiSelect', { static: true }) multiSelect: MatSelect;

  protected _onDestroy = new Subject<void>();

  constructor() { }


  ngOnInit() {
    this.filteredCategoriesMulti.next(this.categories);

    this.categoryMultiFilterCtrl.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.filterCategoriesMulti();
      });

    this.categoryMultiCtrl.valueChanges.subscribe((val) => {
      var selectedIds = val.map(a => a.locationId);
      this.selectedCategories.emit(selectedIds);
    });
  }

  ngAfterViewInit() {
    this.setInitialValue();
  }

  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  protected setInitialValue() {
    this.filteredCategoriesMulti
      .pipe(take(1), takeUntil(this._onDestroy))
      .subscribe(() => {
        this.multiSelect.compareWith = (a: any, b: any) => a && b && a.categoryId === b.categoryId;
      });
  }

  protected filterCategoriesMulti() {
    if (!this.categories) {
      return;
    }
    // get the search keyword
    let search = this.categoryMultiFilterCtrl.value;
    if (!search) {
      this.filteredCategoriesMulti.next(this.categories);
      return;
    } else {
      search = search.toLowerCase();
    }

    this.filteredCategoriesMulti.next(
      this.categories.filter(category => category.categoryName.toLowerCase().indexOf(search) > -1)
    );
  }

}
