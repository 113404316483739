<div class="content">
  <h1>PREFERENCES</h1>

  <div class="form-controls">
    <div class="form-title">Add New Equipment</div>
    <div class="form-input">
      <mat-form-field appearance="fill">
        <mat-label class="form-label">Email 1</mat-label>
        <input matInput [(ngModel)]="email1">
      </mat-form-field>
    </div>
    <div class="form-input">
      <mat-form-field appearance="fill">
        <mat-label class="form-label">Email 2</mat-label>
        <input matInput [(ngModel)]="email2">
      </mat-form-field>
    </div>

    <button type="submit" class="add-btn" (click)="saveAddNewEquipmentPreferences()">SAVE</button>
  </div>
</div>