<div class="modal-panel" *ngIf="questionForm != null">
  <form [formGroup]="questionForm" (ngSubmit)="onSubmit()" class="form-manage">
    <div class="header">
      <h1>Add Question</h1>
    </div>

    <div class="form-controls">
      <div class="form-input">
        <mat-form-field appearance="fill">
          <mat-label class="form-label">Question</mat-label>
          <input matInput formControlName="question">
        </mat-form-field>
      </div>

      <div class="form-input">
        <mat-form-field appearance="fill">
          <mat-label class="form-label">Question Type</mat-label>
          <mat-select formControlName="questionTypeId">
            <mat-option [value]="1">Dropdown</mat-option>
            <mat-option [value]="2">Radio</mat-option>
            <mat-option [value]="3">Freetext</mat-option>
            <mat-option [value]="4">Checkbox List</mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div class="form-input" >
        <mat-form-field appearance="fill" *ngIf="data.inspSetQuestionId == null">
          <mat-label class="form-label">Position</mat-label>
          <mat-select formControlName="positionType">
            <mat-option [value]="'At Start'">At Start</mat-option>
            <mat-option [value]="'At End'">At End</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      

      <div class="form-input" *ngFor="let response of responses">
        <mat-checkbox (change)="toggleResponse(response)" [checked]="response.selected">
          {{ response.responseName }}
        </mat-checkbox>
      </div>
    </div>

    <div class="footer">
      <button type="button" class="cancel-btn" (click)="cancel()">CANCEL</button>
      <button type="submit" class="add-btn" [disabled]="!questionForm.valid">SAVE</button>
    </div>
  </form>
</div>