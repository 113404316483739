import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Location } from 'src/app/models/location';
import { LocationService } from 'src/app/services/administration/location.service';
import { SnotifyService } from 'ng-snotify';

@Component({
  selector: 'app-location-manage',
  templateUrl: './location-manage.component.html',
  styleUrls: ['./location-manage.component.scss']
})
export class LocationManageComponent implements OnInit {

  locationForm;

  constructor(private locationService: LocationService, private snotifyService: SnotifyService,
    public dialogRef: MatDialogRef<LocationManageComponent>, @Inject(MAT_DIALOG_DATA) public data: any) { }

  async ngOnInit() {
    var location = new Location();
    if (this.data != null) {
      if (this.data.companyId != null)
        location.companyId = this.data.companyId;

      if (this.data.locationId != null)
        location = await this.locationService.get(this.data.locationId);
      
    }


    this.locationForm = new FormGroup({
      locationId: new FormControl(location.locationId),
      companyId: new FormControl(location.companyId),
      name: new FormControl(location.name, [Validators.required]),
      accNum: new FormControl(location.accNum, [Validators.required]),
      address1: new FormControl(location.address1, [Validators.required]),
      address2: new FormControl(location.address2),
      address3: new FormControl(location.address3),
      address4: new FormControl(location.address4),
      postcode: new FormControl(location.postcode),
      county: new FormControl(location.county),
      telephone: new FormControl(location.telephone),
      fax: new FormControl(location.fax),
      email1: new FormControl(location.email1),
      email2: new FormControl(location.email2),
      expired: new FormControl(location.expired),
      monthlyInterval: new FormControl(location.monthlyInterval, [Validators.required]),
      nextInspectionPeriod: new FormControl(location.nextInspectionPeriod),
      nextInspectionPeriodInterval: new FormControl(location.nextInspectionPeriodInterval),
    })
  }

  cancel() {
    this.dialogRef.close(false);
  }

  async onSubmit() {
    var success = await this.locationService.save(this.locationForm.value);
    if (!success) {
      this.snotifyService.error("Error saving Location");
      return;
    }

    this.dialogRef.close(true);
    this.snotifyService.success("Successfully saved Location");
  }

}
