<div class="modal-panel" *ngIf="dataForm != null">
  <form [formGroup]="dataForm" (ngSubmit)="onSubmit()" class="form-manage">
    <div class="header">
      <h1>Category Management</h1>
    </div>

    <div class="form-controls">
      <div class="form-input">
        <mat-form-field appearance="fill">
          <mat-label class="form-label">Category Name</mat-label>
          <input matInput required formControlName="categoryName">
        </mat-form-field>
      </div>

      <div class="form-input">
        <mat-form-field appearance="fill">
          <mat-label class="form-label">Time Period Interval</mat-label>
          <mat-select formControlName="inspTimePeriod" required>
            <mat-option [value]="number" *ngFor="let number of [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31]">{{ number }}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div class="form-input">
        <mat-form-field appearance="fill">
          <mat-label class="form-label">Time Period Unit</mat-label>
          <mat-select formControlName="inspTimePeriodUnits" required>
            <mat-option [value]="1">Days</mat-option>
            <mat-option [value]="2">Weeks</mat-option>
            <mat-option [value]="3">Months</mat-option>
            <mat-option [value]="4">Years</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="form-input">
        <mat-checkbox formControlName="loadTest">Load Test Applicable</mat-checkbox>
      </div>
    </div>


    <div class="footer">
      <button type="button" class="cancel-btn" (click)="cancel()">CANCEL</button>
      <button type="submit" class="add-btn" [disabled]="!dataForm.valid">SAVE</button>
    </div>
  </form>
</div>