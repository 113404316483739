import { Injectable } from "@angular/core";
import { environment } from '../../../environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';
import { CurrentJobViewModel } from "src/app/models/view-models/current-job-view-model";
import { Observable } from "rxjs";
import { ApiResponse } from "src/app/models/api-response";
import { DataService } from "../../service-data-sources/data-table/data-service-interface";
import { DataServiceParam } from "../../service-data-sources/data-table/data-service-param";


@Injectable()
export class InspSetService {
  constructor(private http: HttpClient) {
    
  }

  getQuestionById(inspSetQuestionId) : Promise<any> {
    const headers = {
      'Content-Type': 'application/json',
    };

    return new Promise((resolve, reject) => {
      this.http.get<any>(environment.apiUrl + "inspset/getquestionbyid?inspSetQuestionId=" + inspSetQuestionId, { headers: headers }).subscribe(data => {
        resolve(data);
      }, (error) => {
        resolve(null)
      });
    });
  }

  getResponses() : Promise<any> {
    const headers = {
      'Content-Type': 'application/json',
    };

    return new Promise((resolve, reject) => {
      this.http.get<any>(environment.apiUrl + "inspset/getresponses", { headers: headers }).subscribe(data => {
        resolve(data);
      }, (error) => {
        resolve(null)
      });
    });
  }

  getByCategoryId(categoryId: number) : Promise<any> {
    const headers = {
      'Content-Type': 'application/json',
    };

    return new Promise((resolve, reject) => {
      this.http.get<any>(environment.apiUrl + "inspset/getbycategoryid?categoryId=" + categoryId, { headers: headers }).subscribe(data => {
        resolve(data);
      }, (error) => {
        resolve(null)
      });
    });
  }

  updateQuestions(questions) : Promise<ApiResponse> {
    const headers = {
      'Content-Type': 'application/json',
    };

    return new Promise((resolve, reject) => {
      this.http.put<ApiResponse>(environment.apiUrl + "inspset/putquestions", JSON.stringify(questions), { headers: headers }).subscribe(data => {
        resolve(data);
      }, (error) => {
        resolve(null)
      });
    });
  }

  addQuestion(question, responses) : Promise<ApiResponse> {
    const headers = {
      'Content-Type': 'application/json',
    };

    question.responses = [];
    for (let response of responses) {
      question.responses.push({
        responseId: response.responseId,
        selected: response.selected
      })
    }

    return new Promise((resolve, reject) => {
      this.http.post<ApiResponse>(environment.apiUrl + "inspset/postquestion", JSON.stringify(question), { headers: headers }).subscribe(data => {
        resolve(data);
      }, (error) => {
        resolve(null)
      });
    });
  }

  updateQuestion(question, responses) : Promise<ApiResponse> {
    const headers = {
      'Content-Type': 'application/json',
    };

    question.responses = [];
    for (let response of responses) {
      question.responses.push({
        inspSetQuestionResponseId: response.inspSetQuestionResponseId,
        responseId: response.responseId,
        selected: response.selected
      })
    }

    return new Promise((resolve, reject) => {
      this.http.put<ApiResponse>(environment.apiUrl + "inspset/putquestion", JSON.stringify(question), { headers: headers }).subscribe(data => {
        resolve(data);
      }, (error) => {
        resolve(null)
      });
    });
  }
}