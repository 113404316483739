import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { SnotifyService } from 'ng-snotify';
import { tap } from 'rxjs/operators';
import { PdaLoginComponent } from 'src/app/components/pda-login/pda-login.component';
import { Company } from 'src/app/models/company';
import { DataServiceParam } from 'src/app/service-data-sources/data-table/data-service-param';
import { PendingJobsDataSource } from 'src/app/service-data-sources/job-management/pending-jobs-data-source';
import { CompanyService } from 'src/app/services/administration/company-service';
import { JobService } from 'src/app/services/administration/job-service';
import { LocationService } from 'src/app/services/administration/location.service';

@Component({
  selector: 'app-pending-jobs',
  templateUrl: './pending-jobs.component.html',
  styleUrls: ['./pending-jobs.component.scss']
})
export class PendingJobsComponent implements OnInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  pageSize = localStorage.getItem("pending-jobs-page") ?? 10;


  filter = {
    companyId: 0,
    locationId: 0,
    accNum: "",
    salesOrderNum: ""
  }

  dataSource: PendingJobsDataSource;
  displayedColumns: string[] = ['companyName', 'name', 'accNum', 'created', 'salesOrderNum', 'noOfAssetsWhenCreated', 'view', 'remove'];

  pdaUser;
  
  companies: Company[];
  locations: Location[];

  constructor( private jobService: JobService, private companyService: CompanyService, private locationService: LocationService, public dialog: MatDialog) { }

  ngOnInit(): void {
    this.dataSource = new PendingJobsDataSource(this.jobService);
    this.loadCompanies();
    
    //this.loadJobsPage();
    
    this.getPDAUser();
  }

  pageChanged(event) { localStorage.setItem("pending-jobs-page", event.pageSize); }

  clear() {
    this.filter.companyId = 0;
    this.filter.locationId = 0;
    this.filter.accNum = "";
    this.filter.salesOrderNum = "";

    this.locations = [];
  }

  loadCompanies() {
    this.companies = [];
    this.companyService.getAll().then((companies: Company[]) => {
      this.companies = companies;
      this.locations = [];
    });
  }

  loadLocations(companyId: number) {
    this.locationService.getAll(companyId, true, "All").then((locations: Location[]) => {
      this.locations = locations;
    });
  }

  companyChanged($ev) {
    this.loadLocations($ev);
  }

  ngAfterViewInit() {
    this.paginator.page.pipe(tap(() => this.loadJobsPage())).subscribe();
    this.loadJobsPage();
  }

  loadJobs() {
    var params: DataServiceParam[] = [];
    params.push(<DataServiceParam>{ paramName: 'companyId', paramValue: this.filter.companyId });
    params.push(<DataServiceParam>{ paramName: 'locationId', paramValue: this.filter.locationId });
    params.push(<DataServiceParam>{ paramName: 'accNum', paramValue: this.filter.accNum });
    params.push(<DataServiceParam>{ paramName: 'salesOrderNum', paramValue: this.filter.salesOrderNum });
    this.dataSource.loadData(params);
  }

  loadJobsPage() {
    var params: DataServiceParam[] = [];
    params.push(<DataServiceParam>{ paramName: 'companyId', paramValue: this.filter.companyId });
    params.push(<DataServiceParam>{ paramName: 'locationId', paramValue: this.filter.locationId });
    params.push(<DataServiceParam>{ paramName: 'accNum', paramValue: this.filter.accNum });
    params.push(<DataServiceParam>{ paramName: 'salesOrderNum', paramValue: this.filter.salesOrderNum });
    this.dataSource.loadData(params, 'asc', this.paginator.pageIndex, this.paginator.pageSize);
  }

  async removeJob(locationInspectionId: number) {
    if (!confirm('Are you sure you want to remove?')) return false;

    var success = await this.jobService.unCompleteJob(locationInspectionId);
    if (success) {
      this.loadJobsPage();
    }
  }

  
  pdaUserLogin() {
    const dialogRef = this.dialog.open(PdaLoginComponent, {
      width: '500px',
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.getPDAUser();
      }
    });
  }

  pdaUserLogout() {
    this.pdaUser = null;
  }

  getPDAUser() {
    this.pdaUser = null;
    var pdaUserJson = localStorage.getItem('pdaUser');
    if (pdaUserJson != null && pdaUserJson != '') {
      this.pdaUser = JSON.parse(pdaUserJson);
    }
  }
}
