<div class="modal-panel" *ngIf="ausForm != null">
  <form [formGroup]="ausForm" (ngSubmit)="onSubmit()" class="form-manage">
    <div class="header">
      <h1>Australian Standard Management</h1>
    </div>

    <div class="form-controls">
      <div class="form-input">
        <mat-form-field appearance="fill">
          <mat-label class="form-label">Australian Standard</mat-label>
          <input matInput required formControlName="australianStd">
        </mat-form-field>
      </div>
    </div>

    <div class="footer">
      <button type="button" class="cancel-btn" (click)="cancel()">CANCEL</button>
      <button type="submit" class="add-btn" [disabled]="!ausForm.valid">SAVE</button>
    </div>
  </form>
</div>