import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import * as moment from 'moment';
import { EquipmentService } from 'src/app/services/administration/equipment.service';

@Component({
  selector: 'app-load-test',
  templateUrl: './load-test.component.html',
  styleUrls: ['./load-test.component.scss']
})
export class LoadTestComponent implements OnInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  
  filter = {
    startDate: moment().add(-2, 'years').toDate(),
    endDate: moment().toDate(),
  }

  displayedColumns: string[] = ['loadTestTime', 'pdaUser', 'maxLoadTest'];
  dataSource;

  constructor(private equipService: EquipmentService, @Inject(MAT_DIALOG_DATA) public data: {equipmentId: number}, 
  public dialogRef: MatDialogRef<LoadTestComponent>) { }

  ngOnInit(): void {
    this.loadTable();
  }

 
  loadTable() {
    this.equipService.getLoadTests(this.data.equipmentId, this.filter.startDate.toString(), this.filter.endDate.toString()).then((tests) => {
      this.dataSource = new MatTableDataSource(tests);
      this.dataSource.paginator = this.paginator;
    })
  }

  updateDates(ev) {
    this.filter.startDate = ev.startDate;
    this.filter.endDate = ev.endDate;
  }
}
