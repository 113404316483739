<div class="option-picker">
  <label (click)="showOptions = !showOptions">{{ label }} <i class="fas fa-chevron-down"></i></label>

  
  <div class="options-container" [class.show_options]="showOptions == true">
    <div class="options">
      <div *ngFor="let item of data">
        <mat-checkbox [(ngModel)]="item.selected">{{ item.text }}</mat-checkbox>
      </div>
    </div>
  </div>
</div>
