import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { tap } from 'rxjs/operators';
import { Company } from 'src/app/models/company';
import { CompanyService } from 'src/app/services/administration/company-service';
import { DataServiceParam } from 'src/app/service-data-sources/data-table/data-service-param';
import { TableDataSource } from 'src/app/service-data-sources/data-table/table-data-source';
import { CompanyManageComponent } from './company-manage/company-manage.component';
import { saveAs as importedSaveAs } from 'file-saver';

@Component({
  selector: 'app-company-management',
  templateUrl: './company-management.component.html',
  styleUrls: ['./company-management.component.scss']
})
export class CompanyManagementComponent implements OnInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  
  dataSource: TableDataSource<Company>;
  // displayedColumns: string[] = ['companyName', 'accNum', 'address1', 'state', 'telephone', 'expired', 'mainContactNumber', 'officeNumber', 'showClientNo', 'hideSynthetic'];
  displayedColumns: string[] = ['companyName', 'accNum', 'address1', 'state', 'telephone', 'expired', 'showClientNo', 'hideSynthetic', 'option', 'locations'];

  filter: string = "";

  constructor(private companyService: CompanyService, public dialog: MatDialog) { }

  ngOnInit(): void {
    this.dataSource = new TableDataSource(this.companyService);
    this.loadCompanies();
  }

  ngAfterViewInit() {
    this.paginator.page.pipe(tap(() => this.loadCompaniesPage())).subscribe();  
  }

  loadCompanies() {
    var params: DataServiceParam[] = [];
    params.push(<DataServiceParam>{ paramName: 'filter', paramValue: this.filter });
    this.dataSource.loadData(params);
  }

  loadCompaniesPage() {
    var params: DataServiceParam[] = [];
    params.push(<DataServiceParam>{ paramName: 'filter', paramValue: this.filter });
    this.dataSource.loadData(params, 'asc', this.paginator.pageIndex, this.paginator.pageSize);
  }

  export() {
    this.companyService.export().then((data) => {
      importedSaveAs(data, 'Companies.xls');
    });
  }

  add() {
    const dialogRef = this.dialog.open(CompanyManageComponent, {
      width: '900px',
      // data: {name: this.name, animal: this.animal}
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.loadCompanies();
      }
    });
  }
  
  edit(companyId) {
    const dialogRef = this.dialog.open(CompanyManageComponent, {
      width: '900px',
      data: {companyId: companyId}
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.loadCompanies();
      }
    });
  }

}
