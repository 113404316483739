<div class="dialog-content">
  <!-- <h1>PDA User Login</h1> -->
  <form (ngSubmit)="onSubmit()">
    <div class="panel-section">
      <!-- <mat-form-field appearance="fill" class="full-width">
        <mat-label class="form-label">PDA User</mat-label>
        <mat-select name="pdaUserId" [(ngModel)]="pdaUserId">
          <mat-option [value]="user.pdaUserId" *ngFor="let user of users">{{ user.name }}</mat-option>
        </mat-select>
      </mat-form-field> -->

      <app-search-select [(ngModel)]="pdaUserId" [label]="'PDA User'" [list]="users" [value]="'pdaUserId'" [name]="'name'"></app-search-select>

      <mat-form-field appearance="fill" class="full-width">
        <mat-label class="form-label">PIN</mat-label>
        <input matInput [(ngModel)]="pin" name="pin" type="password">
      </mat-form-field>
    </div>

    <button type="submit" class="add-btn">Login</button>
  </form>
</div>