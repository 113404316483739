import { CollectionViewer, DataSource } from "@angular/cdk/collections";
import { BehaviorSubject, Observable, of } from "rxjs";
import { Location } from "src/app/models/location";;

import { catchError, finalize } from 'rxjs/operators';
import { AusStd } from "src/app/models/aus-std";
import { DataService } from "./data-service-interface";
import { DataServiceParam } from "./data-service-param";

type LoadTableCallback = (params: DataServiceParam[], sortDirection: string, pageIndex: number, pageSize: number) => any;

export class TableDataSource<T> implements DataSource<T> {
  private dataSubject = new BehaviorSubject<T[]>([]);
  private loadingSubject = new BehaviorSubject<boolean>(false);
  private countSubject = new BehaviorSubject<number>(0);

  public loading$ = this.loadingSubject.asObservable();
  public total$ = this.countSubject.asObservable();
  
  constructor(private dataService: DataService) {

  }

  connect(collectionViewer: CollectionViewer): Observable<T[]> {
    return this.dataSubject.asObservable();
  }

  disconnect(collectionViewer: CollectionViewer): void {
    this.dataSubject.complete();
    this.loadingSubject.complete();
  }

  loadData(params: DataServiceParam[], sortDirection = 'asc', pageIndex = 0, pageSize = 10) {
    this.loadingSubject.next(true);
    this.dataService.getTable(params, sortDirection, pageIndex, pageSize)
      .pipe(catchError(() => of([])), finalize(() => { this.loadingSubject.next(false)}))
      .subscribe((response: any) => {
        this.countSubject.next(response.total); 
        this.dataSubject.next(response.data);
      })
  };
}