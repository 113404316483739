import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { BatchManuf } from 'src/app/models/batch-manuf';
import { BatchManufService } from 'src/app/services/administration/batch-manuf-service';
import { DataServiceParam } from 'src/app/service-data-sources/data-table/data-service-param';
import { TableDataSource } from 'src/app/service-data-sources/data-table/table-data-source';
import { AusStdManageComponent } from '../australian-standard-management/aus-std-manage/aus-std-manage.component';
import { BatchManufacturerManageComponent } from './batch-manufacturer-manage/batch-manufacturer-manage.component';

@Component({
  selector: 'app-batch-manufacturers-management',
  templateUrl: './batch-manufacturers-management.component.html',
  styleUrls: ['./batch-manufacturers-management.component.scss']
})
export class BatchManufacturersManagementComponent implements OnInit {

  @ViewChild(MatPaginator) paginator: MatPaginator;
  
  dataSource: TableDataSource<BatchManuf>;
  displayedColumns: string[] = ['batchManuf', 'edit'];

  filter: string = "";

  constructor(private batchManufService: BatchManufService, public dialog: MatDialog) { }

  ngOnInit(): void {
    this.dataSource = new TableDataSource(this.batchManufService);
    this.loadTable();
  }

  loadTable() {
    var params: DataServiceParam[] = [];
    params.push(<DataServiceParam>{ paramName: 'filter', paramValue: this.filter });
    
    this.dataSource.loadData(params);
  }

  loadTablePage() {
    var params: DataServiceParam[] = [];
    params.push(<DataServiceParam>{ paramName: 'filter', paramValue: this.filter });
    this.dataSource.loadData(params, 'asc', this.paginator.pageIndex, this.paginator.pageSize);
  }
  
  add() {
    const dialogRef = this.dialog.open(BatchManufacturerManageComponent, {
      width: '900px',
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.loadTable();
      }
    });
  }

  edit(batchManufId) {
    const dialogRef = this.dialog.open(BatchManufacturerManageComponent, {
      width: '900px',
      data: {batchManufId: batchManufId}
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.loadTable();
      }
    });
  }


}
