<div class="content">
  <h1>REGISTER</h1>

  <form class="panel" (ngSubmit)="loadTable()">
    <div class="panel-section-grid">
      <div class="panel-section">
        <app-search-select [(ngModel)]="filter.companyId" [label]="'Companies'" [list]="companies" [value]="'companyId'" [name]="'companyName'" (onChanged)="companyChanged($event)"></app-search-select>
      </div>
  
      <div class="panel-section">
        <app-search-select [(ngModel)]="filter.locationId" [label]="'Locations'" [list]="locations" [value]="'locationId'" [name]="'name'"></app-search-select>
      </div>
  
      <div class="panel-section">
        <app-search-select [(ngModel)]="filter.categoryId" [label]="'Categories'" [list]="categories" [value]="'categoryId'" [name]="'categoryName'"></app-search-select>
      </div>
  
      <div class="panel-section">
        <mat-checkbox name="filterByName" [(ngModel)]="filter.filterByDate">Filter By Date</mat-checkbox>
      </div>
  
      <div class="panel-section">
        <app-datepicker [startDate]="filter.startDate" [endDate]="filter.endDate" (onChange)="updateDates($event)"></app-datepicker>
        <!-- <mat-form-field appearance="fill">
          <mat-label>Date Range</mat-label>
          <mat-date-range-input [rangePicker]="picker" >
            <input matStartDate name="startDate" [(ngModel)]="filter.startDate" placeholder="Start date">
            <input matEndDate name="endDate" [(ngModel)]="filter.endDate" placeholder="End date">
          </mat-date-range-input>
          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-date-range-picker #picker></mat-date-range-picker>
        </mat-form-field> -->
      </div>
  
      <div class="panel-section">
        <mat-form-field appearance="fill">
          <mat-label class="form-label">Pass/Fail Status</mat-label>
          <mat-select name="showPassFail" [(ngModel)]="filter.showPassFail">
            <mat-option value="ALL">All</mat-option>
            <mat-option value="PASS">Pass</mat-option>
            <mat-option value="FAIL">Fail</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
  
      <div class="panel-section">
        <mat-form-field appearance="fill">
          <mat-label class="form-label">Condemned Status</mat-label>
          <mat-select name="showDecommisioned" [(ngModel)]="filter.showDecommisioned">
            <mat-option value="ALL">All</mat-option>
            <mat-option value="YES">Yes</mat-option>
            <mat-option value="NO">No</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
  
      <div class="panel-section">
        <mat-form-field appearance="fill">
          <mat-label class="form-label">Missing Status</mat-label>
          <mat-select name="showMissing" [(ngModel)]="filter.showMissing">
            <mat-option value="ALL">All</mat-option>
            <mat-option value="YES">Yes</mat-option>
            <mat-option value="NO">No</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
  
      <div class="panel-section">
        <mat-form-field>
          <mat-label class="form-label">Serial #</mat-label>
          <input type="text" name="serial" [(ngModel)]="filter.serial" matInput placeholder="Serial #" >
        </mat-form-field>
      </div>
  
      <div class="panel-section">
        <mat-form-field>
          <mat-label class="form-label">Job #</mat-label>
          <input type="text" name="salesOrderNum" [(ngModel)]="filter.salesOrderNum" matInput placeholder="Job #">
        </mat-form-field>
      </div>
  
      <div class="panel-section">
        <mat-checkbox name="showArchived"  name="showArchived" [(ngModel)]="filter.showArchived">Show Archived</mat-checkbox>
      </div>
  
      <div class="panel-section">
        <mat-form-field>
          <mat-label class="form-label">Department</mat-label>
          <input type="text" name="department" [(ngModel)]="filter.department" matInput placeholder="Department">
        </mat-form-field>
      </div>
    </div>
  
    
    <button class="panel-btn panel-btn-margin" type="submit">
      FILTER
    </button>
  </form>

  <div class="spinner-container" *ngIf="(dataSource.loading$ | async)">
    <mat-spinner [diameter]="'50'"></mat-spinner>
  </div>

  <div class="kpi-section">
    <div class="kpi kpi-success">
      <i class="fas fa-check-circle"></i>
      <div class="kpi-info">
        <div class="kpi-title">Passed</div>
        <div>{{ dataSource.passed$ | async }}</div>
      </div>
    </div>

    <div class="kpi kpi-failed">
      <i class="fas fa-check-circle"></i>
      <div class="kpi-info">
        <div class="kpi-title">Failed</div>
        <div>{{ dataSource.failed$ | async }}</div>
      </div>
    </div>

    <div class="kpi kpi-missing">
      <i class="fas fa-question-circle"></i>
      <div class="kpi-info">
        <div class="kpi-title">Missing</div>
        <div>{{ dataSource.missing$ | async }}</div>
      </div>
    </div>

    <div class="kpi kpi-repaired">
      <i class="fas fa-cogs"></i>
      <div class="kpi-info">
        <div class="kpi-title">Repaired</div>
        <div>{{ dataSource.repaired$ | async }}</div>
      </div>
    </div>

    <div class="kpi kpi-condemned">
      <i class="fas fa-trash"></i>
      <div class="kpi-info">
        <div class="kpi-title">Condemned</div>
        <div>{{ dataSource.condemned$ | async }}</div>
      </div>
    </div>

    <div class="kpi kpi-total">
      <i class="fas fa-list-ol"></i>
      <div class="kpi-info">
        <div class="kpi-title">Total</div>
        <div>{{ dataSource.total$ | async }}</div>
      </div>
    </div>
  </div>

  <div class="group-cert-section">
    <div class="spacer"></div>
    <button (click)="batchLifting()">Lifting</button>
    <button (click)="batchNata()">NATA</button>
    <button (click)="batchNew()">New</button>
    <button (click)="batchRepair()">Repair</button>
    <button class="export-btn" (click)="exportBatch()">
      <span *ngIf="!isBatchExporting">Export</span>
      <mat-progress-spinner *ngIf="isBatchExporting" [diameter]="'16'" [color]="'red'" [mode]="'indeterminate'"></mat-progress-spinner>
    </button>
  </div>


  <table mat-table class="data-table mat-elevation-z8" [dataSource]="dataSource" fixedLayout="true">
  
    <ng-container matColumnDef="images">
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let element"><a href="javascript:void(0)" class="truncate-text" (click)="viewImages(element.inspectionId)">VIEW</a></td>
    </ng-container>

    <ng-container matColumnDef="inspectionDate">
      <th mat-header-cell *matHeaderCellDef>Inspection Date</th>
      <td mat-cell *matCellDef="let element"><span class="truncate-text">{{element.inspDate}}</span></td>
    </ng-container>

    <ng-container matColumnDef="serialNum">
      <th mat-header-cell *matHeaderCellDef>Serial #</th>
      <td mat-cell *matCellDef="let element">
        <a href="javascript:void(0)" (click)="viewMovementHistory(element.equipmentId)" class="truncate-text clickable-url">{{element.serialNum}}</a>
      </td>
    </ng-container>

    <ng-container matColumnDef="description">
      <th mat-header-cell *matHeaderCellDef>Description</th>
      <td mat-cell *matCellDef="let element">
        <!-- <span class="truncate-text">{{element.description}}</span> -->
        <a href="javascript:void(0)" (click)="viewInspectionHistory(element.equipmentId)" class="truncate-text clickable-url">{{element.description}}</a>
      </td>
    </ng-container>

    <ng-container matColumnDef="australianStd">
      <th mat-header-cell *matHeaderCellDef>Standards</th>
      <td mat-cell *matCellDef="let element"><span class="truncate-text">{{element.australianStd}}</span></td>
    </ng-container>

    <ng-container matColumnDef="safeForUse">
      <th mat-header-cell *matHeaderCellDef>Safe For Use</th>
      <td mat-cell *matCellDef="let element">
        <span *ngIf="!element.passedCertLoading" (click)="downloadPassedCert(element.inspectionId, element)" class="truncate-text clickable-link">{{element.safeForUse == true ? '&#10003;' : null }}</span>
        <mat-progress-spinner *ngIf="element.passedCertLoading"  [diameter]="'16'" [color]="'#000'" [mode]="'indeterminate'"></mat-progress-spinner>
      </td>
    </ng-container>

    <ng-container matColumnDef="failed">
      <th mat-header-cell *matHeaderCellDef>Failed</th>
      <td mat-cell *matCellDef="let element">
        <span *ngIf="!element.failedCertLoading" (click)="downloadFailedCert(element.inspectionId, element)" class="truncate-text clickable-link">{{element.failed }}</span>
        <mat-progress-spinner *ngIf="element.failedCertLoading"  [diameter]="'16'" [color]="'#000'" [mode]="'indeterminate'"></mat-progress-spinner>
      </td>
    </ng-container>

    <ng-container matColumnDef="missing">
      <th mat-header-cell *matHeaderCellDef>Missing</th>
      <td mat-cell *matCellDef="let element">
        <span *ngIf="!element.missingCertLoading" (click)="downloadMissedCert(element.inspectionId, element)" class="truncate-text clickable-link">{{element.missing }}</span>
        <mat-progress-spinner *ngIf="element.missingCertLoading"  [diameter]="'16'" [color]="'#000'" [mode]="'indeterminate'"></mat-progress-spinner>
      </td>
    </ng-container>

    <ng-container matColumnDef="wasRepaired">
      <th mat-header-cell *matHeaderCellDef>Repaired</th>
      <td mat-cell *matCellDef="let element">
        <span *ngIf="!element.repairedCertLoading" (click)="downloadRepairCert(element.inspectionId, element)" class="truncate-text clickable-link">{{element.wasRepaired }}</span>
        <mat-progress-spinner *ngIf="element.repairedCertLoading"  [diameter]="'16'" [color]="'#000'" [mode]="'indeterminate'"></mat-progress-spinner>
      </td>
    </ng-container>

    <ng-container matColumnDef="condemned">
      <th mat-header-cell *matHeaderCellDef>Condemned</th>
      <td mat-cell *matCellDef="let element">
        <span *ngIf="!element.condemnedCertLoading" (click)="downloadCondemnedCert(element.inspectionId, element)" class="truncate-text clickable-link">{{element.condemned }}</span>
        <mat-progress-spinner *ngIf="element.condemnedCertLoading"  [diameter]="'16'" [color]="'#000'" [mode]="'indeterminate'"></mat-progress-spinner>
      </td>
    </ng-container>

    <ng-container matColumnDef="nataCert">
      <th mat-header-cell *matHeaderCellDef>NATA Cert</th>
      <td mat-cell *matCellDef="let element">
        <span *ngIf="!element.nataCertLoading" (click)="downloadNataCert(element.inspectionId, element)" class="truncate-text clickable-link">{{element.nataCert }}</span>
        <mat-progress-spinner *ngIf="element.nataCertLoading"  [diameter]="'16'" [color]="'#000'" [mode]="'indeterminate'"></mat-progress-spinner>
      </td>
    </ng-container>

    <ng-container matColumnDef="newIntoService">
      <th mat-header-cell *matHeaderCellDef>New Into Service</th>
      <td mat-cell *matCellDef="let element">
        <span *ngIf="!element.serviceCertLoading" (click)="downloadServiceCert(element.equipmentId, element)" class="truncate-text clickable-link">{{element.newIntoService == true ? '&#10003;' : null }}</span>
        <mat-progress-spinner *ngIf="element.serviceCertLoading"  [diameter]="'16'" [color]="'#000'" [mode]="'indeterminate'"></mat-progress-spinner>
      </td>
    </ng-container>

    <ng-container matColumnDef="hasRAMS">
      <th mat-header-cell *matHeaderCellDef>RAMS</th>
      <td mat-cell *matCellDef="let element">
        <span *ngIf="!element.ramsCertLoading" (click)="downloadRAMS(element.locationInspectionId, element)" class="truncate-text clickable-link">{{element.hasRAMS == true ? '&#10003;' : null }}</span>
        <mat-progress-spinner *ngIf="element.ramsCertLoading"  [diameter]="'16'" [color]="'#000'" [mode]="'indeterminate'"></mat-progress-spinner>
      </td>
    </ng-container>

    <ng-container matColumnDef="hasJobForm">
      <th mat-header-cell *matHeaderCellDef>Job Form</th>
      <td mat-cell *matCellDef="let element">
        <span *ngIf="!element.jobFormLoading" (click)="downloadJobForm(element.locationInspectionId, element)" class="truncate-text clickable-link">{{element.hasJobForm == true ? '&#10003;' : null }}</span>
        <mat-progress-spinner *ngIf="element.jobFormLoading"  [diameter]="'16'" [color]="'#000'" [mode]="'indeterminate'"></mat-progress-spinner>
      </td>
    </ng-container>
    
    <ng-container matColumnDef="nextInspDateFormatted">
      <th mat-header-cell *matHeaderCellDef>Next Insp Date</th>
      <td mat-cell *matCellDef="let element"><span class="truncate-text">{{element.nextInspDateFormatted }}</span></td>
    </ng-container>
    
    <ng-container matColumnDef="location">
      <th mat-header-cell *matHeaderCellDef>Location</th>
      <td mat-cell *matCellDef="let element"><span class="truncate-text">{{element.location }}</span></td>
    </ng-container>

    <ng-container matColumnDef="clientAssetNumber">
      <th mat-header-cell *matHeaderCellDef>Client Asset #</th>
      <td mat-cell *matCellDef="let element"><span class="truncate-text">{{element.clientAssetNumber }}</span></td>
    </ng-container>

    <ng-container matColumnDef="department">
      <th mat-header-cell *matHeaderCellDef>Department</th>
      <td mat-cell *matCellDef="let element"><span class="truncate-text">{{element.department }}</span></td>
    </ng-container>

    <ng-container matColumnDef="options">
      <th mat-header-cell *matHeaderCellDef>Option</th>
      <td mat-cell *matCellDef="let element"><a href="javascript:void(0)" (click)="moveEquipment(element.equipmentId)" class="truncate-text clickable-url">Move</a></td>
    </ng-container>

    <ng-container matColumnDef="load">
      <th mat-header-cell *matHeaderCellDef>Load</th>
      <td mat-cell *matCellDef="let element">
        <a href="javascript:void(0)" (click)="loadTests(element.equipmentId)" class="truncate-text clickable-url"><img class="table-link-img" src="/assets/loadtest.png"/></a>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>

  <mat-paginator [pageSizeOptions]="[10, 25, 50, 100]" [pageSize]='10' [length]="dataSource.total$ | async" showFirstLastButtons></mat-paginator>
</div>
