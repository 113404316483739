import { Injectable } from "@angular/core";
import { environment } from '../../../environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from "rxjs";
import { Location } from "src/app/models/location";
import { AusStd } from "src/app/models/aus-std";
import { DataService } from "../../service-data-sources/data-table/data-service-interface";
import { DataServiceParam } from "../../service-data-sources/data-table/data-service-param";
import { Equipment } from "src/app/models/equipment";
import * as moment from "moment";
import { ApiResponse } from "src/app/models/api-response";
import { SnotifyService } from "ng-snotify";


@Injectable()
export class EquipmentService {
  constructor(private http: HttpClient, private snotifyService: SnotifyService) {

  }

  get(equipmentId: number, includeLatestInspection: boolean = false): Promise<Equipment> {
    const headers = {
      'Content-Type': 'application/json',
    };

    return new Promise((resolve, reject) => {
      this.http.get<any>(environment.apiUrl + "equipment/get?equipmentId=" + equipmentId + "&includeLatestInspection=" + includeLatestInspection, { headers: headers }).subscribe(data => {
        resolve(data);
      }, (error) => {
        resolve(null)
      });
    });
  }
  getBatchNumbers(equipmentId: number): Promise<any> {
    const headers = {
      'Content-Type': 'application/json',
    };

    return new Promise((resolve, reject) => {
      this.http.get<any>(environment.apiUrl + "equipment/GetBatches?equipmentId=" + equipmentId, { headers: headers }).subscribe(data => {
        console.log(data);
        resolve(data);
      }, (error) => {
        resolve(null)
      });
    });
  }

  getInspections(equipmentId: number): Promise<[]> {
    const headers = {
      'Content-Type': 'application/json',
    };

    return new Promise((resolve, reject) => {
      this.http.get<any>(environment.apiUrl + "equipment/getinspections?equipmentId=" + equipmentId, { headers: headers }).subscribe(data => {
        resolve(data);
      }, (error) => {
        resolve(null)
      });
    });
  }

  getRepairedInspections(equipmentId: number): Promise<[]> {
    const headers = {
      'Content-Type': 'application/json',
    };

    return new Promise((resolve, reject) => {
      this.http.get<any>(environment.apiUrl + "equipment/getrepairedinspections?equipmentId=" + equipmentId, { headers: headers }).subscribe(data => {
        resolve(data);
      }, (error) => {
        resolve(null)
      });
    });
  }

  getInspectionImages(equipmentId: number): Promise<[]> {
    const headers = {
      'Content-Type': 'application/json',
    };

    return new Promise((resolve, reject) => {
      this.http.get<any>(environment.apiUrl + "equipment/getinspectionimages?equipmentId=" + equipmentId, { headers: headers }).subscribe(data => {
        resolve(data);
      }, (error) => {
        resolve(null)
      });
    });
  }

  getEquipmentSupportingDocuments(equipmentId: number): Promise<[]> {
    const headers = {
      'Content-Type': 'application/json',
    };

    return new Promise((resolve, reject) => {
      this.http.get<any>(environment.apiUrl + "equipment/GetEquipmentDocuments?equipmentId=" + equipmentId, { headers: headers }).subscribe(data => {
        console.log(data);
        resolve(data);
      }, (error) => {
        console.log(error);
        resolve(null)
      });
    });
  }
  
  getEquipmentFiles(equipmentId: number): Promise<[]> {
    const headers = {
      'Content-Type': 'application/json',
    };

    return new Promise((resolve, reject) => {
      this.http.get<any>(environment.apiUrl + "equipment/getequipmentfiles?equipmentId=" + equipmentId, { headers: headers }).subscribe(data => {
        console.log(data);
        resolve(data);
      }, (error) => {
        console.log(error);
        resolve(null)
      });
    });
  }

  moveLocation(equipmentId: number, locationId: number, movedBy: string): Promise<Equipment> {
    const headers = {
      'Content-Type': 'application/json',
    };

    var dto = {
      equipmentId: equipmentId,
      locationId: locationId,
      movedBy: movedBy
    }

    return new Promise((resolve, reject) => {
      this.http.post<any>(environment.apiUrl + "equipment/postmovelocation", dto, { headers: headers }).subscribe(data => {
        resolve(data);
      }, (error) => {
        resolve(null)
      });
    });
  }

  moveLocationBatch(ids, locationId: number, movedBy: string): Promise<Equipment> {
    const headers = {
      'Content-Type': 'application/json',
    };

    var dto = {
      ids: ids,
      locationId: locationId,
      movedBy: movedBy
    }

    return new Promise((resolve, reject) => {
      this.http.post<any>(environment.apiUrl + "equipment/postmovelocationbatch", dto, { headers: headers }).subscribe(data => {
        resolve(data);
      }, (error) => {
        resolve(null)
      });
    });
  }

  getMovementHistory(equipmentId: number) : Promise<[]> {
    const headers = {
      'Content-Type': 'application/json',
    };

    return new Promise((resolve, reject) => {
      this.http.get<any>(environment.apiUrl + "equipment/getmovementhistory?equipmentId=" + equipmentId, { headers: headers }).subscribe(data => {
        resolve(data);
      }, (error) => {
        resolve(null)
      });
    });
  }

  getInspectionHistory(equipmentId: number) : Promise<[]> {
    const headers = {
      'Content-Type': 'application/json',
    };

    return new Promise((resolve, reject) => {
      this.http.get<any>(environment.apiUrl + "equipment/getinspectionhistory?equipmentId=" + equipmentId, { headers: headers }).subscribe(data => {
        resolve(data);
      }, (error) => {
        resolve(null)
      });
    });
  }

  getLoadTests(equipmentId: number, startDate: string, endDate: string) : Promise<[]> {
    const headers = {
      'Content-Type': 'application/json',
    };

    var httpParams = new HttpParams()
    .set('equipmentId', equipmentId.toString())
    .set('startDate', moment(startDate).format("YYYY-MM-DD"))
    .set('endDate', moment(endDate).format("YYYY-MM-DD"));

    return new Promise((resolve, reject) => {
      this.http.get<any>(environment.apiUrl + "equipment/getloadtests", { headers: headers, params: httpParams }).subscribe(data => {
        resolve(data);
      }, (error) => {
        resolve(null)
      });
    });
  }

  add(equipment: Equipment) {
    const headers = {
      'Content-Type': 'application/json',
    };

    return new Promise((resolve, reject) => {
      this.http.post<any>(environment.apiUrl + "equipment/post", equipment, { headers: headers }).subscribe(data => {
        resolve(true);
      }, (error) => {
        resolve(false)
      });
    });
  }

  uploadSupportingDocument(dto) {
    const headers = {
      'Content-Type': 'application/json',
    };
    return new Promise((resolve, reject) => {
      this.http.post<any>(environment.apiUrl + "equipment/uploadSupportingDocument", dto, { headers: headers }).subscribe(data => {
        this.snotifyService.success("Successfuly uploaded supporting document");
        resolve(true);
      }, (error) => {
        this.snotifyService.error("Error uploaded supporting document");
        this.snotifyService.error(error);
        resolve(false)
      });
    });
  }
  archiveDocument(equipmentId: number,documentId: number, archived: boolean) {
    const headers = {
      'Content-Type': 'application/json',
    };

    var dto = {
      equipmentId: equipmentId,
      documentId: documentId,
      archived: archived
    }

    return new Promise((resolve, reject) => {
      this.http.post<any>(environment.apiUrl + "equipment/archiveSupportingDocument", dto, { headers: headers }).subscribe(data => {
        this.snotifyService.success("Successfuly removed supporting document");
        resolve(true);
      }, (error) => {
        this.snotifyService.error("Error removing supporting document");
        resolve(false)
      });
    });
  }
  
  archive(equipmentId: number, archived: boolean) {
    const headers = {
      'Content-Type': 'application/json',
    };

    var dto = {
      equipmentId: equipmentId,
      archived: archived
    }

    return new Promise((resolve, reject) => {
      this.http.post<any>(environment.apiUrl + "equipment/archive", dto, { headers: headers }).subscribe(data => {
        resolve(true);
      }, (error) => {
        resolve(false)
      });
    });
  }

  getEquipmentBatch(startDate: string, endDate: string) : Promise<any> {
    const headers = {
      'Content-Type': 'application/json',
    };

    startDate = moment(startDate).format("YYYY-MM-DD");
    endDate = moment(endDate).format("YYYY-MM-DD");

    return new Promise((resolve, reject) => {
      this.http.get<any>(environment.apiUrl + "equipment/getequipmentbatch?startDate=" + startDate + "&endDate=" + endDate, { headers: headers }).subscribe(data => {
        let blob = this.convertBase64toBlob(data.value, 'application/vnd.ms-excel');
        resolve(blob);
      }, (error) => {
        resolve(null)
      });
    });
  }

  uploadEquipment(base64) : Promise<ApiResponse>  {
    const headers = {
      'Content-Type': 'application/json',
    };

    var dto = {
      file: base64
    }

    return new Promise((resolve, reject) => {
      this.http.post<ApiResponse>(environment.apiUrl + "equipment/uploadequipment", dto, { headers: headers }).subscribe(data => {
        resolve(data);
      }, (error) => {
        resolve(null)
      });
    });
  }

  convertBase64toBlob(content, contentType) {
    contentType = contentType || '';
    let sliceSize = 512;
    let byteCharacters = window.atob(content); //method which converts base64 to binary
    let byteArrays = [
    ];
    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      let slice = byteCharacters.slice(offset, offset + sliceSize);
      let byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
      let byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }
    let blob = new Blob(byteArrays, {
      type: contentType
    }); //statement which creates the blob
    return blob;
  }
}
