<div class="dialog-content">
  <div class="title">
    <h1>JOBS LIST</h1>
  </div>
  <form>
    <div class="location-content">
      <table mat-table matSort [dataSource]="dataSource">
        <ng-container matColumnDef="salesOrderNum">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>JOB #</th>
          <td mat-cell *matCellDef="let element" >{{element.salesOrderNum}} </td>
        </ng-container>

        <ng-container matColumnDef="created">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>INSPECTION DATE</th>
          <td mat-cell *matCellDef="let element">{{element.created | date: 'dd/MM/yyyy'}} </td>
        </ng-container>

        <ng-container matColumnDef="inspector">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>INSPECTOR</th>
          <td mat-cell *matCellDef="let element">{{element.inspector}}</td>
        </ng-container>

        <ng-container matColumnDef="summary">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>SUMMARY REPORT</th>
          <td mat-cell class="center-td" *matCellDef="let element">
            <img class="download-img" (click)="downloadSummary(element.locationInspectionId,element.salesOrderNum)" src="/assets/icons/download-icon.png"/>
          </td>
        </ng-container>
        <ng-container matColumnDef="jobForm">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>JOB FORM / SERVICE AGREEMENT</th>
          <td mat-cell *matCellDef="let element">
            <img *ngIf="element.jobFormCount > 0" class="download-img" (click)="downloadJobForm(element.locationInspectionId)" src="/assets/icons/download-icon.png"/>
            <img *ngIf="element.serviceAgreementsCount > 0" class="download-img" (click)="downloadServiceAgreement(element.serviceAgreementId)" src="/assets/icons/download-icon-service.png"/>
          </td>
        </ng-container>

        <ng-container matColumnDef="rams">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>RAMS</th>
          <td mat-cell *matCellDef="let element" style="text-align: center">
            <img *ngIf="element.ramsCount > 0" class="download-img" (click)="downloadRams(element.locationInspectionId)" src="/assets/icons/download-icon.png"/>
          </td>
        </ng-container>

        <ng-container matColumnDef="view">
          <th mat-header-cell *matHeaderCellDef>VIEW</th>
          <td mat-cell class="center-td" *matCellDef="let element">
            <span class="download-img" (click)="view(element.salesOrderNum)" ><i  class="fas fa-chevron-right"></i></span>
            <!-- <a class="view" [routerLink]="['/administration/equipment/details', element.equipmentId]"><i class="fas fa-chevron-right"></i></a> -->
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>

      <mat-paginator [pageSizeOptions]="[5, 10]" [pageSize]='10'></mat-paginator>
    </div>

    <div class="action-buttons">
      <button class="btn-cancel" type="button" (click)="cancel()">
        CLOSE
      </button>
    </div>
  </form>
</div>
