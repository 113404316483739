import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatSelect } from '@angular/material/select';
import { ReplaySubject, Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-location-search',
  templateUrl: './location-search.component.html',
  styleUrls: ['./location-search.component.scss']
})
export class LocationSearchComponent implements OnInit {
  @Input() locations;
  @Input() disabled: boolean = false;
  @Output() selectedLocations = new EventEmitter<any>();

  public locationMultiCtrl: FormControl = new FormControl();

  public locationMultiFilterCtrl: FormControl = new FormControl();

  public filteredLocationsMulti: ReplaySubject<[]> = new ReplaySubject<[]>(1);

  @ViewChild('multiSelect', { static: true }) multiSelect: MatSelect;

  protected _onDestroy = new Subject<void>();

  constructor() { }


  ngOnInit() {
    this.filteredLocationsMulti.next(this.locations);

    this.locationMultiFilterCtrl.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.filterLocationsMulti();
      });

    this.locationMultiCtrl.valueChanges.subscribe((val) => {
      var selectedIds = val.map(a => a.locationId);
      this.selectedLocations.emit(selectedIds);
    });
  }

  ngAfterViewInit() {
    this.setInitialValue();
  }

  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  protected setInitialValue() {
    this.filteredLocationsMulti
      .pipe(take(1), takeUntil(this._onDestroy))
      .subscribe(() => {
        this.multiSelect.compareWith = (a: any, b: any) => a && b && a.locationId === b.locationId;
      });
  }

  protected filterLocationsMulti() {
    if (!this.locations) {
      return;
    }
    // get the search keyword
    let search = this.locationMultiFilterCtrl.value;
    if (!search) {
      this.filteredLocationsMulti.next(this.locations);
      return;
    } else {
      search = search.toLowerCase();
    }
    
    this.filteredLocationsMulti.next(
      this.locations.filter(location => location.name.toLowerCase().indexOf(search) > -1)
    );
  }
}
