import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { Company } from 'src/app/models/company';
import { DataServiceParam } from 'src/app/service-data-sources/data-table/data-service-param';
import { TableDataSource } from 'src/app/service-data-sources/data-table/table-data-source';
import { CompanyService } from 'src/app/services/administration/company-service';
import { WebUserService } from 'src/app/services/administration/web-user.service';
import { LocationManageComponent } from '../company-management/location-management/location-manage/location-manage.component';
import { WebUserManageComponent } from './web-user-manage/web-user-manage.component';
import { tap } from 'rxjs/operators';
import { saveAs as importedSaveAs } from 'file-saver';

@Component({
  selector: 'app-web-user-management',
  templateUrl: './web-user-management.component.html',
  styleUrls: ['./web-user-management.component.scss']
})
export class WebUserManagementComponent implements OnInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  
  filter = {
    companyId: 0,
    filter: ""
  }

  companies: Company[];
  
  dataSource: TableDataSource<any>;
  displayedColumns: string[] = ['firstname', 'lastname', 'email', 'lastLoginDate', 'userType', 'company', 'state', 'isLockedOut', 'option'];

  constructor(private webUserService: WebUserService, private companyService: CompanyService, public dialog: MatDialog) { }

  ngOnInit(): void {
    this.loadCompanies();

    this.dataSource = new TableDataSource(this.webUserService);
  }

  ngAfterViewInit() {
    this.paginator.page.pipe(tap(() => this.loadTablePage())).subscribe();
    
    this.loadTable();
  }

  loadCompanies() {
    this.companies = [];
    this.companyService.getAll(-1, true, 'All').then((companies: Company[]) => {
      this.companies = companies;
    });
  }

  loadTable() {
    var params: DataServiceParam[] = [];
    params.push(<DataServiceParam>{ paramName: 'companyId', paramValue: this.filter.companyId });
    params.push(<DataServiceParam>{ paramName: 'filter', paramValue: this.filter.filter });
    
    this.dataSource.loadData(params);
  }

  loadTablePage() {
    var params: DataServiceParam[] = [];
    params.push(<DataServiceParam>{ paramName: 'companyId', paramValue: this.filter.companyId });
    params.push(<DataServiceParam>{ paramName: 'filter', paramValue: this.filter.filter });
    this.dataSource.loadData(params, 'asc', this.paginator.pageIndex, this.paginator.pageSize);
  }

  add() {
    const dialogRef = this.dialog.open(WebUserManageComponent, {
      width: '900px',
      // data: {name: this.name, animal: this.animal}
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.loadTable();
      }
    });
  }

  edit(userId) {
    const dialogRef = this.dialog.open(WebUserManageComponent, {
      width: '900px',
      data: {userId: userId}
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.loadTable();
      }
    });
  }

  export() {
    this.webUserService.export().then((data) => {
      importedSaveAs(data, 'Data.xls'); 
    });
  }
}
