<div class="dialog-content">
  <div class="spinner-container" *ngIf="(dataSource.loading$ | async)">
    <mat-spinner [diameter]="'50'"></mat-spinner>
  </div>

  <button class="add-btn" (click)="export()">
    <span *ngIf="!isExporting">Export</span>
    <mat-progress-spinner *ngIf="isExporting" [diameter]="'16'" [color]="'red'" [mode]="'indeterminate'">
    </mat-progress-spinner>
  </button>

  <form class="panel" (ngSubmit)="loadTable()">
    <mat-form-field>
      <mat-label class="form-label">Serial #</mat-label>
      <input type="text" name="serial" [(ngModel)]="serialNum" matInput placeholder="Serial #" >
    </mat-form-field>
  
    <button class="panel-btn panel-btn-margin" type="submit">
      FILTER
    </button>
  </form>


  <table mat-table [dataSource]="dataSource" class="table">
    <ng-container matColumnDef="links">
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let element" style="padding-right: 20px; cursor: pointer;">
        <div *ngIf="element.inspectionId != null">
          <div *ngIf="!element.certLoading" (click)="downloadCert(element.inspectionId, element.safeForUse, element)"><i class="fas fa-file-pdf"></i></div>
          <mat-progress-spinner *ngIf="element.certLoading"  [diameter]="'16'" [color]="'#000'" [mode]="'indeterminate'"></mat-progress-spinner>
        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="serialNum">
      <th mat-header-cell *matHeaderCellDef>Serial #</th>
      <td mat-cell *matCellDef="let element">{{element.serialNum}}</td>
    </ng-container>

    <ng-container matColumnDef="description">
      <th mat-header-cell *matHeaderCellDef>Description</th>
      <td mat-cell *matCellDef="let element">{{element.description}}</td>
    </ng-container>

    <ng-container matColumnDef="category">
      <th mat-header-cell *matHeaderCellDef>Category</th>
      <td mat-cell *matCellDef="let element">{{element.category}}</td>
    </ng-container>

    <ng-container matColumnDef="inspectedAt">
      <th mat-header-cell *matHeaderCellDef>Inspected At</th>
      <td mat-cell *matCellDef="let element">{{element.inspectedAt | date: 'dd/MM/yyyy' }}</td>
    </ng-container>

    <ng-container matColumnDef="nextInspectionDate">
      <th mat-header-cell *matHeaderCellDef>Next Inspection</th>
      <td mat-cell *matCellDef="let element">{{element.nextInspDate | date: 'dd/MM/yyyy'}}</td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>

  <mat-paginator [pageSizeOptions]="[10, 25, 50, 100]" [pageSize]='10' [length]="dataSource.total$ | async"
    showFirstLastButtons></mat-paginator>
</div>