<div class="content">
  <h1>Company Management</h1>

  <div class="sub-title">COMPANY SEARCH</div>

  <form class="panel" (ngSubmit)="loadCompanies()">
    <div class="panel-section-grid">
      <div class="panel-section">
        <mat-form-field>
          <mat-icon matPrefix style="padding-right: 10px;">search</mat-icon>
          <input type="text" matInput name="filter" placeholder="Name" [(ngModel)]="filter" >
        </mat-form-field>
      </div>
    </div>
    
    <div class="panel-btns__block">
      <button class="panel-btn panel-btn-margin" (click)="export()">
        EXPORT
      </button>

      <button class="panel-btn panel-btn-margin" type="submit">
        FILTER
      </button>
  
      <button class="add-btn" type="button" (click)="add()">
        ADD NEW
      </button>
    </div>
   
  </form>

  <div class="spinner-container" *ngIf="dataSource.loading$ | async">
    <mat-spinner [diameter]="'50'"></mat-spinner>
  </div>


  <table mat-table class="data-table mat-elevation-z8" [dataSource]="dataSource" fixedLayout="true">
  
    <ng-container matColumnDef="companyName">
      <th mat-header-cell *matHeaderCellDef>COMPANY NAME</th>
      <td mat-cell *matCellDef="let element"><span class="truncate-text">{{element.companyName}}</span></td>
    </ng-container>

    <ng-container matColumnDef="accNum">
      <th mat-header-cell *matHeaderCellDef>ACC #</th>
      <td mat-cell *matCellDef="let element"><span class="truncate-text">{{element.accNum}}</span></td>
    </ng-container>

    <ng-container matColumnDef="address1">
      <th mat-header-cell *matHeaderCellDef>ADDRESS 1</th>
      <td mat-cell *matCellDef="let element"><span class="truncate-text">{{element.address1}}</span></td>
    </ng-container>

    <ng-container matColumnDef="state">
      <th mat-header-cell *matHeaderCellDef>SUBURB</th>
      <td mat-cell *matCellDef="let element"><span class="truncate-text">{{element.address4}}</span></td>
    </ng-container>

    <ng-container matColumnDef="telephone">
      <th mat-header-cell *matHeaderCellDef>TELEPHONE</th>
      <td mat-cell *matCellDef="let element"><span class="truncate-text">{{element.telephone}}</span></td>
    </ng-container>

    <ng-container matColumnDef="expired">
      <th mat-header-cell *matHeaderCellDef>EXPIRED</th>
      <td mat-cell *matCellDef="let element"><span class="truncate-text">{{element.expired == true ? 'YES' : 'NO'}}</span></td>
    </ng-container>

    <ng-container matColumnDef="showClientNo">
      <th mat-header-cell *matHeaderCellDef>SHOW CLIENT #</th>
      <td mat-cell *matCellDef="let element"> {{element.showClientNo == true ? 'YES' : 'NO'}} </td>
    </ng-container>

    <ng-container matColumnDef="hideSynthetic">
      <th mat-header-cell *matHeaderCellDef>HIDE SYNTHETIC</th>
      <td mat-cell *matCellDef="let element"> {{element.hideSynthetic == true ? 'YES' : 'NO'}} </td>
    </ng-container>

    <ng-container matColumnDef="option">
      <th mat-header-cell *matHeaderCellDef>EDIT</th>
      <td mat-cell *matCellDef="let element"><a class="link-icon" (click)="edit(element.companyId)"><mat-icon>edit</mat-icon></a></td>
    </ng-container>

    <ng-container matColumnDef="locations">
      <th mat-header-cell *matHeaderCellDef>LOCATIONS</th>
      <td mat-cell *matCellDef="let element"><a [routerLink]="['locations', element.companyId]" class="link-icon"><mat-icon>location_on</mat-icon></a></td>
    </ng-container>
  

    
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>

  <mat-paginator [pageSizeOptions]="[10, 25, 50, 100]" [pageSize]='10' [length]="dataSource.total$ | async" showFirstLastButtons></mat-paginator>
</div>

