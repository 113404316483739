<div class="dialog-content">
  <h1>Load Test Report</h1>

  <form class="panel" (ngSubmit)="loadTable()">
    <app-datepicker [startDate]="filter.startDate" [endDate]="filter.endDate" (onChange)="updateDates($event)"></app-datepicker>
    <!-- <mat-form-field appearance="fill">
      <mat-label>Date Range</mat-label>
      <mat-date-range-input [rangePicker]="picker" >
        <input matStartDate name="startDate" [(ngModel)]="filter.startDate" placeholder="Start date">
        <input matEndDate name="endDate" [(ngModel)]="filter.endDate" placeholder="End date">
      </mat-date-range-input>
      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-date-range-picker #picker></mat-date-range-picker>
    </mat-form-field> -->
  
    
    <button class="panel-btn panel-btn-margin" type="submit">
      FILTER
    </button>
  </form>

  <table mat-table [dataSource]="dataSource" class="table">
    <ng-container matColumnDef="loadTestTime">
      <th mat-header-cell *matHeaderCellDef>Load Test Time</th>
      <td mat-cell *matCellDef="let element">{{element.loadTestTime}}</td>
    </ng-container>
  
    <ng-container matColumnDef="pdaUser">
      <th mat-header-cell *matHeaderCellDef>PDA User</th>
      <td mat-cell *matCellDef="let element">{{element.pdaUser}}</td>
    </ng-container>

    <ng-container matColumnDef="maxLoadTest">
      <th mat-header-cell *matHeaderCellDef>Max Load Test</th>
      <td mat-cell *matCellDef="let element">{{element.value | number: '1.4-4'}}</td>
    </ng-container>
  
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>

  <mat-paginator [pageSizeOptions]="[10, 25, 50, 100]" showFirstLastButtons></mat-paginator>
</div>