import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SnotifyService } from 'ng-snotify';
import { PDAUser } from 'src/app/models/pda-user';
import { PDAUserService } from 'src/app/services/administration/pda-user.service';

@Component({
  selector: 'app-pda-user-manage',
  templateUrl: './pda-user-manage.component.html',
  styleUrls: ['./pda-user-manage.component.scss']
})
export class PdaUserManageComponent implements OnInit {

  pdaUserForm;

  constructor(private pdaUserService: PDAUserService, private snotifyService: SnotifyService, 
    public dialogRef: MatDialogRef<PdaUserManageComponent>, @Inject(MAT_DIALOG_DATA) public data: any) { }

  async ngOnInit() {

    var user = new PDAUser();
    if (this.data != null && this.data.pdaUserId != null) {
      user = await this.pdaUserService.get(this.data.pdaUserId);
    }
    
    console.log(user);

    this.pdaUserForm = new FormGroup({
      pdaUserId: new FormControl(user.pdaUserId),
      firstname: new FormControl(user.firstname, [Validators.required]),
      lastname: new FormControl(user.lastname, [Validators.required]),
      email: new FormControl(user.email, [Validators.required]),
      expired: new FormControl(user.expired),
      pin: new FormControl(user.pin, [Validators.required]),
      pin2: new FormControl(user.pin, [Validators.required]),
      signature: new FormControl(user.signature, []),
    })
  }

  cancel() {
    this.dialogRef.close(false);
  }


  async inputChange(fileInputEvent: any) {
    // this.message = "";

    // this.isLoading = true;
    const file = fileInputEvent.target.files[0];
    var base64 = await toBase64(file);
    var fileBase64 = base64.toString().split(",")[1];
    var fileName = file.name;

    this.pdaUserForm.patchValue({
      'signature': fileBase64
    })
  }

  async onSubmit() {

    var user = this.pdaUserForm.value;
    if (user.pin != user.pin2) {
      this.snotifyService.error("PINs do not match");
      return;      
    }

    var success = await this.pdaUserService.save(this.pdaUserForm.value);
    if (!success) {
      this.snotifyService.error("Error saving user");
      return;
    }

    this.dialogRef.close(true);
    this.snotifyService.success("Successfully saved User");
  }

}

const toBase64 = file => new Promise((resolve, reject) => {
  const reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = () => resolve(reader.result);
  reader.onerror = error => reject(error);
});