<div class="download-container">
  <div style="display: flex;flex-direction: column;justify-content:center;align-items:stretch"></div>
  <h1> Images for item: {{this.data.title}}</h1>
  <div style="height: 65vh;width: auto\9; /* ie8 */"><img style="height:90%" [src]="base64" alt="Inspection Image"></div>
  <div style="display: flex;flex-direction: row;align-content: space-around;align-items: stretch;justify-content: space-around; width:100">
    <div *ngIf="maxStep > 1" style="width: 100%;display: flex;justify-content: center;align-items: center;"><button mat-button type="button"  class="add-btn" (click)="previousImage()">Previous</button></div> 
    <div style="width: 100%;display: flex;justify-content: center;align-items: center;"><button mat-button type="button" class="cancel-btn" style="margin-right:0px" (click)="cancel()">Close</button></div> 
    <div *ngIf="maxStep > 1" style="width: 100%;display: flex;justify-content: center;align-items: center;"><button mat-button type="button"  class="add-btn" (click)="nextImage()">Next</button></div> 
  </div>
</div>
