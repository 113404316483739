<div class="dialog-content">
  <h1>Email Report</h1>
  <form (ngSubmit)="onSubmit()">
    <mat-form-field class="full-width" appearance="fill">
      <mat-label>Email</mat-label>
      <input type="email" matInput [formControl]="emailFormControl" [errorStateMatcher]="matcher"
        placeholder="Ex. pat@example.com">
      <mat-error *ngIf="emailFormControl.hasError('email') && !emailFormControl.hasError('required')">
        Please enter a valid email address
      </mat-error>
      <mat-error *ngIf="emailFormControl.hasError('required')">
        Email is <strong>required</strong>
      </mat-error>
    </mat-form-field>

    <button type="submit" class="add-btn">Send</button>
  </form>
</div>