<div class="modal-panel" *ngIf="pdaUserForm != null">
  <form [formGroup]="pdaUserForm" (ngSubmit)="onSubmit()" class="form-manage">
    <div class="header">
      <h1>PDA User Management</h1>
    </div>

    <div class="form-controls">
      <div class="form-input">
        <mat-form-field appearance="fill">
          <mat-label class="form-label">Firstname</mat-label>
          <input matInput formControlName="firstname">
        </mat-form-field>
      </div>

      <div class="form-input">
        <mat-form-field appearance="fill">
          <mat-label class="form-label">Lastname</mat-label>
          <input matInput formControlName="lastname">
        </mat-form-field>
      </div>

      <div class="form-input">
        <mat-form-field appearance="fill">
          <mat-label class="form-label">Email</mat-label>
          <input matInput formControlName="email" type="email" />
        </mat-form-field>
      </div>

      <div class="form-input">
        <mat-form-field appearance="fill">
          <mat-label class="form-label">PIN</mat-label>
          <input matInput formControlName="pin" type="password">
        </mat-form-field>
      </div>

      <div class="form-input">
        <mat-form-field appearance="fill">
          <mat-label class="form-label">Confirm PIN</mat-label>
          <input matInput formControlName="pin2" type="password">
        </mat-form-field>
      </div>

      <div class="form-input">
        <mat-checkbox formControlName="expired">Expired</mat-checkbox>
      </div>
    </div>

    <input #csvInput hidden="true" type="file" onclick="this.value=null" (change)="inputChange($event)" accept=".png,.jpg,.jpeg"/>
    <button mat-flat-button type="button" color="primary" (click)="csvInput.click()">Upload Signature</button>  
    <br/>
    <img 
      style="max-width: 150px; margin-top: 10px;"
      *ngIf="pdaUserForm.controls.signature.value != null && pdaUserForm.controls.signature.value != ''" 
      src="{{'data:image/png;base64,' + pdaUserForm.controls.signature.value}}"
    />

    <div class="footer">
      <button type="button" class="cancel-btn" (click)="cancel()">CANCEL</button>
      <button type="submit" class="add-btn" [disabled]="!pdaUserForm.valid">SAVE</button>
    </div>
  </form>
</div>