<div class="content">
  <h1>Question Sets Management</h1>

  <form class="panel">
    <div class="panel-section">
      <mat-form-field>
        <mat-select placeholder="Category" name="categoryId" [(ngModel)]="categoryId" (selectionChange)="getQuestions()">
          <mat-option [value]="-1">All</mat-option>
          <mat-option *ngFor="let category of categories" [value]="category.categoryId">{{ category.categoryName }}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div class="spacer"></div>
  </form>

  <button class="add-btn" type="button" (click)="add()" *ngIf="categoryId != -1">
    ADD QUESTION
  </button>

  <table *ngIf="questions != null">
    <thead>
      <tr>
        <td>Question</td>
        <td>Type</td>
        <td>Position</td>
        <td></td>
        <td></td>
        <td></td>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let question of questions">
        <td>{{ question.question }}</td>
        <td>{{ question.questionTypeFormatted }}</td>
        <td>{{ question.position }}</td>
        <td><mat-icon (click)="moveQuestionDown(question)">arrow_downward</mat-icon></td>
        <td><mat-icon (click)="moveQuestionUp(question)">arrow_upward</mat-icon></td>
        <td><a class="link-icon" (click)="edit(question.inspSetQuestionId)"><mat-icon>edit</mat-icon></a></td>
      </tr>
    </tbody>
  </table>
</div>

