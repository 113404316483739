import { Injectable } from "@angular/core";
import { environment } from '../../../environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';
import { ApiResponse } from "src/app/models/api-response";


@Injectable()
export class PreferencesService  {
  constructor(private http: HttpClient) {
    
  }

  getEquipmentPreferences() : Promise<any> {
    const headers = {
      'Content-Type': 'application/json',
    };

    return new Promise((resolve, reject) => {
      this.http.get<any>(environment.apiUrl + "preference/getpreferencesaddequipment", { headers: headers }).subscribe(data => {
        resolve(data);
      }, (error) => {
        resolve(null)
      });
    });
  }

  saveEquipmentPreferences(email1, email2) : Promise<ApiResponse> {
    const headers = {
      'Content-Type': 'application/json',
    };

    var dto = {
      email1: email1,
      email2: email2
    }

    return new Promise((resolve, reject) => {
      this.http.post<any>(environment.apiUrl + "preference/savepreferencesaddequipment", dto, { headers: headers }).subscribe(data => {
        resolve(data);
      }, (error) => {
        resolve(null)
      });
    });
  }
  
}