import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { SnotifyService } from 'ng-snotify';
import { PDAUserService } from 'src/app/services/administration/pda-user.service';

@Component({
  selector: 'app-pda-login',
  templateUrl: './pda-login.component.html',
  styleUrls: ['./pda-login.component.scss']
})
export class PdaLoginComponent implements OnInit {

  users = [];
  pdaUserId = 0;
  pin;

  constructor(private pdaUserService: PDAUserService, private snotifyService: SnotifyService, public dialogRef: MatDialogRef<PdaLoginComponent>) { }

  ngOnInit(): void {
    this.pdaUserService.getAll(true, "Please Select").then((users) => {
      this.users = users;
    });
  }

  onSubmit() {
    this.pdaUserService.authenticate(this.pdaUserId, this.pin).then((user) => {
      if (user != null) {
        console.log(user);
        localStorage.setItem('pdaUser', JSON.stringify(user));
        this.snotifyService.success("Successfully logged in");
        this.dialogRef.close(true);
      } else {
        this.pin = "";
        this.snotifyService.error("Invalid PIN. Please try again");
      }
    });
  }
}
