import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SnotifyService } from 'ng-snotify';
import { Company } from 'src/app/models/company';
import { State } from 'src/app/models/state';
import { WebUser } from 'src/app/models/web-user';
import { CompanyService } from 'src/app/services/administration/company-service';
import { WebUserService } from 'src/app/services/administration/web-user.service';

@Component({
  selector: 'app-web-user-manage',
  templateUrl: './web-user-manage.component.html',
  styleUrls: ['./web-user-manage.component.scss']
})
export class WebUserManageComponent implements OnInit {

  webUserForm;
  companies: Company[];
  states: State[] = [];
  isEdit: boolean = false;

  constructor(private companyService: CompanyService, private webUserService: WebUserService, 
    public dialogRef: MatDialogRef<WebUserManageComponent>, private snotifyService: SnotifyService,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  async ngOnInit() {
    this.states = await this.companyService.getStates().then((states: State[]) => this.states = states);
    this.companies = await this.companyService.getAll(-1, false, "Please Select");
    
    var webUser = new WebUser();

    if (this.data != null && this.data.userId != null) {
      webUser = await this.webUserService.get(this.data.userId);
      this.isEdit = true;
    }

    if (this.isEdit) {
      this.webUserForm = new FormGroup({
        userId: new FormControl(webUser.userId),
        firstname: new FormControl(webUser.firstname, [Validators.required]),
        lastname: new FormControl(webUser.lastname, [Validators.required]),
        password: new FormControl(""),
        password2: new FormControl(""),
        email: new FormControl({ value: webUser.email, disabled: true}),
        email2: new FormControl({ value: webUser.email, disabled: true}),
        userTypeId: new FormControl(webUser.userTypeId, [Validators.required]),
        state: new FormControl(webUser.state, [Validators.required]),
        companyId: new FormControl(webUser.companyId, [Validators.required]),
        isLockedOut: new FormControl(webUser.isLockedOut),
      })


    } else {
      this.webUserForm = new FormGroup({
        firstname: new FormControl(webUser.firstname, [Validators.required]),
        lastname: new FormControl(webUser.lastname, [Validators.required]),
        password: new FormControl("", [Validators.required]),
        password2: new FormControl("", [Validators.required]),
        email: new FormControl(webUser.email, [Validators.required]),
        email2: new FormControl(webUser.email2, [Validators.required]),
        userTypeId: new FormControl(webUser.userTypeId, [Validators.required]),
        state: new FormControl(webUser.state, [Validators.required]),
        companyId: new FormControl(webUser.companyId, [Validators.required]),
      })
    }
  }

  stateChanged($ev) {
    this.companyService.getAll($ev.value, false, "Please Select").then((companies: Company[]) => this.companies = companies);
  }

  cancel() {
    this.dialogRef.close(false);
  }

  async onSubmit() {
    var user = this.webUserForm.value;
    if (user.password != user.password2) {
      this.snotifyService.error("Passwords do not match");
      return;      
    }

    if (user.email != user.email2) {
      this.snotifyService.error("Emails do not match");
      return;      
    }

    if (user.companyId == 0) {
      this.snotifyService.error("Select company");
      return;      
    }

    var success = await this.webUserService.save(this.webUserForm.value);
    if (!success) {
      this.snotifyService.error("Error saving user");
      return;
    }

    this.dialogRef.close(true);
    this.snotifyService.success("Successfully saved user");
  }

}
