<div class="content">
  <h1>Download Data</h1>

  <div class="panel-section">
    <app-datepicker [startDate]="filter.startDate" [endDate]="filter.endDate" (onChange)="updateDates($event)"></app-datepicker>
    <!-- <mat-form-field appearance="fill">
      <mat-label>Date Range</mat-label>
      <mat-date-range-input [rangePicker]="picker" >
        <input matStartDate name="startDate" [(ngModel)]="filter.startDate" placeholder="Start date">
        <input matEndDate name="endDate" [(ngModel)]="filter.endDate" placeholder="End date">
      </mat-date-range-input>
      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-date-range-picker #picker></mat-date-range-picker>
    </mat-form-field> -->
  </div>

  <button type="submit" class="add-btn" (click)="download()">DOWNLOAD</button>

  <app-loading *ngIf="isLoading"></app-loading>
</div>