import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { EquipmentService } from 'src/app/services/administration/equipment.service';

@Component({
  selector: 'app-movement-history',
  templateUrl: './movement-history.component.html',
  styleUrls: ['./movement-history.component.scss']
})
export class MovementHistoryComponent implements OnInit {

  displayedColumns: string[] = ['movementDate', 'location', 'movedBy',];
  dataSource;

  constructor(private equipService: EquipmentService, @Inject(MAT_DIALOG_DATA) public data: {equipmentId: number}, 
  public dialogRef: MatDialogRef<MovementHistoryComponent>) { }

  ngOnInit(): void {
    this.equipService.getMovementHistory(this.data.equipmentId).then((history) => {
      this.dataSource = history;
    })
  }

}
