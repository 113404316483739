<div class="dialog-content" *ngIf="currentState == 2">
  <div class="title">
    <h1>THANK YOU</h1>
  </div>
  <div class="confirm-message">
    <p>Our Inspections team will be in touch with you to make the necessary changes</p>
    <div class="action-buttons">
      <button class="btn-cancel" type="button" (click)="cancel()">
        CLOSE
      </button>
    </div>
  </div>
</div>

<div class="dialog-content" *ngIf="currentState == 1">
  <div class="subtitle">
    <h3>INTERNAL NOTIFICATION</h3>
  </div>

  <div class="title">
    <h1 *ngIf="!newLocation">LOCATION EDIT OR EXPIRATION</h1>
    <h1 *ngIf="newLocation">NEW LOCATION</h1>
  </div>

  <p *ngIf="!newLocation">The following locations need to be amended or expired.</p>
  <p *ngIf="newLocation">
    Enter the location details
  </p>

  <form (ngSubmit)="onSend()">
    <mat-form-field appearance="fill" class="full-width">
      <mat-label *ngIf="!newLocation">Notes</mat-label>
      <mat-label *ngIf="newLocation">Location details...</mat-label>
      <textarea rows="5" [(ngModel)]="notes" name="notes" matInput placeholder="Notes..."></textarea>
    </mat-form-field>
    
    <div class="confirm-list-content" *ngIf="!newLocation">
      <div class="selected-location" *ngFor="let location of selection.selected">
        {{ location.name }}
      </div>
    </div>

    <div class="action-buttons">
      <button class="btn-save" type="submit">
        SEND
      </button>

      <button class="btn-cancel" type="button" (click)="cancel()">
        CANCEL
      </button>
    </div>
  </form>
</div>


<div class="dialog-content" *ngIf="currentState == 0">
  <div class="title">
    <h1>LOCATIONS</h1>
    <div style="display: flex; flex-direction: column">
      <p style="margin: 0; font-weight: bold;">NOTICE AN ERROR?</p>
      <p>SELECT THE LOCATIONS AND NOTIFY US</p>
    </div>
    <img src="/assets/icons/send-icon.png"/>
  </div>
  <form class="form" (ngSubmit)="onSubmit()">
    <div class="location-content">
      <table mat-table matSort [dataSource]="dataSource">
        <ng-container matColumnDef="option">
          <th mat-header-cell *matHeaderCellDef>
            <mat-checkbox (change)="$event ? toggleAllRows() : null"
              [checked]="selection.hasValue() && isAllSelected()"
              [indeterminate]="selection.hasValue() && !isAllSelected()">
            </mat-checkbox>
          </th>
          <td mat-cell *matCellDef="let row">
            <mat-checkbox (click)="$event.stopPropagation()"
              (change)="$event ? selection.toggle(row) : null"
              [checked]="selection.isSelected(row)">
            </mat-checkbox>
          </td>
        </ng-container>

        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>NAME #</th>
          <td mat-cell *matCellDef="let element" >{{element.name}} </td>
        </ng-container>

        <ng-container matColumnDef="address1">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>ADDRESS</th>
          <td mat-cell *matCellDef="let element">{{element.address1}} </td>
        </ng-container>

        <ng-container matColumnDef="county">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>SUBURB</th>
          <td mat-cell *matCellDef="let element">{{element.address4 }}</td>
        </ng-container>

        <ng-container matColumnDef="state">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>STATE</th>
          <td mat-cell *matCellDef="let element">{{ element.county }}</td>
        </ng-container>

        <ng-container matColumnDef="postcode">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>POSTCODE</th>
          <td mat-cell *matCellDef="let element">{{element.postcode}}</td>
        </ng-container>

        <ng-container matColumnDef="email1">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>EMAIL</th>
          <td mat-cell *matCellDef="let element">{{element.email1}}</td>
        </ng-container>

        <ng-container matColumnDef="monthlyInterval">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>INSPECTION INTERVAL</th>
          <td mat-cell *matCellDef="let element">{{element.monthlyInterval}}</td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>

      <mat-paginator [pageSizeOptions]="[5, 10]" [pageSize]='10'></mat-paginator>
    </div>

    <div class="action-buttons">
      <button class="btn-save" type="submit">
        NEXT
      </button>

      <button class="btn-cancel" type="button" (click)="cancel()">
        CANCEL
      </button>

      <button class="btn-location" type="button" (click)="addNewLocation()">
        ADD NEW LOCATION
      </button>
    </div>
  </form>
  <!-- <form (ngSubmit)="onSubmit()">
    <div class="location-content">
      <div class="list-header list-cell">
        <div>Name</div>
        <div>Address Line 1</div>
        <div>Address Line 2</div>
        <div>Suburb</div>
        <div>State</div>
        <div>Postcode</div>
        <div>Email</div>
        <div>Inspection Interval</div>
        <div>Select</div>
      </div>

      <div class="location-list">
        <div class="list-row list-cell" *ngFor="let location of locations">
          <div>{{ location.name }}</div>
          <div>{{ location.address1 }}</div>
          <div>{{ location.address2 }}</div>
          <div>{{ location.county }}</div>
          <div></div>
          <div>{{ location.postcode }}</div>
          <div>{{ location.email1 }}</div>
          <div>{{ location.monthlyInterval }}</div>
          <div style="text-align: center;"><mat-checkbox name="selected" [(ngModel)]="location.selected"></mat-checkbox></div>
        </div>
      </div>
    </div>

    <div class="action-buttons">
      <button class="btn-save" type="submit">
        SEND
      </button>
  
      <button class="btn-cancel" type="button" (click)="cancel()">
        CANCEL
      </button>
    </div>
  </form> -->
</div>