import { Injectable } from "@angular/core";
import { environment } from '../../../environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from "rxjs";
import { Location } from "src/app/models/location";
import { AusStd } from "src/app/models/aus-std";
import { DataService } from "../../service-data-sources/data-table/data-service-interface";
import { DataServiceParam } from "../../service-data-sources/data-table/data-service-param";
import { Colour } from "src/app/models/colour";


@Injectable()
export class ColourService implements DataService {
  constructor(private http: HttpClient) {
    
  }

  getAll(includePleaseSelect: boolean = false, pleaseSelectLabel = "Please Select") : Promise<any> {
    const headers = {
      'Content-Type': 'application/json',
    };

    return new Promise((resolve, reject) => {
      this.http.get<any>(environment.apiUrl + "colour/getall", { headers: headers }).subscribe(data => {
        if (includePleaseSelect) {
          var pleaseSelect = new Colour();
          pleaseSelect.colourId = 0;
          pleaseSelect.colour = pleaseSelectLabel;
          data.unshift(pleaseSelect);
        }

        resolve(data);
      }, (error) => {
        resolve(null)
      });
    });
  }

  getTable(params: DataServiceParam[], sortOrder: string = 'asc', pageIndex: number = 0, pageSize: number = 10) : Observable<Colour[]> {
    return null;
  }

}