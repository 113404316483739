import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";

@Injectable()
export class ZipDownloaderService {
  jobs: BehaviorSubject<any> = new BehaviorSubject<any>([]);

  createJob(inspectionData, jobName, jobType) {
    var job = {
      name: jobName,
      downloaded: 0,
      total: inspectionData.length,

      inspections: inspectionData,
      status: "PENDING",

      percentage: 0,
      jobType: jobType
    }

    
    var jobList = this.jobs.value;
    jobList.push(job);

    this.jobs.next(jobList);
  }

  removeJob(job) {
    var jobList = this.jobs.value;
    jobList.splice(jobList.indexOf(job), 1);

    this.jobs.next(jobList);
  }
}