<div class="content">
  <app-loading *ngIf="isLoading"></app-loading>

  <h1>Import Data</h1>

  <a href="/assets/master.xlsx">Download Master</a>

  <br/><br/>

  <input #csvInput hidden="true" type="file" onclick="this.value=null" (change)="inputChange($event)" accept=".xlsx"/>
  <button mat-flat-button color="primary" (click)="csvInput.click()">Choose Spreadsheet File (XLSX)</button>

  <br/><br/>

  {{ message }}
  <!-- <label for="singleFile">Upload file</label><br/><br/>
  <input id="singleFile" type="file" [fileUploadInputFor]="fileUploadQueue" />

  <br/><br/>

  <mat-file-upload-queue #fileUploadQueue [fileAlias]="'file'">
    <mat-file-upload [file]="file" [id]="i" *ngFor="let file of fileUploadQueue.files; let i = index"></mat-file-upload>
  </mat-file-upload-queue> -->
</div>