import { Component, Input, OnInit, HostListener, ElementRef, forwardRef, Renderer2 } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-option-picker',
  templateUrl: './option-picker.component.html',
  styleUrls: ['./option-picker.component.scss'],
  providers: [
    { 
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => OptionPickerComponent),
      multi: true
    }
  ]
})
export class OptionPickerComponent implements ControlValueAccessor {
  @Input() label = "";
  @Input() data = [];

  showOptions: boolean = false;

  touched = false;
  disabled = false;

  onChange = (data) => {};
  onTouched = () => {};

  constructor(private renderer: Renderer2, private el: ElementRef) {
    this.renderer.listen('window', 'click', (e: Event) => {
      if (e.target !== el.nativeElement && !this.el.nativeElement.contains(e.target) && this.showOptions) {
        this.showOptions = false;
      }
    })
  }

  ngOnInit(): void {
    
  }

  writeValue(obj: any): void {
    this.data = obj;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  markAsTouched() {
    if (this.touched) {
      this.onTouched();
      this.touched = true;
    }
  }

}
