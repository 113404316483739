import { ViewChild } from '@angular/core';
import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { tap } from 'rxjs/operators';
import { CondemnedDataSource } from 'src/app/service-data-sources/dashboard/condemned-data-source';
import { InServiceDataSource } from 'src/app/service-data-sources/dashboard/in-service-data-source';
import { MissingDataSource } from 'src/app/service-data-sources/dashboard/missing-data-source';
import { OutOfServiceDataSource } from 'src/app/service-data-sources/dashboard/out-of-service-data-source';
import { RepairedDataSource } from 'src/app/service-data-sources/dashboard/repaired-data-source';
import { RequiresAttentionDataSource } from 'src/app/service-data-sources/dashboard/requires-attention-data-source';
import { DashboardService } from 'src/app/services/dashboard/dashboard.service';
import { saveAs as importedSaveAs } from 'file-saver';
import { ReportableService } from 'src/app/services/reportable.service';

enum State {
  InService,
  RequiresAttention,
  OutOfService,
  Repaired,
  Missing,
  Condemned
}

@Component({
  selector: 'app-dashboard-list',
  templateUrl: './dashboard-list.component.html',
  styleUrls: ['./dashboard-list.component.scss']
})
export class DashboardListComponent implements OnInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  
  displayedColumns: string[] = ['links', 'serialNum', 'description', 'category', 'inspectedAt', 'nextInspectionDate'];
  dataSource;

  serialNum = "";

  isExporting: boolean = false;

  constructor(private dashboardService: DashboardService, private reportableService: ReportableService,
    @Inject(MAT_DIALOG_DATA) public data: {state: State, companyId: number}) {
    console.log(this.data);
  }

  ngOnInit(): void {

    switch (this.data.state) {
      case State.InService:
        this.dataSource = new InServiceDataSource(this.dashboardService);
        break;
      case State.RequiresAttention:
        this.dataSource = new RequiresAttentionDataSource(this.dashboardService);
        break;
      case State.OutOfService:
        this.dataSource = new OutOfServiceDataSource(this.dashboardService);
        break;
      case State.Repaired:
        this.dataSource = new RepairedDataSource(this.dashboardService);
        break;
      case State.Missing:
        this.dataSource = new MissingDataSource(this.dashboardService);
        break;
      case State.Condemned:
        this.dataSource = new CondemnedDataSource(this.dashboardService);
        break;
    }
    

    this.loadTable();
  }

  
  ngAfterViewInit() {
    this.paginator.page.pipe(tap(() => this.loadTablePage())).subscribe();  
  }

  loadTable() {
    this.dataSource.loadData(this.data.companyId, this.serialNum); 
  }

  loadTablePage() {
    this.dataSource.loadData(this.data.companyId, this.serialNum, 'asc', this.paginator.pageIndex, this.paginator.pageSize);
  }

  downloadCert(inspectionId: number, safeForUse: boolean, row: any) {
    row.certLoading = true;
    this.reportableService.getLiftingExam(inspectionId, safeForUse).then((data) => {
      importedSaveAs(data, 'Report.pdf');
      row.certLoading = false;
    });
  }

  async export() {
    this.isExporting = true;
    var data = null;
    var name = "";
    switch (this.data.state) {
      case State.InService:
        var response = await this.dashboardService.exportInService(this.data.companyId);
        data = this.convertBase64toBlob(response.base64, 'application/vnd.ms-excel');
        name = response.filename;
        break;
      case State.RequiresAttention:
        var response = await this.dashboardService.exportRequiresAttention(this.data.companyId);
        data = this.convertBase64toBlob(response.base64, 'application/vnd.ms-excel');
        name = response.filename;
        break;
      case State.OutOfService:
        var response = await this.dashboardService.exportOutOfService (this.data.companyId);
        data = this.convertBase64toBlob(response.base64, 'application/vnd.ms-excel');
        name = response.filename;
        break;
      case State.Repaired:
        var response = await this.dashboardService.exportRepaired(this.data.companyId);
        data = this.convertBase64toBlob(response.base64, 'application/vnd.ms-excel');
        name = response.filename;
        break;
      case State.Missing:
        var response = await this.dashboardService.exportMissing(this.data.companyId);
        data = this.convertBase64toBlob(response.base64, 'application/vnd.ms-excel');
        name = response.filename;
        break;
      case State.Condemned:
        var response = await this.dashboardService.exportCondemned(this.data.companyId);
        data = this.convertBase64toBlob(response.base64, 'application/vnd.ms-excel');
        name = response.filename;
        break;
    }

    importedSaveAs(data, name + '.xlsx');
    this.isExporting = false;
  }

  convertBase64toBlob(content, contentType) {
    contentType = contentType || '';
    let sliceSize = 512;
    let byteCharacters = window.atob(content); //method which converts base64 to binary
    let byteArrays = [
    ];
    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      let slice = byteCharacters.slice(offset, offset + sliceSize);
      let byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
      let byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }
    let blob = new Blob(byteArrays, {
      type: contentType
    }); //statement which creates the blob
    return blob;
  }
}
