<div class="modal-panel">
  <div class="dialog-content">
    <div class="title">Supporting Document Upload</div>

    <div style="padding:5%">
      <div style="display:flex;flex-direction:row; width:100%;margin-bottom:5%">
        <textarea type="text" name="notes" matInput style="width: 100%; border: 1px solid #CECECE;" [(ngModel)]="notes" placeholder="Please enter notes"></textarea>
      </div>

      <div style="display: flex; flex-direction: row; width: 100%; margin-bottom: 5%">
        <input #csvInput hidden="true" type="file" onclick="this.value=null" (change)="inputChange($event)" accept=".*" />
        <button mat-flat-button color="primary" style="flex:1" (click)="csvInput.click()">Select Document</button>
      </div>

      <div style="flex:1"> {{fileName}}</div>

      <div style="display:flex;flex-direction:row;width:100%;">
        <div style="display:flex;flex-direction:row;width:100%;">
          <button style="margin-top: 5%" class="cancel-btn" (click)="cancel()">Cancel</button>
        </div>

        <div style="display:flex;flex-direction:row-reverse;width:100%;">
          <button style="margin-top: 5%" class="add-btn" (click)="submit()">Save</button>
        </div>
      </div>

    </div>

  </div>
</div>
