<div class="dialog-content">
  <h1>Movement History</h1>

  <table mat-table [dataSource]="dataSource" class="table">
    <ng-container matColumnDef="movementDate">
      <th mat-header-cell *matHeaderCellDef>Movement Date</th>
      <td mat-cell *matCellDef="let element">{{element.movementDate}}</td>
    </ng-container>
  
    <ng-container matColumnDef="location">
      <th mat-header-cell *matHeaderCellDef>Name</th>
      <td mat-cell *matCellDef="let element">{{element.locationMovedTo}}</td>
    </ng-container>
  
    <ng-container matColumnDef="movedBy">
      <th mat-header-cell *matHeaderCellDef>Moved By</th>
      <td mat-cell *matCellDef="let element">{{element.movedBy}}</td>
    </ng-container>
  
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>
</div>