<div class="ranger-header">
  <div class="ranger__block">
    <div class="logo">
      <img src="/assets/riglogo.jpg"/>
    </div>
  
    <div class="spacer"></div>
  
    <div class="contact__block">
      <h1>RANGER LIFTING | RIGGING | SAFETY PTY LTD</h1>
      <div class="contact__table">
        <div class="bold">SYDNEY:</div>
        <div>(02) 9679 9944</div>
        <div>PRESTONS | NSW</div>
  
        <div class="bold">MELBOURNE:</div>
        <div>(03) 8317 9940</div>
        <div>PORT MELBOURNE | VIC</div>
  
        <div class="bold">BRISBANE:</div>
        <div>(07) 2111 3509</div>
        <div>DARRA | QLD</div>
      </div>
    </div>
  
    <div class="images__block">
      <div class="logo">
        <img src="/assets/logos/NATA logo.jpg"/>
      </div>
      <div class="logo">
        <img src="/assets/logos/LEEA logo.jpg"/>
      </div>
      <div class="logo">
        <img src="/assets/logos/AWRF-color-1.png"/>
      </div>
    </div>
  </div>
</div>