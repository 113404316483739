import { coerceNumberProperty } from '@angular/cdk/coercion';
import { Component, Inject, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SnotifyService } from 'ng-snotify';
import { Colour } from 'src/app/models/colour';
import { Company } from 'src/app/models/company';
import { Location } from 'src/app/models/location';
import { PDAUser } from 'src/app/models/pda-user';
import { ColourService } from 'src/app/services/administration/colour.service';
import { CompanyService } from 'src/app/services/administration/company-service';
import { JobService } from 'src/app/services/administration/job-service';
import { LocationService } from 'src/app/services/administration/location.service';
import { PDAUserService } from 'src/app/services/administration/pda-user.service';

@Component({
  selector: 'app-job-manage',
  templateUrl: './job-manage.component.html',
  styleUrls: ['./job-manage.component.scss']
})
export class JobManageComponent implements OnInit {
  companies: Company[];
  locations: Location[];
  colours: Colour[];
  engineers: PDAUser[];

  engineerId: number;
  engineersList = [];

  jobForm;
  isLoading: boolean = false;
  editing: boolean = false;

  constructor(private companyService: CompanyService, private locationService: LocationService,
    private jobService: JobService, private colourService: ColourService, private snotifyService: SnotifyService,
    private pdaUserService: PDAUserService, public dialogRef: MatDialogRef<JobManageComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { locationInspectionId: number }
  ) { }

  ngOnInit(): void {
    this.loadCompanies();
    this.loadColours();
    this.loadEngineers();

    this.jobForm = new FormGroup({
      companyId: new FormControl(0, [Validators.required]),
      locationId: new FormControl(0),
      salesOrderNum: new FormControl('', [Validators.required]),
      colourId: new FormControl('', [Validators.required]),
      engineerId: new FormControl('')
    });
    this.editing = false;

    if (this.data.locationInspectionId != null) {
      this.editing = true;
      this.jobService.get(this.data.locationInspectionId).then((data) => {
        console.log(data);
       
        this.jobForm.patchValue({
          companyId: data.companyId,
          salesOrderNum: data.salesOrderNum,
          colourId: data.colourId,
          locationId: data.locationId
        });
        this.jobService.getUsers(this.data.locationInspectionId).then((data2) => {
          for (var a = 0; a < data2.length; a++) {
            var engineer = data2[a];
            engineer.name = engineer.firstname + ' ' + engineer.lastname;
            engineer.pdaUserId = engineer.tabletUserId;
            this.engineersList.push(engineer);
          }
        });
        this.loadLocations(data.companyId);
      });
    }
  }

  async onSubmit() {
    if (this.engineersList.length == 0) {
      this.snotifyService.error("Select at least 1 engineer");
      return;
    }

    var job = this.jobForm.value;
    this.isLoading = true;
    console.log(this.engineersList);
    var engineerIds = this.engineersList.map(e => e.pdaUserId);
    if (this.editing) { 
      var response = await this.jobService.updateJob(this.data.locationInspectionId, job.companyId,job.locationId,job.salesOrderNum, job.colourId, engineerIds);
    } else {
      var response = await this.jobService.createJob(job.companyId, job.locationId, job.salesOrderNum, job.colourId, engineerIds);
    }
    
    this.isLoading = false;
    if (response.success) {
      this.snotifyService.success("Successfully created job");
      this.dialogRef.close(true);
    } else {
      this.snotifyService.error("Error creating job");
    }
  }

  loadCompanies() {
    this.companyService.getAll().then((companies: Company[]) => {
      this.companies = companies;
      this.locations = [];
    });
  }

  loadLocations(companyId: number) {
    console.log("Loading Locations: " + companyId);
    this.locationService.getAll(companyId, true, "All").then((locations: Location[]) => {
      console.log(locations);
      this.locations = locations;
    });
  }

  loadColours() {
    this.colourService.getAll(true, "Please Select").then((colours: Colour[]) => {
      this.colours = colours;
    });
  }

  loadEngineers() {
    this.pdaUserService.getAll(true, 'Please Select').then((users: PDAUser[]) => {
      this.engineers = users;
    });
  }


  companyChanged(id) {
    this.loadLocations(id);
  }

  cancel() {
    this.dialogRef.close(false);
  }

  addEngineer() {
    var engineer = this.engineers.find(e => e.pdaUserId == this.jobForm.controls.engineerId.value);

    if (this.engineersList.includes(engineer) || engineer.pdaUserId == 0) {
      return;
    }

    this.engineersList.push(engineer);

    this.jobForm.patchValue({
      engineerId: '',
    });
  }

  removeEngineer(engineer) {
    var idx = this.engineersList.indexOf(engineer);
    this.engineersList.splice(idx, 1);
  }
}
