<div class="content">
  <h1>Batch Manufacturers Management</h1>

  <div class="sub-title">SEARCH</div>

  <form class="panel" (ngSubmit)="loadTable()">
    <div class="panel-section-grid">
      <div class="panel-section">
        <mat-form-field>
          <mat-icon matPrefix style="padding-right: 10px;">search</mat-icon>
          <input type="text" matInput name="filter" placeholder="Name" [(ngModel)]="filter" >
        </mat-form-field>
      </div>
    </div>

    <div class="panel-btns__block">
      <button class="panel-btn panel-btn-margin" type="submit">
        FILTER
      </button>

      <button class="add-btn" type="button" (click)="add()">
        ADD NEW
      </button>
    </div>

  </form>

  <div class="spinner-container" *ngIf="dataSource.loading$ | async">
    <mat-spinner [diameter]="'50'"></mat-spinner>
  </div>

  <table mat-table class="data-table mat-elevation-z8" [dataSource]="dataSource" fixedLayout="true">
    <ng-container matColumnDef="batchManuf">
      <th mat-header-cell *matHeaderCellDef>Batch Manufacturer</th>
      <td mat-cell *matCellDef="let element"><span class="truncate-text">{{element.batchManuf}}</span></td>
    </ng-container>

    <ng-container matColumnDef="edit">
      <th mat-header-cell *matHeaderCellDef>EDIT</th>
      <td mat-cell *matCellDef="let element"><a class="link-icon" (click)="edit(element.batchManufId)"><mat-icon>edit</mat-icon></a></td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>

  <mat-paginator [pageSizeOptions]="[10, 25, 50, 100]" [pageSize]='10' [length]="dataSource.total$ | async" showFirstLastButtons></mat-paginator>
</div>

