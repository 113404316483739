<div class="control" (click)="openModal()" [ngClass]="{'disabled' : disabled}">
  <label>{{ title }} <i class="fa fa-chevron-down" aria-hidden="true"></i></label>

  <div class="label-modal" *ngIf="isModalOpen">
    <input class="search-input" placeholder="Search..." [(ngModel)]="searchFilter"/>
    <div class="list">
      <div *ngFor="let item of data | filter: searchFilter: keyPairName" (click)="toggleItem(item)">
        <i [style.display]="item.selected == null || item.selected == false ? 'block' : 'none'" class="far fa-square"></i>
        <i [style.display]="item.selected ? 'block' : 'none'" class="far fa-check-square" ></i>

        {{ getItemName(item) }}
      </div>
    </div>
  </div>
</div>

