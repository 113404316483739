import { CollectionViewer } from "@angular/cdk/collections";
import { BehaviorSubject, Observable, of } from "rxjs";
import { catchError, finalize } from "rxjs/operators";
import { JobService } from "src/app/services/administration/job-service";
import { DataServiceParam } from "../data-table/data-service-param";

export class PendingJobDataSource {
  private dataSubject = new BehaviorSubject<any>([]);
  private loadingSubject = new BehaviorSubject<boolean>(false);
  private countSubject = new BehaviorSubject<number>(0);

  public loading$ = this.loadingSubject.asObservable();
  public total$ = this.countSubject.asObservable();

  constructor(private jobService: JobService) {

  }

  connect(collectionViewer: CollectionViewer): Observable<[]> {
    return this.dataSubject.asObservable();
  }

  disconnect(collectionViewer: CollectionViewer): void {
    this.dataSubject.complete();
    this.loadingSubject.complete();
  }

  loadData(params: DataServiceParam[], sortOrder: string = 'asc', pageIndex: number = 0, pageSize: number = 10) {
    this.loadingSubject.next(true);

    this.jobService.getPendingJob(params, sortOrder, pageIndex, pageSize)
      .pipe(catchError(() => of([])), finalize(() => { this.loadingSubject.next(false) }))
      .subscribe((response: any) => {
        console.log(response);
        this.countSubject.next(response.total);
        this.dataSubject.next(response.data);
      })
  };
}