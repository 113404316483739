import { Injectable } from "@angular/core";
import { environment } from '../../../environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from "rxjs";
import { Location } from "src/app/models/location";
import { AusStd } from "src/app/models/aus-std";
import { DataService } from "../../service-data-sources/data-table/data-service-interface";
import { DataServiceParam } from "../../service-data-sources/data-table/data-service-param";
import { WebUser } from "src/app/models/web-user";


@Injectable()
export class WebUserService implements DataService {
  constructor(private http: HttpClient) {

  }

  export() : Promise<any> {
    const headers = {
      'Content-Type': 'application/json',
    };


    return new Promise((resolve, reject) => {
      this.http.get<any>(environment.apiUrl + "webuser/export", { headers: headers }).subscribe(data => {
        let blob = this.convertBase64toBlob(data.value, 'application/vnd.ms-excel');
        resolve(blob);
      }, (error) => {
        resolve(null)
      });
    });
  }

  get(userId: number) : Promise<WebUser> {
    const headers = {
      'Content-Type': 'application/json',
    };

    return new Promise((resolve, reject) => {
      this.http.get<any>(environment.apiUrl + "webuser/get?userId=" + userId, { headers: headers }).subscribe(data => {
        resolve(data);
      }, (error) => {
        resolve(null)
      });
    });
  }

  getTable(params: DataServiceParam[], sortOrder: string = 'asc', pageIndex: number = 0, pageSize: number = 10): Observable<[]> {
    const headers = {
      'Content-Type': 'application/json',
    };

    var httpParams = new HttpParams()
      .set('sortOrder', sortOrder)
      .set('pageIndex', pageIndex.toString())
      .set('pageSize', pageSize.toString());

    for (let param of params) {
      httpParams = httpParams.append(param.paramName, param.paramValue);
    }

    return this.http.get<[]>(environment.apiUrl + "webuser/gettable", {
      headers: headers,
      params: httpParams
    });
  }

  save(webUser: WebUser) {
    const headers = {
      'Content-Type': 'application/json',
    };

    return new Promise((resolve, reject) => {
      this.http.post<any>(environment.apiUrl + "webuser/post", webUser, { headers: headers }).subscribe(data => {
        resolve(true);
      }, (error) => {
        resolve(false)
      });
    });
  }

  changePassword(username: string, oldPassword: string, newPassword: string) {
    const headers = {
      'Content-Type': 'application/json',
    };

    var dto = {
      username: username,
      oldPassword: oldPassword,
      newPassword: newPassword
    }

    return new Promise((resolve, reject) => {
      this.http.post<any>(environment.apiUrl + "webuser/changepassword", dto, { headers: headers }).subscribe(data => {
        resolve(true);
      }, (error) => {
        resolve(false)
      });
    });
  }

  convertBase64toBlob(content, contentType) {
    contentType = contentType || '';
    let sliceSize = 512;
    let byteCharacters = window.atob(content); //method which converts base64 to binary
    let byteArrays = [
    ];
    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      let slice = byteCharacters.slice(offset, offset + sliceSize);
      let byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
      let byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }
    let blob = new Blob(byteArrays, {
      type: contentType
    }); //statement which creates the blob
    return blob;
  }
}