import { Injectable } from "@angular/core";
import { environment } from '../../../environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from "rxjs";
import { Company } from "src/app/models/company";
import { State } from "src/app/models/state";
import { DataServiceParam } from "../../service-data-sources/data-table/data-service-param";
import { DataService } from "../../service-data-sources/data-table/data-service-interface";



@Injectable()
export class CompanyService implements DataService {
  constructor(private http: HttpClient) {
    
  }

  get(companyId: number) : Promise<Company> {
    const headers = {
      'Content-Type': 'application/json',
    };

    return new Promise((resolve, reject) => {
      this.http.get<any>(environment.apiUrl + "company/get?companyId=" + companyId, { headers: headers }).subscribe(data => {
        resolve(data);
      }, (error) => {
        resolve(null)
      });
    });
  }

  getAll(stateId: number = -1, includePleaseSelect: boolean = false, pleaseSelectLabel = "Please Select") : Promise<Company[]> {
    const headers = {
      'Content-Type': 'application/json',
    };

    var httpParams = new HttpParams().set('stateId', stateId.toString())

    return new Promise((resolve, reject) => {
      this.http.get<any>(environment.apiUrl + "company/getall", { headers: headers, params: httpParams }).subscribe(data => {
        if (includePleaseSelect) {
          var pleaseSelect = new Company();
          pleaseSelect.companyId = 0;
          pleaseSelect.companyName = pleaseSelectLabel;
          data.unshift(pleaseSelect);
        }
        resolve(data);
      }, (error) => {
        resolve(null)
      });
    });
  }

  export() : Promise<any> {
    const headers = {
      'Content-Type': 'application/json',
    };


    return new Promise((resolve, reject) => {
      this.http.get<any>(environment.apiUrl + "company/export", { headers: headers }).subscribe(data => {
        let blob = this.convertBase64toBlob(data.value, 'application/vnd.ms-excel');
        resolve(blob);
      }, (error) => {
        resolve(null)
      });
    });
  }

  getTable(params: DataServiceParam[], sortOrder: string = 'asc', pageIndex: number = 0, pageSize: number = 10) : Observable<Company[]> {
    const headers = {
      'Content-Type': 'application/json',
    };

    var httpParams = new HttpParams()
      .set('sortOrder', sortOrder)
      .set('pageIndex', pageIndex.toString())
      .set('pageSize', pageSize.toString());

    for (let param of params) {
      httpParams = httpParams.append(param.paramName, param.paramValue);
    }

    return this.http.get<Company[]>(environment.apiUrl + "company/gettable", { 
      headers: headers,
      params: httpParams
    });
  }

  getStates() : Promise<State[]> {
    const headers = {
      'Content-Type': 'application/json',
    };

    return new Promise((resolve, reject) => {
      this.http.get<any>(environment.apiUrl + "company/getstates", { headers: headers }).subscribe(data => {
        resolve(data);
      }, (error) => {
        resolve(null)
      });
    });
  }

  save(company: Company) : Promise<boolean> {
    const headers = {
      'Content-Type': 'application/json',
    };

    return new Promise((resolve, reject) => {
      this.http.post<any>(environment.apiUrl + "company/post", company, { headers: headers }).subscribe(data => {
        resolve(true);
      }, (error) => {
        resolve(false)
      });
    });
  }

  convertBase64toBlob(content, contentType) {
    contentType = contentType || '';
    let sliceSize = 512;
    let byteCharacters = window.atob(content); //method which converts base64 to binary
    let byteArrays = [
    ];
    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      let slice = byteCharacters.slice(offset, offset + sliceSize);
      let byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
      let byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }
    let blob = new Blob(byteArrays, {
      type: contentType
    }); //statement which creates the blob
    return blob;
  }
}