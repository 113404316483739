<div class="modal-panel" *ngIf="webUserForm != null">
  <form [formGroup]="webUserForm" (ngSubmit)="onSubmit()" class="form-manage">
    <div class="header">
      <h1>Web User Management</h1>
    </div>

    <div class="form-controls">
      <div class="form-input">
        <mat-form-field appearance="fill">
          <mat-label class="form-label">Firstname</mat-label>
          <input matInput formControlName="firstname">
        </mat-form-field>
      </div>

      <div class="form-input">
        <mat-form-field appearance="fill">
          <mat-label class="form-label">Lastname</mat-label>
          <input matInput formControlName="lastname">
        </mat-form-field>
      </div>

      <div class="form-input">
        <mat-form-field appearance="fill">
          <mat-label class="form-label">User Types</mat-label>
          <mat-select formControlName="userTypeId" required>
            <mat-option [value]="5">Master Admin</mat-option>
            <mat-option [value]="3">Admin</mat-option>
            <mat-option [value]="7">Inspector</mat-option>
            <mat-option [value]="1">Customer</mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div class="form-input">
        <mat-form-field appearance="fill">
          <mat-label class="form-label">Password {{ isEdit ? ' (OPTIONAL)' : null }}</mat-label>
          <input matInput formControlName="password" type="password">
        </mat-form-field>
      </div>

      <div class="form-input">
        <mat-form-field appearance="fill">
          <mat-label class="form-label">Confirm Password {{ isEdit ? ' (OPTIONAL)' : null }}</mat-label>
          <input matInput formControlName="password2" type="password">
        </mat-form-field>
      </div>

      <div class="form-input">
      
      </div>

      <div class="form-input">
        <mat-form-field appearance="fill">
          <mat-label class="form-label">Email</mat-label>
          <input matInput formControlName="email" type="email">
        </mat-form-field>
      </div>

      <div class="form-input">
        <mat-form-field appearance="fill">
          <mat-label class="form-label">Confirm Email</mat-label>
          <input matInput formControlName="email2" type="email">
        </mat-form-field>
      </div>

      <div class="form-input">
      
      </div>

      <div class="form-input">
        <mat-form-field appearance="fill">
          <mat-label class="form-label">State</mat-label>
          <mat-select formControlName="state" required (selectionChange)="stateChanged($event)">
            <mat-option [value]="state.stateId" *ngFor="let state of states">{{ state.stateName }}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div class="form-input">
        <mat-form-field appearance="fill">
          <mat-label class="form-label">Company</mat-label>
          <mat-select formControlName="companyId" required>
            <mat-option [value]="company.companyId" *ngFor="let company of companies">{{ company.companyName }}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div class="form-input">
        <mat-checkbox formControlName="isLockedOut">Locked Out</mat-checkbox>
      </div>
    </div>

    <div class="footer">
      <button type="button" class="cancel-btn" (click)="cancel()">CANCEL</button>
      <button type="submit" class="add-btn" [disabled]="!webUserForm.valid">SAVE</button>
    </div>
  </form>
</div>