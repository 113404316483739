import { Component, OnInit } from '@angular/core';
import { SnotifyService } from 'ng-snotify';
import { EquipmentService } from 'src/app/services/administration/equipment.service';

@Component({
  selector: 'app-import-data',
  templateUrl: './import-data.component.html',
  styleUrls: ['./import-data.component.scss']
})
export class ImportDataComponent implements OnInit {

  isLoading: boolean = false;
  message: string = "";

  constructor(private equipmentService: EquipmentService, private snotifyService: SnotifyService) { }

  ngOnInit(): void {
  }

  async inputChange(fileInputEvent: any) {
    this.message = "";

    this.isLoading = true;
    const file = fileInputEvent.target.files[0];
    var base64 = await toBase64(file);
    var excelBase64 = base64.toString().split(",")[1];

    this.equipmentService.uploadEquipment(excelBase64).then((response) => {
      this.isLoading = false;
      
      if (!response.success) {
        this.snotifyService.error(response.message);
        return;
      }

      this.message = response.message;
      
    });
  }
}

const toBase64 = file => new Promise((resolve, reject) => {
  const reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = () => resolve(reader.result);
  reader.onerror = error => reject(error);
});