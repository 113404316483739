export class PDAUser {
  pdaUserId: number = 0;
  firstname: string;
  lastname: string;
  pin: string;
  expired: boolean = false;
  createdDate: string;
  adminUser: boolean = false;
  email: string;
  androidPin: string;
  signature: string;
}