import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SnotifyService } from 'ng-snotify';
import { ImageService } from 'src/app/services/image.service';

@Component({
  selector: 'app-image-upload',
  templateUrl: './image-upload.component.html',
  styleUrls: ['./image-upload.component.scss']
})
export class ImageUploadComponent implements OnInit {

  images = [];

  constructor(private snotifyService: SnotifyService, private imageService: ImageService,
    @Inject(MAT_DIALOG_DATA) public data: {inspectionId: number}) { }

  ngOnInit(): void {
    this.loadImages();
  }

  loadImages() {
    this.imageService.getInspectionImages(this.data.inspectionId).then((images) => {
      this.images = images;
    });
  }

  async inputChange(fileInputEvent: any) {
    // this.message = "";

    // this.isLoading = true;
    const file = fileInputEvent.target.files[0];
    var base64 = await toBase64(file);
    var fileBase64 = base64.toString().split(",")[1];
    var fileName = file.name;

    this.imageService.uploadInspectionImage(fileBase64, this.data.inspectionId, fileName).then((response) => {
      if (!response.success) {
        this.snotifyService.error(response.message);
        return;
      }

      this.snotifyService.success("Successfully uploaded image");
      this.loadImages();
    });
  }

  deleteImage(filename) {
    this.imageService.deleteInspectionImage(this.data.inspectionId, filename).then((response) => {
      this.snotifyService.success("Successfully deleted image");
      this.loadImages();
    });
  }
}

const toBase64 = file => new Promise((resolve, reject) => {
  const reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = () => resolve(reader.result);
  reader.onerror = error => reject(error);
});
