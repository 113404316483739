import { Component, Inject, OnInit, NgModule } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import * as moment from 'moment';
import { SnotifyService } from 'ng-snotify';
import { Equipment } from 'src/app/models/equipment';
import { AusStdService } from 'src/app/services/administration/aus-std.service';
import { CategoryService } from 'src/app/services/administration/category.service';
import { DescriptionService } from 'src/app/services/administration/description.service';
import { EquipmentService } from 'src/app/services/administration/equipment.service';
import { LocationService } from 'src/app/services/administration/location.service';
import { AuthService } from 'src/app/services/auth.service';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from "@angular/material/form-field";
import * as XLSX from 'xlsx';
import { BatchManufService } from '../../../../services/administration/batch-manuf-service';
import { BatchTypeService } from '../../../../services/administration/batch-type-service';
import { UUID } from 'angular2-uuid';


@Component({
  selector: 'app-equipment-manage',
  templateUrl: './equipment-manage.component.html',
  styleUrls: ['./equipment-manage.component.scss']
})
export class EquipmentManageComponent implements OnInit {

  descriptions = [];
  categories = [];
  ausStds = [];
  locations = [];
  batchTypes = [];
  batchManf = [];
  filteredDescriptions = [];
  filteredCategories = [];
  filteredAusStds = [];
  filteredLocations = [];
  filteredBatchTypes = [];
  filteredBatchManf = [];
  locationId;

  batchs = [];
  batchManufId: number;
  batchTypeId: number;
  batchNum: '';
  equipmentForm;

  constructor(private descriptionService: DescriptionService, private categoryService: CategoryService, private authService: AuthService,
    private ausStdService: AusStdService, private snotifyService: SnotifyService, private locationService: LocationService,
    @Inject(MAT_DIALOG_DATA) public data: any, public dialogRef: MatDialogRef<EquipmentManageComponent>, private equipmentService: EquipmentService,
    public batchManufService: BatchManufService,public batchTypeService: BatchTypeService  ) { }

  async ngOnInit()  {
    
    this.descriptions = await this.descriptionService.getAll(true, "Other");
    this.locations = await this.locationService.getAll(this.data.companyId, true, "Please Select");
    this.categories = await this.categoryService.getAll(true, "Please Select");
    this.ausStds = await this.ausStdService.getAll(true, "Please Select");
    this.batchTypes = await this.batchTypeService.getAll(true, "Please Select");
    this.batchManf = await this.batchManufService.getAll(true, "Please Select");

    var equipment = new Equipment();
    if (this.data.equipmentId != null) {
      equipment = await this.equipmentService.get(this.data.equipmentId);
      await this.addBatchFromAPI(await this.equipmentService.getBatchNumbers(this.data.equipmentId));
    }

    this.equipmentForm = new FormGroup({
      equipmentId: new FormControl(equipment.equipmentId),
      LocationInspectionIdWhenCreated: new FormControl(this.data.locationInspectionId),
      locationId: new FormControl(equipment.locationId, [Validators.required]),
      descriptionList: new FormControl(equipment.description),
      description: new FormControl(equipment.description),
      categoryId: new FormControl(equipment.categoryId, [Validators.required]),
      serialNum: new FormControl(equipment.serialNum, [Validators.required]),
      assetNum: new FormControl(equipment.assetNum),
      loadTestTo: new FormControl(equipment.loadTestTo),
      wll: new FormControl(equipment.wll),
      australianStdId: new FormControl(equipment.australianStdId, [Validators.required]),
      dateOfManuf: new FormControl(equipment.dateOfManuf),
      ownerId: new FormControl(equipment.ownerId, [Validators.required]),
      otherDetails: new FormControl(equipment.otherDetails),
      newIntoService: new FormControl(equipment.newIntoService, [Validators.required]),
      decommisioned: new FormControl(equipment.decommisioned, [Validators.required]),
      clientAssetNumber: new FormControl(equipment.clientAssetNumber),
      department: new FormControl(equipment.department),
      batchTypeId: new FormControl(this.batchTypeId),
      batchManufId: new FormControl(this.batchManufId),
      batchNum: new FormControl(this.batchNum),
    });

    if (this.descriptions.filter(d => d.descriptionText == equipment.description).length == 0) {
      this.equipmentForm.patchValue({ descriptionList: "" });
    }
    
  }
 async addBatchFromAPI(batchs) {
    for (var c = 0; c < batchs.length; c++) {
      var b = batchs[c];
      var manufName = '';
      var batchType = '';

      for (var a = 0; a < this.batchManf.length; a++) {
        var manuf = this.batchManf[a];
        if (manuf.batchManufId == b.batch_Manuf_ID) {
          manufName = manuf.batchManuf;
        }
      }

      for (var a = 0; a < this.batchTypes.length; a++) {
        var batch = this.batchTypes[a];
        if (batch.batchTypeId == b.batch_Type_ID) {
          batchType = batch.type;
        }
      }

      var dto = {
        equipment_Batch_GUID: b.equipment_Batch_GUID,
        batch_Manuf_ID: b.batch_Manuf_ID,
        Batch_Type_ID: b.batch_Type_ID,
        Batch_Num: b.batch_Num,
        batchType: batchType,
        manufName: manufName,
      };
      this.batchs.push(dto);
    }
  }
  cancel() {
    this.dialogRef.close(false);
  }

  protected filterList(control, search) {
    ////console.log("filtering");
    var mainList;
    var filteredList;
    var name;
    if (control == 1) {
      mainList = this.locations;
      filteredList = this.filteredLocations;
      name = 'name';
    }
  

    if (!mainList) {
      return;
    }
    // get the search keyword
  
    if (!search) {
      filteredList = mainList;
      return;
    } else {
      search = search.toLowerCase();
    }

    var newFilteredList = mainList.filter(data => data[name].toLowerCase().indexOf(search) > -1);
    filteredList = newFilteredList;
  }

  async onSubmit() {
    var equipment = this.equipmentForm.value;
    if (equipment.descriptionList != '' && equipment.descriptionList != "Other") {
      equipment.description = equipment.descriptionList;
    }
    this.snotifyService.error(equipment.description);
    //else if (equipment.descriptionList != '' && equipment.descriptionList != "Other") {
    //  equipment.description = equipment.descriptionList;
    //}
    equipment.changedBy = this.authService.currentUser.value.username;
  
    if (equipment.description == "" || equipment.description == "Other") {
      this.snotifyService.error("Enter description");
     
      return;
    }
  
    if (equipment.dateOfManuf != null && equipment.dateOfManuf != '')
      equipment.dateOfManuf = moment(equipment.dateOfManuf).format("YYYY-MM-DD");

    equipment.locationId;// = this.locationId;

    equipment.Batches = this.batchs;

    //console.log("Saving");
    //console.log(equipment);
    //console.log(this.locationId);

    if (equipment.serialNum.includes("|")) {
      const serialNums = equipment.serialNum.split("|");
  
      for (const serialNum of serialNums) {
        const newEquipment = { ...equipment, serialNum };
  
        const success = await this.equipmentService.add(newEquipment);
        if (!success) {
          this.snotifyService.error("Error saving Equipment");
          return;
        }
      }
    } else {
      const success = await this.equipmentService.add(equipment);
      if (!success) {
        this.snotifyService.error("Error saving Equipment");
        return;
      }
    }
  
    this.dialogRef.close(true);
    this.snotifyService.success("Successfully saved Equipment");
  }

  showFileUpload = false;

  onUploadClick() {
  this.showFileUpload = !this.showFileUpload;
}

display: FormControl = new FormControl("", Validators.required);
file_store: FileList;

handleFileInputChange(l: FileList): void {
  this.file_store = l;
  if (l.length) {
    const f = l[0];
    const count = l.length > 1 ? `(+${l.length - 1} files)` : "";
    this.display.patchValue(`${f.name}${count}`);
  } else {
    this.display.patchValue("");
  }
}

async handleSubmit(): Promise<void> {
  const reader = new FileReader();
  reader.onload = (event) => {
    const data = event.target.result as string;
    const workbook = XLSX.read(data, { type: 'binary' });
    const sheetName = workbook.SheetNames[0];
    const sheet = workbook.Sheets[sheetName];
    const rows = XLSX.utils.sheet_to_json(sheet, {header: 1});
    let Serials = '';
    let Assets = '';
    //console.log(rows.length);
    //console.log(rows);
    for (var a = 0; a < rows.length; a++) {
      var row = rows[a];
      //console.log(row);
      if (row[0] != undefined && row[0] != null && row[0] != '')
        Serials += row[0] + '|';
      if (row[1] != undefined && row[1] != null && row[1] != '')
        Assets += row[1] + '|';
    }

    Serials = Serials.slice(0, -1);
    Assets = Assets.slice(0, -1);
    //console.log(Serials);
    //console.log(Assets);
    //console.log(rows);
    this.equipmentForm.get('assetNum').setValue(Assets);
    this.equipmentForm.get('serialNum').setValue(Serials);
  };
  reader.readAsBinaryString(this.file_store[0]);
}

  onDescriptionChange(value) {
    //console.log("onDescriptionChange");
    if (value != null && value != undefined && value != '' && value != 'Other') {
      this.descriptionService.get(value).then((data => {
        ////console.log(data);
        this.equipmentForm.patchValue({ australianStdId: data[0].aus_std_Id });
        this.equipmentForm.patchValue({ categoryId: data[0].category_Id });
        this.equipmentForm.patchValue({ wll: data[0].wll });
      }));
    }
  }

  addBatch() {
    var batchTypeId = this.equipmentForm.get('batchTypeId').value;
    var batchManufId = this.equipmentForm.get('batchManufId').value;
    var batchNum = this.equipmentForm.get('batchNum').value;
    var exists = false;
    var manufName = '';
    var batchType = '';
    for (var a = 0; a < this.batchs.length; a++) {
      var batch = this.batchs[a];
      if (batch.Batch_Type_ID == batchTypeId && batch.batch_Manuf_ID == batchManufId && batch.Batch_Num == batchNum) {
        exists = true;
      }
    }

    for (var a = 0; a < this.batchManf.length; a++) {
      var manuf = this.batchManf[a];
      if (manuf.batchManufId == batchManufId) {
        manufName = manuf.batchManuf;
      }
    }

    for (var a = 0; a < this.batchTypes.length; a++) {
      var batch = this.batchTypes[a];
      if (batch.batchTypeId == batchTypeId) {
        batchType = batch.type;
      }
    }

    if (!exists) {
      var dto = {
        equipment_Batch_GUID: UUID.UUID(),
        batch_Manuf_ID: batchManufId,
        Batch_Type_ID: batchTypeId,
        Batch_Num: batchNum,
        batchType: batchType,
        manufName: manufName,
      };
      this.batchs.push(dto)
    }

    this.equipmentForm.patchValue({ batchTypeId: undefined });
    this.equipmentForm.patchValue({ batchManufId: undefined });
    this.equipmentForm.patchValue({ batchNum: undefined });

  }
}
