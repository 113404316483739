import { Injectable } from "@angular/core";
import { environment } from '../../../environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from "rxjs";
import { Location } from "src/app/models/location";
import { AusStd } from "src/app/models/aus-std";
import { DataService } from "../../service-data-sources/data-table/data-service-interface";
import { DataServiceParam } from "../../service-data-sources/data-table/data-service-param";


@Injectable()
export class DescriptionService {
  constructor(private http: HttpClient) {

  }


  getAll(includePleaseSelect: boolean = false, pleaseSelectLabel: string = ""): Promise<any> {
    const headers = {
      'Content-Type': 'application/json',
    };

    return new Promise((resolve, reject) => {
      this.http.get<any>(environment.apiUrl + "description/getall", { headers: headers }).subscribe(data => {
        if (includePleaseSelect) {
          data.unshift({
            descriptionText: pleaseSelectLabel
          });
        }
        resolve(data);
      }, (error) => {
        resolve(null)
      });
    });
  }
  get(value): Promise<any> {
    const headers = {
      'Content-Type': 'application/json',
    };

    return new Promise((resolve, reject) => {
      this.http.get<any>(environment.apiUrl + "description/getValues?value=" + value, { headers: headers }).subscribe(data => {
        resolve(data);
      }, (error) => {
        resolve(null)
      });
    });
  }
}
