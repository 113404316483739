<mat-form-field>
  <mat-date-range-input [rangePicker]="rangePicker" (dateChange)="dateChanged($event)" [disabled]="disabled">
    <input matStartDate [(ngModel)]="startDate" placeholder="Start date">
    <input matEndDate [(ngModel)]="endDate" placeholder="End date">
  </mat-date-range-input>
  <mat-datepicker-toggle matSuffix [for]="rangePicker"></mat-datepicker-toggle>
  <mat-date-range-picker #rangePicker>
    <mat-date-range-picker-actions>
      <div class="dp-with-options">
        <button matDateRangePickerCancel (click)="todayDate()">Today</button>
        <button matDateRangePickerCancel (click)="lastWeekDate()">Last Week</button>
        <button matDateRangePickerCancel (click)="thisMonthDate()">This Month</button>
        <button matDateRangePickerCancel (click)="lastMonthDate()">Last Month</button>
        <button matDateRangePickerCancel (click)="thisYearDate()">This Year</button>
        <button matDateRangePickerCancel (click)="lastYearDate()">Last Year</button>
      </div>

      <button class="dp-cancel" mat-button matDateRangePickerCancel>Cancel</button>
      <button class="dp-apply" mat-raised-button color="primary" matDateRangePickerApply (click)="apply()">Apply</button>
    </mat-date-range-picker-actions>
  </mat-date-range-picker>
</mat-form-field>