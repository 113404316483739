import { Component, OnInit } from '@angular/core';
import {FormControl, FormGroupDirective, NgForm, Validators} from '@angular/forms';
import {ErrorStateMatcher} from '@angular/material/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';

/** Error when invalid control is dirty, touched, or submitted. */
export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}

@Component({
  selector: 'app-email-report-dialog',
  templateUrl: './email-report-dialog.component.html',
  styleUrls: ['./email-report-dialog.component.scss']
})
export class EmailReportDialogComponent implements OnInit {

  emailFormControl = new FormControl('', [
    Validators.required,
    Validators.email,
  ]);

  matcher = new MyErrorStateMatcher();

  constructor(public dialogRef: MatDialogRef<EmailReportDialogComponent>) { }

  ngOnInit(): void {
  }

  onSubmit() {
    console.log(this.emailFormControl);
    if (this.emailFormControl.status == "INVALID")
      return;

    this.dialogRef.close({
      success: true,
      email: this.emailFormControl.value
    });
  }

}
