import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './pages/login/login.component';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { RoleGuard } from './services/role.guard';
import { AuthService } from './services/auth.service';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { LoadingComponent } from './components/loading/loading.component';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { MatSidenavModule } from '@angular/material/sidenav';
import { MatMenuModule } from '@angular/material/menu';
import { MatIconModule } from '@angular/material/icon'; 
import { MatSelectModule } from '@angular/material/select';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatInputModule } from '@angular/material/input';
import { MatNativeDateModule } from '@angular/material/core';
import { MatTableModule } from '@angular/material/table';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSortModule } from '@angular/material/sort';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatRadioModule } from '@angular/material/radio';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatTooltipModule } from '@angular/material/tooltip';
import { SnotifyModule, SnotifyService, ToastDefaults } from 'ng-snotify';
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';
import { NgSelectModule } from '@ng-select/ng-select'
import { ScrollingModule } from '@angular/cdk/scrolling';

import {MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS} from '@angular/material-moment-adapter';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';

import { TopbarComponent } from './components/topbar/topbar.component';
import { DashboardService } from './services/dashboard/dashboard.service';
import { CategoryService } from './services/administration/category.service';
import { CompanyManagementComponent } from './pages/management/company-management/company-management.component';
import { ApiHttpInterceptor } from './services/api-http-interceptor';
import { CompanyService } from './services/administration/company-service';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { CompanyManageComponent } from './pages/management/company-management/company-manage/company-manage.component';
import { LocationManagementComponent } from './pages/management/company-management/location-management/location-management.component';
import { LocationService } from './services/administration/location.service';
import { LocationManageComponent } from './pages/management/company-management/location-management/location-manage/location-manage.component';
import { JobManagementComponent } from './pages/management/job-management/job-management.component';
import { JobService } from './services/administration/job-service';
import { JobDetailsComponent } from './pages/management/job-management/job-details/job-details.component';
import { AustralianStandardManagementComponent } from './pages/management/australian-standard-management/australian-standard-management.component';
import { AusStdService } from './services/administration/aus-std.service';
import { AusStdManageComponent } from './pages/management/australian-standard-management/aus-std-manage/aus-std-manage.component';
import { BatchManufacturersManagementComponent } from './pages/management/batch-manufacturers-management/batch-manufacturers-management.component';
import { BatchManufService } from './services/administration/batch-manuf-service';
import { BatchTypeService } from './services/administration/batch-type-service';
import { ReportableService } from './services/reportable.service';
import { BatchTypesManagementComponent } from './pages/management/batch-types-management/batch-types-management.component';
import { BatchTypeManageComponent } from './pages/management/batch-types-management/batch-type-manage/batch-type-manage.component';
import { BatchManufacturerManageComponent } from './pages/management/batch-manufacturers-management/batch-manufacturer-manage/batch-manufacturer-manage.component';
import { CategoryManagementComponent } from './pages/management/category-management/category-management.component';
import { CategoryManageComponent } from './pages/management/category-management/category-manage/category-manage.component';
import { QuestionSetsManagementComponent } from './pages/management/question-sets-management/question-sets-management.component';
import { InspSetService } from './services/administration/insp-set-service';
import { AdminGuard } from './services/guards/admin.guard';
import { RangerRegisterComponent } from './pages/reports/ranger-register/ranger-register.component';
import { ReportService } from './services/reports/reports.service';
import { AssetRegisterComponent } from './pages/reports/asset-register/asset-register.component';
import { InspectionsComponent } from './pages/reports/inspections/inspections.component';
import { PDAUserService } from './services/administration/pda-user.service';
import { JobReportComponent } from './pages/reports/job-report/job-report.component';
import { BatchReportComponent } from './pages/reports/batch-report/batch-report.component';
import { EmailReportDialogComponent } from './components/email-report-dialog/email-report-dialog.component';
import { MoveEquipComponent } from './components/move-equip/move-equip.component';
import { EquipmentService } from './services/administration/equipment.service';
import { MovementHistoryComponent } from './components/movement-history/movement-history.component';
import { InspectionHistoryComponent } from './components/inspection-history/inspection-history.component';
import { LoadTestComponent } from './components/load-test/load-test.component';
import { CreateUserComponent } from './components/create-user/create-user.component';
import { WebUserManagementComponent } from './pages/management/web-user-management/web-user-management.component';
import { WebUserService } from './services/administration/web-user.service';
import { WebUserManageComponent } from './pages/management/web-user-management/web-user-manage/web-user-manage.component';
import { PdaUserManagementComponent } from './pages/management/pda-user-management/pda-user-management.component';
import { PdaUserManageComponent } from './pages/management/pda-user-management/pda-user-manage/pda-user-manage.component';
import { EquipmentManagementComponent } from './pages/management/equipment-management/equipment-management.component';
import { EquipmentManageComponent } from './pages/management/equipment-management/equipment-manage/equipment-manage.component';
import { DescriptionService } from './services/administration/description.service';
import { PdaLoginComponent } from './components/pda-login/pda-login.component';
import { InspectionComponent } from './components/inspection/inspection.component';
import { QuestionService } from './services/administration/question.service';
import { PendingJobsComponent } from './pages/management/pending-jobs/pending-jobs.component';
import { ViewPendingJobComponent } from './pages/management/pending-jobs/view-pending-job/view-pending-job.component';
import { PreferencesComponent } from './pages/management/preferences/preferences.component';
import { PreferencesService } from './services/administration/preferences.service';
import { DownloadDataComponent } from './pages/management/download-data/download-data.component';
import { ImportDataComponent } from './pages/management/import-data/import-data.component';
import { MatFileUploadModule } from 'angular-material-fileupload';
import { CompletedJobsComponent } from './pages/management/completed-jobs/completed-jobs.component';
import { AddQuestionComponent } from './components/add-question/add-question.component';
import { ImageUploadComponent } from './components/image-upload/image-upload.component';
import { ImageService } from './services/image.service';
import { DatepickerComponent } from './components/datepicker/datepicker.component';
import { DashboardListComponent } from './components/dashboard-list/dashboard-list.component';
import { DocumentUploadComponent } from './components/supporting-document-upload/supporting-document-upload.component';
import { EquipmentDetailsComponent } from './pages/management/equipment-management/equipment-details/equipment-details.component';
import { InspectionPreviewComponent } from './components/inspection-preview/inspection-preview.component';
import { ZipDownloaderComponent } from './components/zip-downloader/zip-downloader.component';
import { ZipDownloaderService } from './components/zip-downloader/zip-downloader-service';
import { InspectionBasketComponent } from './components/inspection-basket/inspection-basket.component';
import { LocationSearchComponent } from './components/location-search/location-search.component';
import { CategorySearchComponent } from './components/category-search/category-search.component';
import { LabelControlComponent } from './components/label-control/label-control.component';
import { DashboardStatsComponent } from './pages/dashboard/dashboard-stats/dashboard-stats.component';
import { InspectionDownloadComponent } from './components/inspection-download/inspection-download.component';
import { ImagePopUpComponent } from './components/image-popup/image-popup.component';
import { ChangePasswordComponent } from './pages/management/change-password/change-password.component';
import { NotifyDialogComponent } from './components/notify-dialog/notify-dialog.component';
import { InspectionService } from './services/inspection.service';
import { NotifyLocationsDialogComponent } from './components/notify-locations-dialog/notify-locations-dialog.component';
import { ViewJobsDialogComponent } from './components/view-jobs-dialog/view-jobs-dialog.component';
import { OptionPickerComponent } from './components/option-picker/option-picker.component';
import { HelpCentreComponent } from './pages/help-centre/help-centre.component';
import { RangerHeaderComponent } from './components/ranger-header/ranger-header.component';
import { FilterPipe } from './pipes/filter-pipe';
import { ColourService } from './services/administration/colour.service';
import { SearchSelectComponent } from './components/search-select/search-select.component';
import { JobManageComponent } from './pages/management/job-management/job-manage/job-manage.component';
import { InspectorGuard } from './services/guards/inspector.guard';
import { MasterAdminGuard } from './services/guards/master-admin.guard';


export const MY_FORMATS = {
  parse: {
    dateInput: 'LL',
  },
  display: {
    dateInput: 'LL',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    DashboardComponent,
    LoadingComponent,
    TopbarComponent,
    CompanyManagementComponent,
    CompanyManageComponent,
    LocationManagementComponent,
    LocationManageComponent,
    JobManagementComponent,
    JobDetailsComponent,
    AustralianStandardManagementComponent,
    AusStdManageComponent,
    BatchManufacturersManagementComponent,
    BatchTypesManagementComponent,
    BatchTypeManageComponent,
    BatchManufacturerManageComponent,
    CategoryManagementComponent,
    CategoryManageComponent,
    QuestionSetsManagementComponent,
    RangerRegisterComponent,
    AssetRegisterComponent,
    InspectionsComponent,
    JobReportComponent,
    BatchReportComponent,
    EmailReportDialogComponent,
    MoveEquipComponent,
    MovementHistoryComponent,
    InspectionHistoryComponent,
    LoadTestComponent,
    CreateUserComponent,
    WebUserManagementComponent,
    WebUserManageComponent,
    PdaUserManagementComponent,
    PdaUserManageComponent,
    EquipmentManagementComponent,
    EquipmentManageComponent,
    PdaLoginComponent,
    InspectionComponent,
    PendingJobsComponent,
    ViewPendingJobComponent,
    PreferencesComponent,
    DownloadDataComponent,
    ImportDataComponent,
    CompletedJobsComponent,
    AddQuestionComponent,
    ImageUploadComponent,
    DatepickerComponent,
    DashboardListComponent,
    DocumentUploadComponent,
    EquipmentDetailsComponent,
    InspectionPreviewComponent,
    ZipDownloaderComponent,
    InspectionBasketComponent,
    LocationSearchComponent,
    CategorySearchComponent,
    LabelControlComponent,
    DashboardStatsComponent,
    InspectionDownloadComponent,
    ImagePopUpComponent,
    ChangePasswordComponent,
    NotifyDialogComponent,
    NotifyLocationsDialogComponent,
    ViewJobsDialogComponent,
    OptionPickerComponent,
    HelpCentreComponent,
    RangerHeaderComponent,

    FilterPipe,

    SearchSelectComponent,

    JobManageComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,

    MatSidenavModule,
    MatMenuModule,
    MatIconModule,
    MatSelectModule,
    NgxMatSelectSearchModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatInputModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    MatProgressSpinnerModule,
    MatDialogModule,
    MatFormFieldModule,
    MatCheckboxModule,
    MatSnackBarModule,
    MatRadioModule,
    MatFileUploadModule,
    MatAutocompleteModule,
    MatProgressBarModule,
    MatButtonToggleModule,
    MatTooltipModule,
    ScrollingModule,

    SnotifyModule,
    NgxDaterangepickerMd.forRoot(),
    NgSelectModule,
  ],
  providers: [
    RoleGuard,
    AdminGuard,
    MasterAdminGuard,
    InspectorGuard,
    AuthService,

    DashboardService,
    CategoryService,
    LocationService,
    CompanyService,
    JobService,
    AusStdService,
    BatchManufService,
    BatchTypeService,
    InspSetService,
    ReportService,
    PDAUserService,
    EquipmentService,
    DescriptionService,
    QuestionService,
    PreferencesService,
    InspectionService,
    ColourService,
    
    MatDatepickerModule,
    SnotifyService,
    WebUserService,
    ZipDownloaderService,

    ReportableService,
    ImageService,

    { provide: HTTP_INTERCEPTORS, useClass: ApiHttpInterceptor, multi: true},

    { provide: 'SnotifyToastConfig', useValue: ToastDefaults},
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },

    {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS},
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
