import { Injectable } from "@angular/core";
import { environment } from '../../../environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from "rxjs";
import { BatchManuf } from "src/app/models/batch-manuf";
import { DataService } from "../../service-data-sources/data-table/data-service-interface";
import { DataServiceParam } from "../../service-data-sources/data-table/data-service-param";


@Injectable()
export class QuestionService {
  constructor(private http: HttpClient) {

  }

  getQuestionsForInspection(equipmentId: number): Promise<any> {
    const headers = {
      'Content-Type': 'application/json',
    };

    return new Promise((resolve, reject) => {
      this.http.get<any>(environment.apiUrl + "question/getinspectionquestions?equipmentID=" + equipmentId, { headers: headers }).subscribe(data => {
        resolve(data);
      }, (error) => {
        resolve(null)
      });
    });
  }

}