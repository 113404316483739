<ng-snotify></ng-snotify>
<app-zip-downloader></app-zip-downloader>

<mat-drawer-container autosize>
  <mat-drawer #drawer mode="side" opened [ngClass]="{'mat-drawer-expanded': menuExpanded}">
    <div class="title" *ngIf="currentUser != null">
      <button (click)="toggleMenu()"><i class="fas fa-bars"></i></button>
    </div>

    <ul *ngIf="currentUser != null" (click)="menuExpanded = true">
      <li>
        <div class="divider" (click)="toggleNav('home')">
          <i *ngIf="!isNavExpanded('home')" class="fas fa-chevron-right divider-icon"></i>
          <i *ngIf="isNavExpanded('home')" class="fas fa-chevron-down divider-icon"></i>
          <span *ngIf="menuExpanded">Home</span>
          <i *ngIf="!menuExpanded" class="fas fa-home"></i>
        </div>
      </li>
      <li *ngIf="isNavExpanded('home')">
        <ul>
          <a [routerLink]="['dashboard']" routerLinkActive="active-link"><span *ngIf="menuExpanded">Home</span></a>
          <li><a [routerLink]="['help-centre']" routerLinkActive="active-link"><span *ngIf="menuExpanded">RIG Help Centre</span></a></li>
          <li><a [routerLink]="['administration/change-password']" routerLinkActive="active-link"><span *ngIf="menuExpanded">Change Password</span></a></li>
          <li><a href="https://www.ranger.com.au/ranger-catalogue/" target="_blank"><span *ngIf="menuExpanded">Product Catalogue</span></a></li>
          <li><a href="https://www.ranger.com.au/australian-standards-for-lifting-equipment/" target="_blank"><span *ngIf="menuExpanded">Australian Standards</span></a></li>
          <li><a href="https://www.ranger.com.au/inspection-checklists/" target="_blank"><span *ngIf="menuExpanded">Inspection Checklists</span></a></li>
          <li><a href="https://www.ranger.com.au/contact-us/" target="_blank"><span *ngIf="menuExpanded">Contact Us</span></a></li>
        </ul>
      </li>
      <!--<li *ngIf="currentUser.userTypeId != 1">
        <div class="divider" (click)="toggleNav('reports')">
          <i *ngIf="!isNavExpanded('reports')" class="fas fa-chevron-right divider-icon"></i>
          <i *ngIf="isNavExpanded('reports')" class="fas fa-chevron-down divider-icon"></i>
          <span *ngIf="menuExpanded">Reports</span>
          <i *ngIf="!menuExpanded" class="far fa-chart-bar"></i>
        </div>
      </li>
      <li *ngIf="isNavExpanded('reports')">
        <ul>
          <li *ngIf="currentUser.userTypeId != 1"><a [routerLink]="['reports/ranger-register']"><span *ngIf="menuExpanded">Ranger Register</span></a></li>
          <li *ngIf="currentUser.userTypeId != 1"><a [routerLink]="['reports/asset-register']"><span *ngIf="menuExpanded">Asset Register</span></a></li>
          <li *ngIf="currentUser.userTypeId != 1"><a [routerLink]="['reports/insp-report']"><span *ngIf="menuExpanded">Insp Report</span></a></li>
          <li *ngIf="currentUser.userTypeId != 1"><a [routerLink]="['reports/job-report']"><span *ngIf="menuExpanded">Job Report</span></a></li>
          <li *ngIf="currentUser.userTypeId != 1"><a [routerLink]="['reports/batch-report']"><span *ngIf="menuExpanded">Batch Search</span></a></li>
        </ul>
      </li>-->
      <li *ngIf="currentUser.userTypeId != 1">
        <div class="divider" (click)="toggleNav('management')">
          <i *ngIf="!isNavExpanded('management')" class="fas fa-chevron-right divider-icon"></i>
          <i *ngIf="isNavExpanded('management')" class="fas fa-chevron-down divider-icon"></i>
          <span *ngIf="menuExpanded">Management</span>
          <i *ngIf="!menuExpanded" class="fas fa-cog"></i>
        </div>
      </li>
      <li *ngIf="isNavExpanded('management')">
        <ul>
          <li *ngIf="currentUser.userTypeId != 1"><a [routerLink]="['administration/companies']"><span *ngIf="menuExpanded">Company Management</span></a></li>
          <li *ngIf="currentUser.userTypeId != 1"><a [routerLink]="['administration/job-management']"><span *ngIf="menuExpanded">Job Management</span></a></li>
          <li *ngIf="currentUser.userTypeId != 1"><a [routerLink]="['administration/jobs-pending']"><span *ngIf="menuExpanded">Jobs Pending Approval</span></a></li>
          <li *ngIf="currentUser.userTypeId != 1"><a [routerLink]="['administration/aus-std-management']"><span *ngIf="menuExpanded">Aus Std Management</span></a></li>
          <li *ngIf="currentUser.userTypeId != 1"><a [routerLink]="['administration/batch-manuf-management']"><span *ngIf="menuExpanded">Batch Manuf Management</span></a></li>
          <li *ngIf="currentUser.userTypeId != 1"><a [routerLink]="['administration/batch-types-management']"><span *ngIf="menuExpanded">Batch Types Management</span></a></li>
          <li *ngIf="currentUser.userTypeId != 1"><a [routerLink]="['administration/category-management']"><span *ngIf="menuExpanded">Category Management</span></a></li>
          <li *ngIf="currentUser.userTypeId != 1"><a [routerLink]="['administration/question-sets-management']"><span *ngIf="menuExpanded">Question Sets Management</span></a></li>
          <li *ngIf="currentUser.userTypeId != 1"><a [routerLink]="['administration/web-users']"><span *ngIf="menuExpanded">Web User Management</span></a></li>
          <li *ngIf="currentUser.userTypeId != 1"><a [routerLink]="['administration/pda-users']"><span *ngIf="menuExpanded">PDA User Management</span></a></li>
          <li *ngIf="currentUser.userTypeId != 1"><a [routerLink]="['administration/preferences']"><span *ngIf="menuExpanded">Preferences</span></a></li>
          <li *ngIf="currentUser.userTypeId != 1"><a [routerLink]="['administration/download-data']"><span *ngIf="menuExpanded">Download Data</span></a></li>
          <li *ngIf="currentUser.userTypeId != 1"><a [routerLink]="['administration/import-data']"><span *ngIf="menuExpanded">Import Data</span></a></li>
          <li *ngIf="currentUser.userTypeId != 1"><a [routerLink]="['administration/completed-jobs']"><span *ngIf="menuExpanded">Completed Jobs</span></a></li>
        </ul>
      </li>
    </ul>
  </mat-drawer>

  <div class="content-container">
    <app-ranger-header *ngIf="currentUser != null"></app-ranger-header>
    <app-topbar *ngIf="currentUser != null"></app-topbar>
    <router-outlet></router-outlet>
  </div>
</mat-drawer-container>
