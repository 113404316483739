import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { NotifyLocationsDialogComponent } from 'src/app/components/notify-locations-dialog/notify-locations-dialog.component';
import { ViewJobsDialogComponent } from 'src/app/components/view-jobs-dialog/view-jobs-dialog.component';
import { AuthService } from 'src/app/services/auth.service';
import { DashboardService } from 'src/app/services/dashboard/dashboard.service';

@Component({
  selector: 'app-dashboard-stats',
  templateUrl: './dashboard-stats.component.html',
  styleUrls: ['./dashboard-stats.component.scss']
})
export class DashboardStatsComponent implements OnInit {
  @Output() toggleFilter = new EventEmitter<string>();
  @Output() filterSalesOrderNum = new EventEmitter<string>();

  currentUser;

  isLoading: boolean = false;

  stats = {
    assetCount: 0,
    compliesCount: 0,
    doesNotComplyCount: 0,
    missingCount: 0,
    beenRepairedCount: 0,
    condemnedCount: 0,
    nextInspectionDate: ''
  }

  constructor(private authService: AuthService, private dashboardService: DashboardService, public dialog: MatDialog) { 
    this.authService.currentUser.subscribe(async (user) => {
      this.currentUser = user;
    });

    this.loadStats();
  }

  ngOnInit(): void {
  }

  viewComplies() {
    this.toggleFilter.emit("0");
  }

  viewDoesNotComply() {
    this.toggleFilter.emit("1");
  }

  viewCondemned() {
    this.toggleFilter.emit("4");
  }

  viewRepaired() {
    this.toggleFilter.emit("3");
  }

  viewMissing() {
    this.toggleFilter.emit("2");
  }


  refresh() {
    this.loadStats();
  }

  async loadStats() {
    this.stats = await this.dashboardService.getStats(this.currentUser.companyId);
  }
}
