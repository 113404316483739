<div class="zip-downloader" [class.show] = "jobs.length > 0">
  <div class="header">Download Manager</div>
  
  <div class="job" *ngFor="let job of jobs">
    <div *ngIf="job.status == 'IN_PROGRESS'">{{ job.name }}: {{ job.downloaded }} of {{ job.total }}</div>
    <div *ngIf="job.status == 'SAVING'">Saving to ZIP</div>
    <div class="job-progress">
      <mat-progress-bar [mode]="'determinate'" [value]="job.percentage">
      </mat-progress-bar>
      <mat-progress-spinner [mode]="'indeterminate'" [diameter]="'20'"></mat-progress-spinner>
    </div>
  </div>
</div>