export class Equipment {
  equipmentId: number = 0;
  locationId: number = 0;
  descriptionList: string = "";
  description: string = "";
  categoryId: number = 0;
  serialNum: string;
  australianStdId: number = 0;
  ownerId: number = 1;
  rfidTag: string;
  decommisioned: boolean = false;
  lastInspectionId: number;
  created: string;
  nextInspDate: string;
  manufId: number;
  dateOfManuf: string;
  assetNum: string;
  otherDetails: string;
  assemblyDate: string;
  locationInspectionId: number;
  loadTestTo: string;
  wll: string;
  hasBeenRepaired: boolean;
  newIntoService: boolean = false;
  clientAssetNumber: string;
  visualNextDate: string;
  department: string;
  companyId: number;
}
