import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { SnotifyService } from 'ng-snotify';
import { AuthService } from 'src/app/services/auth.service';
import { DashboardService } from 'src/app/services/dashboard/dashboard.service';

@Component({
  selector: 'app-help-centre',
  templateUrl: './help-centre.component.html',
  styleUrls: ['./help-centre.component.scss']
})
export class HelpCentreComponent implements OnInit {

  quarterIdx = 0;

  constructor(private router: Router, private authService: AuthService, private dashboardService: DashboardService, private snotifyService: SnotifyService) { }

  ngOnInit(): void {
    var monthIdx = moment().month();
    if (monthIdx >= 0 && monthIdx <= 2)
      this.quarterIdx = 0;
    else if (monthIdx >= 3 && monthIdx <= 5)
      this.quarterIdx = 1;
    else if (monthIdx >= 6 && monthIdx <= 8)
      this.quarterIdx = 2;
    else if (monthIdx >= 9 && monthIdx <= 11)
      this.quarterIdx = 3;
  }

  async rigPurchaseEmail(subject) {
    var user = this.authService.currentUser.value;
    this.dashboardService.sendRigPurchaseEmail(user.username, user.name, subject).subscribe(() => {
      //alert("Sent email purchase");
    });
    this.snotifyService.success("Thank you. Someone from RIG will contact you shortly.")
  }
}
