<div class="content">
  <h1>Asset List</h1>

  
  <div class="asset-list-buttons">
    <mat-checkbox [(ngModel)]="viewAllAssets" (change)="loadJobs()">View All Assets</mat-checkbox>
    <div class="spacer"></div>
    <div *ngIf="showEmail">
      <p style="padding-right:10px; color:red;" >
        No Email address assigned.
      </p>
    </div>
    
    <button class="add-btn" type="button" (click)="approveJob()">
      Approve Job
    </button>

  </div>

  <div class="spinner-container" *ngIf="(dataSource.loading$ | async)">
    <mat-spinner [diameter]="'50'"></mat-spinner>
  </div>


  <table mat-table class="data-table mat-elevation-z8" [dataSource]="dataSource" fixedLayout="true">
  
    <ng-container matColumnDef="inspectionDate">
      <th mat-header-cell *matHeaderCellDef>INSPECTION DATE</th>
      <td mat-cell *matCellDef="let element"><span class="truncate-text">{{element.pdaStartTime}}</span></td>
    </ng-container>

    <ng-container matColumnDef="serial">
      <th mat-header-cell *matHeaderCellDef>SERIAL</th>
      <td mat-cell *matCellDef="let element"><span class="truncate-text">{{element.serialNum}}</span></td>
    </ng-container>

    <ng-container matColumnDef="description">
      <th mat-header-cell *matHeaderCellDef>DESCRIPTION</th>
      <td mat-cell *matCellDef="let element"><span class="truncate-text">{{element.description}}</span></td>
    </ng-container>

    <ng-container matColumnDef="pass">
      <th mat-header-cell *matHeaderCellDef>PASS</th>
      <td mat-cell *matCellDef="let element"><span class="truncate-text">{{element.safeForUse == true ? '&#10003;' : null }}</span></td>
    </ng-container>

    <ng-container matColumnDef="fail">
      <th mat-header-cell *matHeaderCellDef>FAIL</th>
      <td mat-cell *matCellDef="let element"><span class="truncate-text">{{element.safeForUse == false && element.missing == null ? 'X' : null }}</span></td>
    </ng-container>

    <ng-container matColumnDef="missing">
      <th mat-header-cell *matHeaderCellDef>MISSING</th>
      <td mat-cell *matCellDef="let element"><span class="truncate-text">{{element.missing}}</span></td>
    </ng-container>

    <ng-container matColumnDef="repaired">
      <th mat-header-cell *matHeaderCellDef>REPAIRED</th>
      <td mat-cell *matCellDef="let element"><span class="truncate-text">{{element.repaired}}</span></td>
    </ng-container>

    <ng-container matColumnDef="condemned">
      <th mat-header-cell *matHeaderCellDef>CONDEMNED</th>
      <td mat-cell *matCellDef="let element"><span class="truncate-text">{{element.condemned}}</span></td>
    </ng-container>

    <ng-container matColumnDef="nata">
      <th mat-header-cell *matHeaderCellDef>NATA</th>
      <td mat-cell *matCellDef="let element"><span class="truncate-text">{{element.natacert}}</span></td>
    </ng-container>

    <ng-container matColumnDef="newIntoService">
      <th mat-header-cell *matHeaderCellDef>NEW INTO SERVICE</th>
      <td mat-cell *matCellDef="let element"><span class="truncate-text">{{element.newIntoService}}</span></td>
    </ng-container>

    <ng-container matColumnDef="nextInspectionDate">
      <th mat-header-cell *matHeaderCellDef>NEXT INSPECTION DATE</th>
      <td mat-cell *matCellDef="let element"><span class="truncate-text">{{element.nextInspDate}}</span></td>
    </ng-container>

    <ng-container matColumnDef="completed">
      <th mat-header-cell *matHeaderCellDef>COMPLETED</th>
      <td mat-cell *matCellDef="let element"><a href="javascript:void(0)" class="truncate-text" (click)="view(element.locationInspectionEquipId, element.equipmentId, element.inspectionId)">VIEW</a></td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>

  <mat-paginator [pageSizeOptions]="[10, 25, 50, 100]" [pageSize]='10' [length]="dataSource.total$ | async" showFirstLastButtons></mat-paginator>
</div>
