import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { tap } from 'rxjs/operators';
import { Company } from 'src/app/models/company';
import { Location } from 'src/app/models/location';
import { CompanyService } from 'src/app/services/administration/company-service';
import { JobService } from 'src/app/services/administration/job-service';
import { LocationService } from 'src/app/services/administration/location.service';
import { SnotifyService } from 'ng-snotify';
import { TableDataSource } from 'src/app/service-data-sources/data-table/table-data-source';
import { CurrentJobViewModel } from 'src/app/models/view-models/current-job-view-model';
import { DataServiceParam } from 'src/app/service-data-sources/data-table/data-service-param';
import { ColourService } from 'src/app/services/administration/colour.service';
import { Colour } from 'src/app/models/colour';
import { MatDialog } from '@angular/material/dialog';
import { JobManageComponent } from './job-manage/job-manage.component';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';

@Component({
  selector: 'app-job-management',
  templateUrl: './job-management.component.html',
  styleUrls: ['./job-management.component.scss']
})
export class JobManagementComponent implements OnInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  
  companies: Company[];
  locations: Location[];
  colours: Colour[];
  jobForm;
  isLoading: boolean = false;


  filter = {
    companyId: 0,
    locationId: 0,
    accNum: "",
    salesOrderNum: ""
  }
  
  dataSource: MatTableDataSource<CurrentJobViewModel>;
  displayedColumns: string[] = ['companyName', 'name', 'accNum', 'created', 'salesOrderNum', 'noOfAssetsWhenCreated', 'print', 'complete', 'delete', 'edit']; 

  constructor(private companyService: CompanyService, private locationService: LocationService, 
    private jobService: JobService, private snotifyService: SnotifyService, private colourService: ColourService,
    public dialog: MatDialog) { }

  ngOnInit(): void {
    //this.jobService.getTable()
    // this.dataSource = new TableDataSource(this.jobService);
    this.loadCompanies();
    this.loadJobs();
  }

  ngAfterViewInit() {
    //this.paginator.page.pipe(tap(() => this.loadJobsPage())).subscribe();  
  }

  clear() {
    this.filter.companyId = 0;
    this.filter.locationId = 0;
    this.filter.accNum = "";
    this.filter.salesOrderNum = "";

    this.locations = [];
  }

  loadCompanies() {
    this.companies = [];
    this.companyService.getAll().then((companies: Company[]) => {
      this.companies = companies;
      this.locations = [];
    });
  }

  loadLocations(companyId: number) {
    this.locationService.getAll(companyId, true, "All").then((locations: Location[]) => {
      this.locations = locations;
    });
  }

  companyChanged($ev) {
    this.loadLocations($ev);
  }

  loadJobs() {
    this.loadJobsPage();
  }

  loadJobsPage() {
    var params: DataServiceParam[] = [];
    params.push(<DataServiceParam>{ paramName: 'companyId', paramValue: this.filter.companyId });
    params.push(<DataServiceParam>{ paramName: 'locationId', paramValue: this.filter.locationId });
    params.push(<DataServiceParam>{ paramName: 'accNum', paramValue: this.filter.accNum });
    params.push(<DataServiceParam>{ paramName: 'salesOrderNum', paramValue: this.filter.salesOrderNum });

    this.jobService.getTable(params).subscribe((response: any) => {
      this.dataSource = new MatTableDataSource(response.data);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;

      this.dataSource.sortingDataAccessor = (item, property) => {
        switch (property) {
          case 'created': return new Date(item.created);
          default: return item[property];
        }
      };
    });
  }

  async completeJob(locationInspectionId) {
    if (!confirm('Are you sure you want to complete this job?')) return false;
    this.isLoading = true;
    var success = await this.jobService.completeJob(locationInspectionId);
    this.isLoading = false;
    if (success) {
      this.snotifyService.success("Successfully completed job");
      this.loadJobs();
    } else {
      this.snotifyService.error("Error completing job");
    }
  }

  async deleteJob(locationInspectionId) {
    if (!confirm('Are you sure you want to delete this job?')) return false;

    this.isLoading = true;
    var response = await this.jobService.deleteJob(locationInspectionId);
    this.isLoading = false;
    if (response.success) {
      this.snotifyService.success("Successfully deleted job");
      this.loadJobs();
    } else {
      this.snotifyService.error(response.message);
    }
  }

  add() {
    const dialogRef = this.dialog.open(JobManageComponent, {
      width: '900px',
      data: { locationInspectionId: null }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.loadJobs();
      }
    });
  }
  edit(locationInspectionId) {
    const dialogRef = this.dialog.open(JobManageComponent, {
      width: '900px',
      data: { locationInspectionId: locationInspectionId }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.loadJobs();
      }
    });
  }
}
