import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import { Component, EventEmitter, forwardRef, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatSelect } from '@angular/material/select';
import { Subject } from 'rxjs';
import { ReplaySubject } from 'rxjs/internal/ReplaySubject';
import { take, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-search-select',
  templateUrl: './search-select.component.html',
  styleUrls: ['./search-select.component.scss'],
  providers: [
    { 
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SearchSelectComponent),
      multi: true
    }
  ]
})
export class SearchSelectComponent implements ControlValueAccessor, OnInit, OnDestroy, OnChanges {
  @ViewChild(CdkVirtualScrollViewport, { static: false }) cdkVirtualScrollViewPort: CdkVirtualScrollViewport;

  @Input() label;
  @Input() list;

  @Input() value;
  @Input() name;

  @Output() onChanged = new EventEmitter<any>();
  @ViewChild('singleSelect', { static: true }) singleSelect: MatSelect;

  public filteredList = [];
  //public filteredList: ReplaySubject<[]> = new ReplaySubject<[]>(1);
  protected _onDestroy = new Subject<void>();
  
  //@ts-ignore
  public selectedId: FormControl<any> = new FormControl<any>('');
  //@ts-ignore
  public filter: FormControl<string> = new FormControl<string>('');
  
  onChange: any = () => { }
  onTouch: any = () => {}

  constructor() {
    
  }

  ngOnInit(): void {

  }

  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }


  ngAfterViewInit() {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.list.currentValue != null) {
      this.init();
    }
  }

  init() {
    this.filteredList = this.list;
    this.filter.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.filterList();
      });
  }


  selectionChanged = (value) => {
    console.log(value);
    this.onChange(value);
    this.onChanged.emit(value);
  }

  protected filterList() {
    if (!this.list) {
      return;
    }
    // get the search keyword
    let search = this.filter.value;
    if (!search) {
      this.filteredList = this.list;
      return;
    } else {
      search = search.toLowerCase();
    }
    
    var newFilteredList = this.list.filter(data => data[this.name].toLowerCase().indexOf(search) > -1);
    this.filteredList = newFilteredList;
  }


  writeValue(id: any): void {
    this.selectedId.setValue(id);    
  }
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }
  setDisabledState?(isDisabled: boolean): void {
    throw new Error('Method not implemented.');
  }
}
