<div class="basket-container">
  <div class="loading-spinner" *ngIf="isDownloadingPDF">
    <mat-progress-spinner [mode]="'indeterminate'" [diameter]="'20'"></mat-progress-spinner>
  </div>

  <div class="basket-info">
    <label>Items ({{ items.length }})</label>
    <i class="fas fa-layer-group"></i>

    <button mat-button class="add-all-btn" (click)="addAllToBasket()">
      ADD ALL
    </button>

    <button mat-button class="clear-btn" *ngIf="items.length > 0" (click)="clearBasket()">
      CLEAR
    </button>
  </div>
  <div class="lifting-options" [class.lifting-options-active]="items.length > 0">
    <input type="text" placeholder="Report Name" [(ngModel)]="reportName"/>

    <mat-button-toggle-group class="export-type-group" [(ngModel)]="exportType">
      <mat-button-toggle value="PDF">PDF</mat-button-toggle>
      <mat-button-toggle value="ZIP">ZIP</mat-button-toggle>
    </mat-button-toggle-group>

    <div class="export-buttons">
      <button mat-button (click)="exportLifting()"><label>LIFTING ({{ items.length }})</label></button>
      <button mat-button (click)="exportNata()"><label>NATA ({{ nataCount }})</label></button>
      <button mat-button (click)="exportNewIntoService()"><label>NEW ({{ newCount }})</label></button>
      <button mat-button (click)="exportRepair()"><label>REPAIR ({{ repairCount }})</label></button>
    </div>
  </div>

  
  <div class="basket-options" [class.basket-options-active]="items.length > 0">
    <button mat-button class="alt-button" (click)="moveBatch()">
      <i class="fas fa-compress-alt"></i>
      <label>MOVE</label>
    </button>
    <button mat-button class="alt-button">
      <i class="far fa-share-square"></i>
      <label>SHARE</label>
    </button>
  </div>

</div>