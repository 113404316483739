import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-ranger-header',
  templateUrl: './ranger-header.component.html',
  styleUrls: ['./ranger-header.component.scss']
})
export class RangerHeaderComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
