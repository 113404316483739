<div class="modal-panel" *ngIf="locationForm != null">
  <form [formGroup]="locationForm" (ngSubmit)="onSubmit()" class="form-manage">
    <div class="header">
      <h1>Location Management</h1>
    </div>

    <div class="form-controls">
      <div class="form-input">
        <mat-form-field appearance="fill">
          <mat-label class="form-label">Location Name</mat-label>
          <input matInput required formControlName="name">
        </mat-form-field>
      </div>

      <div class="form-input">
        <mat-form-field appearance="fill">
          <mat-label class="form-label">Acc #</mat-label>
          <input matInput required formControlName="accNum">
        </mat-form-field>
      </div>

      <div class="form-input">
        <mat-form-field appearance="fill">
          <mat-label class="form-label">Address 1</mat-label>
          <input matInput required formControlName="address1">
        </mat-form-field>
      </div>

      <div class="form-input">
        <mat-form-field appearance="fill">
          <mat-label class="form-label">Address 2</mat-label>
          <input matInput formControlName="address2">
        </mat-form-field>
      </div>

      <div class="form-input">
        <mat-form-field appearance="fill">
          <mat-label class="form-label">Address 3</mat-label>
          <input matInput formControlName="address3">
        </mat-form-field>
      </div>

      <div class="form-input">
        <mat-form-field appearance="fill">
          <mat-label class="form-label">Suburb</mat-label>
          <input matInput formControlName="address4">
        </mat-form-field>
      </div>

      <div class="form-input">
        <mat-form-field appearance="fill">
          <mat-label class="form-label">Postcode</mat-label>
          <input matInput formControlName="postcode">
        </mat-form-field>
      </div>

      <div class="form-input">
        <mat-form-field appearance="fill">
          <mat-label class="form-label">State</mat-label>
          <input matInput formControlName="county">
        </mat-form-field>
      </div>

      <div class="form-input">
        <mat-form-field appearance="fill">
          <mat-label class="form-label">Telephone #</mat-label>
          <input matInput formControlName="telephone">
        </mat-form-field>
      </div>

      <div class="form-input">
        <mat-form-field appearance="fill">
          <mat-label class="form-label">Fax</mat-label>
          <input matInput formControlName="fax">
        </mat-form-field>
      </div>

      <div class="form-input">
        <mat-form-field appearance="fill">
          <mat-label class="form-label">Email 1</mat-label>
          <input matInput formControlName="email1">
        </mat-form-field>
      </div>

      <div class="form-input">
        <mat-form-field appearance="fill">
          <mat-label class="form-label">Email 2</mat-label>
          <input matInput formControlName="email2">
        </mat-form-field>
      </div>

      <div class="form-input">
        <mat-form-field appearance="fill">
          <mat-label class="form-label">Monthly Interval</mat-label>
          <mat-select formControlName="monthlyInterval" required>
            <mat-option [value]="0">N/A</mat-option>
            <mat-option [value]="number" *ngFor="let number of [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36]">{{ number }}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div class="form-input">
        <mat-form-field appearance="fill">
          <mat-label class="form-label">Next Inspection Period</mat-label>
          <mat-select formControlName="nextInspectionPeriod" required>
            <mat-option [value]="0">Australian Standard</mat-option>
            <mat-option [value]="1">January</mat-option>
            <mat-option [value]="2">February</mat-option>
            <mat-option [value]="3">March</mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div class="form-input">
        <mat-form-field appearance="fill" *ngIf="locationForm.controls.nextInspectionPeriod.value != 0">
          <mat-label class="form-label">Next Inspection Period Interval</mat-label>
          <mat-select formControlName="nextInspectionPeriodInterval">
            <mat-option [value]="1">1 Month</mat-option>
            <mat-option [value]="3">3 Months</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      
      <div class="form-input">
        <mat-checkbox formControlName="expired">Expired</mat-checkbox>
      </div>
    </div>

    <div class="footer">
      <button type="button" class="cancel-btn" (click)="cancel()">CANCEL</button>
      <button type="submit" class="add-btn" [disabled]="!locationForm.valid">SAVE</button>
    </div>
  </form>
</div>