import { SelectionModel } from '@angular/cdk/collections';
import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute } from '@angular/router';
import * as moment from 'moment';
import * as dayjs from 'dayjs'
import { SnotifyService } from 'ng-snotify';
import { InspectionDownloadComponent } from 'src/app/components/inspection-download/inspection-download.component';
import { MoveEquipComponent } from 'src/app/components/move-equip/move-equip.component';
import { NotifyDialogComponent } from 'src/app/components/notify-dialog/notify-dialog.component';
import { NotifyLocationsDialogComponent } from 'src/app/components/notify-locations-dialog/notify-locations-dialog.component';
import { ViewJobsDialogComponent } from 'src/app/components/view-jobs-dialog/view-jobs-dialog.component';
import { CategoryService } from 'src/app/services/administration/category.service';
import { JobService } from 'src/app/services/administration/job-service';
import { LocationService } from 'src/app/services/administration/location.service';
import { AuthService } from 'src/app/services/auth.service';
import { DashboardService } from 'src/app/services/dashboard/dashboard.service';
import { CompanyService } from 'src/app/services/administration/company-service';
import { saveAs as importedSaveAs } from 'file-saver';
import { DomSanitizer } from '@angular/platform-browser';
import { InspectionService } from 'src/app/services/inspection.service';
import { EquipmentService } from 'src/app/services/administration/equipment.service';
import { ImagePopUpComponent } from 'src/app/components/image-popup/image-popup.component';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit, AfterViewInit {
  minDate = dayjs("2012-01-01", "YYYY-MM-DD");
  //minDate = dayjs("2022-11-01", "YYYY-MM-DD");
  currentUser;

  stats = {
    assetCount: 0,
    compliesCount: 0,
    doesNotComplyCount: 0,
    outOfServiceCount: 0,
    nextInspectionDate: ''
  }

  filter = {
    statusIds: ["0", "1", "2"],
    categoryIds: [],
    locationIds: [],
    dates: {start: this.minDate, end: dayjs()},
    jobNo: "",
    serialNum: "",
  }

  

  statusList = [
    { id: 0, name: "Complies", selected: true },
    { id: 1, name: "Does Not Comply", selected: true },
    { id: 2, name: "Out Of Service", selected: true }
  ]

  months = [
    {text: "January", value: 1, selected: false}, 
    {text: "February", value: 2, selected: false}, 
    {text: "March", value: 3, selected: false}, 
    {text: "April", value: 4, selected: false}, 
    {text: "May", value: 5,selected: false}, 
    {text: "June", value: 6, selected: false}, 
    {text: "July", value: 7, selected: false}, 
    {text: "August", value: 8, selected: false}, 
    {text: "September", value: 9, selected: false}, 
    {text: "October", value: 10, selected: false}, 
    {text: "November", value: 11, selected: false}, 
    {text: "December", value: 12, selected: false}, 
  ]
  startYear = moment().add(0, 'years').startOf('year');
  years = [
    {text: moment().startOf('year').format("YYYY"), value: moment().startOf('year').year(), selected: false}, 
    {text: moment().add(1, 'years').startOf('year').format("YYYY"), value: moment().add(1, 'years').startOf('year').year(), selected: false}, 
    {text: moment().add(2, 'years').startOf('year').format("YYYY"), value: moment().add(1, 'years').startOf('year').year(), selected: false}, 
    {text: moment().add(3, 'years').startOf('year').format("YYYY"), value: moment().add(2, 'years').startOf('year').year(), selected: false}
  ]
  filterByNextInspectionDate: boolean = false;

  selectedCompanyId = null;

  companies = [];
  categories = [];
  locations = [];

  dataSource: MatTableDataSource<any>;
  selection = new SelectionModel<any>(true, []);
  displayedColumns: string[] = ['option', 'image', 'serialNum', 'inspectionDate', 'nextInspDate', 'description', 'wll', 'currentLocation', 'inService', 'view'];
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  showsNextInspectionPeriod: boolean = false;
  isLoading: boolean = false;

  colour: string = "";
  isUniqueColour: boolean = null;

  constructor(private categoryService: CategoryService, private locationService: LocationService, private authService: AuthService,
    private dashboardService: DashboardService, public dialog: MatDialog, private snotifyService: SnotifyService,
    private jobService: JobService, private companyService: CompanyService,private equipmentService: EquipmentService) { }

  ngOnInit() {
    this.authService.currentUser.subscribe(async (user) => {
      console.log(user);
      this.currentUser = user;
      if (this.currentUser.userTypeId != 1) {
        this.companyService.getAll(-1, false, "").then((companies) => {
          this.companies = companies;

          // Check if we have a prechecked key
          var key = localStorage.getItem("@companyKey");
          if (key != null) {
            this.selectedCompanyId = parseInt(key);
            this.selectCompany();
          }
        });
      } 

      this.categoryService.getAll().then((categories) => {
        this.categories = categories;
      });

      
    });
  }

  loadDropdownData(companyId) {
    this.locationService.getAll(companyId).then((locations) => {
      this.locations = locations;
    });
  }

  ngAfterViewInit() {
    if (this.currentUser.userTypeId == 1) {
      this.selectedCompanyId = this.currentUser.companyId;
      this.loadDropdownData(this.selectedCompanyId);
      this.loadStats();
      this.loadAssetStatusTable();
    } 
  }

  selectCompany() {
    this.resetFilters();
    if (this.selectedCompanyId != null) {
      this.loadDropdownData(this.selectedCompanyId);
      this.loadStats();
      this.loadAssetStatusTable();

      localStorage.setItem("@companyKey", this.selectedCompanyId);
    }
  }

  reset() {
    this.resetFilters();

    this.loadAssetStatusTable();
  }

  loadNextInspectionsTable() {
    if (this.months.filter(m => m.selected).length == 0) {
      alert("Select at least 1 month");
      return;
    }

    if (this.years.filter(m => m.selected).length == 0) {
      alert("Select at least 1 year");
      return;
    }

    this.filterByNextInspectionDate = true;
    this.dataSource = new MatTableDataSource([]);
    this.dashboardService.getAssetStatusRegisterByNextInspectionDate(this.selectedCompanyId, this.months.filter(m => m.selected).map(m => m.value), this.years.filter(m => m.selected).map(m => m.value)).subscribe((data: any) => {
      this.dataSource = new MatTableDataSource(data.data);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
      this.selection = new SelectionModel<any>(true, []);
    });
  }

  clearNextInspectionsTable() {
    this.filterByNextInspectionDate = false;

    this.loadAssetStatusTable();
  }


  loadTable() {
    this.dashboardService.saveSearchFilters(this.filter);
    this.loadAssetStatusTable();
  }

  loadAssetStatusTable() {
    if ((this.selectedCompanyId == null || this.selectedCompanyId == '') && (this.filter.serialNum == null || this.filter.serialNum == '') && (this.filter.jobNo == null || this.filter.jobNo == '')) {
      this.snotifyService.error("Please select a company");
      return;
    }

    this.isLoading = true;
    this.dataSource = new MatTableDataSource([]);
    this.dashboardService.getAssetStatusRegister(this.selectedCompanyId, this.filter.statusIds, this.filter.jobNo, this.filter.serialNum, this.filter.dates.start.format("YYYY-MM-DD"), this.filter.dates.end.format("YYYY-MM-DD"), this.filter.categoryIds, this.filter.locationIds).subscribe((data: any) => {
      this.dataSource = new MatTableDataSource(data.data);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
      this.selection = new SelectionModel<any>(true, []);

      this.showsNextInspectionPeriod = data.data.length > 0 && data.data[0].nextInspectionPeriod != "" && data.data[0].nextInspectionPeriod != null;

      // Determine the colour
      this.colour = data.colour;
      this.isUniqueColour = data.isUniqueColour;
  
      this.isLoading = false;
    });
  }

  showImage(row){
    // this.imageSource = this.sanitizer.bypassSecurityTrustResourceUrl(`data:image/png;base64, ${image}`);
    console.log(row);//row.equipmentId
    this.equipmentService.getInspectionImages(parseInt(row.equipmentId)).then((images) => {
      console.log(images);
      const dialogRef = this.dialog.open(ImagePopUpComponent, {
        data: {
          title: row.serialNum,
          base64Array: images
        }
      });

      dialogRef.afterClosed().subscribe(result => {

      });
    })


  }

  setStatusList() {
    this.statusList[0].selected = this.filter.statusIds.filter(s => s == "0").length > 0;
    this.statusList[1].selected = this.filter.statusIds.filter(s => s == "1").length > 0;
    this.statusList[2].selected = this.filter.statusIds.filter(s => s == "2").length > 0;
  }

  resetFilters() {
    this.filter = {
      statusIds: ["0", "1", "2"],
      categoryIds: [],
      locationIds: [],
      dates: {start: this.minDate, end: dayjs()},
      jobNo: "",
      serialNum: "",
    };
    this.setStatusList();
    for (let location of this.locations) { location.selected = false; }
    for (let category of this.categories) { category.selected = false; }
  }

  resetAssetStatusTable() {
    this.resetFilters();

    this.loadAssetStatusTable();
  }

  filterSalesOrderNum($event) {
    this.resetFilters();
    this.filter.jobNo = $event;

    this.loadAssetStatusTable();
  }

  toggleFilter($event) {
    for (let status of this.statusList) {
      status.selected = false;
      if (status.id == $event)
        status.selected = true;
    }

    this.filter.statusIds = [$event.toString()];
    this.setStatusList();
    this.loadAssetStatusTable();
  }

  export() {
    this.dashboardService.exportAssetRegister(this.selectedCompanyId, this.filter.statusIds, this.filter.jobNo, this.filter.serialNum, this.filter.dates.start.format("YYYY-MM-DD"), this.filter.dates.end.format("YYYY-MM-DD"), this.filter.categoryIds, this.filter.locationIds).then((data) => {
      importedSaveAs(data, 'Register.xls');
    });
  }

  download() {
    const dialogRef = this.dialog.open(InspectionDownloadComponent, {
      data: { 
        allInspections: this.dataSource.data,
        inspections: this.selection.selected,
        share: false
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      
    });
    
    //console.log(this.dataSource.data.filter(d => d.selected));
  }

  share() {
    const dialogRef = this.dialog.open(InspectionDownloadComponent, {
      data: { 
        inspections: this.selection.selected,
        companyId: this.selectedCompanyId,
        filters: this.filter,
        share: true
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      
    });
  }

  move() {
    var user = this.authService.currentUser.value;

    var ids = this.selection.selected.filter(i => i.inService == 0).map(a => a.equipmentId);
    const dialogRef = this.dialog.open(MoveEquipComponent, {
      data: { batchIds: ids, companyId: this.selectedCompanyId }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result.success) {
        this.loadAssetStatusTable();
        this.snotifyService.success("Successfully moved equipment");
      }
    });
  }

  notify() {
    var user = this.authService.currentUser.value;

    var inspections = this.selection.selected;
    const dialogRef = this.dialog.open(NotifyDialogComponent, {
      data: { inspections: inspections, companyId: user.companyId }
    });

    dialogRef.afterClosed().subscribe(result => {
      
    });
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  toggleAllRows() {
    if (this.isAllSelected()) {
      this.selection.clear();
      return;
    }

    this.selection.select(...this.dataSource.data);
  }

  /* --------------------------- */
  /* - STATS 
  /* --------------------------- */
  viewComplies() {
    this.resetFilters();
    this.toggleFilter("0");
  }

  viewDoesNotComply() {
    this.resetFilters();
    this.toggleFilter("1");
  }

  viewOutOfService() {
    this.resetFilters();
    this.toggleFilter("2");
  }

  refresh() {
    this.loadStats();
  }

  async loadStats() {
    this.stats = await this.dashboardService.getStats(this.selectedCompanyId);
  }

  notifyLocations() {
    if (this.selectedCompanyId == null)
      return;

    var user = this.authService.currentUser.value;

    const dialogRef = this.dialog.open(NotifyLocationsDialogComponent, {
      data: { companyId: this.selectedCompanyId, requestedBy: user.firstname + ' ' + user.lastname }
    });

    dialogRef.afterClosed().subscribe(result => {
      
    });
  }

  viewJobs() {
    if (this.selectedCompanyId == null)
      return;
      
    const dialogRef = this.dialog.open(ViewJobsDialogComponent, {
      data: { companyId: this.selectedCompanyId }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result != null && result.salesOrderNum != null) {
        this.filterSalesOrderNum(result.salesOrderNum);
      }
    });
  }
  /* --------------------------- */
  /* - SHORCUTS
  /* --------------------------- */
  loadLastSearched() {
    this.resetFilters();

    var filter = this.dashboardService.getSearchFilters();
    if (filter == null)
      return;

    filter.dates = { start: dayjs(filter.dates.start), end: dayjs(filter.dates.end) };

    this.filter = filter;

    this.loadAssetStatusTable();
  }

  mostRecentInspection() {
    this.resetFilters();
    // Need to get the latest job number
    this.jobService.getLatestJob(this.selectedCompanyId).subscribe((job) => {
      if (job != null) {
        this.filter.jobNo = job.jobNumber;
        this.loadAssetStatusTable();
      }
    });
  }

  itemsDueThisMonth() {
    this.resetFilters();

    this.filter.dates = {start: dayjs().startOf('months'), end: dayjs().endOf('month')},

    this.filter.statusIds = ["5"];

    this.loadAssetStatusTable();
  }
}
