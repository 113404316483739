import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AusStd } from 'src/app/models/aus-std';
import { BatchType } from 'src/app/models/batch-type';
import { BatchTypeService } from 'src/app/services/administration/batch-type-service';
import { SnotifyService } from 'ng-snotify';

@Component({
  selector: 'app-batch-type-manage',
  templateUrl: './batch-type-manage.component.html',
  styleUrls: ['./batch-type-manage.component.scss']
})
export class BatchTypeManageComponent implements OnInit {

  dataForm;


  constructor(private batchService: BatchTypeService, private snotifyService: SnotifyService,
    public dialogRef: MatDialogRef<BatchTypeManageComponent>, @Inject(MAT_DIALOG_DATA) public data: any) { }

  async ngOnInit() {
    var batchType = new BatchType();
    
    if (this.data != null && this.data.batchTypeId != null) {
      batchType = await this.batchService.get(this.data.batchTypeId);
    }

    this.dataForm = new FormGroup({
      batchTypeId: new FormControl(batchType.batchTypeId),
      type: new FormControl(batchType.type, [Validators.required]),
    })
  }

  cancel() {
    this.dialogRef.close(false);
  }

  async onSubmit() {
    var success = await this.batchService.save(this.dataForm.value);
    if (!success) {
      this.snotifyService.error("Error saving batch type");
      return;
    }

    this.dialogRef.close(true);
    this.snotifyService.success("Successfully saved Batch Type");
  }


}
