<div class="login__container">
  <div class="topbar">
    <img src="/assets/ranger-logo-white.png" />
    <div class="spacer"></div>
    <!-- <img src="/assets/etestinspect-logo.png" /> -->
  </div>

  <form class="login__form" [formGroup]="loginForm" (ngSubmit)="submit()" *ngIf="currentState == 0">
    <div style="padding: 20px;">
      <app-loading *ngIf="isLoading"></app-loading>

      <div class="login-form-item">
        <i class="far fa-user"></i>
        <input type="text" placeholder="Email" formControlName="username" />
      </div>
      <p class="error-validation"
        *ngIf="loginForm.controls['username'].invalid && (loginForm.controls['username'].dirty || loginForm.controls['username'].touched)">
        Enter email
      </p>

      <div class="login-form-item">
        <i class="fas fa-lock"></i>
        <input type="password" placeholder="Password" formControlName="password" />
      </div>
      <p class="error-validation"
        *ngIf="loginForm.controls['password'].invalid && (loginForm.controls['password'].dirty || loginForm.controls['password'].touched)">
        Enter password
      </p>

      <label><input type="checkbox" formControlName="rememberMe" />Remember Me</label>

      <a class="forgot-password" href="javascript:void(0)" (click)="currentState = 1">Forgot your password?</a>
      <input type="submit" value="Login" [disabled]="!loginForm.valid" />

      <label *ngIf="invalid">Invalid username/password. Please try again</label>
    </div>

    <div class="register__block">
      <h1>Don't have an account?</h1>
      <a href="#">Contact Us</a>
    </div>

    <!-- <img src="../../../assets/ranger-logo.png" /> -->
  </form>

  <form class="login__form" *ngIf="currentState == 1" (ngSubmit)="reset()" [formGroup]="loginForm">
    <div style="padding: 20px;">
      <app-loading *ngIf="isLoading"></app-loading>

      <div class="login-form-item">
        <i class="far fa-user"></i>
        <input type="text" placeholder="Enter email" formControlName="username" />
      </div>

      <input type="submit" value="Reset Password" />
    </div>
  </form>

  <form class="login__form" [formGroup]="resetForm" (ngSubmit)="resetPassword()" *ngIf="currentState == 2">
    <div style="padding: 20px;">
      <app-loading *ngIf="isLoading"></app-loading>

      <div class="login-form-item">
        <i class="fas fa-lock"></i>
        <input type="password" placeholder="Password" formControlName="password" />
      </div>
      <p class="error-validation"
        *ngIf="resetForm.controls['password'].invalid && (resetForm.controls['password'].dirty || resetForm.controls['password'].touched)">
        Enter password
      </p>

      <div class="login-form-item">
        <i class="fas fa-lock"></i>
        <input type="password" placeholder="Confirm Password" formControlName="confirmPassword" />
      </div>
      <p class="error-validation"
        *ngIf="resetForm.controls['confirmPassword'].invalid && (resetForm.controls['confirmPassword'].dirty || resetForm.controls['confirmPassword'].touched)">
        Enter password
      </p>

      <input type="submit" value="Reset" [disabled]="!resetForm.valid" />

      <input type="button" class="cancel-btn" value="Cancel" (click)="currentState = 0"/>
    </div>
  </form>
</div>