import { Injectable } from "@angular/core";
import { environment } from '../../../environments/environment';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { CurrentJobViewModel } from "src/app/models/view-models/current-job-view-model";
import { Observable } from "rxjs";
import { ApiResponse } from "src/app/models/api-response";
import { DataService, MAX_INT_32_BIT } from "../../service-data-sources/data-table/data-service-interface";
import { DataServiceParam } from "../../service-data-sources/data-table/data-service-param";
import * as moment from "moment";
import { CurrentJobUserViewModel } from "../../models/view-models/current-job-user-view-model";


@Injectable()
export class JobService implements DataService {
  constructor(private http: HttpClient) {

  }

  get(locationInspectionId: number): Promise<CurrentJobViewModel> {
    const headers = {
      'Content-Type': 'application/json',
    };

    return new Promise((resolve, reject) => {
      this.http.get<any>(environment.apiUrl + "job/get?locationInspectionId=" + locationInspectionId, { headers: headers }).subscribe(data => {
        resolve(data);
      }, (error) => {
        resolve(null)
      });
    });
  }
  
  getUsers(locationInspectionId: number): Promise<CurrentJobUserViewModel[]> {
    const headers = {
      'Content-Type': 'application/json',
    };

    return new Promise((resolve, reject) => {
      this.http.get<any>(environment.apiUrl + "job/getUsers?locationInspectionId=" + locationInspectionId, { headers: headers }).subscribe(data => {
        resolve(data);
      }, (error) => {
        resolve(null)
      });
    });
  }

  getTable(params: DataServiceParam[], sortDirection: string = 'asc', pageIndex: number = 0, pageSize: number = MAX_INT_32_BIT) {
    const headers = {
      'Content-Type': 'application/json',
    };

    var httpParams = new HttpParams()
      .set('sortOrder', sortDirection)
      .set('pageIndex', pageIndex.toString())
      .set('pageSize', pageSize.toString());

    for (let param of params) {
      httpParams = httpParams.append(param.paramName, param.paramValue);
    }

    return this.http.get<CurrentJobViewModel[]>(environment.apiUrl + "job/getcurrentjobs", {
      headers: headers,
      params: httpParams,
    });
  }

  getPendingJobs(params: DataServiceParam[], sortDirection: string, pageIndex: number, pageSize: number) {
    const headers = {
      'Content-Type': 'application/json',
    };

    var httpParams = new HttpParams()
      .set('sortOrder', sortDirection)
      .set('pageIndex', pageIndex.toString())
      .set('pageSize', pageSize.toString());

    for (let param of params) {
      httpParams = httpParams.append(param.paramName, param.paramValue);
    }

    return this.http.get<CurrentJobViewModel[]>(environment.apiUrl + "job/getpendingjobs", {
      headers: headers,
      params: httpParams,
    });
  }

  getCompletedJobs(companyId) {
    const headers = {
      'Content-Type': 'application/json',
    };

    var httpParams = new HttpParams()
      .set('companyId', companyId)  

    return this.http.get<CurrentJobViewModel[]>(environment.apiUrl + "job/getcompletedjobs", {
      headers: headers,
      params: httpParams,
    });
  }

  getPendingJob(params: DataServiceParam[], sortDirection: string, pageIndex: number, pageSize: number) {
    const headers = {
      'Content-Type': 'application/json',
    };

    var httpParams = new HttpParams()
      .set('sortOrder', sortDirection)
      .set('pageIndex', pageIndex.toString())
      .set('pageSize', pageSize.toString());

    for (let param of params) {
      httpParams = httpParams.append(param.paramName, param.paramValue);
    }

    return this.http.get<[]>(environment.apiUrl + "job/getpendingjob", {
      headers: headers,
      params: httpParams,
    });
  }

  approveJob(locationInspectionId: number): Promise<ApiResponse> {
    const headers = {
      'Content-Type': 'application/json',
    };

    return new Promise((resolve, reject) => {
      this.http.post<any>(environment.apiUrl + "job/approveJob?locationInspectionId=" + locationInspectionId, { headers: headers }).subscribe(data => {
        resolve(data);
      }, (error) => {
        resolve(null)
      });
    });
  }

  getCurrentJobAssets(params: DataServiceParam[], sortOrder: string = 'asc', pageIndex: number = 0, pageSize: number = 10, sortColumn: string = 'inspectedAt'): Observable<CurrentJobViewModel[]> {
    const headers = {
      'Content-Type': 'application/json',
    };

    var httpParams = new HttpParams()
      .set('sortOrder', sortOrder)
      .set('sortColumn', sortColumn)
      .set('pageIndex', pageIndex.toString())
      .set('pageSize', pageSize.toString());

    for (let param of params) {
      httpParams = httpParams.append(param.paramName, param.paramValue);
    }

    return this.http.get<CurrentJobViewModel[]>(environment.apiUrl + "job/getcurrentjobassets", {
      headers: headers,
      params: httpParams
    });
  }

  completeJob(locationInspectionId: number): Promise<boolean> {
    const headers = {
      'Content-Type': 'application/json',
    };

    return new Promise((resolve, reject) => {
      this.http.post<any>(environment.apiUrl + "job/completejob?locationInspectionId=" + locationInspectionId, { headers: headers }).subscribe(data => {
        resolve(true);
      }, (error) => {
        resolve(false)
      });
    });
  }

  unCompleteJob(locationInspectionId: number): Promise<boolean> {
    const headers = {
      'Content-Type': 'application/json',
    };

    return new Promise((resolve, reject) => {
      this.http.post<any>(environment.apiUrl + "job/uncompletejob?locationInspectionId=" + locationInspectionId, { headers: headers }).subscribe(data => {
        resolve(true);
      }, (error) => {
        resolve(false)
      });
    });
  }

  deleteJob(locationInspectionId: number): Promise<ApiResponse> {
    const headers = {
      'Content-Type': 'application/json',
    };

    return new Promise((resolve, reject) => {
      this.http.post<any>(environment.apiUrl + "job/deletejob?locationInspectionId=" + locationInspectionId, { headers: headers }).subscribe(data => {
        resolve(data);
      }, (error) => {
        resolve(null)
      });
    });
  }

  updateJob(locationInspectionId: number,companyId: number, locationId: number, salesOrderNum: string, colourId: number, engineerIds: any): Promise<ApiResponse> {
    const headers = {
      'Content-Type': 'application/json',
    };

    var dto = {
      locationInspectionId: locationInspectionId,
      companyId: companyId,
      locationId: locationId,
      salesOrderNum: salesOrderNum,
      colourId: colourId,
      engineerIds: engineerIds
    }

    return new Promise((resolve, reject) => {
      this.http.post<any>(environment.apiUrl + "job/updatejob", dto, { headers: headers }).subscribe(data => {
        resolve(data);
      }, (error) => {
        resolve(null)
      });
    });
  }
  
  createJob(companyId: number, locationId: number, salesOrderNum: string, colourId: number, engineerIds: any): Promise<ApiResponse> {
    const headers = {
      'Content-Type': 'application/json',
    };

    var dto = {
      companyId: companyId,
      locationId: locationId,
      salesOrderNum: salesOrderNum,
      colourId: colourId,
      engineerIds: engineerIds
    }

    return new Promise((resolve, reject) => {
      this.http.post<any>(environment.apiUrl + "job/createjob", dto, { headers: headers }).subscribe(data => {
        resolve(data);
      }, (error) => {
        resolve(null)
      });
    });
  }

  passEquipmentBatch(ids, inspDate, pdaUserId): Promise<ApiResponse> {
    const headers = {
      'Content-Type': 'application/json',
    };

    var dto = {
      locationInspectionEquipIds: ids,
      inspectionDate: moment(inspDate).format("YYYY-MM-DD"),
      pdaUserId: pdaUserId
    }

    return new Promise((resolve, reject) => {
      this.http.post<ApiResponse>(environment.apiUrl + "job/passequipmentbatch", dto, { headers: headers }).subscribe(data => {
        resolve(data);
      }, (error) => {
        resolve(null)
      });
    });
  }

  missingEquipmentBatch(ids, inspDate, pdaUserId): Promise<ApiResponse> {
    const headers = {
      'Content-Type': 'application/json',
    };

    var dto = {
      locationInspectionEquipIds: ids,
      inspectionDate: moment(inspDate).format("YYYY-MM-DD"),
      pdaUserId: pdaUserId
    }

    return new Promise((resolve, reject) => {
      this.http.post<ApiResponse>(environment.apiUrl + "job/missingequipmentbatch", dto, { headers: headers }).subscribe(data => {
        resolve(data);
      }, (error) => {
        resolve(null)
      });
    });
  }

  decomEquipmentBatch(ids, inspDate, pdaUserId): Promise<ApiResponse> {
    const headers = {
      'Content-Type': 'application/json',
    };

    var dto = {
      locationInspectionEquipIds: ids,
      inspectionDate: moment(inspDate).format("YYYY-MM-DD"),
      pdaUserId: pdaUserId
    }

    return new Promise((resolve, reject) => {
      this.http.post<ApiResponse>(environment.apiUrl + "job/decomequipmentbatch", dto, { headers: headers }).subscribe(data => {
        resolve(data);
      }, (error) => {
        resolve(null)
      });
    });
  }

  public inspect(equipmentId: number, locationInspectionEquipId: number, pdaUserId: number, inspDate: string, safeForUse: boolean,
    decomissioned: boolean, excepCirc: boolean, createCert: boolean, isRepaired: boolean, repairNotes: string, questions, inspectionId: number = 0): Promise<ApiResponse> {
    const headers = {
      'Content-Type': 'application/json',
    };

    var dto = {
      equipmentId: equipmentId,
      locationInspectionEquipId: locationInspectionEquipId,
      pdaUserId: pdaUserId,
      inspDate: moment(inspDate).format("YYYY-MM-DD"),
      safeForUse: safeForUse,
      decomissioned: decomissioned,
      excepCirc: excepCirc,
      createCert: createCert,
      isRepaired: isRepaired,
      repairNotes: repairNotes,
      questions: questions,
      inspectionId: inspectionId
    }
    console.log(dto);
    return new Promise((resolve, reject) => {
      this.http.post<ApiResponse>(environment.apiUrl + "job/inspect", dto, { headers: headers }).subscribe(data => {
        resolve(data);
      }, (error) => {
        console.log(error);
        resolve(null)
      });
    });
  }

  public missing(equipmentId: number, locationInspectionEquipId: number, pdaUserId: number, inspDate: string, safeForUse: boolean,
    decomissioned: boolean, excepCirc: boolean, createCert: boolean, isRepaired: boolean, repairNotes: string, questions, 
    inspectionId: number = 0): Promise<ApiResponse> {
    const headers = {
      'Content-Type': 'application/json',
    };

    var dto = {
      equipmentId: equipmentId,
      locationInspectionEquipId: locationInspectionEquipId,
      pdaUserId: pdaUserId,
      inspDate: moment(inspDate).format("YYYY-MM-DD"),
      safeForUse: safeForUse,
      decomissioned: decomissioned,
      excepCirc: excepCirc,
      createCert: createCert,
      isRepaired: isRepaired,
      repairNotes: repairNotes,
      questions: questions,
      inspectionId: inspectionId
    }

    return new Promise((resolve, reject) => {
      this.http.post<ApiResponse>(environment.apiUrl + "job/missing", dto, { headers: headers }).subscribe(data => {
        resolve(data);
      }, (error) => {
        resolve(null)
      });
    });
  }

  getLatestJob(companyId: number) {
    const headers = {
      'Content-Type': 'application/json',
    };

    return this.http.get<CurrentJobViewModel>(environment.apiUrl + "job/getlatestjob?companyId=" + companyId, {
      headers: headers,
    });
  }

  updateColour(locationInspectionId: number, colourId: number) : Promise<boolean> {
    const headers = {
      'Content-Type': 'application/json',
    };

    var dto = {
      locationInspectionId: locationInspectionId,
      colourId: colourId
    }

    return new Promise((resolve, reject) => {
      this.http.put<any>(environment.apiUrl + "job/updatecolour", dto, { headers: headers }).subscribe(data => {
        resolve(true);
      }, (error) => {
        resolve(false)
      });
    });
  }


  

  getAllEmails(locationInspectionId: number): Promise<any> {
    const headers = {
      'Content-Type': 'application/json',
    };
    console.log("in service");
    return new Promise((resolve, reject) => {
      this.http.get<any>(environment.apiUrl + "job/getemails?locationInspectionId=" + locationInspectionId, { headers: headers }).subscribe(data => {
        console.log("service");
        console.log(data);
        resolve(data);
      }, error => {
        console.log(error);
        resolve(null)
      });
    });

    
  }




}
