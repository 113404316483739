<div class="dialog-content">
  <h1>Inspection</h1>
  <div>
    <table class="equipment-details-table" *ngIf="equipment != null">
      <tr>
        <td>
          <div class="table-field">
            <div class="field-header">Description</div>
            <div class="field-value">&nbsp;{{equipment.description}}&nbsp;</div>
          </div>
        </td>
        <td>
          <div class="table-field">
            <div class="field-header">Date Of Manuf</div>
            <div class="field-value">&nbsp;{{equipment.dateOfManuf}}&nbsp;</div>
          </div>
        </td>
        <td>
          <div class="table-field">
            <div class="field-header">Category</div>
            <div class="field-value">&nbsp;{{equipment.categoryName}}&nbsp;</div>
          </div>
        </td>
      </tr>
      <tr>
        <td>
          <div class="table-field">
            <div class="field-header">Owner</div>
            <div class="field-value">&nbsp;{{equipment.owner}}&nbsp;</div>
          </div>
        </td>
        <td>
          <div class="table-field">
            <div class="field-header">Serial</div>
            <div class="field-value">&nbsp;{{equipment.serialNum}}&nbsp;</div>
          </div>
        </td>
        <td>
          <div class="table-field">
            <div class="field-header">Other Details</div>
            <div class="field-value">&nbsp;{{equipment.otherDetails}}&nbsp;</div>
          </div>
        </td>
      </tr>
      <tr>
        <td>
          <div class="table-field">
            <div class="field-header">Asset</div>
            <div class="field-value">&nbsp;{{equipment.assetNum}}&nbsp;</div>
          </div>
        </td>
        <td>
          <div class="table-field">
            <div class="field-header">Manufacturer</div>
            <div class="field-value">&nbsp;{{equipment.manuf}}&nbsp;</div>
          </div>
        </td>
        <td>
          <div class="table-field">
            <div class="field-header">Decommissioned</div>
            <div class="field-value"><mat-checkbox [checked]="equipment.decommisioned"></mat-checkbox></div>
          </div>
        </td>
      </tr>
    </table>
  </div>

  <div style="margin-top: 10px;">
    <mat-form-field appearance="fill">
      <mat-label>Inspection Date</mat-label>
      <input matInput [(ngModel)]="inspectionDate" [matDatepicker]="picker">
      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-datepicker #picker></mat-datepicker>
    </mat-form-field>

    <div class="hozitonal-buttons" *ngIf="!viewOnly">
      <button class="add-btn" type="button" (click)="setAsMissing()">Equipment Missing</button>
      <div class="spacer"></div>
      <button class="add-btn" type="button" (click)="passAll()">Pass All</button>
    </div>

    <table class="questions-table">
      <thead>
        <tr>
          <th>Question</th>
          <th>Status</th>
          <th>Comments</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let question of questions">
          <td>{{ question.question }}</td>
          <td>
            <mat-radio-group class="question-group" [(ngModel)]="question.status">
              <mat-radio-button [value]="true">Pass</mat-radio-button>
              <mat-radio-button [value]="false">Fail</mat-radio-button>
            </mat-radio-group>
          </td>
          <td>
            <input type="text" width="200" [(ngModel)]="question.comments"/>
          </td>
        </tr>
        <tr>
          <td>Is this examination carried out after the occurrence of exceptional circumstance / follow up?</td>
          <td>
            <mat-radio-group class="question-group" [(ngModel)]="excepCirc">
              <mat-radio-button [value]="true">Yes</mat-radio-button>
              <mat-radio-button [value]="false">No</mat-radio-button>
            </mat-radio-group>
          </td>
          <td></td>
        </tr>
        <tr>
          <td>Is the equipment safe for use?</td>
          <td>
            <mat-radio-group class="question-group" [(ngModel)]="safeForUse">
              <mat-radio-button [value]="true">Yes</mat-radio-button>
              <mat-radio-button [value]="false">No</mat-radio-button>
            </mat-radio-group>
          </td>
          <td></td>
        </tr>
        <tr>
          <td>Create NATA Cert?</td>
          <td>
            <mat-radio-group class="question-group" [(ngModel)]="nataCert">
              <mat-radio-button [value]="true" >Yes</mat-radio-button>
              <mat-radio-button [value]="false">No</mat-radio-button>
            </mat-radio-group>
          </td>
          <td></td>
        </tr>
        <tr *ngIf="safeForUse == false">
          <td>Is a repair going to be carried out?</td>
          <td>
            <mat-radio-group class="question-group" [(ngModel)]="isRepaired">
              <mat-radio-button value="true" >Yes</mat-radio-button>
              <mat-radio-button value="false">No</mat-radio-button>
            </mat-radio-group>
          </td>
          <td></td>
        </tr>
        <tr *ngIf="safeForUse == false">
          <td>Decommission</td>
          <td>
            <mat-radio-group class="question-group" [(ngModel)]="decomissioned">
              <mat-radio-button value="true" >Yes</mat-radio-button>
              <mat-radio-button value="false">No</mat-radio-button>
            </mat-radio-group>
          </td>
          <td></td>
        </tr>
        <tr *ngIf="safeForUse == false">
          <td>Repair Notes</td>
          <td>
            <textarea type="text" rows="4" [(ngModel)]="repairNotes"></textarea>
          </td>
          <td></td>
        </tr>
      </tbody>
    </table>

    <button class="add-btn submit-btn" type="button" (click)="submit()">Save</button>
  </div>
</div>
