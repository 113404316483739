import { Component, OnInit } from '@angular/core';
import { SnotifyService } from 'ng-snotify';
import { WebUserService } from 'src/app/services/administration/web-user.service';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit {

  currentPassword: string = "";
  newPassword: string = "";
  newPassword2: string = "";

  constructor(private webUserService: WebUserService, private authService: AuthService, private snotifyService: SnotifyService) { 

  }

  ngOnInit(): void {
  }

  async changePassword() {
    if (this.currentPassword == "" || this.newPassword == "" || this.newPassword2 == "") {
      this.snotifyService.error("Enter all fields");
      return;
    }

    if (this.newPassword != this.newPassword2) {
      this.snotifyService.error("Passwords do not match");
      return;
    }

    var user = this.authService.currentUser.value;

    var success = await this.webUserService.changePassword(user.username, this.currentPassword, this.newPassword);
    if (!success) {
      this.snotifyService.error("Error saving password");
      return;
    }

    this.snotifyService.success("Successfully changed password");
    this.authService.logout();
  }
}
