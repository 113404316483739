<div class="content">
  <h1>INSPECTIONS</h1>

  <form class="panel" (ngSubmit)="loadTable()">
    <div class="panel-section-grid">
      <div class="panel-section">        
        <app-search-select [(ngModel)]="filter.companyId" [label]="'Companies'" [list]="companies" [value]="'companyId'" [name]="'companyName'" (onChanged)="companyChanged($event)"></app-search-select>
      </div>
  
      <div class="panel-section">
        <app-search-select [(ngModel)]="filter.locationId" [label]="'Locations'" [list]="locations" [value]="'locationId'" [name]="'name'"></app-search-select>
      </div>
  
      <div class="panel-section">
        <app-search-select [(ngModel)]="filter.categoryId" [label]="'Categories'" [list]="categories" [value]="'categoryId'" [name]="'categoryName'"></app-search-select>
      </div>
  
      <div class="panel-section">
        <mat-form-field appearance="fill">
          <mat-label class="form-label">PDA User</mat-label>
          <mat-select [(ngModel)]="filter.pdaUserId" name="pdaUserId" >
            <mat-option [value]="pdaUser.pdaUserId" *ngFor="let pdaUser of users">{{ pdaUser.name }}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div class="panel-section">
        <app-datepicker [startDate]="filter.startDate" [endDate]="filter.endDate" (onChange)="updateDates($event)"></app-datepicker>
        <!-- <mat-form-field appearance="fill">
          <mat-label>Date Range</mat-label>
          <mat-date-range-input [rangePicker]="picker" >
            <input matStartDate name="startDate" [(ngModel)]="filter.startDate" placeholder="Start date">
            <input matEndDate name="endDate" [(ngModel)]="filter.endDate" placeholder="End date">
          </mat-date-range-input>
          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-date-range-picker #picker></mat-date-range-picker>
        </mat-form-field> -->
      </div>

      <div class="panel-section">
        <mat-form-field>
          <mat-label class="form-label">Serial #</mat-label>
          <input type="text" name="serial" [(ngModel)]="filter.serialNum" matInput placeholder="Serial #" >
        </mat-form-field>
      </div>
  
      <div class="panel-section">
        <mat-form-field appearance="fill">
          <mat-label class="form-label">Status</mat-label>
          <mat-select name="status" [(ngModel)]="filter.status">
            <mat-option value="All">All</mat-option>
            <mat-option value="Pass">Pass</mat-option>
            <mat-option value="Fail">Fail</mat-option>
            <mat-option value="Missing">Missing</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
  
      <div class="panel-section">
        <mat-form-field>
          <mat-label class="form-label">Department</mat-label>
          <input type="text" name="department" [(ngModel)]="filter.department" matInput placeholder="Department">
        </mat-form-field>
      </div>

      <div class="panel-section">
        <mat-checkbox name="showArchived"  name="showArchived" [(ngModel)]="filter.showArchived">Show Archived</mat-checkbox>
      </div>
    </div>
  
    
    <button class="panel-btn panel-btn-margin" type="submit">
      FILTER
    </button>
  </form>

  <div class="spinner-container" *ngIf="(dataSource.loading$ | async)">
    <mat-spinner [diameter]="'50'"></mat-spinner>
  </div>

  <table mat-table class="data-table mat-elevation-z8" [dataSource]="dataSource" fixedLayout="true">
  
    <ng-container matColumnDef="link">
      <th mat-header-cell *matHeaderCellDef>Links</th>
      <td mat-cell *matCellDef="let element">
        <span *ngIf="!element.certLoading" (click)="downloadCert(element.inspectionId, element.safeForUse, element)" class="truncate-text clickable-link"><mat-icon aria-hidden="false">summarize</mat-icon></span>
        <mat-progress-spinner *ngIf="element.certLoading"  [diameter]="'16'" [color]="'#000'" [mode]="'indeterminate'"></mat-progress-spinner>
      </td>
    </ng-container>

    <ng-container matColumnDef="description">
      <th mat-header-cell *matHeaderCellDef>Description</th>
      <td mat-cell *matCellDef="let element"><span class="truncate-text">{{element.description}}</span></td>
    </ng-container>

    <ng-container matColumnDef="serialNum">
      <th mat-header-cell *matHeaderCellDef>Serial #</th>
      <td mat-cell *matCellDef="let element"><span class="truncate-text">{{element.serialNum}}</span></td>
    </ng-container>

    <ng-container matColumnDef="assetNum">
      <th mat-header-cell *matHeaderCellDef>Asset #</th>
      <td mat-cell *matCellDef="let element"><span class="truncate-text">{{element.assetNum}}</span></td>
    </ng-container>

    <ng-container matColumnDef="categoryName">
      <th mat-header-cell *matHeaderCellDef>Category</th>
      <td mat-cell *matCellDef="let element"><span class="truncate-text">{{element.category }}</span></td>
    </ng-container>

    <ng-container matColumnDef="manuf">
      <th mat-header-cell *matHeaderCellDef>Manuf</th>
      <td mat-cell *matCellDef="let element"><span class="truncate-text">{{element.manuf}}</span></td>
    </ng-container>

    <ng-container matColumnDef="wll">
      <th mat-header-cell *matHeaderCellDef>WLL</th>
      <td mat-cell *matCellDef="let element"><span class="truncate-text">{{element.wll }}</span></td>
    </ng-container>

    <ng-container matColumnDef="loadTestTo">
      <th mat-header-cell *matHeaderCellDef>Load Test To</th>
      <td mat-cell *matCellDef="let element"><span class="truncate-text">{{element.loadTestTo }}</span></td>
    </ng-container>

    <ng-container matColumnDef="australianStd">
      <th mat-header-cell *matHeaderCellDef>Australian Std</th>
      <td mat-cell *matCellDef="let element"><span class="truncate-text">{{element.australianStd}}</span></td>
    </ng-container>

    <ng-container matColumnDef="nextInspDate">
      <th mat-header-cell *matHeaderCellDef>Next Insp Date</th>
      <td mat-cell *matCellDef="let element"><span class="truncate-text">{{element.nextInspDate | date: 'dd MMM YYYY'}}</span></td>
    </ng-container>

    <ng-container matColumnDef="thirdParty">
      <th mat-header-cell *matHeaderCellDef>Third Party</th>
      <td mat-cell *matCellDef="let element"><span class="truncate-text">{{element.thirdParty}}</span></td>
    </ng-container>

    <ng-container matColumnDef="newIntoService">
      <th mat-header-cell *matHeaderCellDef>New Into Service</th>
      <td mat-cell *matCellDef="let element">
        <a href="javascript:void(0)" *ngIf="!element.serviceCertLoading" (click)="downloadServiceCert(element.equipmentId, element)" class="truncate-text clickable-url">{{element.newIntoService == true ? '&#10003;' : null }}</a>
        <mat-progress-spinner *ngIf="element.serviceCertLoading"  [diameter]="'16'" [color]="'#000'" [mode]="'indeterminate'"></mat-progress-spinner>
      </td>
    </ng-container>

    <ng-container matColumnDef="department">
      <th mat-header-cell *matHeaderCellDef>Department</th>
      <td mat-cell *matCellDef="let element"><span class="truncate-text">{{element.department }}</span></td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>

  <mat-paginator [pageSizeOptions]="[10, 25, 50, 100]" [pageSize]='10' [length]="dataSource.total$ | async" showFirstLastButtons></mat-paginator>
</div>

