<div class="content">
  <h1>OVERVIEW</h1>

  <div class="tiles">
    <div class="tiles-1">
      <div class="welcome__block">
        <div class="highlight"></div>
        <h3>WELCOME {{ currentUser.firstname }}</h3>
        <ng-select *ngIf="currentUser.userTypeId != 1" [(ngModel)]="selectedCompanyId" (change)="selectCompany()">
          <ng-option *ngFor="let company of companies" [value]="company.companyId" [virtualScroll]="'true'">{{company.companyName}}</ng-option>
        </ng-select>
        <h2 *ngIf="currentUser.userTypeId == 1">{{ currentUser.companyName }}</h2>
      </div>

      <div class="tiles-flex">
        <div class="tile show-cursor" (click)="notifyLocations()">
          <div class="tile-img small-tile-img"><img src="/assets/icons/locations.png"/></div>
          <div class="tile-text">
            <span>View</span>
            Locations
          </div>
        </div>
        <div class="tile show-cursor" (click)="viewJobs()">
          <div class="tile-img small-tile-img"><img src="/assets/icons/checked.png"/></div>
          <div class="tile-text">
            <span>View</span>
            Recent Jobs List
          </div>
        </div>
      </div>
    </div>

    <div class="tiles-2">
      <div class="status-summary tiles-flex">
        <div class="status-tile" (click)="viewComplies()">
          <div class="tile-img"><img src="/assets/svgs/arrows_circle_check_colour.svg" /></div>
          <div class="tile-text">
            <h1 class="complies">{{ stats?.compliesCount }}</h1>
            <h3>COMPLIES</h3>
            <p>Item was inspected within the current period and has passed inspection</p>
          </div>
          <button>VIEW <i class="fas fa-chevron-right"></i></button>
        </div>
        
        <div class="status-tile" (click)="viewDoesNotComply()">
          <div class="tile-img"><img src="/assets/svgs/basic_ban_colour.svg" /></div>
          <div class="tile-text">
            <h1 class="condemned">{{ stats?.doesNotComplyCount }}</h1>
            <h3>DOES NOT COMPLY</h3>
            <p>Item has failed inspection, or been condemned</p>
          </div>
          <button>VIEW <i class="fas fa-chevron-right"></i></button>
        </div>

        <div class="status-tile" (click)="viewOutOfService()">
          <div class="tile-img"><img src="/assets/svgs/basic_exclamation_colour.svg" /></div>
          <div class="tile-text">
            <h1 class="does-not-comply">{{ stats?.outOfServiceCount }}</h1>
            <h3>OUT OF SERVICE</h3>
            <p>Item has not been inspected during the current period or is being repaired</p>
          </div>
          <button>VIEW <i class="fas fa-chevron-right"></i></button>
        </div>        
      </div>

      <div class="inspection-grid">
        <div class="view-items-for-inspection">
          <img class="img" src="/assets/icons/calendar.png"/>
  
          <div class="inspection-items-title">
            <span>VIEW ITEMS</span>
            <span>DUE FOR INSPECTION</span>
          </div>
  
          <div></div>
  
          <app-option-picker [label]="'Months'" [(ngModel)]="months"></app-option-picker>
  
          <app-option-picker [label]="'Year'" [(ngModel)]="years"></app-option-picker>
  
          <div class="filter-btn">
            <button class="panel-btn" type="button" (click)="loadNextInspectionsTable()">
              FILTER
            </button>
  
            <label (click)="clearNextInspectionsTable()">CLEAR</label>
          </div>
        </div>

        <div class="help-block" [routerLink]="['/help-centre']">
          <img src="/assets/icons/question-mark.png"/>

          <div>
            <p>NEED HELP?</p>
            <p>ACCESS USER GUIDE AND TUTORIALS.</p>
          </div>
        </div>
      </div>
    </div>

    <div class="tiles-3">
      <div class="status-tile" (click)="reset()">
        <div class="tile-img small-tile-img">
          <img src="/assets/icons/folder.png"/>
          <div class="asset-count">{{ stats?.assetCount }}</div>
        </div>
        <h1>TOTAL</h1>
        <h3>ITEMS ON REGISTER</h3>

      </div>

      <div class="shortcut-tile">
        <div class="title">
          <h3><span>QUICK</span> LINKS</h3>
          <i class="fa fa-plus-circle" aria-hidden="true"></i>
        </div>

        <ul>
          <li><button (click)="loadLastSearched()">Last Searched</button></li>
          <li><button (click)="mostRecentInspection()">Latest Inspection</button></li>
          <li><button (click)="itemsDueThisMonth()">Items due this month</button></li>
          <li><a href="https://www.ranger.com.au/inspection-checklists/" target="_blank">Inspection Checklists</a></li>
        </ul>
      </div>
    </div>
  </div>
 
  <!--<app-dashboard-stats (filterSalesOrderNum)="filterSalesOrderNum($event)" (toggleFilter)="toggleFilter($event)"></app-dashboard-stats>-->

  <div class="sub-title">REGISTER SEARCH</div>

  <div class="panel" [ngClass]="{'panel-disabled' : filterByNextInspectionDate}">
    <div class="panel-section-grid">
      <div class="panel-section">
        <app-label-control [title]="'Status'" [(ngModel)]="statusList" (selectedIds)="filter.statusIds = $event" [disabled]="filterByNextInspectionDate"></app-label-control>
      </div>

      <div class="panel-section" *ngIf="categories != null && categories.length > 0">
        <app-label-control [title]="'Categories'" [(ngModel)]="categories" [disabled]="filterByNextInspectionDate"
          [keyPairValue]="'categoryId'" [keyPairName]="'categoryName'"
          (selectedIds)="filter.categoryIds = $event">
        </app-label-control>
      </div>

      <div class="panel-section" *ngIf="locations != null && locations.length > 0">
        <app-label-control [title]="'Locations'" [(ngModel)]="locations" [disabled]="filterByNextInspectionDate"
          [keyPairValue]="'locationId'" [keyPairName]="'name'"
          (selectedIds)="filter.locationIds = $event">
        </app-label-control>
      </div>

      <div class="panel-section" *ngIf="locations != null && locations.length > 0">
        <div class="filter-input">
          <i class="fa fa-calendar" aria-hidden="true"></i>
          <input type="text" ngxDaterangepickerMd [disabled]="filterByNextInspectionDate"
            [alwaysShowCalendars]="true" [linkedCalendars]="true"
            [(ngModel)]="filter.dates" [showDropdowns]="true" [minDate]="minDate"
            startKey="start" endKey="end" [locale]="{applyLabel: 'apply', format: 'DD/MM/YYYY'}"/>
        </div>
      </div>

      <div class="panel-section">
        <div class="filter-input">
          <i class="fas fa-search"></i>
          <input type="text" placeholder="Job #" [(ngModel)]="filter.jobNo" [disabled]="filterByNextInspectionDate"/>
        </div>
      </div>

      <div class="panel-section">
        <div class="filter-input">
          <i class="fas fa-search"></i>
          <input type="text" placeholder="Search..." [(ngModel)]="filter.serialNum" [disabled]="filterByNextInspectionDate"/>
        </div>
      </div>

      <div class="spacer"></div>

      <div class="panel-section">
        <div class="filter-btn">
          <button class="panel-btn" type="button" (click)="loadTable()">
            FILTER
          </button>

          <label (click)="resetAssetStatusTable()">CLEAR</label>
        </div>
      </div>
    </div>
  </div>


  <div class="export-options">
    <h1>
      <span style="display: flex;">
        <span style="margin-right: 10px; display: flex; align-items: center;">
          ASSET REGISTER
          <span class="colour-tag" *ngIf="colour != null && isUniqueColour != null">
            TAG COLOUR USED:
            <span *ngIf="isUniqueColour == true" style="font-weight: bold" [style.color]="colour"> {{ colour }}</span>
            <span *ngIf="isUniqueColour == false" style="font-weight: bold"> Please select single location or refer to Job Form</span>
          </span>
        </span>
        <div *ngIf="isLoading" class="rig-loader"><img src="/assets/rigspinner.jpg"/></div>
      </span>
    </h1>

    <div class="spacer"></div>

    <button (click)="export()" *ngIf="currentUser.userTypeId != 1">
      <img src="/assets/icons/download-icon.png"/>
      <label>Export</label>
    </button>

    <button (click)="download()">
      <img src="/assets/icons/download-icon.png"/>
      <label>Download ({{ selection.selected.length }})</label>
    </button>

    <button (click)="move()">
      <img src="/assets/icons/move-icon.png"/>
      <label>Move</label>
    </button>

    <button (click)="share()">
      <img src="/assets/icons/share-icon.png"/>
      <label>Share</label>
    </button>

    <button (click)="notify()" *ngIf="selection.selected.length > 0">
      <img src="/assets/icons/send-icon.png"/>
      <label>Notify ({{ selection.selected.length }})</label>
    </button>
  </div>

  <div class="no-data-block" *ngIf="dataSource != null && dataSource.data.length == 0 && isLoading == false">
    NO RESULTS FOUND
  </div>

  <div class="table-wrapper" [hidden]="dataSource == null || dataSource.data.length == 0 || isLoading == true">
    <div class="table-block">
      <table mat-table matSort matSortActive="inspectionDate" matSortDirection="desc" matSortDisableClear [dataSource]="dataSource">
        <ng-container matColumnDef="option">
          <th mat-header-cell *matHeaderCellDef>
            <mat-checkbox (change)="$event ? toggleAllRows() : null"
              [checked]="selection.hasValue() && isAllSelected()"
              [indeterminate]="selection.hasValue() && !isAllSelected()">
            </mat-checkbox>
          </th>
          <td mat-cell *matCellDef="let row">
            <mat-checkbox (click)="$event.stopPropagation()"
              (change)="$event ? selection.toggle(row) : null"
              [checked]="selection.isSelected(row)">
            </mat-checkbox>
          </td>
        </ng-container>

        <ng-container matColumnDef="image">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>IMAGE</th>
          <td mat-cell *matCellDef="let row">
            <i *ngIf="row.hasImage"  (click)="showImage(row)"  class="fa fa-camera" style="cursor:pointer ;" aria-hidden="true"></i>
          </td>
        </ng-container>


        <ng-container matColumnDef="serialNum">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>SERIAL NUM</th>
          <td mat-cell *matCellDef="let element" >{{element.serialNum}} </td>
        </ng-container>

        <ng-container matColumnDef="inspectionDate">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>INSPECTION DATE</th>
          <td mat-cell *matCellDef="let element">
            <span>{{element.inspectionDate | date: 'dd/MM/yyyy'}}</span>
          </td>
        </ng-container>

        <ng-container matColumnDef="nextInspDate">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            <span>
              NEXT INSPECTION DATE
              <i matTooltipClass="ranger-tooltip" [matTooltip]="'This date is either based on the Australian Standards or the custom inspection interval you have specified for this location. For the exact inspection date refer to the product detail page or report/certificate'" class="far fa-info-circle"></i>
            </span>
          </th>
          <td mat-cell *matCellDef="let element">
            <span *ngIf="element.nextInspectionPeriod == null">{{element.nextInspectionDate | date: 'MMM yyyy'}}</span>
            <span *ngIf="element.nextInspectionPeriod != null">
              {{element.nextInspectionPeriod}}
            </span>
          </td>
        </ng-container>

        <ng-container matColumnDef="description">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>DESCRIPTION</th>
          <td mat-cell *matCellDef="let element">{{element.description}} </td>
        </ng-container>

        <ng-container matColumnDef="wll">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>WLL</th>
          <td mat-cell *matCellDef="let element">{{element.wll}} </td>
        </ng-container>

        <ng-container matColumnDef="currentLocation">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>ASSET LOCATION</th>
          <td mat-cell *matCellDef="let element">{{element.currentLocation}} </td>
        </ng-container>

        <ng-container matColumnDef="inService">
          <th mat-header-cell *matHeaderCellDef>STATUS</th>
          <td mat-cell *matCellDef="let element">
            <div class="service-notes">
              <div class="service-circle" [style.background-color]="element.inServiceColour"></div>
              <span *ngIf="element.inspectionNotes != null && element.inspectionNotes != ''">
                <i matTooltipClass="ranger-tooltip" [matTooltip]="element.inspectionNotes" class="far fa-info-circle"></i>
              </span>
            </div>
            
          </td>
        </ng-container>

        <!-- <ng-container matColumnDef="status">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>STATUS</th>
          <td mat-cell *matCellDef="let element">
            <div class="service-notes">
              <span class="service-text">{{element.inServiceFormatted}}</span>
              <span *ngIf="element.inspectionNotes != null && element.inspectionNotes != ''">
                <i [matTooltip]="element.inspectionNotes" class="far fa-info-circle"></i>
              </span>
            </div>
            
          </td>
        </ng-container> -->

        <ng-container matColumnDef="view">
          <th mat-header-cell *matHeaderCellDef>VIEW</th>
          <td mat-cell class="center-td" *matCellDef="let element"><a class="view"
              [routerLink]="['/administration/equipment/details', element.equipmentId]"><i class="fas fa-chevron-right"></i></a></td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>

      <mat-paginator [pageSizeOptions]="[5, 10, 25, 100]" [pageSize]='10'></mat-paginator>
    </div>
  </div>
</div>