import { CollectionViewer, DataSource } from "@angular/cdk/collections";
import { BehaviorSubject, Observable, of } from "rxjs";
import { Location } from "src/app/models/location";;

import { catchError, finalize } from 'rxjs/operators';
import { DataServiceParam } from "../data-table/data-service-param";
import { ReportService } from "src/app/services/reports/reports.service";

export class RangerRegisterDataSource {
  private dataSubject = new BehaviorSubject<[]>([]);
  private loadingSubject = new BehaviorSubject<boolean>(false);
  private countSubject = new BehaviorSubject<number>(0);
  private missingCountSubject = new BehaviorSubject<number>(0);
  private passedCountSubject = new BehaviorSubject<number>(0);
  private failedCountSubject = new BehaviorSubject<number>(0);
  private repairedCountSubject = new BehaviorSubject<number>(0);
  private condemnedCountSubject = new BehaviorSubject<number>(0);

  public loading$ = this.loadingSubject.asObservable();
  public total$ = this.countSubject.asObservable();
  public missing$ = this.missingCountSubject.asObservable();
  public passed$ = this.passedCountSubject.asObservable();
  public failed$ = this.failedCountSubject.asObservable();
  public repaired$ = this.repairedCountSubject.asObservable();
  public condemned$ = this.condemnedCountSubject.asObservable();
  
  constructor(private reportsService: ReportService) {

  }

  connect(collectionViewer: CollectionViewer): Observable<[]> {
    return this.dataSubject.asObservable();
  }

  disconnect(collectionViewer: CollectionViewer): void {
    this.dataSubject.complete();
    this.loadingSubject.complete();
  }

  loadData(dto, sortDirection = 'asc', pageIndex = 0, pageSize = 10) {
    this.loadingSubject.next(true);

    this.reportsService.getRangerRegister(dto, sortDirection, pageIndex, pageSize)
      .pipe(catchError(() => of([])), finalize(() => { this.loadingSubject.next(false)}))
      .subscribe((response: any) => {
        console.log(response);
        this.countSubject.next(response.total);
        this.dataSubject.next(response.data);
        this.missingCountSubject.next(response.missing);
        this.passedCountSubject.next(response.passed);
        this.failedCountSubject.next(response.failed);
        this.repairedCountSubject.next(response.repaired);
        this.condemnedCountSubject.next(response.condemned);
      })
  };
}