<div class="dialog-content">
  <h1>Inspection History</h1>

  <table mat-table [dataSource]="dataSource" class="table">
    <ng-container matColumnDef="inspectedDate">
      <th mat-header-cell *matHeaderCellDef>Inspected Date</th>
      <td mat-cell *matCellDef="let element">{{element.inspectedDate}}</td>
    </ng-container>
  
    <ng-container matColumnDef="pdaUser">
      <th mat-header-cell *matHeaderCellDef>PDA User</th>
      <td mat-cell *matCellDef="let element">{{element.pdaUser}}</td>
    </ng-container>

    <ng-container matColumnDef="status">
      <th mat-header-cell *matHeaderCellDef>Status</th>
      <td mat-cell *matCellDef="let element">{{element.status}}</td>
    </ng-container>

    <ng-container matColumnDef="location">
      <th mat-header-cell *matHeaderCellDef>Location</th>
      <td mat-cell *matCellDef="let element">{{element.location}}</td>
    </ng-container>

    <ng-container matColumnDef="nextInspectionDate">
      <th mat-header-cell *matHeaderCellDef>Next Inspection Date</th>
      <td mat-cell *matCellDef="let element">{{element.nextInspectionDate}}</td>
    </ng-container>

    <ng-container matColumnDef="repaired">
      <th mat-header-cell *matHeaderCellDef>Repaired</th>
      <td mat-cell *matCellDef="let element">{{element.repaired}}</td>
    </ng-container>

    <ng-container matColumnDef="repairedNotes">
      <th mat-header-cell *matHeaderCellDef>Repaired Notes (If Applicable)</th>
      <td mat-cell *matCellDef="let element">{{element.repairedNotes}}</td>
    </ng-container>
  
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>

  <mat-paginator [pageSizeOptions]="[10, 25, 50, 100]" showFirstLastButtons></mat-paginator>
</div>