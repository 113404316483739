import { Injectable } from "@angular/core";
import { Router, RouterStateSnapshot, ActivatedRouteSnapshot, CanActivate } from "@angular/router";

import { AuthService } from '../auth.service'

@Injectable()
export class MasterAdminGuard implements CanActivate {

  constructor(private authService: AuthService, private router: Router) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      if (this.authService.isAuthenticated() && this.authService.isMasterAdmin()) {
        resolve(true);
      } else {
        resolve(false);
        this.router.navigate(['dashboard']);
      }
    });

  }

  canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      if (this.authService.isAuthenticated() && this.authService.isMasterAdmin()) {
        resolve(true);
      } else {
        resolve(false);
        this.router.navigate(['dashboard']);
      }
    });
  }
}
