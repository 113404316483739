import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { EquipmentService } from 'src/app/services/administration/equipment.service';

@Component({
  selector: 'app-inspection-history',
  templateUrl: './inspection-history.component.html',
  styleUrls: ['./inspection-history.component.scss']
})
export class InspectionHistoryComponent implements OnInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  
  displayedColumns: string[] = ['inspectedDate', 'pdaUser', 'status', 'location', 'nextInspectionDate', 'repaired', 'repairedNotes',];
  dataSource;

  constructor(private equipService: EquipmentService, @Inject(MAT_DIALOG_DATA) public data: {equipmentId: number}, 
  public dialogRef: MatDialogRef<InspectionHistoryComponent>) { }

  ngOnInit(): void {
    this.equipService.getInspectionHistory(this.data.equipmentId).then((inspections) => {
      this.dataSource = new MatTableDataSource(inspections);
      this.dataSource.paginator = this.paginator;
    })
  }

  ngAfterViewInit() {
    
  }
}
