import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import * as moment from 'moment';
import { tap } from 'rxjs/operators';
import { Category } from 'src/app/models/category';
import { Company } from 'src/app/models/company';
import { Location } from 'src/app/models/location';
import { InspReportDataSource } from 'src/app/service-data-sources/reports/insp-report-data-source';
import { CategoryService } from 'src/app/services/administration/category.service';
import { CompanyService } from 'src/app/services/administration/company-service';
import { LocationService } from 'src/app/services/administration/location.service';
import { PDAUserService } from 'src/app/services/administration/pda-user.service';
import { AuthService } from 'src/app/services/auth.service';
import { ReportService } from 'src/app/services/reports/reports.service';
import { ReportableService } from 'src/app/services/reportable.service';
import { saveAs as importedSaveAs } from 'file-saver';

@Component({
  selector: 'app-inspections',
  templateUrl: './inspections.component.html',
  styleUrls: ['./inspections.component.scss']
})
export class InspectionsComponent implements OnInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  
  filter = {
    stateId: 0,
    companyId: 0,
    locationId: 0,
    categoryId: 0,
    pdaUserId: 0,
    showArchived: false,
    startDate: moment().add(-2, 'M').toDate(),
    endDate: moment().toDate(),
    serialNum: "",
    status: "All",
    department: "",
  }

  companies: Company[];
  locations: Location[];
  categories: Category[];
  users;

  dataSource: InspReportDataSource;
  displayedColumns: string[] = ['link', 'description', 'serialNum', 'assetNum', 'categoryName', 'manuf', 'wll', 'loadTestTo', 
    'australianStd', 'nextInspDate', 'newIntoService', 'department']

  constructor(private authService: AuthService, private companyService: CompanyService, private locationService: LocationService, 
    private categoryService: CategoryService, private reportsService: ReportService, private pdaUserService: PDAUserService, private reportableService: ReportableService) { }

  ngOnInit(): void {
    this.loadCompanies();

    this.loadCategories();

    this.loadUsers();

    this.dataSource = new InspReportDataSource(this.reportsService);
  }

  ngAfterViewInit() {
    this.paginator.page.pipe(tap(() => this.loadTablePage())).subscribe();  
  }

  loadTable() {
    this.dataSource.loadData(this.filter); 
  }

  loadTablePage() {
    this.dataSource.loadData(this.filter, 'asc', this.paginator.pageIndex, this.paginator.pageSize);
  }

  loadUsers() {
    this.pdaUserService.getAll(true, "All").then((users) => {
      this.users = users;
    });
  }

  loadCompanies() {
    this.companies = [];
    if (this.authService.isAdmin()) {
      this.companyService.getAll().then((companies: Company[]) => {
        this.companies = companies;
        this.locations = [];
      });
    } else {
      this.companyService.get(this.authService.currentUser.value.companyId).then((company: Company) => {
        this.companies.push(company);
        this.filter.companyId = company.companyId;
        this.loadLocations(this.filter.companyId);
      });
    }
  }

  loadLocations(companyId: number) {
    this.locationService.getAll(companyId, true, "All").then((locations: Location[]) => {
      this.locations = locations;
    });
  }

  loadCategories() {
    this.categoryService.getAll(true, "All").then((categories: Category[]) => {
      this.categories = categories;
    })
  }

  companyChanged($ev) {
    this.loadLocations($ev);
  }

  updateDates(ev) {
    this.filter.startDate = ev.startDate;
    this.filter.endDate = ev.endDate;
  }

  /* -------------------------------------------------------------------- */
  /* CERT HELPERS */
  /* -------------------------------------------------------------------- */
  //TODO: Get proper names for reports when downloading
  downloadCert(inspectionId: number, safeForUse: boolean, row: any) {
    row.certLoading = true;
    this.reportableService.getLiftingExam(inspectionId, safeForUse).then((data) => {
      importedSaveAs(data, 'Report.pdf');
      row.certLoading = false;
    });
  }

  downloadServiceCert(equipmentId: number, row: any) {
    row.serviceCertLoading = true;
    this.reportableService.getServiceCert(equipmentId).then((data) => {
      importedSaveAs(data, 'Service Report.pdf');
      row.serviceCertLoading = false;
    });
  }
}
