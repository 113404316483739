import { Component, OnInit } from '@angular/core';
import { SnotifyService } from 'ng-snotify';
import { PreferencesService } from 'src/app/services/administration/preferences.service';

@Component({
  selector: 'app-preferences',
  templateUrl: './preferences.component.html',
  styleUrls: ['./preferences.component.scss']
})
export class PreferencesComponent implements OnInit {

  email1: string;
  email2: string;

  constructor(private preferencesService: PreferencesService, private snotifyService: SnotifyService) { }

  ngOnInit(): void {
    this.loadAddNewEquipmentSettings();
  }

  async loadAddNewEquipmentSettings() {
    var preferences = await this.preferencesService.getEquipmentPreferences();
    this.email1 = preferences.email1;
    this.email2 = preferences.email2;
  }

  async saveAddNewEquipmentPreferences() {
    var response = await this.preferencesService.saveEquipmentPreferences(this.email1, this.email2);
    if (!response.success) {
      this.snotifyService.error("Error saving preferences");
    } else {
      this.snotifyService.success("Successfully saved");
    }
  }
}
