import { Component } from '@angular/core';
import { AuthService } from './services/auth.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'RangerWeb';

  menuExpanded: boolean = false;
  currentUser;

  expandedNavs = [];

  constructor(private authService: AuthService) { 
    authService.currentUser.subscribe((user) => {
      this.currentUser = user;
    })
  }

  isNavExpanded(nav) {
    return this.expandedNavs.includes(nav);
  }

  toggleNav(nav) {
    if (this.isNavExpanded(nav)) {
      this.expandedNavs.splice(this.expandedNavs.indexOf(nav));
    } else {
      this.expandedNavs.push(nav);
    }
  }

  toggleMenu() {
    this.menuExpanded = !this.menuExpanded;
    if (!this.menuExpanded) {
      this.expandedNavs = [];
    }
  }
}
