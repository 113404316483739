import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { BatchType } from 'src/app/models/batch-type';
import { DataServiceParam } from 'src/app/service-data-sources/data-table/data-service-param';
import { TableDataSource } from 'src/app/service-data-sources/data-table/table-data-source';
import { BatchTypeService } from 'src/app/services/administration/batch-type-service';
import { BatchTypeManageComponent } from './batch-type-manage/batch-type-manage.component';

@Component({
  selector: 'app-batch-types-management',
  templateUrl: './batch-types-management.component.html',
  styleUrls: ['./batch-types-management.component.scss']
})
export class BatchTypesManagementComponent implements OnInit {

  @ViewChild(MatPaginator) paginator: MatPaginator;
  
  dataSource: TableDataSource<BatchType>;
  displayedColumns: string[] = ['batchType', 'edit'];

  filter: string = "";

  constructor(private batchTypeService: BatchTypeService, public dialog: MatDialog) { }

  ngOnInit(): void {
    this.dataSource = new TableDataSource(this.batchTypeService);
    this.loadTable();
  }

  loadTable() {
    var params: DataServiceParam[] = [];
    params.push(<DataServiceParam>{ paramName: 'filter', paramValue: this.filter });
    
    this.dataSource.loadData(params);
  }

  loadTablePage() {
    var params: DataServiceParam[] = [];
    params.push(<DataServiceParam>{ paramName: 'filter', paramValue: this.filter });
    this.dataSource.loadData(params, 'asc', this.paginator.pageIndex, this.paginator.pageSize);
  }
  
  add() {
    const dialogRef = this.dialog.open(BatchTypeManageComponent, {
      width: '900px',
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.loadTable();
      }
    });
  }

  edit(batchTypeId) {
    const dialogRef = this.dialog.open(BatchTypeManageComponent, {
      width: '900px',
      data: {batchTypeId: batchTypeId}
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.loadTable();
      }
    });
  }


}
