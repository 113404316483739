<div class="modal-panel" *ngIf="dataForm != null">
  <form [formGroup]="dataForm" (ngSubmit)="onSubmit()" class="form-manage">
    <div class="header">
      <h1>Batch Type Management</h1>
    </div>

    <div class="form-controls">
      <div class="form-input">
        <mat-form-field appearance="fill">
          <mat-label class="form-label">Batch Type</mat-label>
          <input matInput required formControlName="type">
        </mat-form-field>
      </div>
    </div>

    <div class="footer">
      <button type="button" class="cancel-btn" (click)="cancel()">CANCEL</button>
      <button type="submit" class="add-btn" [disabled]="!dataForm.valid">SAVE</button>
    </div>
  </form>
</div>