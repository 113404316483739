import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { ActivatedRoute } from '@angular/router';
import * as moment from 'moment';
import { MatSort } from '@angular/material/sort';
import { SnotifyService } from 'ng-snotify';
import { tap } from 'rxjs/operators';
import { InspectionComponent } from 'src/app/components/inspection/inspection.component';
import { MoveEquipComponent } from 'src/app/components/move-equip/move-equip.component';
import { PdaLoginComponent } from 'src/app/components/pda-login/pda-login.component';
import { Colour } from 'src/app/models/colour';
import { DataServiceParam } from 'src/app/service-data-sources/data-table/data-service-param';
import { CurrentJobAssetsDataSource } from 'src/app/service-data-sources/job-management/current-job-assets-data-source';
import { ColourService } from 'src/app/services/administration/colour.service';
import { EquipmentService } from 'src/app/services/administration/equipment.service';
import { JobService } from 'src/app/services/administration/job-service';
import { EquipmentManageComponent } from '../../equipment-management/equipment-manage/equipment-manage.component';
// import { CurrentJobAssetsDataSource } from 'src/app/services/data-sources/administration/job-asset-data-source';

@Component({
  selector: 'app-job-details',
  templateUrl: './job-details.component.html',
  styleUrls: ['./job-details.component.scss']
})
export class JobDetailsComponent implements OnInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  colours: Colour[];

  dataSource: CurrentJobAssetsDataSource;
  locationInspectionId: number;
  displayedColumns: string[] = ['checkbox', 'description', 'categoryName', 'name', 'serialNum', 'assetNum', 'manuf', 'inspectedAt', 'otherDetails', 'completed', 'archived', 'clientAssetNumber', 'department'];

  job;
  pdaUser;
  inspectionDate = moment();
  selectedColourId: number = 0;

  filter = "";

  constructor(private route: ActivatedRoute, private jobService: JobService, private colourService: ColourService, 
    public dialog: MatDialog, private equipmentService: EquipmentService, private snotifyService: SnotifyService) { }

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      this.locationInspectionId = +params['locationInspectionId'];
      this.dataSource = new CurrentJobAssetsDataSource(this.jobService);
      this.sort = new MatSort();
      this.loadColours();
      this.loadAssets();

      this.jobService.get(this.locationInspectionId).then((job) => {
        this.job = job;
        this.selectedColourId = job.colourId;
      });

      this.getPDAUser();
   });
  }

  
  ngAfterViewInit() {
    this.paginator.page.pipe(tap(() => this.loadAssetsPage())).subscribe();  
  }

  loadColours() {
    this.colourService.getAll().then((colours: Colour[]) => {
      this.colours = colours;
  
    });
  }

  async onColourChanged(colourId) {
    // Update the job with the new colour id
    var success = await this.jobService.updateColour(this.locationInspectionId, colourId);
  }

  loadAssets() {
    var params: DataServiceParam[] = [];
    console.log("Sorting");
    console.log(this.sort);
    params.push(<DataServiceParam>{ paramName: 'locationInspectionId', paramValue: this.locationInspectionId });
    params.push(<DataServiceParam>{ paramName: 'filter', paramValue: this.filter });
    this.dataSource.loadData(params, "");
    this.dataSource.updateAllSelectedState(this.toggleAllState);
  }

  loadAssetsPage() {
    var params: DataServiceParam[] = [];
    console.log("Sorting");
    console.log(this.sort);
    console.log(this.sort.direction);
    params.push(<DataServiceParam>{ paramName: 'locationInspectionId', paramValue: this.locationInspectionId });
    params.push(<DataServiceParam>{ paramName: 'filter', paramValue: this.filter });
    this.dataSource.loadData(params, this.sort.direction, this.paginator.pageIndex, this.paginator.pageSize, this.sort.active );
  }

  pdaUserLogin() {
    const dialogRef = this.dialog.open(PdaLoginComponent, {
      width: '500px',
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.getPDAUser();
      }
    });
  }

  pdaUserLogout() {
    this.pdaUser = null;
  }

  getPDAUser() {
    this.pdaUser = null;
    var pdaUserJson = localStorage.getItem('pdaUser');
    if (pdaUserJson != null && pdaUserJson != '') {
      this.pdaUser = JSON.parse(pdaUserJson);
    }
  }

  addEquipment() {
    console.log(this.job);
    const dialogRef = this.dialog.open(EquipmentManageComponent, {
      width: '900px',
      data: {companyId: this.job.companyId, locationInspectionId: this.job.locationInspectionId}
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.loadAssetsPage();
      }
    });
  }

  editEquipment(equipmentId) {
    const dialogRef = this.dialog.open(EquipmentManageComponent, {
      width: '900px',
      data: {companyId: this.job.companyId, locationInspectionId: this.job.locationInspectionId, equipmentId: equipmentId}
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.loadAssetsPage();
      }
    });
  }

  unarchive(equipmentId: number) {
    this.equipmentService.archive(equipmentId, false).then(() => {
      this.snotifyService.success("Successfully activated equipment");
      this.loadAssetsPage();
    });
  }

  toggleAllState: boolean = false;

  selectAll() {
    this.toggleAllState = !this.toggleAllState;

    this.dataSource.updateAllSelectedState(this.toggleAllState);
  }

  inspectSelected() {
    var selectedAssets = this.dataSource.getSelected();
    if (selectedAssets.length == 0) {
      this.snotifyService.error("No equipment selected");
      return;
    }

    var ids = selectedAssets.map(a => a.locationInspectionEquipId);
    this.jobService.passEquipmentBatch(ids, this.inspectionDate, this.pdaUser.pdaUserId).then((response) => {
      if (response.success) {
        this.snotifyService.success("Successfully inspected equipment");
        this.loadAssetsPage();
      } else {
        this.snotifyService.error("Error inspecting all equipment. Please try again.")
      }
    });
  }

  missingSelected() {
    var selectedAssets = this.dataSource.getSelected();
    if (selectedAssets.length == 0) {
      this.snotifyService.error("No equipment selected");
      return;
    }

    var ids = selectedAssets.map(a => a.locationInspectionEquipId);
    this.jobService.missingEquipmentBatch(ids, this.inspectionDate, this.pdaUser.pdaUserId).then((response) => {
      if (response.success) {
        this.snotifyService.success("Successfully set equipment as missing");
        this.loadAssetsPage();
      } else {
        this.snotifyService.error("Error marking equipment as missing. Please try again.")
      }
    });
  }

  decomSelected() {
    var selectedAssets = this.dataSource.getSelected();
    if (selectedAssets.length == 0) {
      this.snotifyService.error("No equipment selected");
      return;
    }

    var ids = selectedAssets.map(a => a.equipmentId);
    this.jobService.decomEquipmentBatch(ids, this.inspectionDate, this.pdaUser.pdaUserId).then((response) => {
      if (response.success) {
        this.snotifyService.success("Successfully decomissioned equipment");
        this.loadAssetsPage();
      } else {
        this.snotifyService.error("Error marking equipment as missing. Please try again.")
      }
    });
  }

  inspect(locationInspectionEquipId: number, equipmentId: number) {
    const dialogRef = this.dialog.open(InspectionComponent, {
      width: '900px',
      data: {
        locationInspectionEquipId: locationInspectionEquipId, 
        pdaUserId: this.pdaUser.pdaUserId, 
        equipmentId: equipmentId,
        viewOnly: false,
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.loadAssetsPage();
      }
    });
  }

  moveSelected() {
    var selectedAssets = this.dataSource.getSelected();
    if (selectedAssets.length == 0) {
      this.snotifyService.error("No equipment selected");
      return;
    }
    
    var ids = selectedAssets.map(a => a.equipmentId);
    const dialogRef = this.dialog.open(MoveEquipComponent, {
      data: { batchIds: ids, companyId: this.job.companyId }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result.success) {
        this.snotifyService.success("Successfully moved equipment");
        this.loadAssetsPage();
      }
    });
  }
}
