import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { SnotifyService } from 'ng-snotify';
import { AddQuestionComponent } from 'src/app/components/add-question/add-question.component';
import { Category } from 'src/app/models/category';
import { CategoryService } from 'src/app/services/administration/category.service';
import { InspSetService } from 'src/app/services/administration/insp-set-service';

@Component({
  selector: 'app-question-sets-management',
  templateUrl: './question-sets-management.component.html',
  styleUrls: ['./question-sets-management.component.scss']
})
export class QuestionSetsManagementComponent implements OnInit {

  categories: Category[];
  categoryId: number = -1;

  questions;
  inspSet;

  constructor(private categoryService: CategoryService, private inspSetService: InspSetService, 
    private snotifyService: SnotifyService, public dialog: MatDialog) { }

  ngOnInit(): void {
    this.categoryService.getAll().then((data) => { this.categories = data; })
  }

  getQuestions() {
    this.inspSetService.getByCategoryId(this.categoryId).then((data) => {
      this.inspSet = data;
      this.questions = data.questions.sort((a, b) => a.position - b.position);
    });
  } 

  add() {
    const dialogRef = this.dialog.open(AddQuestionComponent, {
      width: '900px',
      data: {inspSetId: this.inspSet.inspSetId}
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.getQuestions();
      }
    });
  }

  edit(inspSetQuestionId) {
    const dialogRef = this.dialog.open(AddQuestionComponent, {
      width: '900px',
      data: {inspSetId: this.inspSet.inspSetId, inspSetQuestionId: inspSetQuestionId}
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.getQuestions();
      }
    });
  }

  moveQuestionDown(question) {
    var maxPosition = this.questions.sort((a, b) => b.position - a.position)[0].position;
    var currentPosition = question.position;

    if (currentPosition == maxPosition) {
      this.questions = this.questions.sort((a, b) => a.position - b.position);
      return;
    }

    // Get the question at the new position and replace it
    var nextQuestion = this.questions.find(q => q.position == question.position + 1);
    nextQuestion.position--;

    question.position++;

    this.questions = this.questions.sort((a, b) => a.position - b.position);

    this.inspSetService.updateQuestions(this.questions).then((response) => {
      if (!response.success) {
        this.snotifyService.error("Error updating questions");
      }
    })
  }

  moveQuestionUp(question) {
    var currentPosition = question.position;

    if (currentPosition == 0) {
      this.questions = this.questions.sort((a, b) => a.position - b.position);
      return;
    }

    // Get the question at the new position and replace it
    var prevQuestion = this.questions.find(q => q.position == question.position - 1);
    if (prevQuestion == null) {
      this.questions = this.questions.sort((a, b) => a.position - b.position);
      return;
    }

    prevQuestion.position++;

    question.position--;

    this.questions = this.questions.sort((a, b) => a.position - b.position);

    this.inspSetService.updateQuestions(this.questions).then((response) => {
      if (!response.success) {
        this.snotifyService.error("Error updating questions");
      }
    })
  }
}
