import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-inspection-preview',
  templateUrl: './inspection-preview.component.html',
  styleUrls: ['./inspection-preview.component.scss']
})
export class InspectionPreviewComponent implements OnInit {
  @Input() inspection;
  @Input() small: boolean = false;
  @Input() allowExport: boolean = false;

  @Output() exportCert = new EventEmitter<number>();

  constructor() { 
    
  }

  ngOnInit(): void {

  }

  export(inspectionId: number) {
    if (!this.allowExport)
      return;
      
    this.exportCert.emit(inspectionId);
  }

}
