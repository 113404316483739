import { SelectionModel } from '@angular/cdk/collections';
import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { TableDataSource } from 'src/app/service-data-sources/data-table/table-data-source';
import { LocationService } from 'src/app/services/administration/location.service';
import { InspectionService } from 'src/app/services/inspection.service';

enum State {
  selectList,
  confirmation,
  thankYou
}

@Component({
  selector: 'app-notify-locations-dialog',
  templateUrl: './notify-locations-dialog.component.html',
  styleUrls: ['./notify-locations-dialog.component.scss']
})
export class NotifyLocationsDialogComponent implements OnInit {

  dataSource: MatTableDataSource<any>;
  displayedColumns: string[] = ['option', 'name', 'address1', 'county', 'state', 'postcode', 'email1', 'monthlyInterval'];
  selection = new SelectionModel<any>(true, []);
  @ViewChild(MatPaginator) paginator: MatPaginator;

  currentState = State.selectList;
  notes: string = "";
  newLocation: boolean = false;

  constructor(public dialogRef: MatDialogRef<NotifyLocationsDialogComponent>, private locationService: LocationService,
    private inspectionService: InspectionService, @Inject(MAT_DIALOG_DATA) public data: {companyId: number, requestedBy: string}) { }

  ngOnInit(): void {
    this.newLocation = false;
  }

  ngAfterViewInit() {
    this.locationService.getAll(this.data.companyId, false, "").then((locations) => {
      //this.locations = locations.sort((a, b) => a.name.localeCompare(b.name));
      this.dataSource = new MatTableDataSource(locations);
      this.dataSource.paginator = this.paginator;
    });
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  toggleAllRows() {
    if (this.isAllSelected()) {
      this.selection.clear();
      return;
    }

    this.selection.select(...this.dataSource.data);
  }

  addNewLocation() {
    this.newLocation = true;
    this.currentState = State.confirmation;
  }

  onSubmit() {
    var ids = this.selection.selected.map(l => l.locationId);
    if (ids.length == 0) {
      alert("Must select at least 1 location");
      return;
    }

    this.currentState = State.confirmation;
  }

  onSend() {
    if (!this.newLocation) {
      var ids = this.selection.selected.map(l => l.locationId);
      if (ids.length == 0) {
        alert("Must select at least 1 location");
        return;
      }
    }

    this.inspectionService.notifyLocations(this.data.companyId, ids, this.data.requestedBy, this.notes);
    this.currentState = State.thankYou;
  }


  cancel() {
    this.dialogRef.close();
  }

}
