import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Company } from 'src/app/models/company';
import { State } from 'src/app/models/state';
import { CompanyService } from 'src/app/services/administration/company-service';
import { SnotifyService } from 'ng-snotify';

@Component({
  selector: 'app-company-manage',
  templateUrl: './company-manage.component.html',
  styleUrls: ['./company-manage.component.scss']
})
export class CompanyManageComponent implements OnInit {

  companyForm;

  states: State[] = [];

  constructor(private companyService: CompanyService, private snotifyService: SnotifyService,
    public dialogRef: MatDialogRef<CompanyManageComponent>, @Inject(MAT_DIALOG_DATA) public data: any) { }

  async ngOnInit() {
    
    this.companyService.getStates().then((states: State[]) => this.states = states);

    var company = new Company();
    
    if (this.data != null && this.data.companyId != null) {
      company = await this.companyService.get(this.data.companyId);
    }

    this.companyForm = new FormGroup({
      companyId: new FormControl(company.companyId),
      companyName: new FormControl(company.companyName, [Validators.required]),
      accNum: new FormControl(company.accNum, [Validators.required]),
      address1: new FormControl(company.address1, [Validators.required]),
      address2: new FormControl(company.address2),
      address3: new FormControl(company.address3),
      address4: new FormControl(company.address4),
      postcode: new FormControl(company.postcode),
      county: new FormControl(company.county),
     
      mainContactNumber: new FormControl(company.mainContactNumber),
      officeNumber: new FormControl(company.officeNumber),
      telephone: new FormControl(company.telephone),
      fax: new FormControl(company.fax),
      email: new FormControl(company.email),
      stateId: new FormControl(company.stateId, [Validators.required]),
      monthlyInterval: new FormControl(company.monthlyInterval, [Validators.required]),
      hideSynthetic: new FormControl(company.hideSynthetic),
      showClientNo: new FormControl(company.showClientNo),
      expired: new FormControl(company.expired),

      nextInspectionPeriod: new FormControl(company.nextInspectionPeriod),
      nextInspectionPeriodInterval: new FormControl(company.nextInspectionPeriodInterval),
    })
  }

  cancel() {
    this.dialogRef.close(false);
  }

  async onSubmit() {
    var success = await this.companyService.save(this.companyForm.value);
    if (!success) {
      this.snotifyService.error("Error saving Company");
      return;
    }

    this.dialogRef.close(true);
    this.snotifyService.success("Successfully saved Company");
  }

}