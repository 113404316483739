<div class="modal-panel" *ngIf="jobForm != null">
  <form [formGroup]="jobForm" (ngSubmit)="onSubmit()" class="form-manage">
    <div class="header">
      <h1>Job Management</h1>
    </div>

    <div class="form-controls">
      <div class="form-input">
        <app-search-select formControlName="companyId" [label]="'Company'" [list]="companies" [value]="'companyId'" [name]="'companyName'" (onChanged)="companyChanged($event)"></app-search-select>
      </div>

      <div class="form-input">
        <app-search-select formControlName="locationId" [label]="'Location'" [list]="locations" [value]="'locationId'" [name]="'name'"></app-search-select>
      </div>

      <div class="form-input">
        <mat-form-field appearance="fill">
          <mat-label class="form-label">Colour</mat-label>
          <mat-select formControlName="colourId" required >
            <mat-option [value]="colour.colourId" *ngFor="let colour of colours">{{ colour.colourName }}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div class="form-input">
        <mat-form-field appearance="fill">
          <mat-label class="form-label">Job #</mat-label>
          <input type="text" matInput placeholder="Job #" formControlName="salesOrderNum" >
        </mat-form-field>
      </div>
    </div>

    <div class="form-controls">
      <div class="form-input">
        <div style="display: flex; flex-direction: row; align-items: center">
          <app-search-select name="engineerId" formControlName="engineerId" [label]="'Inspectors'" [list]="engineers" [value]="'pdaUserId'" [name]="'name'"></app-search-select>
          <button type="button" class="add-btn" (click)="addEngineer()">Add</button>
        </div>        
      </div>
    </div>

    <table>
      <tbody>
        <tr *ngFor="let engineer of engineersList">
          <td>{{ engineer.name }}</td>
          <td><i (click)="removeEngineer(engineer)" style="cursor: pointer" class="fas fa-trash"></i></td>
        </tr>
      </tbody>
    </table>

    <div class="footer">
      <button type="button" class="cancel-btn" (click)="cancel()">CANCEL</button>
      <button type="submit" class="add-btn" [disabled]="!jobForm.valid">SAVE</button>
    </div>
  </form>
</div>