export class WebUser {
  userId: string = null;
  firstname: string;
  lastname: string;
  password: string;
  password2: string;
  email: string;
  email2: string;
  userTypeId: number = 5;
  state: number = 0;
  companyId: number = 0;
  isLockedOut: boolean = false;
}