export class Location {
  locationId: number = 0;
  companyId: number;
  name: string;
  accNum: string;
  address1: string;
  address2: string;
  address3: string;
  address4: string;
  county: string;
  postcode: string;
  telephone: string;
  fax: string;
  email1: string;
  email2: string;
  expired: boolean = false;
  monthlyInterval: number = 0;
  nextInspectionPeriod: number;
  nextInspectionPeriodInterval: number = 0;
}