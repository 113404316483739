import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import * as moment from 'moment';
import { SnotifyService } from 'ng-snotify';
import { EquipmentService } from 'src/app/services/administration/equipment.service';
import { JobService } from 'src/app/services/administration/job-service';
import { QuestionService } from 'src/app/services/administration/question.service';

@Component({
  selector: 'app-inspection',
  templateUrl: './inspection.component.html',
  styleUrls: ['./inspection.component.scss']
})
export class InspectionComponent implements OnInit {

  equipment;
  inspectionDate = moment();
  questions;

  excepCirc: boolean = false;
  safeForUse: boolean ;
  nataCert: boolean = false;
  repair: boolean = false;
  decommissioned: boolean = false;
  repairNotes: string;

  viewOnly: boolean = false;

  constructor(@Inject(MAT_DIALOG_DATA) public data: {locationInspectionEquipId: number, equipmentId: number, pdaUserId: number, viewOnly: boolean, inspectionId: number}, 
    public dialogRef: MatDialogRef<InspectionComponent>, private equipmentService: EquipmentService, 
    private questionService: QuestionService, private snotifyService: SnotifyService, private jobService: JobService) { 

  }

  ngOnInit(): void {
    this.viewOnly = this.data.viewOnly;

    this.equipmentService.get(this.data.equipmentId).then((equipment) => {
      this.equipment = equipment;
    });

    this.questionService.getQuestionsForInspection(this.data.equipmentId).then((questions) => {
      this.questions = questions.sort((a, b) => a.position - b.position);
    });
  }

  setAsMissing() {
    this.jobService.missing(this.data.equipmentId, this.data.locationInspectionEquipId, this.data.pdaUserId,
      this.inspectionDate.toString(), this.safeForUse, this.decommissioned, this.excepCirc, this.nataCert, this.repair,
      this.repairNotes, this.questions, this.data.inspectionId).then((response) => {
      if (response.success) {
        this.snotifyService.success("Successfully marked as missing");
        this.dialogRef.close(true);
      } else {
        this.snotifyService.error("Error inspecting equipment. Please try again.")
        this.dialogRef.close(false);
      }
    });
  }

  passAll() {
    for (let question of this.questions) {
      question.status = true;
    }
  }

  submit() {
    for (let question of this.questions) {
      if (question.status == null) {
        this.snotifyService.error("Please answer all questions")
        return;
      }
    }

    if (this.safeForUse == null) {
      this.snotifyService.error("Mark safe for use")
      return;
    }

    if (this.safeForUse == false) {
      if (this.repair == null) {
        this.snotifyService.error("Mark repair")
        return;
      }
      
      if (this.decommissioned == null) {
        this.snotifyService.error("Mark decommissioned")
        return;
      }
    }
    this.jobService.inspect(this.data.equipmentId, this.data.locationInspectionEquipId, this.data.pdaUserId,
      this.inspectionDate.toString(), this.safeForUse, this.decommissioned, this.excepCirc, this.nataCert, this.repair,
      this.repairNotes, this.questions, this.data.inspectionId).then((response) => {
      if (response.success) {
        this.snotifyService.success("Successfully inspected equipment");
        this.dialogRef.close(true);
      } else {
        this.snotifyService.error("Error inspecting equipment. Please try again.")
        this.dialogRef.close(false);
      }
    });
  }
}
