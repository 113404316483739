import { Injectable } from '@angular/core';
import { HttpClient, HttpEvent } from '@angular/common/http';

import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable()
export class ReportableService {
  constructor(private http: HttpClient) { }

  public getBatchById(id: string): Observable<HttpEvent<any>> {
    return this.http.post<any>(
      environment.apiUrl + 'reportable/getbatchbyid?id=' + id, null,
      { observe: 'events', reportProgress: true, responseType: 'blob' as 'json'  }
    );
  }
  

  public getLiftingExamBase64(id: number, safeForUse: boolean): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http.get<any>(environment.apiUrl + 'reportable/getliftingexam?inspectionId=' + id + "&safeForUse=" + safeForUse).subscribe(data => {
       resolve(data);
      }, error => {
        console.log("err");
        console.log(error);
        reject();
      });
    });
  }

  public getLiftingExam(id: number, safeForUse: boolean): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http.get<any>(environment.apiUrl + 'reportable/getliftingexam?inspectionId=' + id + "&safeForUse=" + safeForUse).subscribe(data => {
        let blob = this.convertBase64toBlob(data, 'application/pdf');
        resolve(blob);
      }, error => {
        console.log("err");
        console.log(error);
        reject();
      });
    });
  }

  public getRepairCert(id: number): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http.get<any>(environment.apiUrl + 'reportable/getrepaircert?inspectionId=' + id).subscribe(data => {
        let blob = this.convertBase64toBlob(data, 'application/pdf');
        resolve(blob);
      }, error => {
        console.log("err");
        console.log(error);
        reject();
      });
    });
  }

  public getRepairCertBase64(id: number): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http.get<any>(environment.apiUrl + 'reportable/getrepaircert?inspectionId=' + id).subscribe(data => {
        resolve(data);
      }, error => {
        console.log("err");
        console.log(error);
        reject();
      });
    });
  }

  public getNataCertBase64(id: number): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http.get<any>(environment.apiUrl + 'reportable/getnatacert?inspectionId=' + id).subscribe(data => {
        resolve(data);
      }, error => {
        console.log("err");
        console.log(error);
        reject();
      });
    });
  }

  public getNataCert(id: number): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http.get<any>(environment.apiUrl + 'reportable/getnatacert?inspectionId=' + id).subscribe(data => {
        let blob = this.convertBase64toBlob(data, 'application/pdf');
        resolve(blob);
      }, error => {
        console.log("err");
        console.log(error);
        reject();
      });
    });
  }

  public getServiceCertBase64(id: number): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http.get<any>(environment.apiUrl + 'reportable/getservicecert?equipmentId=' + id).subscribe(data => {
        resolve(data);
      }, error => {
        console.log("err");
        console.log(error);
        reject();
      });
    });
  }

  public getServiceCert(id: number): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http.get<any>(environment.apiUrl + 'reportable/getservicecert?equipmentId=' + id).subscribe(data => {
        let blob = this.convertBase64toBlob(data, 'application/pdf');
        resolve(blob);
      }, error => {
        console.log("err");
        console.log(error);
        reject();
      });
    });
  }

  public getRAMS(id: number): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http.get<any>(environment.apiUrl + 'reportable/getramscert?locationInspectionId=' + id).subscribe(data => {
        let blob = this.convertBase64toBlob(data, 'application/pdf');
        resolve(blob);
      }, error => {
        console.log("err");
        console.log(error);
        reject();
      });
    });
  }

  public getSummaryReport(id: number): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http.get<any>(environment.apiUrl + 'reportable/getJobSummary?locationInspectionId=' + id).subscribe(data => {
        let blob = this.convertBase64toBlob(data, 'application/pdf');
        resolve(blob);
      }, error => {
        console.log("err");
        console.log(error);
        reject();
      });
    });
  }
  public getJobForm(id: number): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http.get<any>(environment.apiUrl + 'reportable/getjobformcert?locationInspectionId=' + id).subscribe(data => {
        let blob = this.convertBase64toBlob(data, 'application/pdf');
        resolve(blob);
      }, error => {
        console.log("err");
        console.log(error);
        reject();
      });
    });
  }
  public getServiceAgreement(id: string): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http.get<any>(environment.apiUrl + 'reportable/getServiceAgreement?Id=' + id).subscribe(data => {
        let blob = this.convertBase64toBlob(data, 'application/pdf');
        resolve(blob);
      }, error => {
        console.log("err");
        console.log(error);
        reject();
      });
    });
  }

  public getLiftingBatch(idList, email): Promise<any> {
    return new Promise((resolve, reject) => {
      var dto = {
        ids: idList,
        email: email
      }
      
      this.http.post<any>(environment.apiUrl + 'reportable/sendliftingbatch', dto).subscribe(data => {
        resolve(true);
      }, error => {
        resolve(false);
      });
    });
  }

  public getNataBatch(idList, email): Promise<any> {
    return new Promise((resolve, reject) => {
      var dto = {
        ids: idList,
        email: email
      }
      
      this.http.post<any>(environment.apiUrl + 'reportable/sendnatabatch', dto).subscribe(data => {
        resolve(true);
      }, error => {
        resolve(false);
      });
    });
  }

  public getNewBatch(idList, email): Promise<any> {
    return new Promise((resolve, reject) => {
      var dto = {
        ids: idList,
        email: email
      }
      
      this.http.post<any>(environment.apiUrl + 'reportable/sendnewintoservicebatch', dto).subscribe(data => {
        resolve(true);
      }, error => {
        resolve(false);
      });
    });
  }

  public getRepairBatch(idList, email): Promise<any> {
    return new Promise((resolve, reject) => {
      var dto = {
        ids: idList,
        email: email
      }
      
      this.http.post<any>(environment.apiUrl + 'reportable/sendrepairbatch', dto).subscribe(data => {
        resolve(true);
      }, error => {
        resolve(false);

      });
    });
  }

  public shareFile(email: string, base64File: string, filename: string, requestedBy: string): Promise<any> {
    const headers = {
      'Content-Type': 'application/json',
    };

    var dto = {
      email: email,
      base64File: base64File,
      fileName: filename,
      requestedBy: requestedBy
    }

    return new Promise((resolve, reject) => {
      this.http.post<any>(environment.apiUrl + "inspection/sharefile", dto, { headers: headers }).subscribe(data => {
        console.log(data);
        resolve(data);
      }, (error) => {
        console.log(error);
        resolve(false)
      });
    });
  }

  public getRangerRegisterExport(filterDto, asBase64: boolean = false) : Promise<any> {
    return new Promise((resolve, reject) => {
      this.http.post<any>(environment.apiUrl + 'reportable/getassetregister', filterDto).subscribe(data => {
        if (asBase64) {
          resolve(data);
        } else {
          let blob = this.convertBase64toBlob(data, 'application/pdf');
          resolve(blob);
        }
      }, error => {
        console.log(error);
        reject();
      });
    });
  }
  public getRangerRegisterExportV2(filterDto, asBase64: boolean = false) : Promise<any> {
    return new Promise((resolve, reject) => {
      this.http.post<any>(environment.apiUrl + 'reportable/getassetregisterV2', filterDto).subscribe(data => {
        if (asBase64) {
          resolve(data);
        } else {
          let blob = this.convertBase64toBlob(data, 'application/pdf');
          resolve(blob);
        }
      }, error => {
        console.log(error);
        reject();
      });
    });
  }

  public getAssetExport(equipmentId: number): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http.get<any>(environment.apiUrl + 'reportable/getAssetExport?equipmentId=' + equipmentId).subscribe(data => {
        let blob = this.convertBase64toBlob(data, 'application/pdf');
        resolve(blob);
      }, error => {
        reject();
      });
    });
  }


  // LEGACY - no longer needed
  public getBatchLifting(batchId: string): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http.get<any>(environment.reportableUrl + 'getliftingbatch?batchId=' + batchId).subscribe(data => {
        let blob = this.convertBase64toBlob(data, 'application/pdf');
        resolve(blob);
      }, error => {
        console.log("err");
        console.log(error);
        reject();
      });
    });
  }


  // LEGACY - no longer needed
  public getBatchRepair(batchId: string): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http.get<any>(environment.reportableUrl + 'getrepairbatch?batchId=' + batchId).subscribe(data => {
        let blob = this.convertBase64toBlob(data, 'application/pdf');
        resolve(blob);
      }, error => {
        console.log("err");
        console.log(error);
        reject();
      });
    });
  }

  
// LEGACY - no longer needed
  public getBatchNew(batchId: string): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http.get<any>(environment.reportableUrl + 'getnewbatch?batchId=' + batchId).subscribe(data => {
        let blob = this.convertBase64toBlob(data, 'application/pdf');
        resolve(blob);
      }, error => {
        console.log("err");
        console.log(error);
        reject();
      });
    });
  }

  // LEGACY - no longer needed
  public getBatchNata(batchId: string): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http.get<any>(environment.reportableUrl + 'getnatabatch?batchId=' + batchId).subscribe(data => {
        let blob = this.convertBase64toBlob(data, 'application/pdf');
        resolve(blob);
      }, error => {
        console.log("err");
        console.log(error);
        reject();
      });
    });
  }

 
  

  

  public getJobReport(locationInspectionId: number, reportName: string) : Promise<any> {
    return new Promise((resolve, reject) => {
      console.log(environment.reportableUrl + 'getjobreport?location_inspection_Id=' + locationInspectionId + "&reportName=" + reportName);
      this.http.get<any>(environment.reportableUrl + 'getjobreport?location_inspection_Id=' + locationInspectionId + "&reportName=" + reportName).subscribe(data => {
        let blob = this.convertBase64toBlob(data, 'application/pdf');
        resolve(blob);
      }, error => {
        reject();
      });
    });
  }

  public convertBase64toBlob(content, contentType) {
    contentType = contentType || '';
    let sliceSize = 512;
    let byteCharacters = window.atob(content); //method which converts base64 to binary
    let byteArrays = [
    ];
    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      let slice = byteCharacters.slice(offset, offset + sliceSize);
      let byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
      let byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }
    let blob = new Blob(byteArrays, {
      type: contentType
    }); //statement which creates the blob
    return blob;
  }
}
