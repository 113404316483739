<div class="content">
  <h1>BATCH SEARCH</h1>

  <form class="panel" (ngSubmit)="loadTable()">
    <div class="panel-section-grid">
      <div class="panel-section">
        <mat-form-field appearance="fill">
          <mat-label class="form-label">Batch Type</mat-label>
          <mat-select [(ngModel)]="filter.batchTypeId" name="batchTypeId" (selectionChange)="batchTypeChanged($event)">
            <mat-option [value]="batchType.batchTypeId" *ngFor="let batchType of batchTypes">{{ batchType.type }}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div class="panel-section">
        <mat-form-field appearance="fill">
          <mat-label class="form-label">Batch Manufacturer</mat-label>
          <mat-select [(ngModel)]="filter.batchManufId" name="batchManufId">
            <mat-option [value]="batchManuf.batchManufId" *ngFor="let batchManuf of batchManufs">{{ batchManuf.batchManuf }}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div class="panel-section">
        <mat-form-field>
          <mat-label class="form-label">Batch #</mat-label>
          <input type="text" name="batchNum" [(ngModel)]="filter.batchNum" matInput placeholder="Batch #" >
        </mat-form-field>
      </div>
    </div>
  
    
    <button class="panel-btn panel-btn-margin" type="submit">
      FILTER
    </button>
  </form>

  <div class="spinner-container" *ngIf="(dataSource.loading$ | async)">
    <mat-spinner [diameter]="'50'"></mat-spinner>
  </div>

  <table mat-table class="data-table mat-elevation-z8" [dataSource]="dataSource" fixedLayout="true">
  
    <ng-container matColumnDef="companyName">
      <th mat-header-cell *matHeaderCellDef>Company</th>
      <td mat-cell *matCellDef="let element"><span class="truncate-text">{{element.companyName}}</span></td>
    </ng-container>

    <ng-container matColumnDef="locationName">
      <th mat-header-cell *matHeaderCellDef>Location</th>
      <td mat-cell *matCellDef="let element"><span class="truncate-text">{{element.locationName}}</span></td>
    </ng-container>

    <ng-container matColumnDef="description">
      <th mat-header-cell *matHeaderCellDef>Description</th>
      <td mat-cell *matCellDef="let element"><span class="truncate-text">{{element.description}}</span></td>
    </ng-container>

    <ng-container matColumnDef="categoryName">
      <th mat-header-cell *matHeaderCellDef>Category</th>
      <td mat-cell *matCellDef="let element"><span class="truncate-text">{{element.categoryName }}</span></td>
    </ng-container>

    <ng-container matColumnDef="serialNum">
      <th mat-header-cell *matHeaderCellDef>Serial #</th>
      <td mat-cell *matCellDef="let element"><span class="truncate-text">{{element.serialNum}}</span></td>
    </ng-container>

    <ng-container matColumnDef="owner">
      <th mat-header-cell *matHeaderCellDef>Owner</th>
      <td mat-cell *matCellDef="let element"><span class="truncate-text">{{element.owner}}</span></td>
    </ng-container>

    <ng-container matColumnDef="batchNum">
      <th mat-header-cell *matHeaderCellDef>Batch Num</th>
      <td mat-cell *matCellDef="let element"><span class="truncate-text">{{element.batchNum }}</span></td>
    </ng-container>

    <ng-container matColumnDef="otherDetails">
      <th mat-header-cell *matHeaderCellDef>Other Details</th>
      <td mat-cell *matCellDef="let element"><span class="truncate-text">{{element.otherDetails}}</span></td>
    </ng-container>

    <ng-container matColumnDef="batchManuf">
      <th mat-header-cell *matHeaderCellDef>Batch Manuf</th>
      <td mat-cell *matCellDef="let element"><span class="truncate-text">{{element.batchManuf }}</span></td>
    </ng-container>

    <ng-container matColumnDef="assemblyDate">
      <th mat-header-cell *matHeaderCellDef>Assembly Date</th>
      <td mat-cell *matCellDef="let element"><span class="truncate-text">{{element.assemblyDate}}</span></td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>

  <mat-paginator [pageSizeOptions]="[10, 25, 50, 100]" [pageSize]='10' [length]="dataSource.total$ | async" showFirstLastButtons></mat-paginator>
</div>