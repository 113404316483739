import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import * as moment from 'moment';

@Component({
  selector: 'app-datepicker',
  templateUrl: './datepicker.component.html',
  styleUrls: ['./datepicker.component.scss']
})
export class DatepickerComponent implements OnInit {
  @Input() startDate;
  @Input() endDate;
  @Input() disabled: boolean = false;

  @Output() onChange = new EventEmitter<any>();

  constructor() { }

  ngOnInit(): void {
  }

  dateChanged($evt) {
    console.log("DATE CHANGED");
  }

  
  todayDate() {
    this.startDate = moment().toDate();
    this.endDate = moment().toDate();

    this.apply();
  }

  lastWeekDate() {
    this.startDate = moment().subtract(1, 'weeks').startOf('isoWeek').toDate();
    this.endDate = moment().subtract(1, 'weeks').startOf('isoWeek').add(7, 'days').toDate();

    this.apply();
  }

  thisMonthDate() {
    this.startDate = moment().startOf('month').toDate();
    this.endDate = moment().endOf('month').toDate();

    this.apply();
  }

  lastMonthDate() {
    this.startDate = moment().startOf('month').add(-1, 'months').toDate();
    this.endDate = moment().endOf('month').add(-1, 'months').toDate();

    this.apply();
  }

  thisYearDate() {
    this.startDate = moment().startOf('year').toDate();
    this.endDate = moment().endOf('year').toDate();

    this.apply();
  }

  lastYearDate() {
    this.startDate = moment().startOf('year').add(-1, 'years').toDate();
    this.endDate = moment().endOf('year').add(-1, 'years').toDate();

    this.apply();
  }

  apply() {
    this.onChange.emit({
      startDate: this.startDate,
      endDate: this.endDate
    });
  }
}
