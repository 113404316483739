import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { HelpCentreComponent } from './pages/help-centre/help-centre.component';
import { LoginComponent } from './pages/login/login.component';
import { AustralianStandardManagementComponent } from './pages/management/australian-standard-management/australian-standard-management.component';
import { BatchManufacturersManagementComponent } from './pages/management/batch-manufacturers-management/batch-manufacturers-management.component';
import { BatchTypesManagementComponent } from './pages/management/batch-types-management/batch-types-management.component';
import { CategoryManageComponent } from './pages/management/category-management/category-manage/category-manage.component';
import { CategoryManagementComponent } from './pages/management/category-management/category-management.component';
import { ChangePasswordComponent } from './pages/management/change-password/change-password.component';
import { CompanyManagementComponent } from './pages/management/company-management/company-management.component';
import { LocationManagementComponent } from './pages/management/company-management/location-management/location-management.component';
import { CompletedJobsComponent } from './pages/management/completed-jobs/completed-jobs.component';
import { DownloadDataComponent } from './pages/management/download-data/download-data.component';
import { EquipmentDetailsComponent } from './pages/management/equipment-management/equipment-details/equipment-details.component';
import { ImportDataComponent } from './pages/management/import-data/import-data.component';
import { JobDetailsComponent } from './pages/management/job-management/job-details/job-details.component';
import { JobManagementComponent } from './pages/management/job-management/job-management.component';
import { PdaUserManagementComponent } from './pages/management/pda-user-management/pda-user-management.component';
import { PendingJobsComponent } from './pages/management/pending-jobs/pending-jobs.component';
import { ViewPendingJobComponent } from './pages/management/pending-jobs/view-pending-job/view-pending-job.component';
import { PreferencesComponent } from './pages/management/preferences/preferences.component';
import { QuestionSetsManagementComponent } from './pages/management/question-sets-management/question-sets-management.component';
import { WebUserManagementComponent } from './pages/management/web-user-management/web-user-management.component';
import { AssetRegisterComponent } from './pages/reports/asset-register/asset-register.component';
import { BatchReportComponent } from './pages/reports/batch-report/batch-report.component';
import { InspectionsComponent } from './pages/reports/inspections/inspections.component';
import { JobReportComponent } from './pages/reports/job-report/job-report.component';
import { RangerRegisterComponent } from './pages/reports/ranger-register/ranger-register.component';
import { AdminGuard } from './services/guards/admin.guard';
import { MasterAdminGuard } from './services/guards/master-admin.guard';
import { InspectorGuard } from './services/guards/inspector.guard';
import { RoleGuard } from './services/role.guard';

const routes: Routes = [
  { 
    path: '',
    redirectTo: 'dashboard',
    pathMatch: 'full'
  },
  { path: 'login', component: LoginComponent },
  { path: 'login/:token', component: LoginComponent },
  { path: 'dashboard', component: DashboardComponent, canActivate: [RoleGuard] },
  { path: 'help-centre', component: HelpCentreComponent, canActivate: [RoleGuard] },
  { path: 'administration', canActivate: [RoleGuard], children: [
    { path: 'change-password', component: ChangePasswordComponent },

    { path: 'companies', component: CompanyManagementComponent, canActivate: [AdminGuard] },
    { path: 'companies/locations/:companyId', component: LocationManagementComponent, canActivate: [AdminGuard] },

    { path: 'job-management', component: JobManagementComponent, canActivate: [InspectorGuard] },
    { path: 'job-management/details/:locationInspectionId', component: JobDetailsComponent, canActivate: [InspectorGuard] },
    { path: 'jobs-pending', component: PendingJobsComponent, canActivate: [InspectorGuard] },
    { path: 'jobs-pending/details/:locationInspectionId', component: ViewPendingJobComponent, canActivate: [InspectorGuard] },

    { path: 'equipment/details/:id', component: EquipmentDetailsComponent, canActivate: [RoleGuard] },

    { path: 'aus-std-management', component: AustralianStandardManagementComponent, canActivate: [MasterAdminGuard] },
    { path: 'batch-manuf-management', component: BatchManufacturersManagementComponent, canActivate: [MasterAdminGuard] },
    { path: 'batch-types-management', component: BatchTypesManagementComponent, canActivate: [MasterAdminGuard] },
    { path: 'category-management', component: CategoryManagementComponent, canActivate: [MasterAdminGuard]},
    { path: 'question-sets-management', component: QuestionSetsManagementComponent, canActivate: [MasterAdminGuard] },
    { path: 'web-users', component: WebUserManagementComponent, canActivate: [AdminGuard] },
    { path: 'pda-users', component: PdaUserManagementComponent, canActivate: [MasterAdminGuard] },
    { path: 'preferences', component: PreferencesComponent, canActivate: [MasterAdminGuard] },
    { path: 'download-data', component: DownloadDataComponent, canActivate: [AdminGuard] },
    { path: 'import-data', component: ImportDataComponent, canActivate: [MasterAdminGuard] },
    { path: 'completed-jobs', component: CompletedJobsComponent, canActivate: [AdminGuard] },
  ]},

  { path: 'reports', canActivate: [RoleGuard], children: [
    { path: 'ranger-register', component: RangerRegisterComponent },
    { path: 'asset-register', component: AssetRegisterComponent },
    { path: 'insp-report', component: InspectionsComponent },
    { path: 'job-report', component: JobReportComponent },
    { path: 'batch-report', component: BatchReportComponent },
  ]}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
