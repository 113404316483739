import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SnotifyService } from 'ng-snotify';
import { ImageService } from 'src/app/services/image.service';
import { EquipmentService } from '../../services/administration/equipment.service';
import { AuthService } from '../../services/auth.service';

@Component({
  selector: 'app-document-upload',
  templateUrl: './supporting-document-upload.component.html',
  styleUrls: ['./supporting-document-upload.component.scss']
})
export class DocumentUploadComponent implements OnInit {

  images = [];
  fileBase64;
  fileName;
  notes;
  currentUser;
  constructor(private snotifyService: SnotifyService, private imageService: ImageService, private authService: AuthService, private equipmentService: EquipmentService,
    public dialogRef: MatDialogRef<DocumentUploadComponent>, @Inject(MAT_DIALOG_DATA) public data: { equipmentId: number }) {
      authService.currentUser.subscribe((user) => {
        this.currentUser = user;
      }) }

  ngOnInit(): void {
  }


  async inputChange(fileInputEvent: any) {

    const file = fileInputEvent.target.files[0];
    var base64 = await toBase64(file);
    this.fileBase64 = base64.toString().split(",")[1];
    this.fileName = file.name;
  }
  async cancel() {
    this.dialogRef.close(true);
  }

  async submit() {
    var dto = {
      equipmentId: this.data.equipmentId,
      fileName: this.fileName,
      fileBase64: this.fileBase64,
      userId: this.currentUser.userId,
      notes: this.notes
    }

    this.equipmentService.uploadSupportingDocument(dto).then(() => {
      this.dialogRef.close(true);
    });

  }
}

const toBase64 = file => new Promise((resolve, reject) => {
  const reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = () => resolve(reader.result);
  reader.onerror = error => reject(error);
});
